import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalVars } from '../../../providers/globalVars';
import { Imgupload } from '../../../providers/imgupload';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase/app';
import { ActionSheetController, AlertController,ModalController,NavController,ToastController,IonRouterOutlet } from '@ionic/angular';
import { SelectimgPage } from '../../support/selectimg/selectimg.page';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-chadd',
  templateUrl: './chadd.page.html',
  styleUrls: ['./chadd.page.scss'],
})
export class ChaddPage implements OnInit {
  //bgimage:any = 'assets/addimg.png';
  title:any;
  description:any;
  password:any = '';
  images:any[] = [];
  imagethumbs:any[] = [];
  category:any[];
  messageForm : FormGroup;
  addcommunitytitle: any;
  publiccommunity: any;

  initcategory:any[] = [];

  bgimage:any = '';
  ch:any;
  chkey;
  modal;
  cangoback;

  News:any = '';
  Shop:any = '';
  Group:any = '';
  Living:any = '';
  Others:any = '';

  selectedLoc:any;
  selectedCate:any;

  choptions:any;

  constructor(public fb: FormBuilder, public gv: GlobalVars,public translate: TranslateService, public afAuth: AngularFireAuth,
    public alertCtrl: AlertController, public actionsheetCtrl: ActionSheetController, public modalCtrl:ModalController, public navCtrl:NavController, private route: ActivatedRoute
    ,@Optional() private outlet: IonRouterOutlet,private imgupload: Imgupload, public afDB: AngularFireDatabase, public toastCtrl:ToastController) {
      
      this.translate.get('Open new Channel').subscribe(res=>{
      this.addcommunitytitle = res;}); 
      this.translate.get('Basic board').subscribe(res=>{
      this.category = [{type:'normal', name:res, orderrate:0}];});
      

      if(this.route.snapshot.paramMap.get('ch'))
        this.ch = this.route.snapshot.paramMap.get('ch');

      this.translate.get("News").subscribe(res=>{this.News=res});
      this.translate.get('Shop').subscribe(res=>{this.Shop=res});
      this.translate.get('Group').subscribe(res=>{this.Group=res});
      this.translate.get('Living').subscribe(res=>{this.Living=res});
      this.translate.get('Others').subscribe(res=>{this.Others=res});
   }

  ngOnInit() {
    if(`${this.chkey}` != 'undefined')
      this.ch = `${this.chkey}`;
    if(this.ch)
    {
    this.setdata();
    let chedit;
    this.translate.get('Edit Channel').subscribe(res=>{chedit=res});
    this.addcommunitytitle = chedit;
    }
    this.cangoback = this.outlet && this.outlet.canGoBack();
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
    this.afDB.database.ref('/systemlog/' + (firebase.auth().currentUser.uid?firebase.auth().currentUser.uid:'unregister') + '/event/' + todaystr + '/chenter').transaction(view=>{
        return (view || 0) + 1;
    })

    if(this.gv.loc && !this.ch)
      this.selectedLoc = this.gv.loc;
  }

  setdata(){
    this.afDB.database.ref('/category/' + this.ch).once('value').then(rst=>{
      let data = rst.val();
      if(data)
      {
        this.title = data.name;
        this.selectedLoc = data.loc;
        this.description = data.description;
        this.images = data.images?data.images:[];
        this.imagethumbs = data.imagethumb?data.imagethumb:[];
        this.bgimage = data.bgimgurl;
        this.category = data.category?data.category:[];
        this.selectedCate = data.typemain;
        this.choptions = data.options;
        this.category.map(cat=>{
          this.initcategory.push(cat);
        })
      }
    })
  }

  toggleanonymouscmt(board){
    board.anonymouscmt = !board.anonymouscmt;
    if( board.anonymouscmt )
      this.presentToast('익명 댓글 기능 활성화.');
    else 
      this.presentToast('익명 댓글 기능 비활성화.');
  }
  toggleprivatecmt(board){
    board.privatecmt = !board.privatecmt;
    if( board.privatecmt )
      this.presentToast('비공개 댓글 기능 활성화.');
    else 
      this.presentToast('비공개 댓글 기능 비활성화.');
  }

  close(){
    if(`${this.modal}` != 'undefined') 
      this.modalCtrl.dismiss();
    if(this.cangoback)
      {this.navCtrl.pop();}
    else
      this.navCtrl.navigateBack('/');
    /*
    this.modalCtrl.dismiss().catch(_=>
      this.navCtrl.back()
    )*/
  }

  async bgsampleshow(){
    const modal = await this.modalCtrl.create({
      component: SelectimgPage,
      cssClass: 'ph-custom-modal'
    });
    modal.onDidDismiss().then(rst=>{
      if(rst.data)
      this.bgimage = rst.data.bgimage;
    })
    return await modal.present();
  }

  reorder(ev){
  ev.detail.complete(this.category);
  //console.log(this.category);
  }

  deletecat(o, item) {
  this.category = this.category.filter( v=>v!=item);
  }

  async htmlupload(fileinput:HTMLInputElement) { 
      if(this.images.length > 0 && this.imagethumbs.length > 0)
        this.imgupload.delete(this.images[0].path, this.imagethumbs[0].path);
      this.images = [];
      this.imagethumbs = [];
      var filelength = fileinput.files.length;
      for(var i = 0; i < filelength; i++)
      {
      var file:File = fileinput.files[i];
      await this.imgupload.readfile(file).then(rst=>
      {
      this.imgupload.imgupload(file, 'category')
      .then(imgrst=>{
        this.images.push({path:imgrst[0]['path'], url:imgrst[0]['url']});
        this.imagethumbs.push({path:imgrst[1]['path'], url:imgrst[1]['url']});
      })
      })
      }  
  }

  /*addchannel(){
    console.log(this.category);
    console.log(this.initcategory);
  }*/
    addchannel(){
      if( this.nonameCategory() ) {
        this.presentToast('이름이 없는 게시판이 있어요!');
        return false;
      }
      if(!this.ch)
        {
        let uid = firebase.auth().currentUser.uid
        let loclan = this.gv.getLoc() + '_' + this.gv.getLan();
        this.afDB.database.ref('/category').push({
          userId: uid,
          name:this.title,
          publiccommunity: true, //this.publiccommunity == '공개 채널',
          password:this.password?this.password:null,
          groupid: loclan + "_community",
          type:'community',
          typemain: this.selectedCate,
          images:this.images,
          imagethumb:this.imagethumbs,
          description:this.description,
          //memberrole:this.memberrole,
          category:this.category,
          bgimgurl:this.bgimage?this.bgimage:'',
          loc: this.selectedLoc,
          location:this.gv.loc,
          point:0,
          createdDate: firebase.database.ServerValue.TIMESTAMP,
          chinfo:{liked:0,post:0,visit:0}
        }).then( added => {
          this.afDB.database.ref('/userData/' + uid + '/favlist').push(
          {chid:added.key, title:this.title, isChecked:false, alarm:true})
          
          /*for(let i = 0; i < this.category.length; i++)
            this.afDB.database.ref('/category/'+ added.key + '/category').push(this.category[i])
            // 내 즐겨찾기에 추가 (만약 즐겨찾기 목록이 전혀 없다면, 디폴트값 즐겨찾기 목록 셋팅후 내 즐겨찾기 추가) 
            this.afDB.database.ref('/userData/' + uid + '/favlist').once('value').then(rst=>{
            if(rst.val())
            {
              this.afDB.database.ref('/userData/' + uid + '/favlist').push(
              {chid:added.key, title:this.title, isChecked:false, alarm:true})
            }
            else
            {
              this.afDB.database.ref('/app/defaulttab/' + loclan).once('value').then(favlist => {
              var tempfavlist = [];
              favlist.forEach(fav => {
                let f = fav.val();
                if(!f.default)
                {
                  let chid;
                  if(f.val.indexOf('chmain') == 0)
                    chid = f.val.substr(8).replace('/t','');
                  else
                    chid = f.val;

                tempfavlist.push({
                  chid:chid,
                  title:f.name,
                  isChecked:true
                })
                }
              })
              tempfavlist.push({chid:added.key, isChecked:false})
              this.afDB.database.ref('/userData/' + uid + '/favlist').set(tempfavlist);
            })
            }

            }).then(_=>{*/
              this.translate.get('Channel registered. Drag down to refresh Channel list.').subscribe(res=>{
              this.presentToast(res);});

              let today = new Date();
              let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
              this.afDB.database.ref('/systemlog/' + (uid?uid:'unregister') + '/event/' + todaystr + '/chopen').transaction(view=>{
                  return (view || 0) + 1;
              })
              this.close();
            })
        }
      else{
        this.afDB.database.ref('/category/' + this.ch).update({
          name:this.title,
          //publiccommunity: true, //this.publiccommunity == '공개 채널',
          //password:this.password?this.password:null,
          //groupid: this.gv.getLoc() + '_' + this.gv.getLan() + "_community",
          //type:'community',
          images:this.images,
          imagethumb:this.imagethumbs,
          description:this.description,
          //memberrole:this.memberrole,
          category:this.category,
          bgimgurl:this.bgimage?this.bgimage:'',
          typemain: this.selectedCate,
          loc: this.selectedLoc,
          location: this.selectedLoc
          //location:'Cairns',
          }).then(_ => {
          /*for(let i = 0; i < this.category.length; i++)
            {
            this.afDB.database.ref('/category/'+ this.ch + '/category').update(this.category[i])
            }*/
          this.translate.get('Updated').subscribe(res=>{
          this.presentToast(res);});
          let today = new Date();
          let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
          this.afDB.database.ref('/systemlog/' + (firebase.auth().currentUser.uid?firebase.auth().currentUser.uid:'unregister') + '/event/' + todaystr + '/chedit').transaction(view=>{
              return (view || 0) + 1;
          })
          this.close();
        }, error => {
          console.log(error);
        });
      }
    }

    async showlocations() {
      let btn;
      let allaus,cairns,brisbane,sydney,melbourne,perth,displayloc;
      this.translate.get("all-aus").subscribe(res=>{allaus=res});
      this.translate.get('cairns').subscribe(res=>{cairns=res});
      this.translate.get('brisbane').subscribe(res=>{brisbane=res});
      this.translate.get('sydney').subscribe(res=>{sydney=res});
      this.translate.get('melbourne').subscribe(res=>{melbourne=res});
      this.translate.get('perth').subscribe(res=>{perth=res});
      this.translate.get('Display location').subscribe(res=>{displayloc=res});

      if(this.gv.admin)
      {
        btn =
        [{
            text: allaus,
            handler: ()=> {
              this.selectedLoc = 'all-aus'
            }
          },
          {
            text: cairns,
            handler: ()=> {
              this.selectedLoc = 'cairns'
            }
          },
          {
            text: brisbane,
            handler: ()=> {
              this.selectedLoc = 'brisbane'
            }
          },
          {
            text: sydney,
            handler: ()=> {
              this.selectedLoc = 'sydney'
            }
          },
          {
            text: melbourne,
            handler: ()=> {
              this.selectedLoc = 'melbourne'
            }
          },
          {
            text: perth,
            handler: ()=> {
              this.selectedLoc = 'perth'
            }
          }]
      }
      else
      {
        btn =
        [{
            text: allaus,
            handler: ()=> {
              this.selectedLoc = 'all-aus'
            }
          },
          {
            text: cairns,
            handler: ()=> {
              this.selectedLoc = 'cairns'
            }
          },
          {
            text: brisbane,
            handler: ()=> {
              this.selectedLoc = 'brisbane'
            }
          
          }]
      }
      const actionsheet = await this.actionsheetCtrl.create({
        header: displayloc,
        buttons: btn
      });
      return await actionsheet.present();
    }

    async showcategories() {
      let btn;
      let Chtype;
      this.translate.get('Type of Channel').subscribe(res=>{Chtype=res});
      if(this.gv.admin){
        btn =[
          {
            text: this.News,
            handler: ()=> {
              this.selectedCate = this.News
            }
          },
          {
            text: this.Shop,
            handler: ()=> {
              this.selectedCate = this.Shop
            }
          },
          {
            text: this.Group,
            handler: ()=> {
              this.selectedCate = this.Group
            }
          },
          {
            text: this.Living,
            handler: ()=> {
              this.selectedCate = this.Living
            }
          },
          {
            text: this.Others,
            handler: ()=> {
              this.selectedCate = this.Others
            }
          }]
      }
      const actionsheet = await this.actionsheetCtrl.create({
        header: Chtype,
        buttons: btn
      });
      return await actionsheet.present();
    }

    presentAlertBoard(){
      // this.category.push({type:'normal', name:'', orderrate:0});
      this.translate.get('Basic board').subscribe(res=>{
        this.category.push({type:'normal', name:res, orderrate:0});
      });
    }


    /*
    async presentAlertType() {
    const alert = await this.alertCtrl.create({
      mode:'ios',
      cssClass:'chalertslt',
      inputs: [
        {
          name: 'public',
          type: 'radio',
          label: '공개 채널\n누구나 입장 가능',
          value: 'public',
          checked: this.publiccommunity != '비공개 채널'
        },
        {
          name: 'private',
          type: 'radio',
          label: '비공개 채널\n비밀번호 입력 후 입장 가능',
          value: 'private',
          checked: this.publiccommunity == '비공개 채널'
        }
      ],
      buttons: [
         {
          text: '확인',
          handler: (data) => {
            if(data == 'public')
              this.publiccommunity = '공개 채널';
            else if(data == 'private')
              this.publiccommunity = '비공개 채널';
          }
        }
      ]
    });

    await alert.present();
  }*/

  /*
   async presentAlertBoardOri() {
    const alert = await this.alertCtrl.create({
      mode:'ios',
      cssClass:'chalertslt',
      inputs: [
        {
          name: 'normal',
          type: 'radio',
          label: '일반게시판',
          value: 'normal',
          checked: true
        },
        {
          name: 'notice',
          type: 'radio',
          label: '공지',
          value: 'notice'
        },
        {
          name: 'book',
          type: 'radio',
          label: '예약',
          value: 'book',
        }
      ],
      buttons: [
         {
          text: '확인',
          handler: (data) => {
            if(data == 'normal')
            {
              this.category.push({type:data, name:'', orderrate:0});
            }
            else if(data == 'notice')
            {
              this.category.push({type:data, name:'공지', orderrate:0});
            }
            else if(data == 'book')
            {
              this.category.push({type:data, name:'예약', orderrate:0});
            } 
          }
        }
      ]
    });

    await alert.present();
  }*/

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
    message: message,
    position: 'bottom',
    duration: 2200
    });
    toast.present();
    }


  nonameCategory() {
    for(let i=0; i<this.category.length; i++) {
      if( !this.category[i].name ) return true;
    }
  }

  funcPreparing(item?){
    switch(item) {
      case 'permission': this.presentToast('작성 권한 설정 준비중 입니다.'); break;
      case 'condition': this.presentToast('작성 조건 설정 준비중 입니다.'); break;
      case 'pointmall': this.presentToast('포인트몰에서 구매 가능 합니다.'); break;
      default: this.presentToast('서비스 준비중 입니다.'); break;
    }
    
  }

}
