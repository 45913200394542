import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {GlobalVars} from '../../../providers/globalVars';
import {ModalController} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-selectchitem',
  templateUrl: './selectchitem.page.html',
  styleUrls: ['./selectchitem.page.scss'],
})
export class SelectchitemPage implements OnInit {
  actlist: any[] = [];
  recentshowlist: any[] = [];
  recentlist: any[] = [];
  datenow: any = new Date().getTime();
  templist: any[] = [];
  uid: any;
  msgshowcount: any = 15;
  recentshowcount: any = 15;
  lastdate: any = null;
  type;
  listtype: any;
  dbname: any;
  selectedcat: any = '작성 한 글';
  editable: boolean = false;
  loadingdone:boolean = false;
  constructor( private gv: GlobalVars, 
    public afAuth: AngularFireAuth,private afDB: AngularFireDatabase,public modalCtrl:ModalController) { }

  ngOnInit() {
    this.recentlist = this.gv.getrecent();
    if(this.gv.uid)
      this.uid = this.gv.uid;
    this.getdata();
    
  }

  getdata(infi?){
    if(this.selectedcat == '최근 본 글'){
      let recentlist = this.recentlist.slice(this.recentshowcount - 15, this.recentshowcount);
      recentlist.map(data => {
        var listdir = this.gv.getListDir(data.loclancode);
        this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
          data.data = val.val()
          if (val.val())
            this.recentshowlist.push(data);
           /* data.data = {
              title: '작성자에 의해 삭제된 글입니다.',
              deleted: true
            };*/
        });
        
      })
      this.recentshowcount = this.recentshowcount + 15;
      this.loadingdone = true;
      if (infi)
        infi.target.complete();
    }
    else{
      if(!infi)
      {
        let recentlist = this.recentlist.slice(this.recentshowcount - 15, this.recentshowcount);
        recentlist.map(data => {
          var listdir = this.gv.getListDir(data.loclancode);
          this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
            data.data = val.val()
            if (val.val())
              this.recentshowlist.push(data);
            /*data.data = {
                title: '작성자에 의해 삭제된 글입니다.',
                deleted: true
              };*/
          });
        })
      }
      this.afDB.database.ref('/userProfile/' + this.uid + '/write')
        .orderByChild('createdDate').endAt(this.lastdate ? this.lastdate - 1 : 9999999999999)
        .limitToLast(this.msgshowcount).once('value').then(rst => {
          if (rst.val() && rst.val()[Object.keys(rst.val())[0]].createdDate)
            this.lastdate = rst.val()[Object.keys(rst.val())[0]].createdDate;

          this.templist = [];
          rst.forEach(item => {
            //console.log(this.templist);
            this.templist.unshift({
              loclancode: item.val().loclancode ? item.val().loclancode : 'cairns_kr',
              commentid: item.val().commentid ? item.val().commentid : null,
              itemid: item.val().itemid,
              createdDate: item.val().createdDate
            })
          })
        }).then(_ => {
          this.templist.map(data => {
            var listdir = this.gv.getListDir(data.loclancode);
            this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
              data.data = val.val()
              if (val.val())
                this.actlist.push(data);
              /*
                data.data = {
                  title: '작성자에 의해 삭제된 글입니다.',
                  deleted: true
                };*/
            });
            
          })
        }).then(_ => {
          this.loadingdone = true;
          if (infi)
            infi.target.complete();
        })
    }
  }

  confirm(item){
    let img, it;
    if(item.data.imagethumb){
      img = item.data.images[0]['url']?item.data.images[0]['url']:'';
      it = item.data.imagethumb[0]['url']?item.data.imagethumb[0]['url']:'';
    }
    this.modalCtrl.dismiss({
      itemid:item.itemid,
      img:img,
      imgthumb:it,
      itemtitle:item.data.title 
    })
  }

  doRefreshMsg(infiniteScroll) {
    this.getdata(infiniteScroll);
  }


}
