import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase} from '@angular/fire/database';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVars } from '../../../providers/globalVars';
import { AngularFireAuth } from '@angular/fire/auth';  
import { NavController, ModalController } from '@ionic/angular';
import { ChmainPage } from '../../../pages/general/chmain/chmain.page';
import { ChviewPage } from '../../../pages/general/chview/chview.page';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-favchlist',
  templateUrl: './favchlist.page.html',
  styleUrls: ['./favchlist.page.scss'],
})
export class FavchlistPage implements OnInit {
  favlist: any[] = [];
  chrecent:any[] = [];
  chtemp:any[] = [];
  uid:any = '';
  loaditemcnt:any=10;
  timenow = new Date().getTime();
  constructor(private afDB: AngularFireDatabase, public afAuth: AngularFireAuth, private gv:GlobalVars,
    private modalCtrl: ModalController,
    public navCtrl: NavController) { }

  ngOnInit() {
      this.uid = this.gv.uid;
      this.getdata();
  }
  getdata(){
        this.afDB.list('/userData/' + this.uid + '/favlist').snapshotChanges().pipe(map( (arr) => { return arr } )).pipe(map((data) => { 
        return data.map((data) => {
        this.afDB.database.ref('/category/' + data.payload.val()['chid']).once('value').then(favchitem=>{
          let favch = favchitem.val();
          var lastupdate;
          if(favch.recent)
            lastupdate = favch.recent[Object.keys(favch.recent)[Object.keys(favch.recent).length-1]].createdDate;
          else
            lastupdate = 0;
          var point;
          if(favch.publiccommunity)
            point = favch.point;
          else
            point = favch.point - 1000;
          if(data.payload.val()['isMine'])
            this.favlist.unshift({key:favchitem.key, isMine:data.payload.val()['isMine']?1:0, typemain:favch.typemain, imagethumb:favch.imagethumb, name:favch.name, replycount:favch.replycount, 
            read:favch.read, createdDate:favch.createdDate, type:favch.type, userId:favch.userId, gorupid:favch.groupid,
              point:point, description:favch.description, publiccommunity:favch.publiccommunity, lastupdate:lastupdate,// new:newupdate,
              recenttitle:this.afDB.list('/category/' + favchitem.key + '/recent')
            });
          else
          this.favlist.push({key:favchitem.key, isMine:data.payload.val()['isMine']?1:0, typemain:favch.typemain, imagethumb:favch.imagethumb, name:favch.name, replycount:favch.replycount, 
            read:favch.read, createdDate:favch.createdDate, type:favch.type, userId:favch.userId, gorupid:favch.groupid,
              point:point, description:favch.description, publiccommunity:favch.publiccommunity, lastupdate:lastupdate,// new:newupdate,
              recenttitle:this.afDB.list('/category/' + favchitem.key + '/recent')
            });
        })
        return data
        })
        }) )
      
        .subscribe()
  } 
  
  async openchannel(chkey){
    const modal = await this.modalCtrl.create({
      component: ChmainPage,
      componentProps:<null>{chkey:chkey},
      cssClass: 'modalfull'
    });
    return await modal.present();
    }

    async showpage(id, loclancode){
    if(loclancode)
    loclancode = loclancode.replace('_community','');
    const modal = await this.modalCtrl.create({
      component: ChviewPage,
      componentProps:<null>{id:id,ignore:true,loclancode:loclancode},
      cssClass: 'modalfull'
    });
    return await modal.present();
    }

  doInfinite(infiniteScroll) {
    this.loaditemcnt = this.loaditemcnt+10;
    infiniteScroll.target.complete();
    }
}
