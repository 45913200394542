import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-pointinfo',
  templateUrl: './pointinfo.page.html',
  styleUrls: ['./pointinfo.page.scss'],
})
export class PointinfoPage implements OnInit {
  constructor(public navCtrl: NavController) { }

  ngOnInit() {
  }
}
