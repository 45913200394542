import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { Imgupload } from '../../../providers/imgupload';
import { ChaddPage } from './chadd.page';

const routes: Routes = [
  {
    path: '',
    component: ChaddPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),ReactiveFormsModule,
    TranslateModule
  ],
  providers: [Imgupload,TranslatePipe],
  declarations: [ChaddPage]
})
export class ChaddPageModule {}
