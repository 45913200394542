import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'LinkImgFullPipe'
})
@Injectable()
export class LinkImgFullPipe  implements PipeTransform {
 transform(item: string): any {
   if (!item || item.length == 0) return [];
   var indexes = [], i = -1;
   while ((i = item.indexOf('<img ', i+1)) != -1){
        let str = item.substring(i, item.indexOf('">', i))
        if(str.indexOf('class="skip') == -1)
            item = item.slice(0,i+5) + "tappable onclick='dummysrc.value = this.src; showbig.click()' " + item.slice(i+5)
    }
   return item;
}
}