import { Component, OnInit } from '@angular/core';
import { PopoverController,NavParams } from '@ionic/angular';
import { GlobalVars } from '../../providers/globalVars';
@Component({
  selector: 'app-chmenu',
  templateUrl: './chmenu.component.html',
  styleUrls: ['./chmenu.component.scss']
})
export class ChmenuComponent implements OnInit {
  chid:any;
  id:any;
  showshare
  owner;
  constructor(public popover:PopoverController, private navParams:NavParams, public gv:GlobalVars) { }

  ngOnInit() {
    //if(this.navParams.get('admin'))
    //  this.admin = this.navParams.get('admin');
    if(this.navParams.get('chid'))
      this.id = this.navParams.get('chid');
  }
  submit(val){
  this.popover.dismiss({value:val});
  }
  async copyToClipboard(){
  let str = 'https://channel.cafe/chmain/' + this.id;
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  }
}
