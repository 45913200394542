import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
//import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { TypeKrNamePipeModule } from '../../../pipe/tkrnamepipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SelectchitemPage } from './selectchitem.page';

const routes: Routes = [
  {
    path: '',
    component: SelectchitemPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,TypeKrNamePipeModule,TimeAgoPipeModule,TranslateModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [SelectchitemPage]
})
export class SelectchitemPageModule {}
