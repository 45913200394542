import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { OrderByPipeModule } from '../../../../pipe/orderbypipe.module';
import { TypeKrNamePipeModule } from '../../../../pipe/tkrnamepipe.module';
import { TimeAgoPipeModule } from '../../../../pipe/time-ago-pipe.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CategoryPipeModule } from '../../../../pipe/categorypipe.module';
import { IonicModule } from '@ionic/angular';

import { InapplinkPage } from './inapplink.page';

const routes: Routes = [
  {
    path: '',
    component: InapplinkPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,TypeKrNamePipeModule,TimeAgoPipeModule,TranslateModule,CategoryPipeModule,OrderByPipeModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [InapplinkPage]
})
export class InapplinkPageModule {}
