import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ruleprivacy',
  templateUrl: './ruleprivacy.page.html',
  styleUrls: ['./ruleprivacy.page.scss'],
})
export class RuleprivacyPage implements OnInit {

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
  }

}
