import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SearchResultCount'
})
@Injectable()

export class SearchResultCount  implements PipeTransform {
 transform(items: any[], field: string, value: string, loc?:string): any[] {
   if (!items) return [];
   if (!value || value.trim() == '') return [];
   let result = [];
   items.forEach(it=>{
      var str = "";
      var order = 0;
      for(var i=0; i < field.split(',').length; i++)
        str += (it[field.split(',')[i]]);
      if (it['region'] == loc)
        order = 30;
      var matchcount = ((""+str).toLowerCase().replace(/ /g, "").match(new RegExp(value.toLowerCase().replace(/ /g, ""), "g")) || []).length;
      if(matchcount)
        {it['order'] = order+matchcount;
        result.push(it);}
   })
   return result;
 }
}