import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

import { WorktoolPage } from './worktool.page';
import { EnterPipeModule } from '../../../../pipe/enterpipe.module';
import { TimeAgoPipeModule } from '../../../../pipe/time-ago-pipe.module';
import { OrderByPipe } from '../../../../pipe/orderbypipe';
import { OrderByPipeModule } from '../../../../pipe/orderbypipe.module';
import { FullCalendarModule } from '@fullcalendar/angular';
//import { CalendarModule } from "ap-angular-fullcalendar";
import { BlockchkPipeModule } from '../../../../pipe/blockchkpipe.module';
import { MatchPipeModule } from '../../../../pipe/matchpipe.module';

import { AddworktoolPageModule } from '../addworktool/addworktool.module';
import { AddworkplacePageModule } from '../addworkplace/addworkplace.module';

const routes: Routes = [
  {
    path: '',
    component: WorktoolPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,EnterPipeModule,BlockchkPipeModule,MatchPipeModule,TranslateModule,
    TimeAgoPipeModule,OrderByPipeModule,FullCalendarModule,AddworktoolPageModule,AddworkplacePageModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [WorktoolPage]
})
export class WorktoolPageModule {}
