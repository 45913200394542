import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SearchPage } from './search.page';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SearchResultPipeModule } from '../../../pipe/searchresultpipe.module';
import { SearchResultCountModule } from '../../../pipe/searchresultwithcount.module';
import { RemovehtmltagPipeModule } from '../../../pipe/removehtmltag.module';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { TypeKrNamePipeModule } from '../../../pipe/tkrnamepipe.module';
import { SafeHtmlPipeModule } from '../../../pipe/safehtmlpipe.module';
import { EnterPipeModule } from '../../../pipe/enterpipe.module';

import { Keyboard } from '@ionic-native/keyboard/ngx';
const routes: Routes = [
  {
    path: '',
    component: SearchPage
  }
];

@NgModule({
  imports: [
    CommonModule,TranslateModule,SearchResultPipeModule,RemovehtmltagPipeModule,OrderByPipeModule,TypeKrNamePipeModule,
    FormsModule,TimeAgoPipeModule,SafeHtmlPipeModule,EnterPipeModule,SearchResultCountModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe, Keyboard],
  declarations: [SearchPage]
})
export class SearchPageModule {}
