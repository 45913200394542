import { NgModule, Injectable } from '@angular/core';
import { EnterPipe } from './enterpipe';

@Injectable()
@NgModule({
  declarations: [
    EnterPipe,
  ],
  imports: [
    
  ],
  exports: [
    EnterPipe
  ]
})
export class EnterPipeModule {}
