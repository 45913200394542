import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'LinkWebPipe'
})
@Injectable()
export class LinkWebPipe  implements PipeTransform {
 transform(item: string): any {
   if (!item || item.length == 0) return [];
   item = item.replace(/ www./ig, ' https://www.').replace(/title="https:\/\//ig, 'title="').replace(/title="http:\/\//ig, 'title="');
   var urlRegex = /(?![^<]*>)['|"|`|#]?(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
   var contactRegex = /(?![^<]*>)(?:\+?(\d\d))?[- ]?[- ]?(?:\((?=.*\)))?(0?[\d])\)?\d?[- ]?(\d\d[- ]?\d\d?\d[- ]?\d[- ]?\d?\d{3}) ?($|(?=\\|<))?(?!\B)/igm;
   ///(?:\+?(dd))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d[- ]?\d\d[- ]?\d[- ]?\d{3}) ?($|(?=\\|<))/igm;  
   
   item = item.replace(urlRegex,function(url) {
       /*if(url.toLowerCase().includes('.jpg') || url.toLowerCase().includes('.jpeg') || url.toLowerCase().includes('.bmp') || url.toLowerCase().includes('.png') || url.toLowerCase().includes('.gif') || url.toLowerCase().includes('.svg'))
        return url;
else*/ if(url.substr(0,1) == "\"" || url.substr(0,1) == "'" || url.substr(0,1) == "`")
        return url;
       else if(url.substr(0,1) == '#')
        return url.substr(1);
       else if(url.indexOf('kakao.com') > -1)      
        return '<a tappable onclick=\'window.open("' + url + '", "_system", "location=no")\'>' + url + '</a>';
       else
        return '<a tappable onclick=\'window.open("' + url + '", "_blank", "location=no,EnableViewPortScale=yes")\'>' + url + '</a>';
    });
    return item
    .replace(contactRegex,function(url) {
       return '<a tappable onclick=\'telfill.innerText=\"' + url + '\"; linktel.click();\'>' + url + '</a>';
   })
}
}