import { NgModule } from '@angular/core';
import { TypeKrNamePipe } from './tkrnamepipe';

@NgModule({
  declarations: [
    TypeKrNamePipe,
  ],
  imports: [
    
  ],
  exports: [
    TypeKrNamePipe
  ]
})
export class TypeKrNamePipeModule {}
