import { NgModule, Injectable } from '@angular/core';
import { LinkWebPipe } from './linkwebpipe';

@Injectable()
@NgModule({
  declarations: [
    LinkWebPipe,
  ],
  imports: [
    
  ],
  exports: [
    LinkWebPipe
  ]
})
export class LinkWebPipeModule {}
