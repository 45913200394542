import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-levelupmodal',
  templateUrl: './levelupmodal.component.html',
  styleUrls: ['./levelupmodal.component.scss']
})
export class LevelupmodalComponent implements OnInit {
  level;
  nick;
  img;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }
closePopup(){this.modalCtrl.dismiss()}
}
