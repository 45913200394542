import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { ChviewPage } from '../../../pages/general/chview/chview.page';
import { map } from 'rxjs/operators';
import { GlobalVars } from '../../../providers/globalVars';
@Component({
  selector: 'app-notice',
  templateUrl: './notice.page.html',
  styleUrls: ['./notice.page.scss'],
})
export class NoticePage implements OnInit {

  constructor(public modalCtrl: ModalController,private gv:GlobalVars,public afDB:AngularFireDatabase) { }
  noticelist:any=[];
  templist:any[]=[];
  msgshowcount:any = 10;
  editlist:any;
  lastdate:any=null;
  ngOnInit() {
    
    this.getdata();
  }

  getdata(){
    this.afDB.database.ref(this.gv.getListDir())
    .orderByChild('categoryId').equalTo('-L56EH_e8EibkS4M_0oq')//.endAt(this.lastdate?this.lastdate-1:9999999999999)
    .once('value').then(msgs=>{
         msgs.forEach(msg => {
          if(msg.val().category == '공지사항')
          {
          var data = msg.val();
          data.key = msg.key;
          data.timediff = (new Date().getTime() - data.createdDate)/1000/60/60/24;
          this.noticelist.unshift(data); }
        });
        })
  }
    async showpage(id, loclancode){
    const modal = await this.modalCtrl.create({
      component: ChviewPage,
      componentProps:<null>{id:id,loclancode:loclancode},
      cssClass: 'modalfull'
    });
    return await modal.present();
    }
    doRefreshMsg(infiniteScroll){
    this.msgshowcount = this.msgshowcount+10;
    infiniteScroll.target.complete();
    }

}
