import { NgModule,Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { Imgupload } from '../../../providers/imgupload';
import { IonicModule } from '@ionic/angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PostPage } from './post.page';
import { HttpModule} from '@angular/http';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import {AutosizeModule} from 'ngx-autosize';
//import { DoublecatmodalPageModule } from '../../support/doublecatmodal/doublecatmodal.module';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
//import { BasicpopupPageModule } from './basicpopup/basicpopup.module';
const routes: Routes = [
  {
    path: '',
    component: PostPage
  }
];
//@Directive({ selector: 'ion-textarea[autosize]' })
@NgModule({
  imports: [
    CommonModule,HttpModule,//MatInputModule,
    FormsModule,ReactiveFormsModule,DragulaModule,AutosizeModule,
    IonicModule,AngularEditorModule,TranslateModule,
    RouterModule.forChild(routes)
  ],
  //entryComponents:[BasicpopupPageModule],
  providers:[TranslatePipe,Imgupload,Clipboard,ImagePicker],
  declarations: [PostPage]
})
export class PostPageModule {}
