import { NgModule, Injectable } from '@angular/core';
import { SafeHtmlPipe } from './safehtmlpipe';

@Injectable()
@NgModule({
  declarations: [
    SafeHtmlPipe,
  ],
  imports: [
    
  ],
  exports: [
    SafeHtmlPipe
  ]
})
export class SafeHtmlPipeModule {}
