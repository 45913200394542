import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { IonicModule } from '@ionic/angular';

import { ListUpdatePage } from './listupdate.page';

const routes: Routes = [
  {
    path: '',
    component: ListUpdatePage
  }
];
@NgModule({
  imports: [
    IonicModule,CommonModule,
    FormsModule,ReactiveFormsModule,
    TranslateModule,OrderByPipeModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [ListUpdatePage]
})
export class ListUpdatePageModule {}
