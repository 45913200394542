import {
  Component,
  OnInit,
  ViewChildren,
  Query
} from '@angular/core';
import {
  NavController,
  NavParams,
  ToastController,
  ModalController
} from '@ionic/angular';
import {
  AngularFireDatabase,
  AngularFireList
} from '@angular/fire/database';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import { GlobalVars } from '../../../../providers/globalVars';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addworkplace',
  templateUrl: './addworkplace.page.html',
  styleUrls: ['./addworkplace.page.scss'],
})
export class AddworkplacePage implements OnInit {
  @ViewChildren('ipname') ipname: Query;
  @ViewChildren('iptime') iptime: Query;
  @ViewChildren('ipmemo') ipmemo: Query;
  sites: any[] = [];
  page: number = 1;
  searchStr: string = '';
  selectedsite: any = '';
  sitenickname: any = '';
  ptype: any = '';
  rate: any = '';
  uid: any;
  wid: any;
  memo: any = '';

  placenname = false;
  placetime = false;
  placememo = false;

  constructor(public navCtrl: NavController, public afDB: AngularFireDatabase, public afAuth: AngularFireAuth,
    private translate: TranslateService, private toastCtrl: ToastController,private gv:GlobalVars, public navParams: NavParams, public modalCtrl: ModalController) {
    if (navParams.get('workgroupid')) {
      this.wid = navParams.get('workgroupid');
      afDB.list('/workgroup/' + this.wid + '/sites').snapshotChanges().subscribe(items => {
        this.sites = [];
        items.forEach(it=>{
          let val;
          val = it.payload.val();
          val.$key = it.key;
          this.sites.push(val);
        })
      })
      if (gv.uid) 
        this.uid = gv.uid;
      else 
        this.navCtrl.pop();
    } else //잘못된 접근
      this.navCtrl.pop();
  }

  onKey(e) {
    if (e.keyCode == 13) {
      let activeElement = < HTMLElement > document.activeElement;
      activeElement && activeElement.blur && activeElement.blur();
    }
  }
  submit() {
    this.afDB.database.ref('/workinfo/' + this.uid).once('value').then(rst => {
      rst.forEach(item => {
        if (item.val().id == this.wid) {
          if (this.ptype == 'auto')
            this.rate = this.selectedsite.rate;
          if (!this.sitenickname)
            this.sitenickname = this.selectedsite.name;
          this.afDB.database.ref('/workinfo/' + this.uid + '/' + item.key + '/sites').push({
            siteid: this.selectedsite.$key,
            nickname: this.sitenickname,
            ptype: this.ptype,
            rate: this.rate,
            memo: this.memo
          });
        }
      });
    }).then(_ => {
      let addedstr;
      this.translate.get("Added").subscribe(res=>{addedstr=res});
      this.presentToast("bottom", addedstr, 3000);
      this.navCtrl.pop();
    });
  }

  closeModal() {
    // this.navCtrl.pop();
    this.modalCtrl.dismiss();
  }

  presentToast(position: string, message: string, duration: number) {
    let toast = this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: duration
    });
    toast.then(view => view.present())
  }


  ngOnInit() {}

}