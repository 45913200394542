import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SearchPipe'
})
@Injectable()

export class SearchPipe  implements PipeTransform {
 transform(items: any[], field: string, value: string): any[] {
   if (!items) return [];
   if (!value) return items;
   return items.filter(it => 
    {
      var str = "";
      for(var i=0; i < field.split(',').length; i++)
        str += (it[field.split(',')[i]]);
      return (""+str).toLowerCase().replace(/ /g, "").includes(value.toLowerCase().replace(/ /g, ""))
    });
 }
}