import {Component,OnInit,ViewChild} from '@angular/core';
import { ModalController,NavParams} from '@ionic/angular';
import { AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-chlistmodal',
  templateUrl: './chlistmodal.page.html',
  styleUrls: ['./chlistmodal.page.scss'],
})
export class ChlistmodalPage implements OnInit {
  channList: any[] = [];
  favItems: any[] = [];
  loaditemcnt: number = 15;

  constructor(
    public modalCtrl: ModalController,
    public afDB: AngularFireDatabase,
    public navParams: NavParams
  ) {}

  ngOnInit() {
    if(this.navParams.get('favlist'))
      this.favItems = this.navParams.get('favlist');
    this.getList();
  }

  ionViewWillLeave(){
    this.channList.forEach(ch=>{
      var checked = false;
      if(ch.isChecked)
      {
        for(let i = 0 ; i < this.favItems.length; i++)
        {
          if(this.favItems[i].chid == ch.chid)
            {
            checked = true;
            break;
            }
        }
        if(!checked)
        {
          this.favItems.push({chid:ch.chid, title:ch.title, isChecked:false});
        }
      }
      else
      {
        for(let i = 0 ; i < this.favItems.length; i++)
        {
          if(this.favItems[i].chid == ch.chid)
            this.favItems.splice(i,1);
        }
      }
    })
  this.modalCtrl.dismiss({
   favlist:this.favItems
  });
  }

  closemodal() {
    this.modalCtrl.dismiss();
  }

  getList() {
     this.channList = [];
     this.afDB.database.ref('/category').orderByChild('groupid').startAt('cairns_kr').once('value').then(list => 
      {
      if(list.val()){
        list.forEach(element => {
          if(element.val().publiccommunity)
            {
            var checked = false;
            for(let i = 0 ; i < this.favItems.length; i++)
            {
              if(this.favItems[i].chid == element.key)
                {
                checked = true;
                break;
                }
            }
            this.channList.push({title:element.val().name, chid:element.key, isChecked:checked});
            }
        });
      }
      });
  }

  checkInit() {
    for( let i=0; i < this.favItems.length; i++ ) {
      if( this.favItems[i].isChecked == true ) {
        // this.channs[i].isChecked = true;
      }
    }
  }

  doInfinite(infiniteScroll) {
    this.loaditemcnt = this.loaditemcnt+15;
    infiniteScroll.target.complete();
    }


}