import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,IonContent } from '@ionic/angular';
import { AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { GlobalVars } from '../../../providers/globalVars';
import { OrderByPipe } from '../../../pipe/orderbypipe';
import { msgfunc } from '../../../providers/msgfunc';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-msgview',
  templateUrl: './msgview.page.html',
  styleUrls: ['./msgview.page.scss'],
})
export class MsgviewPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  msg: any[] = [];
  sendername:any;
  chatwith:any;
  senderid:any;
  sendermboxid:any;
  msgid:any;
  uid:any;
  msgdata:any;
  showclosebtn:boolean = false;
  lancode:string;
  limitcount:any = 20;
  nickname:any;
  constructor(public modal: ModalController, public afAuth: AngularFireAuth, public afDB: AngularFireDatabase,
    public orderbypipe: OrderByPipe, private gv:GlobalVars, public navParams: NavParams, 
    public msgfunc:msgfunc) {
    //if(navParams.get('showclose'))
    //    this.showclosebtn = true;
    this.lancode = gv.getLan();
    this.uid = this.gv.uid;
    this.chatwith = this.navParams.get('nickname');
    this.senderid = this.navParams.get('chatwith');
    this.msgid = this.navParams.get('id');
    /*
    else
    {
        afDB.database.ref('/msgbox/' + this.uid).orderByChild('chatwith').equalTo(this.senderid).once('value').then(mbox=>{
        mbox.forEach(element => {
            this.msgid = element.msgid;
        });
        })
    }*/
    
    afDB.database.ref('/userProfile/' + this.gv.uid + '/nickname').once('value').then(sender=>{
        this.sendername = sender.val()});
    //afDB.database.ref('/userProfile/' + this.senderid + '/nickname').once('value').then(person=>{
    //    this.chatwith = person.val()})
    afDB.database.ref('/msgbox/' + this.senderid).orderByChild('msgid').equalTo(this.msgid).once('value').then(mbox=>{
        mbox.forEach(element => {
            this.sendermboxid = element.key;
        });
    })
    if(this.navParams.get('key'))
    afDB.database.ref('/msgbox/' + this.uid + '/' + this.navParams.get('key')).update({unread:0});
    
    this.msgdata = afDB.list('/msg/' + this.msgid + '/message').snapshotChanges() //, {query:{limitToLast:this.limitcount}}
    .pipe(map((data) => { 
    return  data.map((data) => {
     // 여기서 무한루프/ 코드 새로작서할것 oncreate로 대체 this.afDB.database.ref('/msg/' + this.msgid + '/message/' + data.$key).update({open:true});
        afDB.database.ref('/userProfile/'+data.payload.val()['sendfrom']+'/sum').once('value').then(user=>{
          data.payload.val()['userProfile'] = user.val();
        });
      return data;
                         })
                  })).subscribe(msgs=>{
                    //console.log('첫로딩');
        if(this.msg.length == 0)
        {
          this.msg = msgs// this.orderbypipe.transform(msgs, 'createdDate', 'asc');
        }
        else
        {
            this.msg = msgs//this.orderbypipe.transform(msgs, 'createdDate', 'asc');
            this.content.scrollToBottom(0).catch();
            //스크롤?
            //if(this.content._scroll)
            //this.content.scrollToBottom(0).catch();
        }
    });
     
}
doInfinite(infiniteScroll) {
  if(this.msg.length)
    if(this.limitcount + 20 < this.msg.length)
      this.limitcount = this.limitcount + 20;
    else
      this.limitcount = this.msg.length;
    /*
    this.msgdata = this.afDB.list('/msg/' + this.msgid + '/message', {query:{limitToLast:this.limitcount}})
    .pipe(map((data) => { 
                            return  data.map((data) => {
                              //this.afDB.database.ref('/message/' + data.$key).update({open:true});
                                data.userProfile = this.afDB.object('/userProfile/'+data.sendfrom);
                              return data;
                         })
                  })).subscribe(msgs=>{
        console.log('인피');
        if(this.msg.length == 0)
        {
          this.msg = msgs// this.orderbypipe.transform(msgs, 'createdDate', 'asc');
        }
        else
        {
            this.msg = msgs//this.orderbypipe.transform(msgs, 'createdDate', 'asc');
            this.content.scrollToBottom(0);
            //스크롤?
            //if(this.content._scroll)
            //this.content.scrollToBottom(0).catch();
        }
    })*/
    infiniteScroll.target.complete();
    }
ngOnInit() {
  let today = new Date();
  let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
  this.afDB.database.ref('/systemlog/' + (this.uid?this.uid:'unregister') + '/event/' + todaystr + '/msgopen').transaction(view=>{
    return (view || 0) + 1;
  })
}
ionViewDidEnter(){
    this.content.scrollToBottom(0).catch();
  }

close(){
    this.modal.dismiss({key:this.navParams.get('key')});//{updated:true}
}
sendmsg(msg){
    if(msg.trim())
        this.msgfunc.sendmsg(this.uid, this.senderid, msg, this.sendername);
}
}
