import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Http } from '@angular/http';
@Component({
  selector: 'app-basicpopup',
  templateUrl: './basicpopup.page.html',
  styleUrls: ['./basicpopup.page.scss'],
})
export class BasicpopupPage implements OnInit {

  ipurl:string = "";
  checking:boolean = false;
  image:any;
  title:any;
  description:any;
  url:any;
  searchurl:any;

  attachedalign:any = 'alignLeft';
  isComment;
  constructor(
    private modalController: ModalController,
    private clipboard: Clipboard,
    private http:Http,
  ) {}

  ngOnInit() {
    // this.clipboard.clear();
    
  }

  async closePopup() {
    await this.modalController.dismiss();
  }

  
  checkurl(event) {
    this.checking = true;
    this.searchurl = event.detail.value;
    this.http.get("https://api.linkpreview.net/?key=5df9c6e8d47eda6ed281f7e9457cae891effff58bac30&q=" +  this.searchurl)
    .subscribe(res => {
      if(res['_body'])
      {
      let data = JSON.parse(res['_body']);
      this.title = data.title;
      this.description = data.description;
      this.url = data.url;
      this.image = data.image;
      }
      this.checking = false;
    }, err=>{this.checking = false; this.title=''; this.description=''; this.url=''; this.image=''; throw err;})
  }

  copyfunc(event) {
    this.clipboard.copy(this.ipurl);
  }

  /*
  pastefunc(event) {
    this.clipboard.paste().then(
      (resolve: string) => {
          alert(resolve);
        },
        (reject: string) => {
          alert('Error: ' + reject);
        }
      );
  }*/
  confirm(){
    if(document.getElementById('urlbox') && document.getElementById('urlbox').innerHTML)
      this.modalController.dismiss({tag:document.getElementById('urlbox').innerHTML})
    else
      {
      if(this.searchurl.indexOf('www') == 0)
        this.searchurl = 'https://'+ this.searchurl;
      this.modalController.dismiss({tag:this.searchurl})
      }
  }

  alignitems(align?) {
    this.attachedalign = align;
  };

}