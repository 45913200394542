import { NgModule } from '@angular/core';
import { LinkImgFullPipe } from './linkimgfullpipe';

@NgModule({
  declarations: [
    LinkImgFullPipe,
  ],
  imports: [
    
  ],
  exports: [
    LinkImgFullPipe
  ]
})
export class LinkImgFullPipeModule {}
