import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BlockuserPage } from '../blockuser/blockuser.page';
import { PwdchangePage } from '../pwdchange/pwdchange.page';
@Component({
  selector: 'app-setaccount',
  templateUrl: './setaccount.page.html',
  styleUrls: ['./setaccount.page.scss'],
})
export class SetaccountPage implements OnInit {

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
  }


  async showmodal(page) {
    let pagename;
    let type = null;
    switch (page) {
      case 'blockuser': pagename = BlockuserPage; break;
      case 'pwdchange': pagename = PwdchangePage; break;
    }

    const modal = await this.modalCtrl.create({
      component: pagename,
      componentProps: < null > {
        mv: true,
        type: type
      },
      cssClass: 'modalfull'
    });
    return await modal.present();
  }

}
