import { NgModule } from '@angular/core';
import { CatgoryPipe } from './categorypipe';

@NgModule({
  declarations: [
    CatgoryPipe,
  ],
  imports: [
    
  ],
  exports: [
    CatgoryPipe
  ]
})
export class CategoryPipeModule {}
