import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { Imgupload } from '../../../providers/imgupload';
import { AccountPage } from './account.page';
import { ChartsModule } from 'ng2-charts';
import {AutosizeModule} from 'ngx-autosize';
import { TypeKrNamePipeModule } from '../../../pipe/tkrnamepipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { OrderByPFPipeModule } from '../../../pipe/orderbypf.module';
import { EnterPipeModule } from '../../../pipe/enterpipe.module';
const routes: Routes = [
  {
    path: '',
    component: AccountPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,TranslateModule,ChartsModule,EnterPipeModule,OrderByPFPipeModule,
    TypeKrNamePipeModule,TimeAgoPipeModule,AutosizeModule,
    RouterModule.forChild(routes)
  ],
  providers: [Imgupload,TranslatePipe],
  declarations: [AccountPage]
})
export class AccountPageModule {}
