import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-morebtn',
  templateUrl: './morebtn.component.html',
  styleUrls: ['./morebtn.component.scss'],
})
export class MorebtnComponent implements OnInit {

  targetfolder: any;
  targetinput: any;
  folder: any;
  folderInput: any;

  constructor(
    private popoverController: PopoverController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    // console.table(this.navParams);
    // console.log(this.navParams.data.targetfolder);
    this.folder = this.navParams.data.targetfolder;
    this.folderInput = this.navParams.data.targetinput;
  }

  editfoldername(folder, ip) {
    if (folder.editmode) {
      folder.editmode = false;
    } else {
      folder.editmode = true;
      if( ip ) {
        setTimeout(_=>{
          ip.setFocus();
        },50);
        this.closePopover();
      } else {
        console.log('target input 값을 찾지 못함');
      }
    }
  }

  async closePopover() {
    const onClosedData: string = 'closed';
    await this.popoverController.dismiss(onClosedData);
  }


}
