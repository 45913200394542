import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import {trigger,state,style,animate,transition} from '@angular/animations';
import { ModalController, AnimationController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { GlobalVars } from '../../../providers/globalVars';

@Component({
  selector: 'app-qna',
  templateUrl: './qna.page.html',
  styleUrls: ['./qna.page.scss'],
  /*
  animations: [
    trigger('elementState', [
      state('open', style({
        'max-height': '500px'
      })),
      state('closed', style({
        'max-height': 0
      })),
      transition('open => closed', animate('0ms')),
      transition('closed => open', animate('600ms'))
    ])
  ]
  */
})
export class QnaPage implements OnInit {
  state = false;
  qnadata: any = [];
  @ViewChildren('item') item: QueryList<any>;
  constructor(public modalCtrl: ModalController,private gv:GlobalVars,public afDB:AngularFireDatabase,
    private animationCtrl: AnimationController) { }

  ngOnInit() {
    this.afDB.database.ref('/appqna/cairns_kr')//' + this.gv.loc + '_' + this.gv.lan)
    .once('value').then(data=>{
        data.forEach(d=>{this.qnadata.push(d.val())})
  })
  }

    itemclick(no) {
    // console.log(quest);
    for (let i = 0; i < this.qnadata.length; i++) {
      if (i == no) {
        this.qnadata[i].open = !this.qnadata[i].open;
      } else {
        this.qnadata[i].open = false;
      }
    }
  }

  itemToggle(i){
    let _items = this.item.toArray();
    for (let idx = 0; idx < _items.length; idx++) {
      if (idx == i) {
        this.qnadata[i].open = !this.qnadata[i].open;
        if( _items[i].nativeElement.offsetHeight < 10 ) {
          const animation_thisopen = this.animationCtrl.create()
            .addElement(_items[i].nativeElement)
            .duration(150)
            .iterations(1)
            .fromTo('max-height', '0', '500px');
          animation_thisopen.play();
        }
      } else {
        this.qnadata[idx].open = false;
        if( _items[idx].nativeElement.offsetHeight > 10 ) {
          const animation_Allclose = this.animationCtrl.create()
            .addElement(_items[idx].nativeElement)
            .duration(150)
            .iterations(1)
            .fromTo('max-height', '500px', '0');
          animation_Allclose.play();
        }
      }
    }
    
    
  }
}
