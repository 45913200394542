import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { MailsenderPage } from './mailsender.page';

const routes: Routes = [
  {
    path: '',
    component: MailsenderPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,AngularEditorModule,
    IonicModule,TranslateModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [MailsenderPage]
})
export class MailsenderPageModule {}
