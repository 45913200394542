import { Component, OnInit } from '@angular/core';
import { ModalController,AlertController,ToastController } from '@ionic/angular';
import { ImgmodalPage } from '../../../support/imgmodal/imgmodal.page';
import { Imgupload } from '../../../../providers/imgupload';
import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: 'app-menupan',
  templateUrl: './menupan.page.html',
  styleUrls: ['./menupan.page.scss'],
})
export class MenupanPage implements OnInit {
  menu;
  manager;
  path;
  orimenu;
  constructor(
    public modalCtrl:ModalController,private imgupload: Imgupload, private alertCtrl:AlertController
    , public afDB: AngularFireDatabase,private toastCtrl:ToastController
  ) {}

  ngOnInit() {
    this.orimenu = this.menu;
  }

//   async openPreview(index) {
//     this.modalCtrl.create({
//      component: ImgmodalPage,
//      cssClass: 'imgmodal',
//      componentProps: <null>{
//        index: index,
//        img:this.imglist
//      }
//    }).then(modal => {
//      modal.present();
//    });
//  }

async htmlupload(fileinput:HTMLInputElement) { 
      if(!this.menu)
        this.menu = [];
      var filelength = fileinput.files.length;
      let count = 0;
      for(var i = 0; i < filelength; i++)
      {
      var file:File = fileinput.files[i];
      await this.imgupload.readfile(file).then(rst=>
      {
      this.imgupload.imguploadsingleori(file, 'menuimg')
      .then(imgrst=>{
        this.menu.push({path:imgrst[0]['path'], url:imgrst[0]['url']});
      })
      })
      }  
  }
  async removeimg(i){
    const alert = await this.alertCtrl.create({
    message: '메뉴를 삭제하시겠습니까?',
    buttons: [
      {
        text: '취소',
        role: 'cancel'
      },{
        text: '확인',
        handler: () => {
          this.imgupload.delete(this.menu[i].path, this.menu[i].path);
          this.menu.splice(i, 1);      
          this.presentToast('삭제하였습니다.');       
        }
      }
    ]});
    await alert.present();
  }
  async openPreviewSingle(src) {
    this.modalCtrl.create({
     component: ImgmodalPage,
     cssClass: 'imgmodal',
     componentProps: <null>{
       img:[{url:src}]
     }
   }).then(modal => {
     modal.present();
   });
 }
update(){
  this.modalCtrl.dismiss({menu:this.menu});
}
 close(){
    this.modalCtrl.dismiss();
}
    async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
    message: message,
    position: 'bottom',
    duration: 1500
    });
    toast.present();
    }
}
