import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'MatchPipe'
})
@Injectable()

export class MatchPipe  implements PipeTransform {
 transform(items: any[], field: string, value: string, reverse?:boolean): any[] {
   if (!items) return [];
   if (!value || value == 'All') return items;
   return items.filter(it => {
     if( reverse ) {
      return (""+it[field]).toLowerCase().replace(/ /g, "") != value.toLowerCase().replace(/ /g, "")
     } else {
      return (""+it[field]).toLowerCase().replace(/ /g, "") == value.toLowerCase().replace(/ /g, "")
     }
   });
 }
}