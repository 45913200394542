import {Component,ViewChild,OnInit,Optional, ViewChildren, ElementRef, QueryList} from '@angular/core';
import {AngularFireDatabase,AngularFireList} from '@angular/fire/database';
import {GlobalVars} from '../../../providers/globalVars';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {NavController,ModalController,IonRouterOutlet, ActionSheetController,IonContent} from '@ionic/angular';
import { Router } from '@angular/router';
//import { Keyboard } from '@ionic-native/keyboard/ngx';
import * as firebase from 'firebase/app';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import { ChviewPage } from '../chview/chview.page';
import { Http } from '@angular/http';
import { createViewChild } from '@angular/compiler/src/core';
import { concat } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('inputSearch') inputSearch;
  type: any;
  levels: any[] = [];
  chlistdir: any;
  itemlist: any[] = [];
  loaditemcnt: number = 8;
  searchStr: any = "";
  searchph: any = "";

  keywords: any[] = [];
  datenow: any = new Date().getTime();

  id;
  ignore;
  loclancode;
  modal;
  cangoback: boolean = false;
  chid;
  condition: any = '';
  searchscope: any = '';
  justentered: boolean = true;
  from:number;
  until: number = new Date().getTime() -(1000 * 60 * 60 * 24 * 180);

  loadingkeywords = false;
  searchMonths = 6;

  chlist:any[] = [];
  placelist:any[] = [];

  chowner:any;
  chtype:any;
  chorder;

  // @ViewChild('openhourdiv') openhourdiv: ElementRef;
  // @ViewChildren('openhourdiv') openhourdiv: ElementRef;
  // @ViewChildren('openhourdiv') openhourdiv: QueryList<any>;

  constructor(private http:Http, public navCtrl: NavController, private afAuth: AngularFireAuth,
    public router: Router, public gv: GlobalVars, public afDB: AngularFireDatabase, private route: ActivatedRoute,
    public translate: TranslateService, public modalCtrl: ModalController, @Optional() private outlet: IonRouterOutlet,
    //private keyboard: Keyboard, 
    private actionsheetCtrl: ActionSheetController
    ) {
    if (this.route.snapshot.paramMap.get('id'))
      this.chid = this.route.snapshot.paramMap.get('id');
    /*this.afDB.database.ref('/list').orderByChild('categoryId').equalTo('-LZlRs1JmeYY6_M20Tns').once('value').then(item=>{
      item.forEach(it=>{
        let data = it.val();
        if(!data.category)
          this.afDB.database.ref('/list/' + it.key).update({category:'팝니다'});
        else if(data.category != '기타')
        {
          this.afDB.database.ref('/list/' + it.key).update({category:'팝니다'});
        }
      })토마스정육점,8 Chicken Beer
    })*/
    

    /*
    this.afDB.database.ref('/list').orderByChild('categoryId').equalTo('-L85AVp14zEiVIWNGWKS').once('value').then(rst=>{
      rst.forEach(r=>{
        let data = r.val();
        //if(r.key != '-LHjNRvnUi8OHA4Cj7-5')
         // {
            if(data.category == '기본게시판')
              this.afDB.database.ref('/list/'+r.key).update({liketype:'hide'})
            //else
            //  this.afDB.database.ref('/list/'+r.key).update({userId:'brRJIdzNOPaXjYjU4ZfpwZbL0Fi1'})
          //}

//        console.log(data.title);
      })
    })*/
    /*
   this.afDB.database.ref('/list').orderByChild('categoryId').equalTo('-Lj_1R4zgnl5Ogjc8z0S').once('value').then(item=>{
     let t = 0;
     item.forEach(it=>{
      t = t+1000;
      setTimeout(
       aa=> {
       let predate = new Date(it.val().createdDate);
       predate.setFullYear(2020);
       predate.setMonth(0);
       predate.setDate(1+Math.round(Math.random()*9));

      // console.log(it.key+'], 작성일:' + predate + ', ' + it.val().title);
       this.afDB.database.ref('/list/' + it.key + '/createdDate').set(predate.getTime())
        this.afDB.database.ref('/category/-Lj_1R4zgnl5Ogjc8z0S/recent')
       .orderByChild('itemid').equalTo(it.key).once('value').then(rc=>{
         rc.forEach(r=>{
          this.afDB.database.ref('/category/-Lj_1R4zgnl5Ogjc8z0S/recent/'+r.key + '/createdDate').set(predate.getTime())
         })
          
       })
      console.log(predate.toLocaleDateString());
     }, t)
       
    })
     console.log(item.numChildren())
   })

    /*
    for(let i=0; i < 50; i++)
      {
        console.log(10+Math.round(Math.random()*9));
        //new Date().setDate(Math.round(Math.random()*10)).toLocaleString();
      }*/
    /*
   this.afDB.database.ref('/list').orderByChild('categoryId').equalTo('-Lj_1R4zgnl5Ogjc8z0S').once('value').then(item=>{
     let t = 0;
     item.forEach(it=>{
      t = t+1000;
      setTimeout(
       aa=> {
       let predate = new Date(it.val().createdDate);
       predate.setFullYear(2020);
       predate.setMonth(0);
       predate.setDate(1+Math.round(Math.random()*9));

      // console.log(it.key+'], 작성일:' + predate + ', ' + it.val().title);
       this.afDB.database.ref('/list/' + it.key + '/createdDate').set(predate.getTime())
        this.afDB.database.ref('/category/-Lj_1R4zgnl5Ogjc8z0S/recent')
       .orderByChild('itemid').equalTo(it.key).once('value').then(rc=>{
         rc.forEach(r=>{
          this.afDB.database.ref('/category/-Lj_1R4zgnl5Ogjc8z0S/recent/'+r.key + '/createdDate').set(predate.getTime())
         })
          
       })
      console.log(predate.toLocaleDateString());
     }, t)
       
    })
     console.log(item.numChildren())
   })*/
/*
   this.afDB.database.ref('/userProfile').once('value').then(u=>{
     u.forEach(user=>{
       if(user.val().email)
       {
         console.log(user.val().nickname + ']' + user.val().email)

       }
     })
   })*/
   /* this.afDB.database.ref('/subscribe').push(
      {nickname:'오늘가입',uid:'uidhere',createdDate:new Date().getTime(), email:'today@channel.cafe'})
    this.afDB.database.ref('/subscribe').push(
      {nickname:'내일가입예정',uid:'uidhere',createdDate:new Date().getTime(), email:'tomorrow@channel.cafe'})
    */
    
    /*
   this.afDB.database.ref('/subscribe').once('value').then(r=>{
     let domain = [];
     r.forEach(d=>{
      let exist = false;
      for(let i = 0; i < domain.length; i++)
        if(domain[i].domain == d.val().email.split('@')[1])
          {
          domain[i].count++;
          exist = true;
          }
      if(!exist)
        {
        domain.push({domain:d.val().email.split('@')[1], count:1})
        let dom = d.val().email.split('@')[1];
        if(dom != 'naver.com' && dom != 'gmail.com' && dom != 'hanmail.net' && dom != 'daum.net' && dom != 'nate.com')
          console.log(d.val());
        }
      //console.log(d.key + ' :' + d.val().email)
     
    })
    console.log(domain)
   })*/
  /*
   this.afDB.database.ref('/category/-LsG5FDIR8siZPY60uTy/forms/0/fieldlist').once('value').then(rst=>{
     let array = [];
     rst.forEach(r=>{
       array.push(r.val())
     })
     array.splice(5,0,{mustfill:true, name:'직접 픽업 가능', type:'checkbox'})
     this.afDB.database.ref('/category/-LsG5FDIR8siZPY60uTy/forms/0/fieldlist').set(array)
   })*/

   /*
    this.afDB.database.ref('/list').orderByChild('categorytype').equalTo('rss').once('value').then(rst=>{
      console.log(rst.numChildren() + '갯수 있음.')
      rst.forEach(item=>{
        this.afDB.database.ref('/category/'+item.val().categoryId+'/recent').orderByChild('itemid').equalTo(item.key).once('value').then(result=>{
          result.forEach(tt=>{
            this.afDB.database.ref('/category/'+item.val().categoryId+'/recent/'+tt.key).update({createdDate:item.val().createdDate})
//          console.log(item.val().title + ':' + new Date(item.val().createdDate).toString() + '>> 이걸로 입력됨 -' + new Date(tt.val().createdDate).toString())
          })
        })
      })
    })
    }*/
  }

  ionViewWillEnter() {
    setTimeout(() => {
      this.inputSearch.setFocus();
    }, 500);
    if (this.chid) {
      // console.log("this.chid = " + this.chid);
      {
        this.afDB.database.ref('/category/' + this.chid + '/name').once('value').then(chname=>{
        if(chname.val())
          this.searchscope = chname.val();
        else
          this.translate.get('Selected Channel').subscribe(res=>{this.searchscope=res})
        })
          }
      this.translate.get('Search in selected Channel.').subscribe(res=>{this.condition=res})
    } else {
      this.translate.get('All Channel').subscribe(res=>{this.searchscope=res})
      let recent, searchin;
      this.translate.get('Search in ').subscribe(res=>{recent=res})
      this.translate.get('months and all Channel posts.').subscribe(res=>{searchin=res})
      this.condition = recent + this.searchMonths + searchin;
      // if(!this.from) this.condition = '최근 '+this.searchMonths+'개월 이내 모든 채널 글에서 검색합니다.';
      // else this.condition = new Date(this.from).toLocaleDateString() + ' 글 부터 검색합니다.';
    }
  }
  ionViewDidEnter(){this.gv.selectedcontent = this.content;}
  ionViewWillLeave() {
    this.gv.selectedcontent = null;
    this.loadingkeywords = true;
  }
  getdata() {
    this.loadingkeywords = true;

    this.chlistdir = this.gv.getListDir();
    if (this.chid) {
      // console.log('data in chid');
      this.afDB.database.ref(this.chlistdir).orderByChild("categoryId").equalTo(this.chid)
        .once('value').then(Items => {
          Items.forEach(item => {
            let data = item.val();
            // console.log(data);
            if(!data.private)
            this.itemlist.push({
              communityname: data.communityname,
              category: data.category,
              title: data.title,
              description: data.description,
              $key: item.key,
              img: data.imagethumb ? data.imagethumb[0].url : '',
              createdDate: data.createdDate,
              type: data.type ? data.type : '',
              typemain: data.typemain ? data.typemain : '',
              typesub: data.typesub ? data.typesub : ''
            });
          });
        })
    } else {
      // console.log('data just at home');
      let placetemp = [];
      this.afDB.database.ref(this.chlistdir).orderByChild("categoryId").equalTo('-KyU3z4upRvIv0oXJ1gM')
        .once('value').then(list=>{
          list.forEach(places=>{
            let data = places.val();
            if(!data.private)
            placetemp.push({
              category: data.category,
              title: data.title,
              description: data.description,
              $key: places.key,
              img: data.imagethumb ? data.imagethumb[0].url : '',
              openhour: data.openhour ? data.openhour : data.formdata.fieldlist[1] && data.formdata.fieldlist[1].name == '오픈시간' ? data.formdata.fieldlist[1].value : '',
              shortdesc: data.shortdesc ? data.shortdesc : data.formdata.fieldlist[2] && data.formdata.fieldlist[2].name == '요약설명' ? data.formdata.fieldlist[2].value : null,
              address: data.address ? data.address : data.formdata.fieldlist[4] && data.formdata.fieldlist[4].type == 'address' ? data.formdata.fieldlist[4].value:'',
              lat: data.formdata.fieldlist[4] && data.formdata.fieldlist[4].type == 'address' ? data.formdata.fieldlist[4].lat : null,
              lng: data.formdata.fieldlist[4] && data.formdata.fieldlist[4].type == 'address' ? data.formdata.fieldlist[4].lng : null,
              contact: data.contact ? data.contact : data.formdata.fieldlist[5] && data.formdata.fieldlist[5].name == '연락처' ? data.formdata.fieldlist[5].value : '',
              website: data.web ? data.web : data.formdata.fieldlist[6] && data.formdata.fieldlist[6].name == '홈페이지' ? data.formdata.fieldlist[6].value : '',
              region: 'cairns'
            })
          });
        }).then(_=>{
          // console.log(this.placelist);
        });

        this.afDB.database.ref(this.chlistdir).orderByChild("categoryId").equalTo('-Lj-yCBRYPNBImL4KYv0')
        .once('value').then(list=>{
          list.forEach(places=>{
            let data = places.val();
            if(!data.private)
            placetemp.push({
              category: data.category,
              title: data.title,
              description: data.description,
              $key: places.key,
              img: data.imagethumb ? data.imagethumb[0].url : '',
              openhour: data.openhour ? data.openhour : data.formdata.fieldlist[1] && data.formdata.fieldlist[1].name == '오픈시간' ? data.formdata.fieldlist[1].value : '',
              shortdesc: data.shortdesc ? data.shortdesc : data.formdata.fieldlist[2] && data.formdata.fieldlist[2].name == '요약설명' ? data.formdata.fieldlist[2].value : null,
              address: data.address ? data.address : data.formdata.fieldlist[4] && data.formdata.fieldlist[4].type == 'address' ? data.formdata.fieldlist[4].value:'',
              lat: data.formdata.fieldlist[4] && data.formdata.fieldlist[4].type == 'address' ? data.formdata.fieldlist[4].lat : null,
              lng: data.formdata.fieldlist[4] && data.formdata.fieldlist[4].type == 'address' ? data.formdata.fieldlist[4].lng : null,
              contact: data.contact ? data.contact : data.formdata.fieldlist[5] && data.formdata.fieldlist[5].name == '연락처' ? data.formdata.fieldlist[5].value : '',
              website: data.web ? data.web : data.formdata.fieldlist[6] && data.formdata.fieldlist[6].name == '홈페이지' ? data.formdata.fieldlist[6].value : '',
              region: 'brisbane'
            })
          })
        }).then(_=>{
          this.placelist = placetemp;
          // console.log(this.placelist);
        });
        

      let chlisttemp = [];
      this.afDB.database.ref('/category')
        .once('value').then(list=>{
          list.forEach(ch=>{
            var lastupdate;
            if (ch.val().recent)
              lastupdate = ch.val().recent[Object.keys(ch.val().recent)[Object.keys(ch.val().recent).length - 1]].createdDate;
            else
              lastupdate = 0;
            var point;
            if (ch.val().publiccommunity)
              point = ch.val().point;
            else
              point = ch.val().point - 1000;
            
            if(ch.val().publiccommunity) {
              chlisttemp.push({
                key: ch.key,
                typemain: ch.val().typemain,
                imagethumb: ch.val().imagethumb,
                name: ch.val().name,
                replycount: ch.val().replycount,
                read: ch.val().read,
                createdDate: ch.val().createdDate,
                type: ch.val().type,
                userId: ch.val().userId,
                chinfo: ch.val().chinfo,
                // point: point,
                loc: ch.val().loc,
                description: ch.val().description,
                publiccommunity: ch.val().publiccommunity,
                lastupdate: lastupdate, // new:newupdate,
                recentpost: this.afDB.database.ref('/category/' + ch.key + '/recent').orderByChild("createdDate")
                  .startAt(this.datenow - (1000 * 60 * 60 * 24 * 2)).once('value').then(item => {
                    if (item.val())
                      return Object.keys(item.val()).length
                    else
                      return 0
                  })
              })
            }
          })
          this.chlist = chlisttemp;
        }).then(_=>{
          // console.log(this.chlist);
        });
        
      this.afDB.database.ref(this.chlistdir).orderByChild("createdDate")
        .startAt(this.from?this.from:this.until)
        .endAt(this.from?this.until:5000000000000)
        .once('value').then(Items => {
          Items.forEach(item => {
            let data = item.val();
            // console.log(data);
            if(!data.private)
            this.itemlist.push({
              communityname: data.communityname,
              category: data.category,
              title: data.title,
              description: data.description,
              $key: item.key,
              img: data.imagethumb ? data.imagethumb[0].url : '',
              createdDate: data.createdDate,
              type: data.type ? data.type : '',
              typemain: data.typemain ? data.typemain : '',
              typesub: data.typesub ? data.typesub : '',
              chloc: this.afDB.database.ref('/category/' + data.categoryId + '/loc/').once('value').then(chloc=>{
                if( chloc.val() ) return chloc.val();
              }),
              chcategory: this.afDB.database.ref('/category/' + data.categoryId + '/typemain/').once('value').then(chloc=>{
                if( chloc.val() ) return chloc.val();
              })
            });
          })
        }).then(_=>{
          // console.log(this.itemlist);
          if(this.searchStr)
            {
            let temp = this.searchStr;
            this.searchStr = this.searchStr + ' ';
            setTimeout(_=>{this.searchStr = temp;},5)
            }
        })
    }

    this.afDB.database.ref('/searchstr/' + (this.chid ? this.chid : this.loclancode)).orderByChild('count')
      .limitToLast(11).once('value').then(rst => {
        this.keywords = [];
        if(!rst.numChildren())
          this.loadingkeywords = false;
        rst.forEach(item => {
          if (parseInt(item.val().result) > 0 && parseInt(item.val().count) > 1) {
            this.keywords.unshift({
              name: item.val().value,
              count: item.val().count,
              result: parseInt(item.val().result)
            });
            this.loadingkeywords = false;
          }
        })
      })
  }
    

  doInfinite(infiniteScroll) {
    this.loaditemcnt = this.loaditemcnt + 8;
    infiniteScroll.target.complete();
  }
  
  search(nocount) {
    if(this.inputSearch.value.trim().length > 1) {
    this.searchStr = this.inputSearch.value;
    // setTimeout(_=>{console.log(document.getElementById('len').innerText)},500);
    setTimeout(_ => {
      if (this.searchStr && this.gv.uid && !nocount &&
        !this.gv.keyword.filter(item => {
          return item.value === this.searchStr
        }).length) {
        if (!this.gv.keyword)
          this.gv.keyword = [{
            value: this.searchStr
          }];
        else
          this.gv.keyword.push({
            value: this.searchStr
          });
        if (this.chid) {
          this.afDB.database.ref('/searchstr/' + this.chid).orderByChild('value').equalTo(this.searchStr)
            .once('value').then(rst => {
              if (rst.val()) {
                let key = Object.keys(rst.val())[0];
                this.afDB.database.ref('/searchstr/' + this.chid + '/' + key).update({
                  count: rst.val()[key].count + 1,
                  result: document.getElementById('len').innerText
                })
              } else {
                this.afDB.database.ref('/searchstr/' + this.chid)
                  .push({
                    value: this.searchStr,
                    count: 1,
                    result: document.getElementById('len').innerText
                  })
              }

            });
        } else {
          this.afDB.database.ref('/searchstr/' + this.loclancode).orderByChild('value').equalTo(this.searchStr)
            .once('value').then(rst => {
              if (rst.val()) {
                let key = Object.keys(rst.val())[0];
                this.afDB.database.ref('/searchstr/' + this.loclancode + '/' + key).update({
                  count: rst.val()[key].count + 1,
                  result: document.getElementById('len').innerText
                })
              } else {
                this.afDB.database.ref('/searchstr/' + this.loclancode)
                  .push({
                    value: this.searchStr,
                    count: 1,
                    result: document.getElementById('len').innerText
                  })
              }
            });
        }
      }
      // console.log(document.getElementById('len'));
        if(document.getElementById('len').innerText == '0') {
          this.justentered = false;
        }
    }, 300);
    }
    else
      this.searchStr = '';
    //if(this.gv.cordova)
    //  this.keyboard.hide();
  }

  openDetail(itemkey){
    if(this.modal)
    {
      this.modalCtrl.create({
        component: ChviewPage,
        cssClass: 'modalfull',
        componentProps: <null>{
          id: itemkey,
          modal: true
        }
      }).then(modal => {
        modal.present();
      });
    }
    else
      {
        if( this.router.url == '/tabs/search' ) {
          this.router.navigate(['/tabs/search/chview/' + itemkey]);
        } else {
          let parent = this.router.url.substring(0, this.router.url.indexOf('/search'));
          this.router.navigate([parent + '/chview/' + itemkey]);
        }
      }
  }
  ionViewDidLeave(){
    this.outlet.swipeGesture = true;
  }
  ngOnInit() {
    if (`${this.id}` != 'undefined')
      this.chid = `${this.id}`;
    if (`${this.ignore}` != 'undefined')
      this.ignore = `${this.ignore}`;
    if (`${this.modal}` != 'undefined')
      this.modal = `${this.modal}`;
    
    if(!this.chid)
      this.outlet.swipeGesture = false;
    
    this.cangoback = this.outlet && this.outlet.canGoBack();

    this.loclancode = this.gv.getLocLan();

    
    if (this.gv.getLoc())
      this.getdata();
    else
      setTimeout(rst => {
        this.getdata();
      }, 300);
      
      
      //브번채널 복사
      //this.afDB.database.ref('/category/-Lj_1OCU2fkyCarAzIxD').once('value').then(rst=>{
       // this.afDB.database.ref('/category').push(rst.val());})
/*
      this.afDB.database.ref('/userData').once('value').then(a=>{
        a.forEach(item=>{
          if(item.val().favlist)
            {
              Object.keys(item.val().favlist).forEach(fav=>{
                if(item.val().favlist[fav].chid == '-LanOVeASn91sAXuaHOy')
                  {
                    console.log(item.key)
                  }
              })
            } 
          })
            
        })*/
      

    //this.gv.keyword.length ? console.log(this.gv.keyword) : '';
    //this.test()
  }
  /*아래 유저 uvf
  test(){
    this.afDB.database.ref('/userProfile').once('value').then(u=>{
      u.forEach(ur=>{
        if(ur.val())
        {
          this.afDB.database.ref('/userProfile/' + ur.key).update({uvf:true})
        }
        //this.afDB.database.ref('/userData/' + u.key + '/state')
        //.update({receivedprepoint:false})
      })
    })
  }
  /* 이하 채널 게시물 좋아요 읽음 카운팅
  test(){
    let catlist = [];
    this.afDB.database.ref('/list').once('value').then(items=>{
      items.forEach(it=>{
        let item = it.val();
        let exist = -1;
        for(let i = 0; i < catlist.length; i++)
        {
          if(catlist[i].chid == item.categoryId)
            exist = i;
        }

        if(exist == -1)
          catlist.push({chid:item.categoryId,  
            loc:item.loc, up:item.up?parseInt(item.up):0, read:parseInt(item.read), post:1});
        else
          {
          //console.log(exist);
          //console.log(catlist[exist])
          catlist[exist].up = (catlist[exist].up?catlist[exist].up:0) + (item.up?parseInt(item.up):0);
          catlist[exist].read = (catlist[exist].read?catlist[exist].read:0) + parseInt(item.read);
          catlist[exist].post = catlist[exist].post + 1;
          //catlist[exist].loc = catlist[exist].loc;
          }
      })
      catlist.forEach(cat=>{
        this.afDB.database.ref('/category/' + cat.chid).once('value').then(ch=>{
          if(ch.val())
          {
          console.log((ch.val().name?ch.val().name:'이름없음') + '>' +  (ch.val().loc?ch.val().loc:'-')
          + ' > ' + cat.post + ' / ' + cat.up + ' / ' +cat.read);
          if(ch.val().loc)
          this.afDB.database.ref('/category/' + cat.chid + '/chinfo').update({
            post:cat.post, liked:cat.up, visit:cat.read
          })
          }
        })
      })
    })

  }*/

  searchmore(){
    if(this.from)
    {
      this.until = this.from - 1;
      this.from = this.from - (1000 * 60 * 60 * 24 * 180);
    }
    else{
      this.from = this.until - (1000 * 60 * 60 * 24 * 180);
      this.until = this.until - 1;
    }
    this.searchMonths = this.searchMonths + 6;
    this.getdata();
  }

  close() {
    if (`${this.modal}` != 'undefined')
      this.modalCtrl.dismiss();
    if (this.cangoback) {
      this.navCtrl.pop();
    } else
      this.navCtrl.navigateBack('/');
  }


  site(passedWeb) {
    //this.iab.create(passedWeb, '_blank');
    if (passedWeb.indexOf('http:') == 0 || passedWeb.indexOf('https:') == 0)
      window.open(passedWeb, '_system', 'location=no');
    else
      window.open('http://' + passedWeb, '_system', 'location=no');
  }

  call(numbers, postname?) {
    let no;
    numbers = numbers.replace(/ /g,'').replace(/\(/g,'').replace(/\)/g,'').replace(/\+/g,'').replace(/\//g, ',');
    if(numbers.split(',').length > 1)
    {
      let btns = [];
      numbers.split(',').forEach(num=>{
        btns.push({text:num,handler:()=>{this.call(num)}})
      })
      var whichno;
      this.translate.get('Which number do you want to contact').subscribe(res=>{whichno = res;});
      this.actionsheetCtrl.create({
      header: whichno,
      buttons: btns
      }).then(_=>_.present())
    }
    else
    {
    no = numbers;
    if(no.indexOf('04') == 0 || no.indexOf('614') == 0)
    {
      let callstr, smsstr;
      this.translate.get('Call').subscribe(res=>{callstr = res;});
      this.translate.get('SMS').subscribe(res=>{smsstr = res;});
      this.actionsheetCtrl.create({
        header: no,
        buttons: [{
          text: callstr,
          icon: 'call',
          cssClass: 'attach_chpost_icon',
          handler: () => {
            window.open('tel:' + no, '_system', 'location=no');
          }
        }, {
          text: smsstr,
          icon: 'mail',
          cssClass: 'attach_exurl_icon',
          handler: () => {
            if(this.chtype == 'place')
              window.open('sms:' + no, '_system', 'location=no');
            else
            {
            /*let callstart, callend;
            this.translate.get('callstrstart').subscribe(res=>{callstart = res;});
            this.translate.get('callstrend').subscribe(res=>{callend = res;});
            let bodytxt = encodeURI(callstart + postname + callend);*/
            window.open('sms:' + no, '_system', 'location=no');
            }
          }
        }]
      }).then(rst=>{rst.present()})
    }
    else
      window.open('tel:' + no, '_system', 'location=no');
    }
  }

  

  map(addr, lat, lng) {
    if (this.gv.android)
      window.open('geo:0,0?q=' + lat + ',' + lng, '_system', 'location=no');
    else if (this.gv.ios)
      window.open('maps://?q=' + lat + ',' + lng, '_system', 'location=no');
    else {
      let addstr = addr.replace(' ', '+').replace(',', '%2C');
      window.open('https://www.google.com/maps/search/?api=1&query=' + addstr, '_system', 'location=no')
    }
  }


  blockDefault(e){
    e.stopPropagation();
  }

} 