import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'typeKrNamePipe'
})
@Injectable()
export class TypeKrNamePipe  implements PipeTransform {
constructor(public translate: TranslateService) {}
 transform(item: string): any {
   if (!item) return [];
   if (item == 'trade')
    {
    var tradename;
    this.translate.get('Trade').subscribe(res=>{
		tradename = res;
        });
    return tradename;
    }
   if (item == 'place')
    {
    var placename;
    this.translate.get('Place').subscribe(res=>{
		placename = res;
        });
    return placename;
    }
   if (item == 'share' || item == 'house')
    {
        var sharename;
        this.translate.get('Share').subscribe(res=>{
            sharename = res;
            });
        return sharename;
    }
   if (item == 'work')
    {
        var workname;
        this.translate.get('Work').subscribe(res=>{
            workname = res;
            });
        return workname;
    }
   if (item == 'community')
    {
        var communityname;
        this.translate.get('Community').subscribe(res=>{
            communityname = res;
            });
        return communityname;
    }
   if (item == 'talent')
    {
        var talentname;
        this.translate.get('Talent').subscribe(res=>{
            talentname = res;
            });
        return talentname;
    }
   if (item == 'msg')
    {
        var msgname;
        this.translate.get('Message').subscribe(res=>{
            msgname = res;
            });
        return msgname;
    }
    if (item == 'newmember')
        {
            var newmembername;
            this.translate.get('New member').subscribe(res=>{
                newmembername = res;
                });
            return newmembername;
        };
    if (item == 'appmsg')
        {
            var appmsgname;
            this.translate.get('App message').subscribe(res=>{
                appmsgname = res;
                });
            return appmsgname;
        }
   else
    {
        var othername;
        this.translate.get('Others').subscribe(res=>{
            othername = res;
            });
        return othername;
    }
   //return item.replace(/\n/g, "<br>");
}
}