import { Injectable } from '@angular/core';
import { CanActivate, Router,ActivatedRouteSnapshot } from '@angular/router';
import { ToastController, Platform } from '@ionic/angular';
import { AuthData } from './auth-data';
import { GlobalVars } from './globalVars';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  pltinfo:any;
  firstload:boolean = true;
  constructor(public authdata: AuthData, private gv:GlobalVars, 
    public toastCtrl:ToastController,public router: Router, private platform:Platform) {}
 
  canActivate(route?:ActivatedRouteSnapshot): boolean {
    if(this.authdata.isAuthenticated())
      {
        let url = route.url[0]?route.url[0].path:'';
        //if(route.data && route.data['path'] == 'signin')
        //  {this.router.navigate(['']); return false;}
        
        //else
           if(url == 'chadd' || url == 'post' || url == 'pitemreg' || url == 'pitemcheckout')
          {if(this.gv.uvf) return true; else {this.presentToastUvf('이메일 인증후 이용하실수 있는 서비스입니다.');return false}}
        else
          return true;
      }
    else{
      if(route.data && route.data['path'] == 'root')
        {
          /*
          if (this.platform.is('cordova') && 
            this.firstload) 
            {this.firstload = false; this.router.navigate(['signin']); return false;}
          else*/
            return true;
        }
      else if(route.data && route.data['path'] == 'signin')
          {return true}
      else
        {
          this.presentToast('로그인이 필요한 서비스입니다.');
          return false; 
        }
    }
    }

    async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2400,
      buttons:[{
        text:'로그인',
        handler:()=>{
          this.router.navigate(['/tabs/pfmenu']);
        }
      }]
    });
    return await toast.present();
  }

  async presentToastUvf(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2000,
      buttons:[{
        text:'메뉴-인증',
        handler:()=>{
          this.router.navigate(['/tabs/pfmenu']);
        }
      }]
    });
    return await toast.present();
  }
}