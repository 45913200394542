import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { Roulette2Page } from './roulette2.page';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { SafeHtmlPipeModule } from '../../../pipe/safehtmlpipe.module';
import { LinkWebPipeModule } from '../../../pipe/linkwebpipe.module';
import { EnterPipeModule } from '../../../pipe/enterpipe.module';

const routes: Routes = [
  {
    path: '',
    component: Roulette2Page
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule,
    OrderByPipeModule,
    TimeAgoPipeModule,
    SafeHtmlPipeModule,
    LinkWebPipeModule,
    EnterPipeModule
  ],
  providers:[TranslatePipe, OrderByPipeModule, TimeAgoPipeModule, SafeHtmlPipeModule, LinkWebPipeModule, EnterPipeModule],
  declarations: [Roulette2Page]
})
export class Roulette2PageModule {}
