import {Injectable} from '@angular/core';
import { Http } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase/app';
import { ModalController,  } from '@ionic/angular';
import { GetpointmodalComponent } from '../pages/support/getpointmodal/getpointmodal.component';
@Injectable()
export class pointfunc {
  constructor(public http:Http,public translate: TranslateService, public modalCtrl: ModalController) {
  }

  /*
  "newrec":"회원가입", 
  "fillrec":"추천자작성",pinfo1:추천자uid
  "getrec":"가입자추천",
  "updown":"추천하기",
  "gotliked":"좋아요획득",
  "comment":"댓글달기",
  "posting":"글작성",
  "levelup":"등급업",
  "bannerpicked":"메인글당첨",
  "dailylogin":"로그인",
  "10daylogin":"연속로그인",
  "gotreply":"댓글획득",
  "recharge":"캐쉬충전",
  "itempurchase":"상품구매",
  "daily":"출석"
  */
  addpoint(uid,pcode,point,pinfo1,pinfo2,hideani?){
    if(uid && uid != 'undefined')
    {
    let data;
    if(pcode == 'gotliked' || pcode == 'posting' || pcode == 'postingremove') 
      data = {pcode:pcode, point:point, itemid:pinfo1, createdDate:firebase.database.ServerValue.TIMESTAMP}; 
    else if(pcode == 'comment' || pcode == 'gotreply' || pcode == 'commentremove' || pcode == 'gotreplyremove')
      data = {pcode:pcode, point:point, itemid:pinfo1, cmtid:pinfo2, createdDate:firebase.database.ServerValue.TIMESTAMP}; 
    else if(pcode == 'updown')
      data = {pcode:pcode, point:point, itemid:pinfo1, type:pinfo2, createdDate:firebase.database.ServerValue.TIMESTAMP};
    else
      data = {pcode:pcode, point:point, createdDate:firebase.database.ServerValue.TIMESTAMP, title:(pinfo1?pinfo1:null)}; 
    firebase.database().ref('/point/' + uid).push(data).then(_=>{
      if(!hideani && point > 0)
        this.showpointani(point);
    })
    }
    else
    { 
      console.log('포인트 획득 실패');
    }
  }

   async showpointani(point){
    const modal = await this.modalCtrl.create({
      component: GetpointmodalComponent,
      animated: false,
      showBackdrop: false,
      cssClass: 'getpointPopup'
    });
    setTimeout(_=> {
      modal.dismiss()
    }, 2500);
    return await modal.present();
  }

  
}
