import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { MypointPage } from './mypoint.page';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
const routes: Routes = [
  {
    path: '',
    component: MypointPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,TranslateModule,TimeAgoPipeModule,OrderByPipeModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [MypointPage]
})
export class MypointPageModule {}
