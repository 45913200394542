import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-nickname',
  templateUrl: './nickname.page.html',
  styleUrls: ['./nickname.page.scss'],
})
export class NicknamePage implements OnInit {

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
  }

}
