import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
declare var google;
@Component({
  selector: 'app-addmap',
  templateUrl: './addmap.page.html',
  styleUrls: ['./addmap.page.scss'],
})
export class AddmapPage implements OnInit {
  ipaddr:string = "";
  checking:boolean = false;
  lat:any=null;
  lng:any=null;
  mapStyle: any = "style=element:geometry.fill%7Csaturation:-60%7Cweight:2";
  mapWidth:number = window.innerWidth;
  mapHeight:number = 135; // was 200 - 200302 Hong
  mapshow:boolean;
  preaddress:any;
  mapalign:any = 'alignLeft';
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.setautocomplete();
  }

    async closePopup() {
    await this.modalController.dismiss();
  }

  checkaddr(event){
    this.checking = true;
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({
            'address': event.detail.value
        }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                this.lat = results[0].geometry.location.lat();// this.lat = results[0].geometry.location.lat();
                this.lng = results[0].geometry.location.lng();//this.lng = results[0].geometry.location.lng();
            }
            else{
              this.lat = null;
              this.lng = null;
            }
            //this.maploading = false;
             this.checking = false;
        })
     
  }

  confirm(){
    if(document.getElementById('urlbox') && document.getElementById('urlbox').innerHTML)
      this.modalController.dismiss({tag:document.getElementById('urlbox').innerHTML})
    else
      this.modalController.dismiss({tag:this.ipaddr})
  }

  setautocomplete(){
    setTimeout(__=>{
    var input = document.getElementById('ipaddress').getElementsByTagName('input')[0];
    var autocomplete = new google.maps.places.Autocomplete(input);
    //autocomplete.bindTo('bounds', this.map);
    // Set the data fields to return when the user selects a place.
    autocomplete.setFields(['address_components','name','geometry']);
    autocomplete.addListener('place_changed', _=> {
          //this.maploading = true;
          var place = autocomplete.getPlace();
          var address = '';
          if (place.address_components) {
            address = [
              (place.address_components[0] && place.address_components[0].short_name || ''),
              (place.address_components[1] && place.address_components[1].short_name || ''),
              (place.address_components[2] && place.address_components[2].short_name || '')
            ].join(' ');
          }
          if(address)
          {
          this.ipaddr = address;
          this.preaddress = address;
          if(place.geometry){
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng(); //this.lng[targetid] = place.geometry.location.lng();
            this.mapshow = true;
          }
          }
        //this.maploading = false
        }); }, 800)
  }

  alignitems(align?) {
    this.mapalign = align;
  };

}
