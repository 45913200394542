import {Component,OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {GlobalVars} from '../../../providers/globalVars';
import {AngularFireAuth} from '@angular/fire/auth';
import {ChviewPage} from '../../../pages/general/chview/chview.page';
import {AngularFireDatabase} from '@angular/fire/database';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listmodal',
  templateUrl: './listmodal.page.html',
  styleUrls: ['./listmodal.page.scss'],
})
export class ListmodalPage implements OnInit {
  actlist: any[] = [];
  datenow: any = new Date().getTime();
  templist: any[] = [];
  recentlist: any[] = [];
  uid: any;
  msgshowcount: any = 10;
  recentshowcount: any = 10;
  lastdate: any = null;
  type;
  listtype: any;
  dbname: any;
  title: any;
  editable: boolean = false;
  loadingdone:boolean = false;
  editlist;
  constructor(public navCtrl: NavController, public afAuth: AngularFireAuth,private route: ActivatedRoute, 
    private gv: GlobalVars, private afDB: AngularFireDatabase) {}

  ngOnInit() {
    /*
    this.afAuth.authState.subscribe(auth => {
      if(auth) {
        this.uid = auth.uid;
      }
    })*/
    
    //if(this.afAuth.auth.currentUser)
    this.uid = this.gv.uid;

    //if (`${this.type}` != 'undefined')
    //`${this.type}`;
    this.listtype = this.route.snapshot.paramMap.get('type');
    if (this.listtype == 'r') {
      this.recentlist = this.gv.getrecent();
      this.editable = true;
    }
    this.getdata();
    
  }

  /*
  getdata1(infi?)
  {
    this.afDB.database.ref('/userProfile/' + this.uid + '/write').limitToLast(50).once('value').then(rst=>{
      //this.actlist = rst.val();
      rst.forEach(item=>{
        this.actlist.push(item.val());
      })
    }).then(_=>{
      this.actlist.map(data=>{
      var listdir = this.gv.getListDir(data.loclancode);
      data.title = this.afDB.database.ref(listdir + '/' +  data.itemid + '/title').once('value')
      data.imgtb = this.afDB.database.ref(listdir + '/' +  data.itemid + '/imagethumb').once('value')
      data.read = this.afDB.database.ref(listdir + '/' +  data.itemid + '/read').once('value')
      data.replycount = this.afDB.database.ref(listdir + '/' +  data.itemid + '/replycount').once('value')
      return data
    })
    })
  }*/

  getdata(infi ? ) {
    if (this.listtype == 'r') {
      this.title = '최근 본 글';
      let recentlist = this.recentlist.slice(this.recentshowcount - 10, this.recentshowcount);
      recentlist.map(data => {
        var listdir = this.gv.getListDir(data.loclancode);
        this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
          data.data = val.val()
          if (!val.val())
            data.data = {
              title: '작성자에 의해 삭제된 글입니다.',
              deleted: true
            };
        });
        this.actlist.push(data);
      })
      this.recentshowcount = this.recentshowcount + 10;
      this.loadingdone = true;
      if (infi)
        infi.target.complete();
    } else {
      if (this.listtype == 'w') {
        this.title = '작성한 글';
        this.dbname = 'write';
      } else if (this.listtype == 'l') {
        this.title = '좋아요';
        this.dbname = 'like';
      } else if (this.listtype == 'c') {
        this.title = '댓글';
        this.dbname = 'comment';
      }
      this.afDB.database.ref('/userProfile/' + this.uid + '/' + this.dbname)
        .orderByChild('createdDate').endAt(this.lastdate ? this.lastdate - 1 : 9999999999999)
        .limitToLast(this.msgshowcount).once('value').then(rst => {
          if (rst.val() && rst.val()[Object.keys(rst.val())[0]].createdDate)
            this.lastdate = rst.val()[Object.keys(rst.val())[0]].createdDate;

          this.templist = [];
          rst.forEach(item => {
            //console.log(this.templist);
            this.templist.unshift({
              loclancode: item.val().loclancode ? item.val().loclancode : 'cairns_kr',
              commentid: item.val().commentid ? item.val().commentid : null,
              itemid: item.val().itemid,
              createdDate: item.val().createdDate
            })
          })
        }).then(_ => {
          this.templist.map(data => {
            var listdir = this.gv.getListDir(data.loclancode);
            this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
              data.data = val.val()
              if (!val.val())
                data.data = {
                  title: '작성자에 의해 삭제된 글입니다.',
                  deleted: true
                };
            });
            this.actlist.push(data);
          })
        }).then(_ => {
          this.loadingdone = true;
          if (infi)
            infi.target.complete();
        })
    }

  }

  remove(itemid, i) {
    if (this.listtype == 'r')
      this.gv.removerecent(itemid);
    else {
      console.log(itemid);
      //this.afDB.database.ref('/userProfile/' + this.uid + '/' + this.dbname + '/' + ).
      /*orderByChild('itemid').equalTo(itemid).once('value').then(r=>{
        r.forEach(rr=>{
          this.afDB.database.ref('/userProfile/' + this.uid + '/' + this.dbname + '/' + rr.key).remove()
        })
      })*/
      //+ '/' + itemid).remove();
      //      this.afDB.database.ref('/userProfile/' + this.uid + '/' + this.dbname).remove(item);
    }
    this.actlist.splice(i, 1);
  }

  doRefreshMsg(infiniteScroll) {
    this.getdata(infiniteScroll);
  }

  /*
  async showpage(id, loclancode) {
    const modal = await this.modalCtrl.create({
      component: ChviewPage,
      componentProps: < null > {
        id: id,
        ignore: true,
        loclancode: loclancode
      },
      cssClass: 'modalfull'
    });
    return await modal.present();
  }*/

}