import {Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
@Pipe({
	name:'timeAgo',
	pure:false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
	private timer: number;
	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone,
	public translate: TranslateService) {}
	transform(value:string) {
		this.removeTimer();
		let d = new Date(value);
		let now = new Date();
		let seconds = Math.round(Math.abs((now.getTime() - d.getTime())/1000));
		let timeToUpdate = this.getSecondsUntilUpdate(seconds) *1000;
		this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.changeDetectorRef.detectChanges();
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});
		let minutes = Math.round(Math.abs(seconds / 60));
		let hours = Math.round(Math.abs(minutes / 60));
		let days = Math.round(Math.abs(hours / 24));
		let months = Math.round(Math.abs(days/30.416));
		let years = Math.round(Math.abs(days/365));

		var secago, minago, minsago, hourago, hoursago, yesterday, daysago, monthago, year, month, day;
		this.translate.get('a few seconds ago').subscribe(res=>{
		secago = res;
		});
		this.translate.get('minago').subscribe(res=>{
		minago = res;
		});
		this.translate.get('minsago').subscribe(res=>{
		minsago = res;
		});
		this.translate.get('hourago').subscribe(res=>{
		hourago = res;
		});
		this.translate.get('hoursago').subscribe(res=>{
		hoursago = res;
		}); 
		this.translate.get('yesterday').subscribe(res=>{
		yesterday = res;
		});
		this.translate.get('daysago').subscribe(res=>{
		daysago = res;
		});
		this.translate.get('monthago').subscribe(res=>{
		monthago = res;
		}); 
		this.translate.get('year').subscribe(res=>{
		year = res;
		});
		this.translate.get('month').subscribe(res=>{
		month = res;
		});
		this.translate.get('day').subscribe(res=>{
		day = res;
		}); 

		if (seconds <= 45) {
			return secago;
		} else if (seconds <= 90) {
			return minago;
		} else if (minutes <= 45) {
			return minutes + minsago;
		} else if (minutes <= 90) {
			return hourago;
		} else if (hours <= 22) {
			return hours + hoursago;
		} else if (hours <= 36) {
			return yesterday;
		}
		else if(days <= 25)
		{
			return days + daysago;
		}
		else if(days <= 45)
			return monthago;
		else //
			{
			if(d.getFullYear() == now.getFullYear())
				{
					if(month == 'month')
						return  months + 'month ago';//(d.getMonth()+1) + month + ' ' + d.getDate() + day; 
					else
						{
						if(this.translate.currentLang == 'kr' || this.translate.currentLang == 'ch' || this.translate.currentLang == 'jp')
							return (d.getMonth()+1) + month + ' ' + d.getDate() + day; //days + '일전';
						else 
							return (d.getMonth()+1) + '/' + d.getDate();
						}
				}
			else
				{
					if(month == 'month')
						{
							if (days <= 345) {
							return months + 'month ago';
							} 
							else if (days <= 545) {
							return 'a year ago';
							}
							else
							return years + 'years ago';
						} 
					else
						{
						if(this.translate.currentLang == 'kr' || this.translate.currentLang == 'ch' || this.translate.currentLang == 'jp')
							return d.getFullYear() + '. '+ (d.getMonth()+1) + '. ' + d.getDate();
						else// if(this.translate.currentLang == 'en')
							return d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear()// + ' '+  + month + ' ' +  + day;
						}
				}
			}
		
		/*
		  else if (days <= 25) {
			return days + '일전';
		} else if (days <= 45) {
			return '1개월전';
		} else if (days <= 345) {
			return months + '개월전';
		} else if (days <= 545) {
			return '1년전';
		} else { // (days > 545)
			return years + '년전';
		}*/
	}
	ngOnDestroy(): void {
		this.removeTimer();
	}
	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}
	private getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update ever 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}
}