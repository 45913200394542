import { Component, OnInit } from '@angular/core';
import { trigger,state,style,animate,transition} from '@angular/animations';
import {AngularFireDatabase} from '@angular/fire/database';
import {GlobalVars} from '../../providers/globalVars';
import {ModalController} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { OrderByPipe } from '../../pipe/orderbypipe';
import { MatchPipe } from '../../pipe/matchpipe';
import { ChcatPipe } from '../../pipe/chcatpipe';

@Component({
  selector: 'app-selectchannel',
  templateUrl: './selectchannel.component.html',
  styleUrls: ['./selectchannel.component.scss'],
})
export class SelectchannelComponent implements OnInit {

  actlist: any[] = [];
  recentshowlist: any[] = [];
  recentlist: any[] = [];
  // recentObjs: any;
  getrecent: any[] = [];
  recenttemp: any[] = [];
  datenow: any = new Date().getTime();
  templist: any[] = [];
  msgshowcount: any = 15;
  recentshowcount: any = 15;
  lastdate: any = null;
  type;
  listtype: any;
  dbname: any;
  selectedcat: any = 'bucket';
  editable: boolean = false;

  loadingdone:boolean = false;
  folderlist:any[] = [];

  folders: any[] = [];
  default: any[] = [];
  openindex:any = null;

  loaditemcnt: any = 10;
  Arr = Array;
  chlist: any[] = [];
  chrecent: any[] = [];
  categorytemp: any[] = [];
  filterCommon: any = 'plus';

  selectedTab = 'frequentChann';
  typemain: any = '';
  chorder: string = 'visited';

  appitem;
  adbanner:boolean;
  constructor( public gv: GlobalVars, 
    public afAuth: AngularFireAuth,private afDB: AngularFireDatabase,
    private translate: TranslateService,public modalCtrl:ModalController,
    private orderby: OrderByPipe) { }

  ngOnInit() {
    setTimeout(_=>{
      this.getdata();
    },1000);
    
  }

  getdata(infinite?) {

    let visitedch = this.gv.getvisitedch();
    this.chlist = [];
    this.categorytemp = [];
    this.afDB.database.ref('/category')
      .once('value').then(item => {
        this.chrecent = [];
        item.forEach(ch => {
          var lastupdate;
          if (ch.val().recent)
            lastupdate = ch.val().recent[Object.keys(ch.val().recent)[Object.keys(ch.val().recent).length - 1]].createdDate;
          else
            lastupdate = 0;
          var point;
          if (ch.val().publiccommunity)
            point = ch.val().point;
          else
            point = ch.val().point - 1000;

          let visited = 0;
          visitedch.forEach(vc=>{
            if(vc.chid == ch.key)
              visited = vc.createdDate;
          })

          this.categorytemp.push({
            key: ch.key,
            typemain: ch.val().typemain,
            imagethumb: ch.val().imagethumb,
            name: ch.val().name,
            replycount: ch.val().replycount,
            read: ch.val().read,
            createdDate: ch.val().createdDate,
            type: ch.val().type,
            userId: ch.val().userId,
            chinfo: ch.val().chinfo,
            category:ch.val().category,
            point: point,
            visited: visited,
            loc: ch.val().loc,
            description: ch.val().description,
            publiccommunity: ch.val().publiccommunity,
            lastupdate: lastupdate, // new:newupdate,
            options: ch.val().options?ch.val().options:null,
            ismine: ch.val().userId == this.gv.uid ? true : false,
            recentpost: this.afDB.database.ref('/category/' + ch.key + '/recent').orderByChild("createdDate")
              .startAt(this.datenow - (1000 * 60 * 60 * 24 * 2)).once('value').then(item => {
                if (item.val())
                  return Object.keys(item.val()).length
                else
                  return 0
              })
          });

        })
        this.chlist = this.categorytemp;
        // console.log(this.chlist);
      }).then(_ => {
        this.loadingdone = true;
      })

    this.Arr = Array;

    this.afDB.database.ref('/localmanage/' + this.gv.getLocLan()).once('value').then(items=>{
    });
    

  }

  
  confirm(item){
    this.gv.appitem = item;
    this.modalCtrl.dismiss({
      key: item.key,
      name: item.name,
      desc: item.description,
      category: item.category,
      img: item.imagethumb?item.imagethumb[0].url:'',
      options: item.options?item.options:null
    })
  }

  // doRefreshMsg(infiniteScroll) {
  //   if(this.selectedcat == 'recent')
  //     this.getdata(infiniteScroll);
  //   else
  //     infiniteScroll.target.complete();
  // }

  doInfinite(infiniteScroll) {
    this.loaditemcnt = this.loaditemcnt + 10;
    infiniteScroll.target.complete();
  }

}
