import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-infomodal',
  templateUrl: './infomodal.component.html',
  styleUrls: ['./infomodal.component.scss']
})
export class InfomodalComponent implements OnInit {
  imgval;
  name;
  desc;
  constructor(public modalCtrl:ModalController) { }

  ngOnInit() {
  }
}
