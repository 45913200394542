import { NgModule } from '@angular/core';
import { ObjPipe } from './objpipe';

@NgModule({
  declarations: [
    ObjPipe,
  ],
  imports: [
    
  ],
  exports: [
    ObjPipe
  ]
})
export class ObjPipeModule {}
