import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase,AngularFireList } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { GlobalVars } from '../../../providers/globalVars';
import { TranslateService } from '@ngx-translate/core';
import { msgfunc } from '../../../providers/msgfunc';
import { AccountPage } from '../../menu/account/account.page';
import { ModalController,AlertController,ToastController } from '@ionic/angular';
import { Http, Headers } from '@angular/http';

@Component({
  selector: 'app-systemlog',
  templateUrl: './systemlog.page.html',
  styleUrls: ['./systemlog.page.scss'],
})
export class SystemlogPage implements OnInit {
  loglist:any[] = [];//AngularFireList<any>;
  openindex:any;
  loaditemcnt:any = 25;
  lastlogin:any;
  key;
//  limitload:any = 100;
  constructor(public afAuth: AngularFireAuth, public afDB: AngularFireDatabase,public alertCtrl: AlertController, 
    private http:Http, private toastCtrl: ToastController
    ,public modalCtrl:ModalController,public translate: TranslateService,public msgfunc:msgfunc,public gv:GlobalVars) { }

  ngOnInit() {
    if(this.gv.manager)
    {
    if(this.gv.uid)
      this.getdata();
    /*
    this.afDB.database.ref('/systemlog').once('value').then(rst=>{
      rst.forEach(item=>{
        if(!item.val().nickname)// || !item.val().lastlogin)
        {  
          this.afDB.database.ref('/systemlog/unregister/lastlogin').once('value').then(d=>{
          this.afDB.database.ref('/systemlog/' + item.key).update({
          nickname:'익명',lastlogin:d.val()
          })
          })
        }
        /*
        if(item.val().nickname == '익명')
          this.afDB.database.ref('/systemlog/' + item.key).update({
          lastlogin:1563758438397
          })
      })
    })*/
    }
    else
      this.close();
  }

  getdata(infi?){
    let templist = [];
    this.afDB.database.ref('/systemlog').orderByChild('lastlogin')//.endAt(this.lastlogin ? this.lastlogin - 1 : 9999999999999)
        .limitToLast(500).once('value').then(rst=>{
          rst.forEach(item => {
            templist.unshift({key:item.key, data:item.val(), token:this.afDB.database.ref('/userProfile/' + item.key + '/fcmtoken').once('value')});
          })
          this.loglist = this.loglist.concat(templist);
          if(infi)
            infi.target.complete();
    })
  }
  chknoti(key,token){
    //this.afDB.database.ref('/userProfile/' + key + '/fcmtoken').once('value').then(token=>{
      let txt = "";
      var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://iid.googleapis.com/iid/info/' + token + '?details=true';
      new Promise((resolve, reject)=>{
      let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');//'key=AIzaSyBt4016A8UYis-WbUWwXEQQseVJIHNs3p4');
          
          this.http.get(apiUrl, {headers: headers})
              .subscribe(res => {
              console.log('클리너노티 시스템로그.......');
              let result = JSON.parse(res['_body']);
              console.log(result);
              
              if(result.rel && result.rel.topics)
              {
                let data = result.rel.topics;
                Object.keys(data).forEach(item=>{
                  if(item != 'ios' && item != 'android' && item != 'all')
                  {
                    if(item == key)
                      txt = txt? txt+ "<br/><br/>"+'개인알림수신: ' + data[item].addDate + ' 부터<br/>(키:' + item + ')':'개인알림수신: ' + data[item].addDate + ' 부터 (키:' + item + ')';
                    else
                      {
                        this.afDB.database.ref('/category/' + item + '/name').once('value').then(ch=>{
                          txt =  txt? txt+ "<br/><br/>"+ ch.val() + ': ' + data[item].addDate + ' 부터<br/>(키:' + item + ')':txt+ ch.val() + ': ' + data[item].addDate + ' 부터 (키:' + item + ')';
                        })
                      }
                  
                  }
                })
                setTimeout(_=> {
                  this.alertCtrl.create({
                   message:txt, buttons:["확인"]}).then(_=>_.present())
                }, 2000);
                
              }
              resolve(res);
              }, (err) => {
              console.log(err);
              reject(err);
              });
          })
    //})
  }


  

  changeopen(index)
  {
    if(this.openindex == index)
      this.openindex = null;
    else
      this.openindex = index;
  }
  close(){
    this.modalCtrl.dismiss();
  }
  async showprofile(key){
  const modal = await this.modalCtrl.create({
     component: AccountPage,
     cssClass: 'modalfull',
     componentProps: <null>{memid:key}
   });
    modal.present();
  }

  sendmsg(name,key)
  {
    var sendmsg, cancelbtn, sendbtn, phmsg, okmsg, containbadword;
      this.translate.get('Send a message').subscribe(res=>{
        sendmsg = res;
      });
      this.translate.get('Cancel').subscribe(res=>{
        cancelbtn = res;
      });
      this.translate.get('Send').subscribe(res=>{
        sendbtn = res;
      });
      this.translate.get('Please fill in the message').subscribe(res=>{
        phmsg = res;
      });
      this.translate.get('Confirm').subscribe(res=>{
        okmsg = res;
      });
      this.translate.get('Message contains inappropriate words').subscribe(res=>{
        containbadword = res;
      });
    this.alertCtrl.create({
        message: name + ' > ' + sendmsg,
        //message: "<span style='font-size:1.2rem; color:black'></span>",
        inputs: [
            {
            name: 'message',
            type: 'text'
            }
        ],
        buttons: [
            {
            text: cancelbtn,
            role: 'cancel',
            },
            {
            text: sendbtn,
            handler: msgdata => {
              if(!msgdata.message)
                  {
                    let alert = this.alertCtrl.create({
                      message: phmsg,
                      buttons: [okmsg]
                    });
                    this.sendmsg(name,key);
                    return;
                  }
                else
                {
                msgdata.message = this.bad_chkback(msgdata.message);
                this.msgfunc.sendmsg(this.gv.uid, key, msgdata.message, name);
                var sentmsg;
                this.translate.get('Message has sent').subscribe(res=>{
                  sentmsg = res;
                });
                let today = new Date();
                let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
                this.afDB.database.ref('/systemlog/' + (this.gv.uid?this.gv.uid:'unregister') + '/event/' + todaystr + '/msgsend').transaction(view=>{
                  return (view || 0) + 1;
                })
                this.presentToast('bottom', sentmsg);           
                }

            }
            }
        ]
        }).then(_=>_.present())
  }
  
    bad_chkback(str){ // 욕 필터링 , 스크립트 아이프레임도 필터링
    if(str == '')
        return false;
    var badword;
    this.translate.get('badword').subscribe(res=>{
      badword = res;
    });
    var YokList = badword.replace(/\'/g, '').split(",");
    var Tmp = str;
    for(var i=0 ; i<YokList.length ; i++){
        var tempRegex = new RegExp(YokList[i], "ig");
        Tmp = Tmp.replace(tempRegex, '*'.repeat(YokList[i].length))
    }
    return Tmp;
    }

    async presentToast(position,message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: position,
      duration: 1500
    });
    return await toast.present();
  }

   doInfinite(infiniteScroll) {
    
    setTimeout(_=>{this.loaditemcnt = this.loaditemcnt + 25;infiniteScroll.target.complete()},80);
    }

}
