import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { TypeKrNamePipe } from '../../../pipe/tkrnamepipe';
import { LevelinfoPage } from '../levelinfo/levelinfo.page';
import { GlobalVars } from '../../../providers/globalVars';

@Component({
  selector: 'app-mylevel',
  templateUrl: './mylevel.page.html',
  styleUrls: ['./mylevel.page.scss'],
})
export class MylevelPage implements OnInit {
  nickname:any
  uid:any;

  curpost:number = 0;
  curcmt:number = 0;
  curupdown:number = 0;
  curinvite:number = 0;

  curlevel:number = 0;
  curpercent:number = 0;

  nextpost:number = 0;
  nextcmt:number = 0;
  nextupdown:number = 0;
  nextinvite:number = 0;

  //recentact:any[] = [];
  constructor(public navCtrl: NavController,public translate: TranslateService,private gv:GlobalVars,
    public tkrpipe:TypeKrNamePipe,public afAuth: AngularFireAuth,public afDB: AngularFireDatabase) { 

  }

  ngOnInit() {
    
    if(this.gv.uid) {
      this.uid = this.gv.uid;
      this.getdata();
    } 
  }

  getdata(){
    var profile = this.afDB.object('/userProfile/'+this.uid );   
    profile.snapshotChanges().subscribe(profile => {
        if(profile['nickname'])
          this.nickname = profile['nickname'];
        if(profile['level'])
        {
        this.curpost = profile['level']['post'];
        this.curcmt = profile['level']['comment'];
        this.curupdown = profile['level']['up'] + profile['level']['down'];
        this.curinvite = profile['level']['invite'];
        this.curlevel = profile['level']['currentlevel'];
        this.afDB.database.ref('/level').once('value').then(levels=>{
          levels.forEach(lvlinfo => {
              if(lvlinfo.val().lid == profile['level']['currentlevel']+1)
              {
                  this.nextpost = lvlinfo.val().post;
                  this.nextcmt = lvlinfo.val().comment;
                  this.nextupdown = lvlinfo.val().updown;
                  this.nextinvite = lvlinfo.val().invite;
              }
          });
        }).then(_=>{
        let itemcount = (this.nextupdown > 0?1:0) + (this.nextcmt > 0?1:0) + 
          (this.nextpost > 0?1:0) + (this.nextinvite > 0?1:0);
        this.curpercent =
         Math.floor((
          (this.nextupdown == 0? 0:(this.curupdown >= this.nextupdown ? 1:this.curupdown/this.nextupdown))
        + (this.nextcmt == 0?0:(this.curcmt >= this.nextcmt ? 1:this.curcmt/this.nextcmt))
        + (this.nextpost == 0?0:(this.curpost >= this.nextpost ? 1: this.curpost/this.nextpost))
        + (this.nextinvite == 0?0:(this.curinvite >= this.nextinvite ? 1:this.curinvite/this.nextinvite))) / itemcount * 100);

        })
        }
        /*
        if(profile['write'])
        {
          console.log(profile['write']);
          console.log(profile['write'].length);

          profile['write'].forEach(item => {
            this.recentact.push({itemid:item.itemid, type:'Posting', 
          optiontype:this.tkrpipe.transform(item.type), createdDate:item.createdDate});                
          });
          }
        if(profile['dislike'])
        {

        }
        if(profile['like'])
        {

        }
        if(profile['comment'])
        {

        }
        if(profile['invite'])
        {

        }

        this.recentact =  this.recentact.map( (Items) => {
        return Items; } ).map((Items) =>{
            this.afDB.object('/list/' + Items.itemid + '/title').subscribe(item=>{
              Items.title = item.$value;
              if(!Items.title)
              {
                  this.translate.get('Deleted posting by writer').subscribe(res=>{
                  Items.title = "(" + res + ")";
                  });
              }
          });
          return Items;
        });*/
    });

  }

}
