import { NgModule } from '@angular/core';
import { RemovehtmltagPipe } from './removehtmltag';

@NgModule({
  declarations: [
    RemovehtmltagPipe,
  ],
  imports: [
    
  ],
  exports: [
    RemovehtmltagPipe
  ]
})
export class RemovehtmltagPipeModule {}
