import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonToggle,ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase,AngularFireList } from '@angular/fire/database';
import { GlobalVars } from '../../../providers/globalVars';

import {map} from 'rxjs/operators';
@Component({
  selector: 'app-setnoti',
  templateUrl: './setnoti.page.html',
  styleUrls: ['./setnoti.page.scss'],
})
export class SetnotiPage implements OnInit {

  favlist: any;

  //@ViewChild('all') allalarm: IonToggle;
  @ViewChild('personal') public personalalarm: IonToggle;
  @ViewChild('recent') recentalarm: IonToggle;
  @ViewChild('fav') public favalarm: IonToggle;
  uid: any;
  firstchk: boolean = true;
  eachchk: boolean = false;
  constructor(public modalCtrl: ModalController, public toastCtrl: ToastController, public gv:GlobalVars,
    public afAuth: AngularFireAuth, public afDB: AngularFireDatabase) {}

    
  ngOnInit() {
    this.uid = this.gv.uid;
    this.favlist = this.gv.ufavlist;
    this.afDB.database.ref('/userProfile/' + this.uid + '/alarm').once('value').then(rst => {
      let alarm = rst.val();
      if (alarm) {
        this.personalalarm.checked = alarm['personal'] ? alarm['personal'] : false; // 개인 알림
        // this.recentalarm.checked = alarm['recent']?alarm['recent']:false; // 모든 새글 알림
        this.favalarm.checked = alarm['fav'] ? alarm['fav'] : false; // 즐겨찾는 채널 알림
        
        
        this.eachchk = alarm['fav'];
      }
      setTimeout(_ => {
        this.initdone()
      }, 200)
    })
    //this.personalalarm.checked = true;
    //this.recentalarm.checked = true;
    //this.favalarm.checked = true;

    //this.getuserdata();
  }

  initdone() {
    this.personalalarm.disabled = false;
    // this.recentalarm.disabled = false;
    // if(this.recentalarm.checked == false)
    //   this.favalarm.disabled = false;
    this.favalarm.disabled = false;
    this.firstchk = false;
  }

  setpersonal(ev) {
    if (!this.firstchk) {
      this.afDB.database.ref('/userProfile/' + this.uid + '/alarm/personal').set(ev.detail.checked);
      this.presentToast(ev.detail.checked ? "개인알람을 수신합니다." : "개인알람을 받지않습니다.");
    }
  }
  setrecent(ev) {
    if (!this.firstchk) {
      if (ev.detail.checked)
        this.favalarm.disabled = true;
      else
        this.favalarm.disabled = false;
      this.afDB.database.ref('/userProfile/' + this.uid + '/alarm/recent').set(ev.detail.checked);
      this.presentToast(ev.detail.checked ? "새글 알람을 수신합니다." : "새글 알람을 받지않습니다.");
    }
  }
  setfav(ev) {
    if (!this.firstchk) {
      this.afDB.database.ref('/userProfile/' + this.uid + '/alarm/fav').set(ev.detail.checked);
      this.presentToast(ev.detail.checked ? "즐겨찾기의 모든 새글 알람을 받습니다." : "설정된 즐겨찾기 알람만 받겠습니다.");
    }
    this.eachchk = ev.detail.checked;
  }

  eachfav(ev,key) {
    if (!this.firstchk) {
      this.afDB.database.ref('/userData/' + this.uid + '/favlist/' + key + '/alarm').set(ev.detail.checked);
    }
    }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2200
    });
    toast.present();
  }


/*
  getuserdata() {
    this.afDB.list('/userData/' + this.uid + '/favlist')
      .pipe(map((arr) => {
        return arr
      })).pipe(map((data) => {
        return data.map((data) => {
          this.afDB.database.ref('/category/' + data.chid + '/name').once('value').then(val => { //console.log('읽음 변경됨'); 
            data.title = val.val()
          });
          // this.afDB.database.ref('/category/' + data.chid + '/imagethumb/0/url').once('value').then(val => { //console.log('읽음 변경됨'); 
          //   data.img = val.val();
          // });
          
          // this.afDB.database.ref('/category/' + data.chid + '/userId').once('value').then(val => {
          //   if (this.selfview && val.val() == this.uid)
          //     data.isMine = true;
          //   else if (!this.selfview && val.val() == this.memid)
          //     data.isMine = true;
          //   else
          //     data.isMine = false;
          // });
          return data
        })
      }))
      .subscribe(items => {
        if (items.length) {
          this.favlist = items;
          // console.log(this.favlist);
        }
      });
  }*/



}
