import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular'
@Component({
  selector: 'app-getpointmodal',
  templateUrl: './getpointmodal.component.html',
  styleUrls: ['./getpointmodal.component.scss']
})
export class GetpointmodalComponent implements OnInit {

  loaddone: any = false;
  constructor(public modal:ModalController) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    setTimeout(_=>{
       this.loaddone = true;
    },500);
    // setTimeout(_=>{
    //   this.loaddone = false;
    // },1500);
  }

}
