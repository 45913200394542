import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SearchResultPipeModule } from '../../../../pipe/searchresultpipe.module';
import { AddworkplacePage } from './addworkplace.page';

const routes: Routes = [
  {
    path: '',
    component: AddworkplacePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,TranslateModule,
    IonicModule,ReactiveFormsModule,SearchResultPipeModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [AddworkplacePage]
})
export class AddworkplacePageModule {}
