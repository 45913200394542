import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { MsgviewPage } from '../../../pages/general/msgview/msgview.page';
import { map } from 'rxjs/operators';
import { GlobalVars } from '../../../providers/globalVars';
@Component({
  selector: 'app-message',
  templateUrl: './message.page.html',
  styleUrls: ['./message.page.scss'],
})
export class MessagePage implements OnInit {
  uid:any;
  msgbox:any[] = [];//<any[]>;
  templist:any[] = [];
  msgshowcount:any = 10;
  lastdate:any=null;
  blockuser:any = '';
  editlist:any;
  loadingdone:boolean = false;
  constructor(public modalCtrl: ModalController, 
    private gv:GlobalVars,
    public navCtrl:NavController, public afAuth: AngularFireAuth,public afDB:AngularFireDatabase
  , private toastCtrl: ToastController) { }

  ngOnInit() {
    this.uid = this.gv.uid;
    this.afDB.list('/userProfile/' + this.uid + '/blockuser').snapshotChanges().subscribe(item => {
      this.blockuser = '';
      item.forEach(i=>this.blockuser += i['id'])
    });
  }

  ionViewDidEnter(){
    this.getdata();
  }

  getdata(infi?){
    //var temparr = [];
    this.afDB.database.ref('/msgbox/' + this.uid)
    .orderByChild('lastupdate').endAt(this.lastdate?this.lastdate-1:9999999999999)
    .limitToLast(this.msgshowcount).once('value').then(msgs=>{
        if(msgs.val() && msgs.val()[Object.keys(msgs.val())[0]].lastupdate)
        this.lastdate = msgs.val()[Object.keys(msgs.val())[0]].lastupdate;
        this.templist=[];
         msgs.forEach(msg => {
          var data = msg.val();
          if(data.unread)
            data.type = 'msgbox';
          else
            data.type = 'msgboxread';
          data.key = msg.key;
          this.afDB.database.ref('/msg/' + data.msgid + '/message').limitToLast(1).once('value')
          .then(lastitem=>{
          lastitem.forEach(element => {
              data.title = element.val().content;
              data.createdDate = data.lastupdate;
          });
          })
          data.searchuser = this.afDB.database.ref('/userProfile/'+data.chatwith+'/nickname').once('value');
          this.templist.unshift(data);
        });

        }).then(_=>{
        this.templist.map(data=>{this.msgbox.push(data)});
        if(infi)
          infi.target.complete();
        this.loadingdone = true;
        });
        
      
      /*for(let i = msgs.length; i > 0; i--)
          {
          console.log(i);
          var data = msgs[i].val();
          if(data.unread)
            data.type = 'msgbox';
          else
            data.type = 'msgboxread';
          data.categoryId = data.msgid;
          data.createdDate = data.lastupdate;
          this.afDB.database.ref('/msg/' + data.msgid + '/message').limitToLast(1).once('value')
          .then(lastitem=>{
          lastitem.forEach(element => {
              data.title = element.val().content;
          });
          })
          //data.userProfile = afDB.object('/userProfile/'+data.chatwith);
          data.searchuser = this.afDB.database.ref('/userProfile/'+data.chatwith+'/nickname').once('value')
          
        console.log(data);
        this.msgbox.push(data);
        if(this.msgbox.length > this.msgshowcount)
          break;
          }*/
    //})
    
    /*   
    <AngularFireList<any[]>> this.afDB.list('/msgbox/' + this.uid, {query: {
                          orderByChild: "lastupdate",
                          limitToLast:this.msgshowcount
                        }} )
                .pipe(map((data) => { 
                  return data.reverse().map((data) => {
                    if(data.unread)
                      data.type = 'msgbox';
                    else
                      data.type = 'msgboxread';
                    data.categoryId = data.msgid;
                    data.createdDate = data.lastupdate;
                    this.afDB.database.ref('/msg/' + data.msgid + '/message').limitToLast(1).once('value')
                    .then(lastitem=>{
                    lastitem.forEach(element => {
                        data.title = element.val().content;
                    });
                    })
                    //data.userProfile = afDB.object('/userProfile/'+data.chatwith);
                    data.searchuser = this.afDB.database.ref('/userProfile/'+data.chatwith+'/nickname').once('value')
                    
                  
                  return data
            })
        }));*/
  }
    async showmsg(msg,nickname){
    setTimeout(_=>{msg.unread = 0},1000);
    const modal = await this.modalCtrl.create({
      component: MsgviewPage,
      componentProps:<null>{key:msg.key,id:msg.msgid,chatwith:msg.chatwith,nickname:nickname},
      cssClass: 'modalfull'
    });
    return await modal.present();
    }

    doRefreshMsg(infiniteScroll){
    this.getdata(infiniteScroll);
    }

  removemsgbox(mboxkey,i){
    this.afDB.database.ref('/msgbox/' + this.uid + '/' + mboxkey).remove();
    this.msgbox.splice(i,1);
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 1000
    });
    return await toast.present();
  }



}
