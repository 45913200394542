import {
  Component,
  OnInit
} from '@angular/core';
import {
  ModalController
} from '@ionic/angular';

@Component({
  selector: 'app-doublecatmodal',
  templateUrl: './doublecatmodal.page.html',
  styleUrls: ['./doublecatmodal.page.scss'],
})
export class DoublecatmodalPage implements OnInit {

  selectedmain: any;
  selectedsub: any;
  selected;
  maincat:any;
  subcat:any;

  maxval: any;
  
  constructor(
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    if (`${this.selected}` != 'undefined') {
      this.selectedmain = {
        name: `${this.selected}`.split(' - ')[0]
      };
      this.selectedsub = {
        name: `${this.selected}`.split(' - ')[1]
      };
    }
    
    // 첫 메인 카테고리 on
    if( this.selectedmain.name == '' ) this.selectedmain.name = this.maincat[0].name;

    // 각 서브 카테고리 항목 구해서 높이값 고정
    this.maincat.len = [];
    this.maincat.isempty = [];
    for(let i=0; i < this.maincat.length; i++) {
      this.maincat.len.push(0);
      this.maincat.isempty.push(false);
      for(let d=0; d < this.subcat.length; d++) {
        if(this.maincat[i].name == this.subcat[d].parentVal ) {
          this.maincat.len[i] = this.maincat.len[i]+1;
          this.maincat.isempty[i] = true;
        }
      }
    }
    this.maxval = Math.max.apply(null, this.maincat.len);
    this.maxval = Math.ceil(this.maxval/2);
  }

  selectmain(main) {
    this.selectedmain = main;
  }

  close() {
    // console.log(this.selectedmain.name);
    // console.log(this.selectedsub.name);
    if (this.selectedmain.name == '') {
      alert('분류를 선택 해주세요!');
    } else {
      if (this.selectedsub.name == undefined) {
        this.modalCtrl.dismiss(this.selectedmain.name);
      } else {
        this.modalCtrl.dismiss(this.selectedmain.name + ' - ' + this.selectedsub.name);
      }
    }
  }

}