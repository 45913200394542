import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'EnterPipe'
})
@Injectable()
export class EnterPipe  implements PipeTransform {
 transform(item: string): any {
   if (!item) return [];
   return item.replace(/\n/g, "<br>");
}
}