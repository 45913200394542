import { Component, OnInit } from '@angular/core';
import { trigger,state,style,animate,transition} from '@angular/animations';
import {AngularFireDatabase} from '@angular/fire/database';
import {GlobalVars} from '../../../../providers/globalVars';
import {ModalController} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-inapplink',
  templateUrl: './inapplink.page.html',
  styleUrls: ['./inapplink.page.scss'],
  animations: [
    trigger('elementState', [
      state('open', style({
        // opacity: 1,
        'max-height': '335px',
        // transform: 'scaleY(1)',
        // 'visibility': 'visible'
      })),
      state('closed', style({
        // opacity: 0,
        'max-height': 0,
        // transform: 'scaleY(0)',
        // 'visibility': 'hidden'
      })),
      transition('open => closed', animate('150ms ease-in')),
      transition('closed => open', animate('150ms ease-in'))
      // transition('* => *', animate('500ms'))
      // transition(':enter', animate('400ms')),
      // transition('* => *', animate('400ms')),
    ])
  ]
})
export class InapplinkPage implements OnInit {
  actlist: any[] = [];
  recentshowlist: any[] = [];
  recentlist: any[] = [];
  datenow: any = new Date().getTime();
  templist: any[] = [];
  uid: any;
  msgshowcount: any = 15;
  recentshowcount: any = 15;
  lastdate: any = null;
  type;
  listtype: any;
  dbname: any;
  selectedcat: any = 'bucket';
  editable: boolean = false;

  loadingdone:boolean = false;
  folderlist:any[] = [];

  folders: any[] = [];
  default: any[] = [];
  openindex:any = null;

  constructor( private gv: GlobalVars, 
    public afAuth: AngularFireAuth,private afDB: AngularFireDatabase,
    private translate: TranslateService,public modalCtrl:ModalController) { }

  ngOnInit() {
    this.recentlist = this.gv.getrecent();
    this.uid = this.gv.uid;
    this.getdata();
  }
  getdata(infi?){
    if(this.selectedcat == 'recent'){
      let recentlist = this.recentlist.slice(this.recentshowcount - 15, this.recentshowcount);
      recentlist.map(data => {
        var listdir = this.gv.getListDir(data.loclancode);
        this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
          data.data = val.val()
          if (val.val())
            this.recentshowlist.push(data);
           /* data.data = {
              title: '작성자에 의해 삭제된 글입니다.',
              deleted: true
            };*/
        });
        
      })
      this.recentshowcount = this.recentshowcount + 15;
      this.loadingdone = true;
      if (infi)
        infi.target.complete();
    }
    else{
      if(!infi)
      {
        let recentlist = this.recentlist.slice(this.recentshowcount - 15, this.recentshowcount);
        recentlist.map(data => {
          var listdir = this.gv.getListDir(data.loclancode);
          this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
            data.data = val.val()
            if (val.val())
              this.recentshowlist.push(data);
            /*data.data = {
                title: '작성자에 의해 삭제된 글입니다.',
                deleted: true
              };*/
          });
        })
      }
      this.afDB.database.ref('/userData/' + this.uid + '/mybucket/items').once('value').then(items=>{
            items.forEach(itemdata=>{
              let item = itemdata.val();
              if(!item.loclancode)
                item.loclancode = 'cairns_kr' //'/lists/cairns_kr';
              this.afDB.database.ref(this.gv.getListDir(item.loclancode) + '/' + item.itemid).once('value').then(rst=>{
                item.title = rst.val().title;
                item.communityname = rst.val().communityname;
                item.type = rst.val().type;
                item.createdDate = rst.val().createdDate;
                item.replycount = rst.val().replycount;
                item.description = rst.val().description;
                if(rst.val().imagethumb)
                  item.thumburl = rst.val().imagethumb[0].url;
                item.$key = itemdata.key; 
                this.default.push(item);
              })
            })

            })

            this.afDB.database.ref('/userData/' + this.uid + '/mybucket/folders').once('value').then(items=>{
              this.folderlist = [];
              items.forEach(item=>{
                this.folderlist.unshift({$key:item.key, title:item.val().title});
              })
            })
          .then(_ => {
          this.loadingdone = true;
          if (infi)
            infi.target.complete();
        })
    }
  }

  foldertoggle(i)
  {
    if(this.openindex == i)
      this.openindex = null;
    else this.openindex = i;
  }
  confirm(type,item){
    let tagstr;
    let channelstr;
    this.translate.get('Channel').subscribe(res=>{channelstr = res;});
    if(type == 'ch')
    {
      var div = document.createElement("div");
      div.innerHTML = item.data.description;
      let desc = div.innerText.replace(/\n/g,' ').substr(0,35);
      // tagstr = 
      // `<div class="attached_links" class="link*#*` + item.itemid + `*#*` + item.loclancode + `"><div class="item ch-card"><div class="linkthumb">` + (item.data.imagethumb?`<img class="skip" src="` + item.data.imagethumb[0]['url'] + `" />`:``) + `</div><div class="linkcont"><div class="inner"><span class="tit">`+ item.data.title +`</span><span class="txt">` + desc + `</span><span class="link">채널 > ` + item.data.communityname + `</span></div></div></div></div>`
      // pipe 처리할때 attached_links 클래스 추가하는 방식으로 변경 - 191122
      tagstr = 
      `<div class="attached_links"><div class="link*#*` + item.itemid + `*#*` + item.loclancode + `"><div class="item ch-card"><div class="linkthumb">` + (item.data.imagethumb?`<img class="skip" src="` + item.data.imagethumb[0]['url'] + `" />`:`<img class="nothumb" src="/assets/img/blankimg.png" />`) + `</div><div class="linkcont"><div class="inner"><span class="tit">`+ item.data.title +`</span><span class="txt">` + desc + `</span><span class="link">` + channelstr + ` > ` + item.data.communityname + `</span></div></div></div></div></div>`
    }
    else if(type == 'bucketitem')
    {
      var div = document.createElement("div");
      div.innerHTML = item.description;
      let desc = div.innerText.replace(/\n/g,' ').substr(0,35);
      // tagstr = 
      // `<div class="attached_links" class="link*#*` + item.itemid + `*#*` + item.loclancode + `"><div class="item ch-card"><div class="linkthumb">` + (item.thumburl?`<img class="skip" src="` + item.thumburl + `" />`:``) + `</div><div class="linkcont"><div class="inner"><span class="tit">`+ item.title +`</span><span class="txt">` + desc + `</span><span class="link">채널 > ` + item.communityname + `</span></div></div></div></div>`
      // pipe 처리할때 attached_links 클래스 추가하는 방식으로 변경 - 191122
      tagstr = 
      `<div class="attached_links"><div class="link*#*` + item.itemid + `*#*` + item.loclancode + `"><div class="item ch-card"><div class="linkthumb">` + (item.thumburl?`<img class="skip" src="` + item.thumburl + `" />`:`<img class="nothumb" src="/assets/img/blankimg.png" />`) + `</div><div class="linkcont"><div class="inner"><span class="tit">`+ item.title +`</span><span class="txt">` + desc + `</span><span class="link">` + channelstr + ` > ` + item.communityname + `</span></div></div></div></div></div>`
    }
    this.modalCtrl.dismiss({tag:tagstr})
  }

  doRefreshMsg(infiniteScroll) {
    if(this.selectedcat == 'recent')
      this.getdata(infiniteScroll);
    else
      infiniteScroll.target.complete();
  }

}
