import { Component, OnInit } from '@angular/core';
import { NavController, AlertController, ModalController, LoadingController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase/app';
import { GlobalVars } from '../../../providers/globalVars';
import { Http, Headers } from '@angular/http';
@Component({
  selector: 'app-mypoint',
  templateUrl: './mypoint.page.html',
  styleUrls: ['./mypoint.page.scss'],
})
export class MypointPage implements OnInit {
  pointlist: any[] = [];
  uid:any;
  loaditemcnt:any = 10;
//  lastshowcount:any = 0;
  lastdate:any = null;
  todaypoint:any = 0;
  pointcalcdata:any = [];
  pointreceived:any = true;
  nomoredata:boolean = false;
  reload:boolean = true;
  constructor(public navCtrl: NavController,public afAuth: AngularFireAuth,public gv:GlobalVars,private alertCtrl:AlertController,
    private http:Http ,private modalCtrl:ModalController,
    public translate: TranslateService, public afDB: AngularFireDatabase, private loadingCtrl:LoadingController) { }

  ngOnInit() {
    this.uid = this.gv.uid;
  }
  
  ionViewWillEnter(){
    if(this.reload)
    {this.gettoday();
    this.lastdate = null;
    this.loaditemcnt = 10;
    this.pointlist = [];
    this.getdata();
    }
    else
      this.reload = true;
  }

  close(){
    this.navCtrl.pop();//("/tabs/pfmenu");
  }
  gettoday(){ 
    let today = new Date();    
    this.afDB.database.ref('/point/'+this.uid).orderByChild('createdDate')
    .startAt(today.getTime()-(1000*60*60*24)).once('value').then(pt=>{
      this.todaypoint = 0;
      let todaystr = today.toLocaleDateString();
      //console.log(Object.keys(pt.val()).length + '개항목')
      pt.forEach(p=>{
        if(
          (p.val().point > 0) && 
          (todaystr == new Date(p.val().createdDate).toLocaleDateString())
          )
          this.todaypoint += p.val().point;
      })
    });
    this.afDB.database.ref('/userProfile/' + this.gv.uid + '/totalcount').once('value').then(total => {
        this.gv.utotal = total.val();
      })
  }
  getdata(infi?){
    /*
    this.afDB.database.ref('/userProfile/' + this.uid + '/invite').once('value').then(data=>{
      //console.log(Object.keys(data.val()).length);
      data.forEach(element => {
        this.afDB.database.ref('/userProfile/' + this.uid + '/activity/invite').push(element.val())
      });
    }).then(_=>{

    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/like').once('value').then(data=>{
      //console.log(Object.keys(data.val()).length);
      data.forEach(element => {
        this.afDB.database.ref('/userProfile/' + this.uid + '/activity/invite').push(element.val())
      });
    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/dislike').once('value').then(data=>{
      //console.log(Object.keys(data.val()).length);
      data.forEach(element => {
        this.afDB.database.ref('/userProfile/' + this.uid + '/activity/invite').push(element.val())
      });
    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/comment').once('value').then(data=>{
      //console.log(data.length);
    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/write').once('value').then(data=>{
      //console.log(data.length);
    })
*/

    this.afDB.database.ref('/point/'+this.uid).orderByChild('createdDate')
    .endAt(this.lastdate?this.lastdate-1:9999999999999)
    .limitToLast(this.loaditemcnt).once('value').then(points=>{
      if(points.val() && points.val()[Object.keys(points.val())[0]].createdDate)
        this.lastdate = points.val()[Object.keys(points.val())[0]].createdDate;
      if(!points.val())
        this.nomoredata = true;
      points.forEach(point => {
          //this.pointtotal = this.pointtotal + point.val().point;
          //if(this.lastshowcount < this.loaditemcnt)
          //{
          this.pointlist.push(point.val());
          //this.lastshowcount++;
          //}
      });
  }).then(_=>{
    this.pointlist.map( (Items) => {
return Items; } ).map((Items) =>{
    if(Items.pcode == 'updown' || Items.pcode == 'gotliked' || Items.pcode == 'comment' ||
      Items.pcode == 'gotreplyremove' || Items.pcode == 'commentremove' ||
       Items.pcode == 'posting' || Items.pcode == 'gotreply')
    {
        /*if(Items.loclancode != 'cairns_kr')
        {
            this.afDB.object('/lists/' + Items.loclancode + '/' + Items.itemid + '/title').subscribe(item=>{
            Items.title = item.$value;
            if(!Items.title)
            {
                this.translate.get('Deleted posting by writer').subscribe(res=>{
                Items.title = "(" + res + ")";
                });
            }
            });
        }
        else
        {*/
            this.afDB.object('/list/' + Items.itemid + '/title').snapshotChanges().subscribe(item=>{
            Items.title = item.payload.val();
            if(!Items.title)
            {
                this.translate.get('Deleted posting by writer').subscribe(res=>{
                Items.title = "(" + res + ")";
                });
            }
            });
        //}
    }
    else if(Items.pcode == 'itempurchase' || Items.pcode == 'itemsale')
    {
      this.afDB.object('/userData/' + this.gv.uid + '/pointitem/' + Items.itemid + '/pitemid').snapshotChanges().subscribe(item=>{
        this.afDB.database.ref('/pmall/' + item.payload.val() + '/title').once('value').then(rst=>{
          Items.title = rst.val();
          if(!Items.title)
          {
              Items.title = "(없어진 상품입니다.)";
          }
          });
        })
      /*Items.title = item.$value;
      if(!Items.title)
      {
          Items.title = "(없어진 상품입니다.)";
      }
      });*/
    }
    else if(Items.pcode == 'daily')
    {
      Items.title = Items.day;
    }
  })
  }).then(_=>{//this.lastshowcount = this.loaditemcnt
              if(infi) infi.target.complete();
              })
  }

  async getunreceivedpoint(){
    let loadingPopup = await this.loadingCtrl.create({
        spinner: 'crescent', 
        message: "포인트 집계중입니다"
      });
    loadingPopup.present();

    console.log('***** 누적포인트 집계 결과 *****');
    console.log('회원명:' + this.gv.unickname);
    this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('newreg').once('value').then(
            recdata=>{
              if(!recdata.val())
                {
                this.afDB.database.ref('/point/' + this.uid).push
                ({pcode:'newreg', point:50, createdDate:firebase.database.ServerValue.TIMESTAMP});
                console.log('<<가입 포인트 50점 지급완료>>')
                }
              else
                recdata.forEach(element => { console.log('이미지급됨] 가입 포인트 50점- ' + 
              new Date(element.val().createdDate).toLocaleString()); });
                   //recdata.val().createdDate);
            }
    )
    this.afDB.database.ref('/userProfile/' + this.uid).once('value').then(profile=>{
      if(profile.val().recommended)
        {
          this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('fillrec').once('value').then(
            recdata=>{
              if(!recdata.val())
                {
                this.afDB.database.ref('/point/' + this.uid).push({pcode:'fillrec', nickname:profile.val().recommended, point:50, createdDate:firebase.database.ServerValue.TIMESTAMP});
                console.log('<<추천인 작성 포인트 50점 지급완료>>')
                }

              else
                recdata.forEach(element => { console.log('이미지급됨] 추천인 작성 포인트 50점- ' + new Date(element.val().createdDate).toLocaleString()); });
                
            }
          )
        }
     else
        console.log('###추천자없음###');

      //console.log('등급업시 : pont:50*레벨, pcode:levelup'); // 유저 level 생성후 level 체크
      if(profile.val().level && profile.val().level.currentlevel)
      {
        for(let i = 1; i <= profile.val().level.currentlevel; i++)
          {
            this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('levelup').once('value').then(
            data=>{

                  var chkhasitem = false;
                  data.forEach(recdata => {
                    if(recdata.val().level == i)
                      {
                      chkhasitem = true;
                      console.log('이미지급됨] 레벨업' + i + ' 포인트(' + recdata.val().point + ') - ' + new Date(recdata.val().createdDate).toLocaleString());
                      }
                  });
                  if(!chkhasitem)
                    {
                    this.afDB.database.ref('/point/' + this.uid).push({pcode:'levelup', point:i*50 ,level:i, createdDate:firebase.database.ServerValue.TIMESTAMP});
                    console.log('<<' + i + '레벨업 포인트 ' + i*50 + '점 지급완료>>')
                    }    
            
                }
          )
            //console.log(i + '레벨포인트, 획득 : pont:' + i*50 + ', pcode:levelup');
          }
      }
      else{
        console.log('###레벨정보없음###');
      }
      })
    .then(_=>{
        this.afDB.database.ref('/userProfile/' + this.uid + '/invite').once('value').then(invites=>{
          invites.forEach(invite => {
            if(invite.val().title == 'inviteuser')
              {
                this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('getrec').once('value').then(
                data=>{
                  var chkhasitem = false;
                  data.forEach(recdata => {
                    if(recdata.val().nickname == invite.val().nickname)
                      {
                      chkhasitem = true;
                      console.log('이미지급됨] 가입자 추천포인트 100점(' + recdata.val().nickname + ') - ' + new Date(recdata.val().createdDate).toLocaleString());
                      }
                  });
                  if(!chkhasitem)
                    {
                    this.afDB.database.ref('/point/' + this.uid).push({pcode:'getrec', point:100 ,nickname:invite.val().nickname, createdDate:firebase.database.ServerValue.TIMESTAMP});
                    console.log('<<가입자 추천포인트(' + invite.val().nickname + ') 100점 지급완료>>')
                    }    
                }
              )
              }
          });
        }).then(_=>{
          this.afDB.database.ref('/userProfile/' + this.uid + '/like').once('value').then(likes=>{
          likes.forEach(like => {
            
            var itemid, loclancode;
            if(!like.val().itemid)
              itemid = like.val().itemId;
            else
              itemid = like.val().itemid;
            if(!like.val().loclancode)
              loclancode = 'cairns_kr';
            else
              loclancode = like.val().loclancode;

            this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('updown').once('value').then(
                data=>{
                  var chkhasitem = false;
                  data.forEach(recdata => {
                    if(recdata.val().itemid == itemid)
                      {
                      chkhasitem = true;
                      console.log('이미지급됨] 좋아요 포인트 1점(' + recdata.val().itemid + ') - ' + new Date(recdata.val().createdDate).toLocaleString());
                      }
                  });
                  if(!chkhasitem)
                    {
                    this.afDB.database.ref('/point/' + this.uid).push({pcode:'updown', point:1, loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                    console.log('<<좋아요 포인트(' + itemid + ') 1점 지급완료>>')
                    }    
                }
              )
          });
        }).then(_=>{
          this.afDB.database.ref('/userProfile/' + this.uid + '/dislike').once('value').then(dislikes=>{
          dislikes.forEach(dislike => {
            var itemid, loclancode;
            if(!dislike.val().itemid)
              itemid = dislike.val().itemId;
            else
              itemid = dislike.val().itemid;
            if(!dislike.val().loclancode)
              loclancode = 'cairns_kr';
            else
              loclancode = dislike.val().loclancode;
            
            this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('updown').once('value').then(
                data=>{
                  var chkhasitem = false;
                  data.forEach(recdata => {
                    if(recdata.val().itemid == itemid)
                      {
                      chkhasitem = true;
                      console.log('이미지급됨] 싫어요 포인트 1점(' + recdata.val().itemid + ') - ' + new Date(recdata.val().createdDate).toLocaleString());
                      }
                  });
                  if(!chkhasitem)
                    {
                    this.afDB.database.ref('/point/' + this.uid).push({pcode:'updown', point:1,loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                    console.log('<<싫어요 포인트(' + itemid + ') 1점 지급완료>>')
                    }    
                }
              )
          });
        }).then(_=>{
            this.afDB.database.ref('/userProfile/' + this.uid + '/comment').once('value').then(comments=>{
          comments.forEach(comment => {
            var itemid, loclancode;
            if(!comment.val().itemid)
              itemid = comment.val().itemId;
            else
              itemid = comment.val().itemid;
            if(!comment.val().loclancode)
              loclancode = 'cairns_kr';
            else
              loclancode = comment.val().loclancode;
            
            this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('comment').once('value').then(
                data=>{
                  var chkhasitem = false;
                  data.forEach(recdata => {
                    if(recdata.val().itemid == itemid)
                      {
                      chkhasitem = true;
                      console.log('이미지급됨] 댓글작성 포인트 2점(' + recdata.val().itemid + ') - ' + new Date(recdata.val().createdDate).toLocaleString());
                      }
                  });
                  if(!chkhasitem)
                    {
                      // 댓글작성포인트에 내글에단 댓글은 포인트 지급하지 않음 - 대신 댓글달린 갯수 포인트는 지급받음
                      // 댓글 작성자 아이디 확인 후 포인트 부여 
                      
                      if(loclancode != 'cairns_kr')
                        {
                          this.afDB.database.ref('/lists/' + loclancode + '/' + itemid + '/userId').once('value').then(rst=>{
                            if(rst.val() == this.uid)
                            {
                              this.afDB.database.ref('/point/' + this.uid).push({pcode:'comment', point:0, selfcomment:true, loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('<<댓글작성 포인트(' + itemid + ') 0점 지급완료>>')
                            }
                            else
                            {
                              this.afDB.database.ref('/point/' + this.uid).push({pcode:'comment', point:2, selfcomment:false, loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('<<댓글작성 포인트(' + itemid + ') 2점 지급완료>>')
                            }
                          })
                        }
                      else
                        {
                          this.afDB.database.ref('/list/' + itemid + '/userId').once('value').then(rst=>{
                            if(rst.val() == this.uid)
                            {
                              this.afDB.database.ref('/point/' + this.uid).push({pcode:'comment', point:0, selfcomment:true, loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('<<댓글작성 포인트(' + itemid + ') 0점 지급완료>>')
                            }
                            else
                            {
                              this.afDB.database.ref('/point/' + this.uid).push({pcode:'comment', point:2, selfcomment:false, loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('<<댓글작성 포인트(' + itemid + ') 2점 지급완료>>')
                            }
                          })
                        }
                    }    
                }
              )
          });
        }).then(_=>{
           this.afDB.database.ref('/userProfile/' + this.uid + '/write').once('value').then(writings=>{
          writings.forEach(writing => {
            var itemid, loclancode;
            if(!writing.val().itemid)
              itemid = writing.val().itemId;
            else
              itemid = writing.val().itemid;
            if(!writing.val().loclancode)
              loclancode = 'cairns_kr';
            else
              loclancode = writing.val().loclancode;
            
            this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('posting').once('value').then(
                data=>{
                  var chkhasitem = false;
                  data.forEach(recdata => {
                    if(recdata.val().itemid == itemid)
                      {
                      chkhasitem = true;
                      console.log('이미지급됨] 글작성 포인트 10점(' + recdata.val().itemid + ') - ' + new Date(recdata.val().createdDate).toLocaleString());
                      }
                  });
                  if(!chkhasitem)
                    {
                    this.afDB.database.ref('/point/' + this.uid).push({pcode:'posting', point:10, loclancode:loclancode, itemid:itemid, createdDate:firebase.database.ServerValue.TIMESTAMP});
                    console.log('<<글작성 포인트(' + itemid + ') 10점 지급완료>>')
                    }    
                }
              )

            if(loclancode != 'cairns_kr')
            {
              this.afDB.database.ref('/lists/' + loclancode + '/' + itemid).once('value').then(wr=>{
                if(wr.val() && wr.val().up && wr.val().up > 0)
                {
                  //console.log(wr.val().up + '좋아요, 획득 : point:' + wr.val().up*2 + ', pcode:gotliked');
                    this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('gotliked').once('value').then(
                    data=>{
                      var chkhasitem = false;
                      data.forEach(recdata => {
                        if(recdata.val().itemid == wr.key)
                          {
                            chkhasitem = true;
                            if(recdata.val().up != wr.val().up)
                            {
                              this.afDB.database.ref('/point/' + this.uid + '/' + recdata.key).update({point:wr.val().up*2, up:wr.val().up, itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('[[좋아요 획득 포인트(' + wr.key + ') ' + wr.val().up + '좋아요 / ' + wr.val().up*2 +'점 수정 지급완료]]')
                            }
                            else
                              console.log('이미지급됨] 좋아요 획득 포인트 (' + recdata.val().itemid + ') ' + recdata.val().up + '좋아요 / ' + recdata.val().point  + '점 - ' + new Date(recdata.val().createdDate).toLocaleString());
                          }
                      });
                      if(!chkhasitem)
                        {
                          
                        this.afDB.database.ref('/point/' + this.uid).push({pcode:'gotliked', point:wr.val().up*2, up:wr.val().up, loclancode:loclancode,itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                        console.log('<<좋아요 획득 포인트(' + wr.key + ') / ' + wr.val().up + '좋아요, ' + wr.val().up*2 + '점 지급완료>>')
                        }    
                    }
                  )
                }
                if(wr.val() && wr.val().replycount && wr.val().replycount > 0)
                {
                  //console.log(wr.val().replycount + '댓글, 획득 : point:' + wr.val().replycount*2 + ', pcode:gotreply');
                  this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('gotreply').once('value').then(
                    data=>{
                      var chkhasitem = false;
                      data.forEach(recdata => {
                        if(recdata.val().itemid == wr.key)
                          {
                            chkhasitem = true;
                            if(recdata.val().replycount != wr.val().replycount)
                            {
                              this.afDB.database.ref('/point/' + this.uid + '/' + recdata.key).update({point:wr.val().replycount*2, replycount:wr.val().replycount, itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('[[댓글 획득 포인트(' + wr.key + ') ' + wr.val().replycount + '댓글 / ' + wr.val().replycount*2 +'점 수정 지급완료]]')
                            }
                            else
                              console.log('이미지급됨] 댓글 획득 포인트 (' + recdata.val().itemid + ') ' + recdata.val().replycount + '댓글 / ' + recdata.val().point  + '점 - ' + new Date(recdata.val().createdDate).toLocaleString());
                          }
                      });
                      if(!chkhasitem)
                        {
                          
                        this.afDB.database.ref('/point/' + this.uid).push({pcode:'gotreply', point:wr.val().replycount*2, replycount:wr.val().replycount,loclancode:loclancode,itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                        console.log('<<댓글 획득 포인트(' + wr.key + ') / ' + wr.val().replycount + '댓글, ' + wr.val().replycount*2 + '점 지급완료>>')
                        }    
                    }
                  )
                }
              })
            }
            else
            {
              this.afDB.database.ref('/list/' + itemid).once('value').then(wr=>{
                if(wr.val() && wr.val().up && wr.val().up > 0)
                {
                  this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('gotliked').once('value').then(
                    data=>{
                      var chkhasitem = false;
                      data.forEach(recdata => {
                        if(recdata.val().itemid == wr.key)
                          {
                            chkhasitem = true;
                            if(recdata.val().up != wr.val().up)
                            {
                              this.afDB.database.ref('/point/' + this.uid + '/' + recdata.key).update({point:wr.val().up*2, up:wr.val().up, itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('[[좋아요 획득 포인트(' + wr.key + ') ' + wr.val().up + '좋아요 / ' + wr.val().up*2 +'점 지급완료]]')
                            }
                            else
                              console.log('이미지급됨] 좋아요 획득 포인트 (' + recdata.val().itemid + ') ' + recdata.val().up + '좋아요 / ' + recdata.val().point  + '점 - ' + new Date(recdata.val().createdDate).toLocaleString());
                          }
                      });
                      if(!chkhasitem)
                        {
                        this.afDB.database.ref('/point/' + this.uid).push({pcode:'gotliked', point:wr.val().up*2, up:wr.val().up,loclancode:loclancode,itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                        console.log('<<좋아요 획득 포인트(' + wr.key + ') / ' + wr.val().up + '좋아요, ' + wr.val().up*2 + '점 지급완료>>')
                        }    
                    }
                  )
                }
                if(wr.val() && wr.val().replycount && wr.val().replycount > 0)
                {
                  //console.log(wr.val().replycount + '댓글, 획득 : point:' + wr.val().replycount*2 + ', pcode:gotreply');
                  this.afDB.database.ref('/point/' + this.uid).orderByChild('pcode').equalTo('gotreply').once('value').then(
                    data=>{
                      var chkhasitem = false;
                      data.forEach(recdata => {
                        if(recdata.val().itemid == wr.key)
                          {
                            chkhasitem = true;
                            if(recdata.val().replycount != wr.val().replycount)
                            {
                              this.afDB.database.ref('/point/' + this.uid + '/' + recdata.key).update({point:wr.val().replycount*2, replycount:wr.val().replycount, itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                              console.log('[[댓글 획득 포인트(' + wr.key + ') ' + wr.val().replycount + '댓글 / ' + wr.val().replycount*2 +'점 수정 지급완료]]')
                            }
                            else
                              console.log('이미지급됨] 댓글 획득 포인트 (' + recdata.val().itemid + ') ' + recdata.val().replycount + '댓글 / ' + recdata.val().point  + '점 - ' + new Date(recdata.val().createdDate).toLocaleString());
                          }
                      });
                      if(!chkhasitem)
                        {
                          
                        this.afDB.database.ref('/point/' + this.uid).push({pcode:'gotreply', point:wr.val().replycount*2, replycount:wr.val().replycount,loclancode:loclancode,itemid:wr.key, createdDate:firebase.database.ServerValue.TIMESTAMP});
                        console.log('<<댓글 획득 포인트(' + wr.key + ') / ' + wr.val().replycount + '댓글, ' + wr.val().replycount*2 + '점 지급완료>>')
                        }    
                    }
                  )
                }
              })
            } 
          });
        })
        })
        })
        })
        })
        this.afDB.database.ref('/userData/' + this.uid + '/state/receivedprepoint').set(true);
        setTimeout(_=> {
          loadingPopup.dismiss();
          this.pointreceived = true;
          var apiUrl = "https://stark-sands-51177.herokuapp.com/https://us-central1-localnews-e350b.cloudfunctions.net/recountPoint?idt=" + this.uid;
          new Promise((resolve, reject)=>{
          let headers = new Headers();
              headers.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
              this.http.get(apiUrl
                , {headers: headers})
                  .subscribe(res => {
                    this.afDB.database.ref('/userProfile/' + this.gv.uid + '/totalcount').once('value').then(total => {
                      this.gv.utotal = total.val();
                    })
                  resolve(res);
                  }, (err) => {
                  console.log(err);
                  reject(err);
                  });
              });
        }, 2500);
        setTimeout(_=>{
          this.lastdate = null;
          this.gettoday();
          this.getdata();},4000);
      })
    
    //console.log('공유하기 : pont:0, pcode:usedshare'); // 포인트는주지말자..악용함.. 유저 invite - title: inviteuser가 아닌 모든항목
    //console.log('**좋아요획득 : pont:2, pcode:getliked'); // 유저 writing의 모든 글 리스트 돌면서 up 횟수만큼 업
    //console.log('메인글당첨시 : pont:100, pcode:babnnerpicked'); // 당첨시 이벤트 작성
    //console.log('로그인 : pont:2, pcode:dailylogin'); // 로그인시 당일 로고 없다면 생성
    //console.log('10일연속로그인 : pont:50, pcode:10daylogin'); // 연속 로그인시마다 카운팅, 0이 아니고 %10하여 0이면 이벤트 발생, 계속 카운팅, 연속로그인 실패시 0 초기화
    
  }


  
doInfinite(infiniteScroll) {
    //this.loaditemcnt = this.loaditemcnt+10;
    this.getdata(infiniteScroll);
  }
}
