import {Injectable} from '@angular/core';
import { Http } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase/app';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AlertController } from '@ionic/angular';
@Injectable()
export class sharefunc {
  constructor(public http:Http,public translate: TranslateService,private sharingVar: SocialSharing, private alertCtrl:AlertController) {
  }
  share(message,subject,url,uid?,sharetype?): Promise<any>{
    return this.sharingVar.share(
      message, subject, null, url)
      .then((rst) => {
        if(rst) // 공유완료
            {
              var appshare, tymsg, donebtn;
              appshare = '공유완료';
              tymsg = '공유해 주셔서 감사합니다.';
              donebtn = '확인';
              /*this.translate.get('App share').subscribe(res=>{
                appshare = res;
              });
              this.translate.get('Thank you for the sharing').subscribe(res=>{
                tymsg = res;
              });
              this.translate.get('Confirm').subscribe(res=>{
                donebtn = res;
              });*/
                /*if(uid)
                {
                firebase.database().ref('/userProfile/' + uid + '/invite')
                .push({title:sharetype?sharetype:'기타 공유', createdDate: firebase.database.ServerValue.TIMESTAMP});
                }*/
                this.alertCtrl.create({
                message: tymsg,
                buttons: [
                    {
                    text: donebtn,
                    }
                ]
                }).then(_=>{_.present(); })
            }
            return rst;
      },
      () => {
        return false;// Promise.reject('failed')
        //alert("공유실패")
      })
  }
  shareapp(uid?): Promise<any>{
    return this.sharingVar.share(
      //nickname + "님이 
      "우리동네 정보앱 채널에 초대합니다.", "채널앱"
      /*this.message, this.subject*/, null/*File*/, "http://channel.cafe/promo.html")
      .then((rst) => {
        if(rst) // 공유완료
            {
              var appshare, tymsg, donebtn;
              this.translate.get('App share').subscribe(res=>{
                appshare = res;
              });
              this.translate.get('Thank you for the sharing').subscribe(res=>{
                tymsg = res;
              });
              this.translate.get('Confirm').subscribe(res=>{
                donebtn = res;
              });
                if(uid)
                {
                firebase.database().ref('/userProfile/' + uid + '/invite')
                .push({title:appshare, createdDate: firebase.database.ServerValue.TIMESTAMP});
                }
                this.alertCtrl.create({
                message: tymsg,
                buttons: [
                    {
                    text: donebtn,
                    }
                ]
                }).then(_=>_.present())
            }
          return rst;
      },
      () => {
        return false;
        //alert("공유실패")
      })
  }
}