import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { TypeKrNamePipeModule } from '../../../pipe/tkrnamepipe.module';
import { MybucketPage } from './mybucket.page';
import { MorebtnComponent } from '../morebtn/morebtn.component';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { ObjPipeModule } from '../../../pipe/objpipe.module';
import { CategoryPipeModule } from '../../../pipe/categorypipe.module';
const routes: Routes = [
  {
    path: '',
    component: MybucketPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,TypeKrNamePipeModule,
    IonicModule,OrderByPipeModule,TimeAgoPipeModule,ObjPipeModule,CategoryPipeModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MybucketPage,MorebtnComponent],
  entryComponents:[MorebtnComponent]
})
export class MybucketPageModule {}
