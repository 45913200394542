import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class GlobalVars {
  lan: any;
  loc: any;
  page: any;
  skipver: any;
  unreadchk: boolean = false;
  unreadalarmchk: boolean = false;

  mainboard: any = [];
  mainbanners: any = [];
  menulist: any = [];
  advlist: any = [];
  //favlist:any = [];

  visitedch: any = [];
  recentlist: any = [];
  recentlistpage;
  scrollvar: Observable<any>;
  param: any = [];
  keyword: any = [];
  cordova: boolean;
  ios: boolean;
  android: boolean;
  chreloadrequest: boolean;
  blockusers = '';

  holdingrate: any;
  holdingtime: any;
  holdingoritime: any;

  tossedcat: any;
  manager: any;
  admin: any;
  hasworktool: any;
  showpager: any = false;

  uid: any;
  uimg: any;
  unickname: any;
  uvf: boolean;
  ulvl: any;
  uemail: any;
  ulvlcount: any;
  ufavlist: any = [];
  umybucket: any;
  utotal: any;
  loadedfav: boolean = false;
  loadingboard: boolean = false;
  boardlist = [];
  boardlistpage: any;
  chklastvisit: any = [];
  msgunread: any;
  alarmunread: any;
  pltinfo: any;
  leveldata: any = [];
  thisver: any;
  currentver: any;

  currentpage: any;
  scrolltop: boolean = false;
  needfillfav: boolean = false;
  norefresh: boolean = false;
  storeready: boolean = false;
  appitem: any;
  aditem: any;
  advtotalpoint: any = 0;
  pm_selectedcatkey: any;
  pm_selectedcatname: any;
  bannertarget: any;
  //pm_snsautodata:any;
  pm_linkurl: any;
  pm_selectedchname: any;
  selectedcontent: any;
  //postcover:any=[];

  hotlivelist: any;
  hotlivePagination = 1;

  activatedRegion: any;
  tid: any; // timestamp to uid for unsigned user
  postPredata;
  chPredata;
  constructor(public storage: Storage, public translate: TranslateService, public afDB: AngularFireDatabase) {
    this.storage.get('savedaccloc').then(rst => {
      if (rst)
        this.loc = rst;
    })

    this.storage.get('recentlist').then(rst => {
      if (rst) {
        let current = new Date().getTime();
        for (let i = 0; i < rst.length; i++) {
          if ((current - rst[i].createdDate) / 1000 / 60 / 60 / 24 > 30) {
            rst.splice(i, 9999);
            break;
          }
        }
        this.recentlist = rst;
      }
      else
        this.recentlist = [];
    })

    this.storage.get('visitedch').then(rst => {
      if (rst) {
        let current = new Date().getTime();
        for (let i = 0; i < rst.length; i++) {
          if ((current - rst[i].createdDate) / 1000 / 60 / 60 / 24 > 30) {
            rst.splice(i, 999);
            break;
          }
        }
        this.visitedch = rst;
      }
      else
        this.visitedch = [];
    })


    this.lan = 'kr';
    translate.setDefaultLang('kr');
    /*
  if(!this.lan)
  {
  this.lan = 'kr';
  }
this.storage.get('savedacclan').then(rst=>{
  if(rst)
    {
    this.lan = rst;
    translate.setDefaultLang(rst);
    }
  /*
  else if(navigator.language)
  {
    let lancode = navigator.language;
    if(lancode.includes('ko'))
    {
    this.lan = 'kr';
    translate.setDefaultLang('kr');
    }
    else if(lancode.includes('en'))
    {
      this.lan = 'en';
      translate.setDefaultLang('en');
    }
    else if(lancode.includes('zh'))
    {
      this.lan = 'ch';
      translate.setDefaultLang('ch');
    }
    else
    { 
      this.lan = 'kr';
      translate.setDefaultLang('kr');
    }
}*//*
      else
      {
      this.lan = 'kr';
      translate.setDefaultLang('kr');
      }
      translate.use(this.lan);
    })*/
  }

  getRegion() {
    this.afDB.database.ref('/region').on('value', region => {
      if (region.val()) {
        this.activatedRegion = region.val();
      }
    });
  }

  storeOnStorage(name, val) {
    this.storage.set(name, val);
  }
  restoreOnStorage(name) {
    return this.storage.get(name);
  }
  clearOnStorage(name) { this.storage.remove(name) }

  setlocinlocal(val) {
    this.storage.set('savedaccloc', val);
  }
  setfavinlocal(fav) {
    this.storage.set('favlist', fav);
  }
  getlocinlocal() {
    return this.storage.get('savedaccloc');
  }
  getfavinlocal() {
    return this.storage.get('favlist');
  }

  setscrollvar(val) {
    this.scrollvar = val;
  }
  getscrollvar() {
    return this.scrollvar;
  }

  /*
  getmenulist(){
    return this.storage.get('menulist').then(rst=>{
        if(rst)
        {
          this.menulist = rst;
          return this.menulist;
        }
        else
         return [];
      });
  }
  setmenulist(menu){
    this.menulist = menu;
    this.storage.set('menulist', menu);
  }*/

  getmainbanner() {
    return this.storage.get('mainbanner').then(rst => {
      if (rst) {
        this.mainbanners = rst;
        return this.mainbanners;
      }
      else
        return [];
    });

  }
  setmainbanner(items) {
    this.mainbanners = items;
    this.storage.set('mainbanner', items);
  }

  getmainboard() {
    return this.storage.get('mainboard').then(rst => {
      if (rst) {
        this.mainboard = rst;
        return this.mainboard;
      }
      else
        return [];
    });

  }
  setmainboard(items) {
    this.mainboard = items;
    this.storage.set('mainboard', items);
  }

  chkchannelvisit() {
    this.chklastvisit = [];
    this.ufavlist.forEach(fav => {
      if (fav.lastposting) {
        let lastposting = 0;
        lastposting = fav.lastposting[Object.keys(fav.lastposting)[Object.keys(fav.lastposting).length - 1]].createdDate ? fav.lastposting[Object.keys(fav.lastposting)[Object.keys(fav.lastposting).length - 1]].createdDate : 0;
        this.visitedch.forEach(v => {
          if (v.chid == fav.chid) {
            if (v.checkedDate > lastposting)
              this.chklastvisit.push(v.chid);
          }
        })
      }
      /*
      fav.lastposting.then(val => {
      let lastposting = 0;
      if(val.val())
        lastposting = val.val()[Object.keys(val.val())[Object.keys(val.val()).length-1]].createdDate?val.val()[Object.keys(val.val())[Object.keys(val.val()).length-1]].createdDate:0;
      this.visitedch.forEach(v=>{
        if(v.chid == fav.chid)
        {
          if(v.checkedDate > lastposting)
            this.chklastvisit.push(v.chid);
        }
      })
      })*/
    })
  }

  getvisitedch() {
    return this.visitedch;
  }
  addvisitedch(chid, checkedDate?) {
    let exist = false;
    let data = [];
    let timenow = new Date().getTime();
    for (let i = 0; i < this.visitedch.length; i++) {
      if (this.visitedch[i].chid == chid) {
        exist = true;
        if (this.visitedch[i].checkedDate) {
          if (this.visitedch[i].checkedDate < (checkedDate ? checkedDate : timenow))
            data = [{
              chid: chid, createdDate: timenow,
              checkedDate: checkedDate ? checkedDate : timenow
            }];
          else
            data = [{
              chid: chid, createdDate: timenow,
              checkedDate: this.visitedch[i].checkedDate
            }];
        }
        else
          data = [{
            chid: chid, createdDate: timenow,
            checkedDate: checkedDate ? checkedDate : timenow
          }];

        this.visitedch.splice(i, 1);
        break;
      }
    }
    if (!exist)
      data = [{ chid: chid, createdDate: new Date().getTime(), checkedDate: checkedDate ? checkedDate : new Date().getTime() }]
    this.visitedch.unshift(data[0]);
    this.storage.set('visitedch', this.visitedch);
  }

  getrecent() {
    return this.recentlist;
  }
  getrecentcount() {
    return this.recentlist.length;
  }
  addrecent(itemid, loclan) {
    for (let i = 0; i < this.recentlist.length; i++) {
      if (this.recentlist[i].itemid == itemid) {
        this.recentlist.splice(i, 1);
        break;
      }
    }
    this.recentlist.unshift({ itemid: itemid, loclancode: loclan, createdDate: new Date().getTime() });
    this.storage.set('recentlist', this.recentlist);
  }

  removerecent(itemid) {
    for (let i = 0; i < this.recentlist.length; i++) {
      if (this.recentlist[i].itemid == itemid) {
        this.recentlist.splice(i, 1);
        break;
      }
    }
    this.storage.set('recentlist', this.recentlist);
  }


  setsavedacclan(savelan) {
    this.storage.set('savedacclan', savelan);
  }
  setsavedaccloc(saveloc) {
    this.storage.set('savedaccloc', saveloc);
  }

  cleanstroage() {
    this.storage.clear();
  }

  setUnread(value) {
    this.unreadchk = value;
  }
  getUnread() {
    return this.unreadchk;
  }

  setUnreadAlarm(value) {
    this.unreadalarmchk = value;
  }
  getUnreadAlarm() {
    return this.unreadalarmchk;
  }

  setLan(value) {
    this.lan = value;
    this.setsavedacclan(value);
    this.translate.use(value);
    //this.setsavedlan(value);
  }

  getLocLan() {
    return (this.loc ? this.loc : 'cairns') + '_' + (this.lan ? this.lan : 'kr')
  }

  getLan() {
    return this.lan;
  }

  setLoc(value) {
    this.loc = value;
    this.setsavedaccloc(value);
    //this.setsavedloc(value);
  }

  getLoc() {
    return this.loc;
  }

  setSkipver(value) {
    this.storage.set('skipver', value);
  }

  getSkipver() {
    return new Promise((res, rej) =>
      this.storage.get('skipver').then(rst => {
        res(rst);
      })
    )
  }

  setPostcover(key, value) {
    if (value)
      this.storage.set('tempsave_' + key, value);
    else
      this.storage.remove('tempsave_' + key);
  }

  getPostcover(key) {
    return new Promise((res, rej) =>
      this.storage.get('tempsave_' + key).then(rst => {
        if (rst)
          res(rst);
        else { res(); }
      }).catch(_ => { console.log('out of cache-memory'); res() })
    )
  }



  setPrelevel(value) {
    this.storage.set('prelevel', value);
  }
  getPrelevel() {
    return new Promise((res, rej) =>
      this.storage.get('prelevel').then(rst => {
        res(rst);
      })
    )
  }


  getListDir(loclan?: string) {
    return '/list';//s/brisbane_kr';
    /*
    if(loclan)
    {
      if(loclan == 'cairns_kr')
        return '/list';
      else if(loclan == 'test_kr')
        return '/test/post';
      else
        return '/lists/' + loclan;
    }
    else
    {
      if(!this.loc)
        this.loc = 'cairns';
      if(this.loc == 'cairns' && this.lan == 'kr')
        return '/list';
      else if(this.loc == 'test' && this.lan == 'kr')
        return '/test/post';
      else
        return '/lists/' + this.loc + '_' + this.lan;
    }*/
  }

  getfolder(foldername: string, loclan?: string) {
    if (loclan) {
      if (loclan == 'cairns_kr')
        return '/' + foldername;
      else
        return '/' + foldername + 'img/' + loclan;
    }
    else {
      if (this.loc == 'cairns' && this.lan == 'kr')
        return '/' + foldername;
      else
        return '/' + foldername + 'img/' + this.loc + '_' + this.lan;
    }
  }

  /*
    setforany(){
      //if(this.storage.get('savedacclan') && this.storage.get('savedaccloc'))
      //    {
          this.storage.get('savedaccloc').then(loc=>{
            if(loc)
              {
              this.setLoc(loc);
              }
            else
              {
              this.setLoc('cairns');
              }
          }).then(_=>{
            this.storage.get('savedacclan').then(rst=>{
            if(rst)
            {
              this.setLan(rst)
            }
            else if(navigator.language)
            {
              let lancode = navigator.language;
              if(lancode.includes('ko'))
              {
              this.setLan('kr');
              }
              else if(lancode.includes('en'))
              {
                this.setLan('en');
              }
              else if(lancode.includes('zh'))
              {
                this.setLan('ch');
              }
              else
              { 
                this.setLan('kr');
              }
            }
            else
            {
                this.setLan('kr');
            }
            })  
          })
    }*/

  getHotLive(ufavlist?) {
    if (!ufavlist) {
      // console.log('no local ufavlist and no global ufavlist');
      return false;
    }
    let readMin = 30; // 최소
    let likeMin = 5;
    let cmtMin = 3;
    let readArr = [];
    let likeArr = [];
    let cmtArr = [];
    let readAvg = 0; // 평균
    let likeAvg = 0;
    let cmtAvg = 0;

    let days = 5;

    let temphotlist = [];
    let today = new Date();

    let cmts: any;
    let realCmtArr = [];
    let realCmtCount: any;

    this.afDB.database.ref('/list/').orderByChild('createdDate')
      .startAt(today.getTime() - ((1000 * 60 * 60 * 24) * days)).once('value').then(daysItems => {
        // .startAt(today.getTime()-((1000*60*60*24)*days)).once('value').then(daysItems=>{
        if (daysItems && daysItems.val()) {
          daysItems.forEach(item => {
            if (item.val().read) readArr.push(item.val().read);
            if (item.val().up) likeArr.push(item.val().up);
            // if(item.val().replycount) cmtArr.push(item.val().replycount);
            realCmtCount = 0;
            if (item.val().comment) {
              realCmtArr = [];
              cmts = item.val().comment;
              for (let i = 0; i < Object.keys(cmts).length; i++) {
                let _key = Object.keys(cmts)[i];
                if (realCmtArr.indexOf(cmts[_key].userId) < 0 && cmts[_key].createdDate > item.val().createdDate)
                  realCmtArr.push(cmts[_key].userId);
              }
              realCmtCount = realCmtArr.length;
              cmtArr.push(realCmtCount);
            }
          });
          for (let i = 0; i < readArr.length; i++) { readAvg += readArr[i]; }
          for (let i = 0; i < likeArr.length; i++) { likeAvg += likeArr[i]; }
          for (let i = 0; i < cmtArr.length; i++) { cmtAvg += cmtArr[i]; }
          readAvg = Math.ceil(readAvg / readArr.length);
          likeAvg = Math.ceil(likeAvg / likeArr.length);
          cmtAvg = Math.ceil(cmtAvg / cmtArr.length);
          // console.log("----- readAvg:"+readAvg+" / likeAvg:"+likeAvg+" / cmtAvg:"+cmtAvg+" -----");
          if (this.admin) console.log("[admin]" + this.unickname + " : readAvg = " + readAvg + " / " + "likeAvg = " + likeAvg + " / " + "cmtAvg = " + cmtAvg);

          for (let i = Object.keys(daysItems.val()).length - 1; i >= 0; i--) {
            let itemsVal = daysItems.val();
            let keys = Object.keys(itemsVal);
            let data = itemsVal[keys[i]];
            data.$key = keys[i];
            // data.realCmtCount = realCmtCount;
            if (data.comment) {
              realCmtArr = [];
              cmts = data.comment;
              for (let i = 0; i < Object.keys(cmts).length; i++) {
                let _key = Object.keys(cmts)[i];
                if (realCmtArr.indexOf(cmts[_key].userId) < 0 && cmts[_key].createdDate > data.createdDate ) {
                  realCmtArr.push(cmts[_key].userId);
                }
              }
              data.realCmtCount = realCmtArr.length;
            }
            let calcedRead: number;
            let calcedLike: number;
            let calcedCmt: number;
            // console.log(data);
            let now = new Date().getTime();
            data.timediff = (now - data.createdDate) / 1000 / 60 / 60 / 24;
            let standard = 5;
            if (temphotlist.length < 16) {
              let filteredChann = ufavlist.map(function (e) { return e.chid; }).indexOf(data.categoryId);
              if( temphotlist.length < 2 ) {
                standard = 0.5;
              } else if( temphotlist.length < 4 ) {
                standard = 0.75;
              } else if( temphotlist.length < 8 ) {
                standard = 1;
              } else {
                if (data.timediff < 1) standard = 1;
                else if (data.timediff < 2) standard = 1;
                else if (data.timediff < 3) standard = 1.15;
                else if (data.timediff < 4) standard = 1.3;
                else standard = 1.5;
              }
              calcedRead = (data.read ? data.read : 0) / readAvg;
              calcedLike = (data.up ? data.up : 0) / likeAvg;
              calcedCmt = (data.realCmtCount ? data.realCmtCount : 0) / cmtAvg;
              // console.log("--------------------- standard = " + standard);
              // console.log("data.read = " + data.read + " / data.up = " + data.up + " / data.realCmtCount = " + data.realCmtCount);
              // console.log("calcedRead = " + calcedRead + " / calcedLike = " + calcedLike + " / calcedCmt = " + calcedCmt);
              if (data.communityloc == this.loc || data.communityloc == 'all-aus') {
                if (data.categoryId == '-L56EH_e8EibkS4M_0oq' && data.category != '건의해요') { // 공식 채널글은 아주 쉽게 인기게시물로 (건의해요 게시판 제외)
                  if (
                    (calcedRead >= 0.5 && calcedLike >= 0.5) ||
                    (calcedRead >= 0.5 && calcedCmt >= 0.5)
                  ) {
                    // console.log(temphotlist.length + ". 공식채널글 : "+ data.title +" / itemread = " + data.read + " / " + "itemlike = " + data.up + " / " + "itemcmt = " + data.replycount);
                    temphotlist.push(data);
                  }
                } else if (filteredChann >= 0) { // 내 즐겨찾기로 되어 있으면 엄격하게 인기게시물로
                  if (
                    (calcedRead >= 3 * standard ||
                      calcedLike >= 3 * standard ||
                      calcedCmt >= 3 * standard)
                    ||
                    ((calcedRead >= 2 * standard) && (calcedLike >= 1 * standard) ||
                      (calcedLike >= 2 * standard) && (calcedCmt >= 1 * standard) ||
                      (calcedCmt >= 2 * standard) && (calcedRead >= 1 * standard))
                    ||
                    (((calcedRead >= 1.5 * standard) && (calcedCmt >= 1.5 * standard) && (calcedLike >= 0.5 * standard)) ||
                      ((calcedRead >= 1.5 * standard) && (calcedLike >= 1.5 * standard) && (calcedCmt >= 0.5 * standard)) ||
                      ((calcedLike >= 1.5 * standard) && (calcedCmt >= 1.5 * standard) && (calcedRead >= 0.5 * standard)))
                    ||
                    (((calcedRead >= 1 * standard) && (calcedCmt >= 1 * standard) && (calcedLike >= 1 * standard)) ||
                      ((calcedRead >= 1 * standard) && (calcedLike >= 1 * standard) && (calcedCmt >= 1 * standard)) ||
                      ((calcedLike >= 1 * standard) && (calcedCmt >= 1 * standard) && (calcedRead >= 1 * standard)))
                  ) {
                    // console.log(temphotlist.length + ". 미즐찾 : "+ data.title +" / itemread = " + data.read + " / " + "itemlike = " + data.up + " / " + "itemcmt = " + data.replycount);
                    temphotlist.push(data);
                  }
                } else { // 내 즐겨찾기로 안되어 있으면 널널하게 인기게시물로 
                  if (
                    (calcedRead >= 2 * standard) ||
                    (calcedLike >= 2 * standard) ||
                    (calcedCmt >= 2 * standard) ||
                    (calcedRead >= 1.5 * standard) && (calcedCmt >= 1 * standard) ||
                    (calcedRead >= 1.5 * standard) && (calcedLike >= 1 * standard) ||
                    (calcedLike >= 1.5 * standard) && (calcedCmt >= 1 * standard) ||
                    ((calcedRead >= 1 * standard) && (calcedCmt >= 0.5 * standard) && calcedLike >= 0.5 * standard) ||
                    ((calcedLike >= 1 * standard) && (calcedCmt >= 0.5 * standard) && calcedRead >= 0.5 * standard) ||
                    ((calcedCmt >= 1 * standard) && (calcedRead >= 0.5 * standard) && calcedLike >= 0.5 * standard)
                  ) {
                    // console.log(temphotlist.length + ". 즐찾 : "+ data.title +" / itemread = " + data.read + " / " + "itemlike = " + data.up + " / " + "itemcmt = " + data.replycount);
                    temphotlist.push(data);
                  }
                }

              } else if (data.communityloc == undefined) { // 모든 글에 communityloc 값 추가 한 다음 이 else if 통째로 삭제 필요 - 200313 hong

                if (data.categoryId == '-L56EH_e8EibkS4M_0oq' && data.category != '건의해요') { // 공식 채널글은 아주 쉽게 인기게시물로 (건의해요 게시판 제외)
                  if (
                    (calcedRead >= 0.5 && calcedLike >= 0.5) ||
                    (calcedRead >= 0.5 && calcedCmt >= 0.5)
                  ) {
                    // console.log(temphotlist.length + ". 공식채널글 : "+ data.title +" / itemread = " + data.read + " / " + "itemlike = " + data.up + " / " + "itemcmt = " + data.replycount);
                    temphotlist.push(data);
                  }
                } else if (filteredChann >= 0) { // 내 즐겨찾기로 되어 있으면 엄격하게 인기게시물로
                  if (
                    (calcedRead >= 3 * standard ||
                      calcedLike >= 3 * standard ||
                      calcedCmt >= 3 * standard)
                    ||
                    ((calcedRead >= 2 * standard) && (calcedLike >= 1 * standard) ||
                      (calcedLike >= 2 * standard) && (calcedCmt >= 1 * standard) ||
                      (calcedCmt >= 2 * standard) && (calcedRead >= 1 * standard))
                    ||
                    (((calcedRead >= 1.5 * standard) && (calcedCmt >= 1.5 * standard) && (calcedLike >= 0.5 * standard)) ||
                      ((calcedRead >= 1.5 * standard) && (calcedLike >= 1.5 * standard) && (calcedCmt >= 0.5 * standard)) ||
                      ((calcedLike >= 1.5 * standard) && (calcedCmt >= 1.5 * standard) && (calcedRead >= 0.5 * standard)))
                    ||
                    (((calcedRead >= 1 * standard) && (calcedCmt >= 1 * standard) && (calcedLike >= 1 * standard)) ||
                      ((calcedRead >= 1 * standard) && (calcedLike >= 1 * standard) && (calcedCmt >= 1 * standard)) ||
                      ((calcedLike >= 1 * standard) && (calcedCmt >= 1 * standard) && (calcedRead >= 1 * standard)))
                  ) {
                    // console.log(temphotlist.length + ". 미즐찾 : "+ data.title +" / itemread = " + data.read + " / " + "itemlike = " + data.up + " / " + "itemcmt = " + data.replycount);
                    temphotlist.push(data);
                  }
                } else { // 내 즐겨찾기로 안되어 있으면 널널하게 인기게시물로 
                  if (
                    (calcedRead >= 2 * standard) ||
                    (calcedLike >= 2 * standard) ||
                    (calcedCmt >= 2 * standard) ||
                    (calcedRead >= 1.5 * standard) && (calcedCmt >= 1 * standard) ||
                    (calcedRead >= 1.5 * standard) && (calcedLike >= 1 * standard) ||
                    (calcedLike >= 1.5 * standard) && (calcedCmt >= 1 * standard) ||
                    ((calcedRead >= 1 * standard) && (calcedCmt >= 0.5 * standard) && calcedLike >= 0.5 * standard) ||
                    ((calcedLike >= 1 * standard) && (calcedCmt >= 0.5 * standard) && calcedRead >= 0.5 * standard) ||
                    ((calcedCmt >= 1 * standard) && (calcedRead >= 0.5 * standard) && calcedLike >= 0.5 * standard)
                  ) {
                    // console.log(temphotlist.length + ". 즐찾 : "+ data.title +" / itemread = " + data.read + " / " + "itemlike = " + data.up + " / " + "itemcmt = " + data.replycount);
                    temphotlist.push(data);
                  }
                }

              }
            }

          }

          this.hotlivelist = temphotlist;
          // this.hotlivelist = this.hotlivelist.reverse(); // push 할때는 reverse 해주셈. 노출 순서를 일단 시간순으로...
          // console.log(this.hotlivelist);
          if (this.hotlivelist.length >= 2) {
            this.hotlivePagination = Math.ceil(this.hotlivelist.length / 2);
          }
        }
      });
  }

}