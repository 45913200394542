import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

import { Clipboard } from '@ionic-native/clipboard/ngx';
import { BasicpopupPage } from './basicpopup.page';

const routes: Routes = [
  {
    path: '',
    component: BasicpopupPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,TranslateModule,
    RouterModule.forChild(routes)
  ],
  providers:[Clipboard,TranslatePipe],
  declarations: [BasicpopupPage]
})
export class BasicpopupPageModule {}
