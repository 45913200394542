import { NgModule } from '@angular/core';
import { LinkAttachPipe } from './linkattachpipe';

@NgModule({
  declarations: [
    LinkAttachPipe,
  ],
  imports: [
    
  ],
  exports: [
    LinkAttachPipe
  ]
})
export class LinkAttachPipeModule {}
