import { Component, OnInit } from '@angular/core';
import { ModalController,AlertController,ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-pwdchange',
  templateUrl: './pwdchange.page.html',
  styleUrls: ['./pwdchange.page.scss'],
})
export class PwdchangePage implements OnInit {
  useremail:any;
  constructor(public afAuth: AngularFireAuth,public modalCtrl: ModalController,public alertCtrl: AlertController,
    public toastCtrl:ToastController, public translate: TranslateService) { }

  ngOnInit() {
      if(firebase.auth().currentUser)
        this.useremail = firebase.auth().currentUser.email;
  }

  async pwdreset()
  {
    var resetmsg, okbtn, cancelbtn;
    this.translate.get('Do you want to reset your password').subscribe(res=>{
    resetmsg = res;
    });
    this.translate.get('Confirm').subscribe(res=>{
    okbtn = res;
    });
    this.translate.get('Cancel').subscribe(res=>{
    cancelbtn = res;
    });
    let alert = await this.alertCtrl.create({
                //title: '이동 확인',
    message: resetmsg,
    buttons: [
        {
        text: cancelbtn,
        role: 'cancel'
        },
        {
        text: okbtn,
        handler: () => {
                        firebase.auth().sendPasswordResetEmail(this.useremail);
                        var resetdone;
                        this.translate.get('resetlinkconfirmmsg').subscribe(res=>{
                        resetdone = res;
                        });
                        this.presentToast(resetdone);
                        this.modalCtrl.dismiss();
                        }
        }
    ]
    });
    return alert.present();
  }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2000
    });
    return await toast.present();
  }

}
