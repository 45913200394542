import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    NavController, ModalController, LoadingController, ActionSheetController,
    ToastController, AlertController, IonSelect
} from '@ionic/angular';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
//import 'rxjs/add/operator/map'; // you might need to import this, or not depends on your setup
import { AngularFireAuth } from '@angular/fire/auth';
import { OrderByPipe } from '../../../../pipe/orderbypipe';
import * as firebase from 'firebase/app';
//import {CalendarComponent} from "ap-angular-fullcalendar";
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AddworktoolPage } from '../addworktool/addworktool.page';
import { AddworkplacePage } from '../addworkplace/addworkplace.page';
import * as $ from 'jquery';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GlobalVars } from '../../../../providers/globalVars';
import { TranslateService } from '@ngx-translate/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import koLocale from '@fullcalendar/core/locales/ko';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
    selector: 'app-worktool',
    templateUrl: './worktool.page.html',
    styleUrls: ['./worktool.page.scss'],
})
export class WorktoolPage implements OnInit {
    calendarPlugins = [dayGridPlugin, interactionPlugin];

    @ViewChild('calendar') workCalendar: FullCalendarComponent;
    calendarEvents: any = [];
    selecteddate: any = new Date;

    startdate: any;
    enddate: any;

    timeline: AngularFireList<any[]>;
    feedView: string = "activity";
    catselection: any = "0";
    authState: boolean = false;
    uid: any;
    manager: boolean = false;
    groupname: any;
    sitename: any;
    groupselect: any = '';
    memberrate: any;
    siteselect: any = "0";
    groupmenu: any[] = [];
    sitemenu: any[] = [];
    sitemenuworker: any[] = [];
    //shares:  AngularFireList<any>;
    sitemsg: AngularFireList<any>;
    sitemsgdata: any[] = [];
    member = [];//  Subscription;//AngularFireList<any[]>;
    tempmember: any[] = [];
    // temptxt: any = "기본";
    // viewtype: any = "cal";
    caldata: any[] = [];
    caldataworkers: any[] = [];
    rancolor: any[];
    showmemberlist = false;
    chatmsg: any;
    today: Date = new Date();
    siteadded: boolean = false;
    blockuser: string = '';
    selectedsiteid: string;
    adddisabled: boolean = false;
    bgarray: any[] = [];
    bgarrayworker: any[] = [];
    workitems: any[] = [];
    workersitems: any[] = [];

    loadingdone: boolean = false;
    editmode: boolean = false;
    editmodemember: boolean = false;
    selectstr: any = 'Select all';
    selectsitestr: any = 'Deselect all';
    workersdatamode: boolean = false;
    noselection: boolean = false;

    removeinfokey: any = '';
    selecteduserid: any[] = [];
    selectedsiteidworker: any[] = [];
    views = {
        week: {
            type: 'basic',
            duration: { weeks: 2 },
        }
    };
    header = {
        left: 'title today',
        right: 'prev,next,month,week,list'
    };

    locale = 'en';

    buttonText = {
        prev: '<',
        next: '>',
        today: 'Today',
        month: 'Month',
        week: '2Weeks'
    }
    totalstr; hoursstr; daystr; numstr; weekdaystr; weekendstr;
    constructor(public navCtrl: NavController, public loadingCtrl: LoadingController, private gv: GlobalVars,
        public alertCtrl: AlertController, public modalCtrl: ModalController, public asCtrl: ActionSheetController,
        public orderby: OrderByPipe, private translate: TranslateService, 
        private toastCtrl: ToastController, public afAuth: AngularFireAuth, public afDB: AngularFireDatabase) {
        /* 
        if(gv.uid) {
             this.authState = true;
             // 그룹 로드 - 유저항목에 등록된 목록을 불러옴
             this.uid = gv.uid;
     
             this.selecteduserid = this.uid;
             //if(this.navParams.get('itemId'))
             //    this.groupselect = this.navParams.get('itemId'); 
             this.getgroup();
           } else {
             this.authState = false;
           }
     */
        //this.authState = true;
        this.uid = this.gv.uid;
        this.selecteduserid = this.uid;
        this.getgroup();

        /* 
         this.sitemsg =
           afDB.list('schedule', {query: {
               orderByChild: "siteId",
               equalTo: this.siteselect 
           }});
        */
        this.translate.get('Today').subscribe(res=>{this.buttonText.today = res;})
        this.translate.get('Month').subscribe(res=>{this.buttonText.month = res;})
        this.translate.get('Fortnightly').subscribe(res=>{this.buttonText.week = res;})

        this.translate.get('Total').subscribe(res=>{this.totalstr = res;})
        this.translate.get('day(s)').subscribe(res=>{this.daystr = res;})   
        this.translate.get('num').subscribe(res=>{this.numstr = res;})
        this.translate.get('Weekday').subscribe(res=>{this.weekdaystr = res;})
        this.translate.get('Weekend').subscribe(res=>{this.weekendstr = res;})
        this.translate.get('hours').subscribe(res=>{this.hoursstr = res;})
    }
    
    ngOnInit() {
    }

    ionViewDidEnter(){
        if(this.gv.lan == 'kr') this.locale = 'ko';
        else if(this.gv.lan == 'ch') this.locale = 'zh-cn';
    }

    handleSelect(e) {
        var tempend = new Date(e.endStr);
        tempend.setDate(tempend.getDate() - 1);
        var endstr;
        if (tempend.getMonth() + 1 < 10)
            endstr = tempend.getFullYear() + '-0' + (tempend.getMonth() + 1);
        else
            endstr = tempend.getFullYear() + '-' + (tempend.getMonth() + 1)
        if (tempend.getDate() < 10)
            endstr += '-0' + tempend.getDate()
        else
            endstr += '-' + tempend.getDate()
        this.setdaydata(e.startStr, endstr)
    }
    
    setdetail() {
        this.caldata = this.orderby.transform(this.caldata, 'start', 'asc');
        var msgtxt = "";
        var nodata = true;
        this.workitems = [];
        if (this.startdate != this.enddate) // 기간일때
        {
            var tempstart = new Date(this.startdate);
            var tempend = new Date(this.enddate);
            msgtxt = "<h3>[" + this.totalstr + " " + Math.floor((tempend.getTime() - tempstart.getTime()) / (1000 * 60 * 60 * 24) + 1) + this.daystr +"] " + this.startdate + " ~ " + this.enddate + "</h3>";
            var totalamount = 0;
            var totalhours = 0;
            var totalweekdayhours = 0;
            var totalweekendhours = 0;
            this.caldata.forEach(item => {
                if (item.start >= this.startdate && item.start <= this.enddate) {
                    var existed = -1;
                    for (let i = 0; i < this.workitems.length; i++) {
                        if (this.workitems[i].title == item.title)
                            existed = i;
                    }
                    if (existed == -1) {
                        // 시간당 계산 필요!
                        if (item.ptype == 'mhour') {
                            var dayval = new Date(item.start).getDay();
                            if (dayval == 0 || dayval == 6) //주말이면
                                this.workitems.push({ key: item.$key, ptype: item.ptype, title: item.title, count: 1, rate: 0, whour: 0, wehour: parseFloat(item.rate) });
                            else //주중이면
                                this.workitems.push({ key: item.$key, ptype: item.ptype, title: item.title, count: 1, rate: 0, whour: parseFloat(item.rate), wehour: 0 });
                        }
                        else
                            this.workitems.push({ key: item.$key, ptype: item.ptype, title: item.title, count: 1, rate: parseFloat(item.rate) });
                    }
                    else {
                        if (item.ptype == 'mhour') {
                            var dayval = new Date(item.start).getDay();
                            if (dayval == 0 || dayval == 6) //주말이면
                                this.workitems[existed].wehour = this.workitems[existed].wehour + parseFloat(item.rate);
                            else
                                this.workitems[existed].whour = this.workitems[existed].whour + parseFloat(item.rate);
                        }
                        else {
                            this.workitems[existed].rate = Math.round((this.workitems[existed].rate + parseFloat(item.rate)) * 100) / 100;
                        }
                        this.workitems[existed].count = this.workitems[existed].count + 1;

                        //({rate:this.workitems[existed].rate + item.rate, count:this.workitems[existed].count+1});
                    }
                    if (item.ptype == 'mhour') {
                        var dayval = new Date(item.start).getDay();
                        if (dayval == 0 || dayval == 6) //주말이면
                            totalweekendhours += parseFloat(item.rate);
                        else
                            totalweekdayhours += parseFloat(item.rate);
                    }
                    else
                        totalamount = Math.round((totalamount + parseFloat(item.rate)) * 100) / 100;

                    //if(item.ptype == 'auto')
                    //    rate = '';
                    //msgtxt +=  "<div class='divworkitem' (click)='removeitem(" + item.$key + ")'>" + item.title + ' (' + item.rate + ')</div>';
                }
            })
            if (this.workitems.length) {
                let sitestr, countstr, paystr;
                this.translate.get('Site').subscribe(res=>{sitestr = res;})
                this.translate.get('Count').subscribe(res=>{countstr = res;})
                this.translate.get('Pay').subscribe(res=>{paystr = res;})

                msgtxt += "<div class='divworktbl'><div class='divworkitemtop'><div class='divworkcell'>"+sitestr+"</div><div class='divworkcell'>"+countstr+"</div><div class='divworkcell'>"+paystr+"</div></div>";
                nodata = false;
            }
            else
                nodata = true;

            this.workitems.forEach(rst => {
                if (rst.ptype == 'mhour') {
                    if (rst.whour > 0 && rst.wehour > 0)
                        msgtxt += "<div class='divworkitem'><div class='divworkcell'>" + rst.title + "</div><div class='divworkcell'>" + rst.count + this.numstr + "</div><div class='divworkcell'>" + this.totalstr + (Math.round(parseFloat(rst.whour + rst.wehour) * 100) / 100) + this.hoursstr + '(' + rst.whour + ',' + rst.wehour + ')' + '</div></div>';
                    else if (rst.whour > 0)
                        msgtxt += "<div class='divworkitem'><div class='divworkcell'>" + rst.title + "</div><div class='divworkcell'>" + rst.count + this.numstr + "</div><div class='divworkcell'>" + this.weekdaystr + rst.whour + this.hoursstr + '</div></div>';
                    else if (rst.wehour > 0)
                        msgtxt += "<div class='divworkitem'><div class='divworkcell'>" + rst.title + "</div><div class='divworkcell'>" + rst.count + this.numstr + "</div><div class='divworkcell'>" + this.weekendstr + rst.wehour + this.hoursstr + '</div></div>';
                }
                else
                    msgtxt += "<div class='divworkitem'><div class='divworkcell'>" + rst.title + "</div><div class='divworkcell'>" + rst.count + this.numstr + "</div><div class='divworkcell'>$" + rst.rate + '</div></div>';
            })
            msgtxt += "</div>";
            if (!nodata) {
                msgtxt += "<div class='divtotal'>";
                if (totalamount > 0)
                    msgtxt += "<span>" + this.totalstr + " $" + totalamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</span>"
                if (totalweekdayhours + totalweekendhours > 0) {
                    if (totalamount > 0)
                        msgtxt += "  +  ";
                    msgtxt += "<span>" + this.totalstr + " " + (totalweekdayhours + totalweekendhours).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this.hoursstr + "</span>("
                }
                if (totalweekdayhours > 0)
                    msgtxt += "<span>" + this.weekdaystr + totalweekdayhours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</span>"
                if (totalweekendhours > 0)
                    msgtxt += "<span>" + this.weekendstr + totalweekendhours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</span>"
                if (totalweekdayhours + totalweekendhours > 0)
                    msgtxt += ")";
                msgtxt += "</div>"
            }

            if (document.getElementById('divbtns'))
                document.getElementById('divbtns').style.display = 'none';
            if (document.getElementById('divbtnsedit'))
                document.getElementById('divbtnsedit').style.display = 'none';
        }
        else // 한날짜일때
        {
            msgtxt = this.startdate;
            let removestr;
            this.translate.get('delete').subscribe(res=>{removestr = res;})
            this.caldata.forEach(item => {
                if (item.start >= this.startdate && item.start <= this.enddate) {
                    nodata = false;
                    var memostr = '';
                    if (item.memo)
                        memostr = " (" + item.memo + ")";
                    if (item.ptype == 'mhour')
                        msgtxt += "<div class='divworkitemedit'>" + item.title + ' : ' + item.rate + this.hoursstr + memostr + "<div class='divremove' onclick='document.getElementById(\"rmid\").value = (\"" + item.title + '#$#' + item.$key + "\"); document.getElementById(\"btnremove\").click()'>" + removestr + "</div></div>";
                    else
                        msgtxt += "<div class='divworkitemedit'" + item.$key + "\')'>" + item.title + ' : $' + item.rate + memostr + "<div class='divremove' onclick='document.getElementById(\"rmid\").value = (\"" + item.title + '#$#' + item.$key + "\"); document.getElementById(\"btnremove\").click()'>" + removestr + "</div></div>";
                }
            })
            if (document.getElementById('divbtns'))
                document.getElementById('divbtns').style.display = 'flex';
            if (document.getElementById('divbtnsedit'))
                document.getElementById('divbtnsedit').style.display = 'block';
        }
        if (document.getElementById('rst'))
            document.getElementById('rst').innerHTML = msgtxt;
        if (nodata) {
            if (document.getElementById('divnodata'))
                document.getElementById('divnodata').style.display = 'block';
        }
        else {
            if (document.getElementById('divnodata'))
                document.getElementById('divnodata').style.display = 'none';
        }

    }



    setdetailworkers() {
        this.caldataworkers = this.orderby.transform(this.caldataworkers, 'title', 'asc');

        var msgtxt = "";
        var msgtop = "";
        var nodata = true;
        this.workersitems = [];

        var fulltotal = 0;
        var fullwehours = 0;
        var fullwhours = 0;
        if (this.startdate != this.enddate) // 기간일때
        {
            var tempstart = new Date(this.startdate);
            var tempend = new Date(this.enddate);
            msgtop = "[" + this.totalstr + " " + Math.floor((tempend.getTime() - tempstart.getTime()) / (1000 * 60 * 60 * 24) + 1) + this.daystr + "] "
                + this.startdate + " ~ " + this.enddate;
            this.caldataworkers.forEach(item => {
                if (item.start >= this.startdate && item.start <= this.enddate)
                    this.workersitems.push(item);
            })

        }
        else // 한날짜일때
        {
            msgtop = this.startdate;

            this.caldataworkers.forEach(item => {
                if (item.start >= this.startdate && item.start <= this.enddate)
                    this.workersitems.push(item);
            })
        }

        if (this.workersitems.length) {
            //msgtxt += "<div class='divworktbl'><div class='divworkitemtop'><div class='divworkcell'>근무지</div><div class='divworkcell'>횟수</div><div class='divworkcell'>합계</div></div>";
            nodata = false;
        }
        else
            nodata = true;
        let Sun,Mon,Tue,Wed,Thu,Fri,Sat;
        this.translate.get('Sun').subscribe(res=>{Sun = res;})
        this.translate.get('Mon').subscribe(res=>{Mon = res;})
        this.translate.get('Tue').subscribe(res=>{Tue = res;})
        this.translate.get('Wed').subscribe(res=>{Wed = res;})
        this.translate.get('Thu').subscribe(res=>{Thu = res;})
        this.translate.get('Fri').subscribe(res=>{Fri = res;})
        this.translate.get('Sat').subscribe(res=>{Sat = res;})
        
        var dayarr = [Sun,Mon,Tue,Wed,Thu,Fri,Sat];
        for (let i = 0; i < this.workersitems.length; i++) {
            var dayval = new Date(this.workersitems[i].start).getDay();

            if (i != 0 && this.workersitems[i - 1].title != this.workersitems[i].title)
                msgtxt += "</div><div class='divtitle' style='text-decoration: underline; margin-top: 20px; font-size: 15px; font-weight: 500;'>" + this.workersitems[i].title + "</div><div class='divcaltable'>";

            else if (i == 0 || this.workersitems[i - 1].title != this.workersitems[i].title)
                msgtxt += "<div class='divtitle' style='text-decoration: underline; margin-top: 20px; font-size: 15px; font-weight: 500;'>" + this.workersitems[i].title + "</div><div class='divcaltable'>";

            msgtxt += "<div class='divcaldatarow' style='display: flex; width: 100%; justify-content: space-between; border-bottom: 1px solid #ddd; padding: 10px 0;'><div class='divcaldata' style='width:70px; font-size: 13px'>" + this.workersitems[i].start.substr(5, 5) + " (" + dayarr[dayval] + ")</div>";

            var totalamount = 0;
            var totalhours = 0;
            for (let j = 0; j < this.workersitems[i].data.length; j++) {
                var memostr = '';
                if (this.workersitems[i].data[j].memo)
                    memostr = " - " + this.workersitems[i].data[j].memo;
                if (j == 0)
                    msgtxt += "<div class='divcaldata' style='flex: 1; font-size: 13px;' >";

                //else
                //    msgtxt += ", "
                if (this.workersitems[i].data[j].ptype == 'mhour') {
                    msgtxt += "<div>" + this.workersitems[i].data[j].name
                        + ' (' + this.workersitems[i].data[j].rate
                        + this.hoursstr + ")" + memostr + "</div>";
                    totalhours += parseFloat(this.workersitems[i].data[j].rate);
                    if (dayval == 0 || dayval == 6)
                        fullwehours += parseFloat(this.workersitems[i].data[j].rate);
                    else
                        fullwhours += parseFloat(this.workersitems[i].data[j].rate);
                }
                else {
                    fulltotal += parseFloat(this.workersitems[i].data[j].rate);
                    totalamount += parseFloat(this.workersitems[i].data[j].rate);
                    if (this.workersitems[i].data[j].ptype == 'auto')
                        msgtxt += "<div style='color:gray; font-size: 13px;'>" + this.workersitems[i].data[j].name
                            + " ($" + this.workersitems[i].data[j].rate
                            + ")" + memostr + "</div>";
                    else
                        msgtxt += "<div>" + this.workersitems[i].data[j].name
                            + ' ($' + this.workersitems[i].data[j].rate
                            + ")" + memostr + "</div>";
                }
                if (j == this.workersitems[i].data.length - 1)
                    msgtxt += "</div>";
            }
            totalamount = Math.round(totalamount * 100) / 100;
            totalhours = Math.round(totalhours * 100) / 100;

            if (totalamount > 0 && totalhours > 0)
                msgtxt += "<div class='divcaldata' style='width:80px; font-size: 13px'>" + this.totalstr + " $" + totalamount + "<br>+ " + totalhours + this.hoursstr + "</div>";
            else if (totalamount > 0)
                msgtxt += "<div class='divcaldata' style='width:80px; font-size: 13px'>" + this.totalstr + " $" + totalamount + "</div>";
            else
                msgtxt += "<div class='divcaldata' style='width:80px; font-size: 13px'>" + this.totalstr + " " + totalhours + this.hoursstr + "</div>";
            msgtxt += "</div>";

        }

        fulltotal = Math.round(fulltotal * 100) / 100;
        fullwehours = Math.round(fullwehours * 100) / 100;
        fullwhours = Math.round(fullwhours * 100) / 100;

        if (fulltotal > 0 && fullwehours + fullwhours > 0) {
            msgtop += "<div class='divfulltotal' style='font-size: 15px; font-weight:500; padding:5px 0;'>" + this.totalstr + " $" + fulltotal;
            if (fullwehours > 0 && fullwhours > 0)
                msgtop += " + " + (fullwhours + fullwehours) + this.hoursstr + " (" + this.weekdaystr +  " " + fullwhours + ", " + this.weekendstr + " " + fullwehours + ")</div>";
            else if (fullwhours > 0)
                msgtop += " + " + this.weekdaystr + " " + fullwhours + this.hoursstr + "</div>";
            else
                msgtop += " + " + this.weekendstr + " " + fullwehours + this.hoursstr + "</div>";
        }
        else if (fulltotal > 0) {
            msgtop += "<div class='divfulltotal' style='font-size: 15px; font-weight:500; padding:5px 0;'>" + this.totalstr + " $" + fulltotal + "</div>";
        }
        else {
            if (fullwehours > 0 && fullwhours > 0)
                msgtop += "<div class='divfulltotal' style='font-size: 15px; font-weight:500; padding:5px 0;'>" + this.totalstr + " " + (fullwhours + fullwehours) + this.hoursstr + " (" + this.weekdaystr + " " + fullwhours + ", " + this.weekendstr + " " + fullwehours + ")</div>";
            else if (fullwhours > 0)
                msgtop += "<div class='divfulltotal' style='font-size: 15px; font-weight:500; padding:5px 0;'>" + this.weekdaystr + " " + fullwhours + this.hoursstr + "</div>";
            else if (fullwehours > 0)
                msgtop += "<div class='divfulltotal' style='font-size: 15px; font-weight:500; padding:5px 0;'>" + this.weekendstr + " " + fullwehours + this.hoursstr + "</div>";
        }


        document.getElementById('rst').innerHTML = msgtop + msgtxt;
        if (nodata)
            document.getElementById('divnodata').style.display = 'block';
        else
            document.getElementById('divnodata').style.display = 'none';
        /*
        this.caldataworkers = this.orderby.transform(this.caldataworkers, 'start', 'asc');
        var msgtxt = "";
        var nodata = true;
        this.workersitems = [];
        if(this.startdate != this.enddate) // 기간일때
        {
        var tempstart = new Date(this.startdate);
        var tempend = new Date(this.enddate);
        msgtxt = "[총 "+ Math.floor((tempend.getTime() - tempstart.getTime()) / (1000*60*60*24)+1) + "일] "+ this.startdate + " ~ " + this.enddate;
        var totalamount = 0;
        var totalhours = 0;
        var totalweekdayhours = 0;
        var totalweekendhours = 0;
        this.caldataworkers.forEach(item=>{
            if(item.start >= this.startdate && item.start <= this.enddate)
            {
                var existed = -1;
                for(let i = 0; i < this.workersitems.length; i++)
                {
                    if(this.workersitems[i].title == item.title)
                        existed = i;
                }
                if(existed == -1)
                {
                    // 시간당 계산 필요!
                    if(item.data.ptype == 'mhour')
                        {
                            var dayval = new Date(item.start).getDay();
                            if(dayval == 0 || dayval == 6) //주말이면
                              this.workersitems.push({key:item.$key, title:item.title, count:1, 
                                totalrate: 0, totalwhour:0, totalwehour:parseFloat(item.data.rate),
                             data:[{ptype:item.data.ptype, whour:0, wehour:parseFloat(item.data.rate), rate:0}]})
                            else //주중이면
                              this.workersitems.push({key:item.$key, title:item.title, count:1,
                                totalrate: 0, totalwhour:parseFloat(item.data.rate), totalwehour:0,
                             data:[{ptype:item.data.ptype, whour:parseFloat(item.data.rate), wehour:0, rate:0}]})   
                        }
                    else
                        this.workersitems.push({key:item.$key,title:item.title, count:1, 
                                totalrate: parseFloat(item.data.rate), totalwhour:0, totalwehour:0,
                             data:[{ptype:item.data.ptype, whour:0, wehour:0, rate:parseFloat(item.data.rate)}]});
                }
                else
                {
                    if(item.data.ptype == 'mhour')
                    {
                        var dayval = new Date(item.start).getDay();
                        if(dayval == 0 || dayval == 6) //주말이면
                            {
                            this.workersitems[existed].data.push({ptype:item.data.ptype, whour:0, wehour:parseFloat(item.data.rate), rate:0})
                            this.workersitems[existed].totalwehour = this.workersitems[existed].totalwehour + parseFloat(item.data.rate);
                            }
                        else
                            {
                            this.workersitems[existed].data.push({ptype:item.data.ptype, whour:parseFloat(item.data.rate), wehour:0, rate:0})
                            this.workersitems[existed].totalwhour = this.workersitems[existed].totalwhour + parseFloat(item.data.rate);
                            }
                    }
                    else{
                    this.workersitems[existed].data.push({ptype:item.data.ptype, whour:0, wehour:0, rate:parseFloat(item.data.rate)})
                    this.workersitems[existed].totalrate = Math.round((this.workersitems[existed].totalrate + parseFloat(item.data.rate))*100)/100;
                    }
                    this.workersitems[existed].count = this.workersitems[existed].count + 1;
                    
                    //({rate:this.workitems[existed].rate + item.rate, count:this.workitems[existed].count+1});
                }
                if(item.data.ptype == 'mhour')
                    {
                        var dayval = new Date(item.start).getDay();
                        if(dayval == 0 || dayval == 6) //주말이면
                            totalweekendhours += parseFloat(item.data.rate); 
                        else
                            totalweekdayhours += parseFloat(item.data.rate); 
                    }
                else
                    totalamount = Math.round((totalamount + parseFloat(item.data.rate))*100)/100;
                
            //if(item.ptype == 'auto')
            //    rate = '';
            //msgtxt +=  "<div class='divworkitem' (click)='removeitem(" + item.$key + ")'>" + item.title + ' (' + item.rate + ')</div>';
            }
        })
        if(this.workersitems.length)
            {
            msgtxt += "<div class='divworktbl'><div class='divworkitemtop'><div class='divworkcell'>근무지</div><div class='divworkcell'>횟수</div><div class='divworkcell'>합계</div></div>";
            nodata = false;
            }
        else
            nodata = true;
        console.log(this.caldataworkers);
        console.log(this.workersitems);
        this.workersitems.forEach(rst=>{
           var sumdata = '';
           if(rst.totalrate > 0)
            {
                sumdata = '$' + rst.totalrate;
                if(rst.totalwehour > 0 && rst.totalwhour > 0)
                    {
                        if(sumdata)
                        sumdata += ", 총 " + rst.totalwehour + '시간';
                        else
                        sumdata = "총 " + rst.totalwehour + '시간';
                    }

                if(rst.totalwhour > 0)
                {
                    if(sumdata)
                        sumdata += ", 주중" + rst.totalwehour;
                    else
                        sumdata = "주중" + rst.totalwehour + '시간';
                }
                if(rst.totalwehour > 0)
                {
                    if(sumdata)
                        sumdata += ", 주말" + rst.totalwehour;
                    else
                        sumdata = "주말" + rst.totalwehour + '시간';
                }
                
            }
           msgtxt += "<div class='divworkitem'><div class='divworkcell'>" + rst.title + 
           "</div><div class='divworkcell'>" + rst.count + "일</div><div class='divworkcell'>" + sumdata + '</div></div>';
        })
        msgtxt += "</div>";
        if(!nodata)
        {
            msgtxt += "<div class='divtotal'>";
            if(totalamount > 0)
                msgtxt += "<span>총 $" + totalamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</span>"
            if(totalweekdayhours + totalweekendhours > 0)
            {
                if(totalamount > 0)
                    msgtxt += "  +  ";
                msgtxt += "<span>총 " + (totalweekdayhours + totalweekendhours).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "시간</span>("
            }
            if(totalweekdayhours > 0)
                msgtxt += "<span>주중" + totalweekdayhours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</span>"
            if(totalweekendhours > 0)
                msgtxt += "<span>주말" + totalweekendhours.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</span>"
            if(totalweekdayhours + totalweekendhours > 0)
                msgtxt += ")";
            msgtxt += "</div>"
        }
        
        }
        else // 한날짜일때
        {
        msgtxt = this.startdate;

        this.caldataworkers.forEach(item=>{
            if(item.start >= this.startdate && item.start <= this.enddate)
                {
                nodata = false;
                if(item.ptype == 'mhour')
                msgtxt += "<div class='divworkitemedit'>" + item.title + ' : ' + item.rate + "시간<div class='divremove' onclick='document.getElementById(\"rmid\").value = (\"" + item.title + '#$#' + item.$key + "\"); document.getElementById(\"btnremove\").click()'>삭제</div></div>";
                else
                msgtxt += "<div class='divworkitemedit'" + item.$key + "\')'>" + item.title + ' : $' + item.rate + "<div class='divremove' onclick='document.getElementById(\"rmid\").value = (\"" +  item.title + '#$#' + item.$key + "\"); document.getElementById(\"btnremove\").click()'>삭제</div></div>";
                }
        })
        if(document.getElementById('divbtns'))
            document.getElementById('divbtns').style.display = 'flex';
        if(document.getElementById('divbtnsedit'))
            document.getElementById('divbtnsedit').style.display = 'block';
        }
        document.getElementById('rst').innerHTML = msgtxt;
        if(nodata)
        document.getElementById('divnodata').style.display = 'block';
        
        else
        document.getElementById('divnodata').style.display = 'none';
        */
    }

    removeitem(val) {
        let hederstr, msgstr, removestr, cancelstr, removedstr;
        this.translate.get('Delete work').subscribe(res=>{hederstr = res;})
        this.translate.get('want to delete this').subscribe(res=>{msgstr = res;})
        this.translate.get('delete').subscribe(res=>{removestr = res;})
        this.translate.get('Cancel').subscribe(res=>{cancelstr = res;})
        this.translate.get('item deleted').subscribe(res=>{removedstr = res;})
        let delalert = this.alertCtrl.create({
            header: hederstr,
            message: val.split('#$#')[0] + msgstr,
            buttons: [
                {
                    text: cancelstr,
                    role: 'cancel'
                },
                {
                    text: removestr,
                    handler: (data) => {
                        this.afDB.database.ref('/workdata/' + this.groupselect + '/' + val.split('#$#')[1]).remove().then(_ => {
                            this.getworkdata();
                            this.presentToast('bottom', removedstr, 2000);
                            this.setdetail();
                        });
                    }
                }
            ]
        });
        delalert.then(view => view.present())
    }
    setdaydata(start, end) {
        this.startdate = start;
        this.enddate = end;
        // 회원근무 확인시 처리필요
        if (!this.noselection) {
            if (this.workersdatamode)
                this.setdetailworkers();
            else
                this.setdetail();
        }
    }


    editsite(sitekey, site) {
        let editwp, onlyname, addnewworkif, nicknamewp, delwp, delbtn, cancelbtn, confirmbtn, prices, doyouwantdel,
        hours, namepaynote, canbechanged, changed, willnotapply,optionfill,optionnote;
        this.translate.get('Edit work place').subscribe(res=>{editwp = res;})
        this.translate.get('Only the name can be changed').subscribe(res=>{onlyname = res;})
        this.translate.get('Add new work place if you want to change pay type').subscribe(res=>{addnewworkif = res;})
        this.translate.get('Nick name of work place').subscribe(res=>{nicknamewp = res;})
        this.translate.get('Delete work place').subscribe(res=>{delwp = res;})
        this.translate.get('delete').subscribe(res=>{delbtn = res;})
        this.translate.get('Cancel').subscribe(res=>{cancelbtn = res;})
        this.translate.get('Confirm').subscribe(res=>{confirmbtn = res;})
        this.translate.get('Do you want to delete the work place').subscribe(res=>{doyouwantdel = res;})
        this.translate.get('Prices').subscribe(res=>{prices = res;})
        this.translate.get('Hours').subscribe(res=>{hours = res;})
        this.translate.get('Name/Pay type/Note').subscribe(res=>{namepaynote = res;})
        this.translate.get('can be changed').subscribe(res=>{canbechanged = res;})
        this.translate.get('*Changed').subscribe(res=>{changed = res;})
        this.translate.get(' will not applied previous work record.').subscribe(res=>{willnotapply = res;})
        this.translate.get('Option) Fill here if static value').subscribe(res=>{optionfill = res;})
        this.translate.get('Option) Note').subscribe(res=>{optionnote = res;})

        if (site.ptype == 'auto') {
            let houralert = this.alertCtrl.create({
                header: editwp,
                message: '<p>' + onlyname + '</p><p>' + addnewworkif + '</p>',
                inputs: [
                    {
                        name: 'nickname',
                        type: 'text',
                        value: site.nickname,
                        placeholder: nicknamewp
                    }
                ],
                buttons: [
                    {
                        text: delwp,
                        handler: () => {
                            let removealert = this.alertCtrl.create({
                                header: delwp,
                                message: doyouwantdel,
                                buttons: [
                                    {
                                        text: cancelbtn,
                                        role: 'cancel'
                                    },
                                    {
                                        text: delbtn,
                                        handler: () => {
                                            this.groupmenu.forEach(gm => {
                                                if (gm.id == this.groupselect) {
                                                    //사이트 삭제 체크
                                                    this.afDB.database.ref('/workinfo/' + this.uid + '/' + gm.infokey
                                                        + '/sites/' + sitekey).remove();
                                                }
                                            })
                                        }
                                    }
                                ]
                            });
                            removealert.then(view => view.present())
                        }
                    },
                    {
                        text: cancelbtn,
                        role: 'cancel'
                    },
                    {
                        text: confirmbtn,
                        handler: (data) => {
                            // 사이트 업데이트
                            this.groupmenu.forEach(gm => {
                                if (gm.id == this.groupselect) {
                                    this.afDB.database.ref('/workinfo/' + this.uid + '/' + gm.infokey
                                        + '/sites/' + sitekey).update({ nickname: data.nickname }).then(_ => this.getworkdata())
                                }
                            })
                        }
                    }
                ]
            });
            houralert.then(view => view.present())
        }
        else {
            var typestr = prices;
            if (site.ptype == 'mhour')
                typestr = hours;
            let houralert = this.alertCtrl.create({
                header: editwp,
                message: '<p>' + namepaynote + '/' + typestr + canbechanged + '</p><p><b>' + changed + ' ' + typestr + willnotapply + '</b></p><p>' + addnewworkif + '</p>',
                inputs: [
                    {
                        name: 'nickname',
                        type: 'text',
                        value: site.nickname,
                        placeholder: nicknamewp
                    },
                    {
                        name: 'rate',
                        type: 'number',
                        value: site.rate,
                        placeholder: optionfill
                    },
                    {
                        name: 'memo',
                        type: 'text',
                        value: site.memo,
                        placeholder: optionnote
                    }
                ],
                buttons: [
                    {
                        text: delwp,
                        handler: () => {
                            let removealert = this.alertCtrl.create({
                                header: delwp,
                                message: doyouwantdel,
                                buttons: [
                                    {
                                        text: cancelbtn,
                                        role: 'cancel'
                                    },
                                    {
                                        text: delbtn,
                                        handler: () => {
                                            this.groupmenu.forEach(gm => {
                                                if (gm.id == this.groupselect) {
                                                    //사이트 삭제 체크
                                                    this.afDB.database.ref('/workinfo/' + this.uid + '/' + gm.infokey
                                                        + '/sites/' + sitekey).remove().then(_ => this.getworkdata())
                                                }
                                            })
                                        }
                                    }
                                ]
                            });
                            removealert.then(view => view.present())
                        }
                    },
                    {
                        text: cancelbtn,
                        role: 'cancel'
                    },
                    {
                        text: confirmbtn,
                        handler: (data) => {
                            // 사이트 업데이트
                            this.groupmenu.forEach(gm => {
                                if (gm.id == this.groupselect) {
                                    this.afDB.database.ref('/workinfo/' + this.uid + '/' + gm.infokey
                                        + '/sites/' + sitekey).update({ nickname: data.nickname, rate: data.rate, memo: data.memo }).then(_ => this.getworkdata());
                                }
                            })
                        }
                    }
                ]
            });
            houralert.then(view => view.present())
        }
    }

    selectsite(key, site) {
        let sltdate, hourinput, priceinput, workhourinput, workpriceinput, houronly, priceonly, cancelbtn, confirmbtn,
        cantemptyhour, cantemptyprice, chkagain, optionnote;
        this.translate.get('Please select a date').subscribe(res=>{sltdate = res;})
        this.translate.get('Input hours').subscribe(res=>{hourinput = res;})
        this.translate.get('Please fill in work hours').subscribe(res=>{workhourinput = res;})
        this.translate.get('Input only numbers / float number(.) possible').subscribe(res=>{houronly = res;})
        this.translate.get("Hours won't be empty").subscribe(res=>{cantemptyhour = res;})        
        this.translate.get('Option) Note').subscribe(res=>{optionnote = res;})
        this.translate.get('Cancel').subscribe(res=>{cancelbtn = res;})
        this.translate.get('Confirm').subscribe(res=>{confirmbtn = res;})
        
        this.translate.get('Please check again').subscribe(res=>{chkagain = res;})
        this.translate.get('Input price').subscribe(res=>{priceinput = res;})
        this.translate.get('Please fill in the price for this work').subscribe(res=>{workpriceinput = res;})
        this.translate.get('Input only price with numbers or float number(.)').subscribe(res=>{priceonly = res;})
        this.translate.get("Price won't be empty").subscribe(res=>{cantemptyprice = res;})        

        if (!this.startdate)
            alert(sltdate);
        else {
            if (site.ptype == 'mhour' && !site.rate) {
                let houralert = this.alertCtrl.create({
                    header: hourinput,
                    message: workhourinput,
                    inputs: [
                        {
                            name: 'workhour',
                            type: 'number',
                            placeholder: houronly
                        },
                        {
                            name: 'memo',
                            value: site.memo,
                            type: 'text',
                            placeholder: optionnote
                        }],
                    buttons: [
                        {
                            text: cancelbtn,
                            role: 'cancel'
                        },
                        {
                            text: confirmbtn,
                            handler: (data) => {
                                //this.caldata.push({start:this.startdate, title:site.nickname, siteid:site.siteid, ptype:site.ptype,
                                //rate:data.workhour,
                                //backgroundColor:this.bgarray[index].color});
                                if (data.workhour) {
                                    site.calrate = data.workhour;
                                    site.memo = data.memo;
                                    this.addWork(key, site);
                                    this.setdetail();
                                }
                                else {
                                    let noratealert = this.alertCtrl.create({
                                        message: '<p>' + cantemptyhour + '</p><p>' + chkagain + '</p>',
                                        buttons: [
                                            {
                                                text: cancelbtn,
                                                role: 'cancel'
                                            },
                                            {
                                                text: confirmbtn,
                                                handler: (data) => {
                                                    this.selectsite(key, site);
                                                }
                                            }
                                        ]
                                    });
                                    noratealert.then(view => view.present())
                                }

                            }
                        }
                    ]
                });
                houralert.then(view => view.present())
            }
            else if (site.ptype == 'mrate' && !site.rate) {
                let houralert = this.alertCtrl.create({
                    header: priceinput,
                    message: workpriceinput,
                    inputs: [
                        {
                            name: 'workrate',
                            type: 'number',
                            placeholder: priceonly
                        },
                        {
                            name: 'memo',
                            type: 'text',
                            value: site.memo,
                            placeholder: optionnote
                        }],
                    buttons: [
                        {
                            text: cancelbtn,
                            role: 'cancel'
                        },
                        {
                            text: confirmbtn,
                            handler: (data) => {
                                //this.caldata.push({start:this.startdate, title:site.nickname, siteid:site.siteid, ptype:site.ptype,
                                //rate:data.workrate,
                                //backgroundColor:this.bgarray[index].color});
                                if (data.workrate) {
                                    site.calrate = data.workrate;
                                    site.memo = data.memo;
                                    this.addWork(key, site);
                                    this.setdetail();
                                }
                                else {
                                    let noratealert = this.alertCtrl.create({
                                        message: '<p>' + cantemptyprice + '</p><p>' + chkagain + '</p>',
                                        buttons: [
                                            {
                                                text: cancelbtn,
                                                role: 'cancel'
                                            },
                                            {
                                                text: confirmbtn,
                                                handler: (data) => {
                                                    this.selectsite(key, site);
                                                }
                                            }
                                        ]
                                    });
                                    noratealert.then(view => view.present())
                                }

                            }
                        }
                    ]
                });
                houralert.then(view => view.present())
            }
            else //if(site.ptype == 'auto')  자동이거나, 수동이지만 레이트가 있는경우
            {
                if (site.ptype == 'auto') {
                    if (!this.memberrate)
                        site.calrate = site.rate;
                    else
                        site.calrate = (site.rate * this.memberrate) / 100;
                }
                else
                    site.calrate = site.rate;
                //this.caldata.push({start:this.startdate, title:site.nickname, siteid:site.siteid, ptype:site.ptype, rate:'', backgroundColor:this.bgarray[index].color});
                this.addWork(key, site);
                this.setdetail();
            }
        }
    }

    addWork(key, site) {
        if (!site.memo)
            site.memo = '';
        this.afDB.database.ref('/workdata/' + this.groupselect).push(
            {
                userId: this.uid, start: this.startdate, title: site.nickname, siteid: site.siteid, sitekey: key, memo: site.memo,
                ptype: site.ptype, rate: site.calrate
            }
        ).then(_ => this.getworkdata())
    }


    addgroup() {
        //this.navCtrl.push('AddWorkToolPage');
        let md = this.modalCtrl.create(
            {
                component: AddworktoolPage,
                cssClass: 'modalfull'
            });
        md.then(view => view.present())
    }

    addworkplace() {
        let md = this.modalCtrl.create(
            {
                component: AddworkplacePage,
                componentProps: <null>{ workgroupid: this.groupselect },
                cssClass: 'modalfull'
            });
        //'AddWorkPlacePage', {workgroupid:this.groupselect}, {cssClass:"modalfull"});
        md.then(view => view.present())
        //this.navCtrl.push('AddWorkPlacePage', {workgroupid:this.groupselect});
    }
    editworktool() {
        let md = this.modalCtrl.create({
            component: AddworktoolPage,
            componentProps: <null>{ wgid: this.groupselect },
            cssClass: 'modalfull'
        });
        // 'AddWorkToolPage', {wgid:this.groupselect}, {cssClass:"modalfull"});
        md.then(view => view.present())
        //this.navCtrl.push('AddWorkToolPage', {wgid:this.groupselect});
    }
    close() {
        this.navCtrl.pop();
        // this.modalCtrl.dismiss();
    }
    closeModal() {
        this.modalCtrl.dismiss();
    }
    selectedgroupname() {
        this.manager = false;
        this.groupmenu.forEach(item => {
            if (item.id == this.groupselect) {
            this.groupname = item.name;
                item.managers.forEach(manager => {
                    if (manager.userId == this.uid)
                        this.manager = true;
                });
                return;
            } //리턴앞 selected=true;
        });
    }

    removegroup() {
        let deleted;
        this.translate.get('Deleted').subscribe(res=>{deleted = res;})
        this.afDB.database.ref('/workinfo/' + this.uid + '/' + this.removeinfokey).remove().then(_ =>
            this.presentToast('bottom', deleted, 3000)
        );
    }

    getgroup() {
        let deletedworkrec;
        this.translate.get('Deleted work record').subscribe(res=>{deletedworkrec = res;})
        this.afDB.list('/workinfo/' + this.uid).snapshotChanges().subscribe(groupitem => {
            var gm = [];
            if (!groupitem.length) {
                this.loadingdone = true;
            }
            else {
                groupitem.forEach(item => {
                    this.afDB.database.ref('/workgroup/' + item.payload.val()['id']).once('value').then(obj => {
                        if (obj.val()) {
                            // 현재 나의 상태가 탈퇴처리되었다면 그룹정보에 그룹이름만 넣고 비활성화..
                            for (let i = 0; i < Object.keys(obj.val().member).length; i++) {
                                if (obj.val().member[Object.keys(obj.val().member)[i]].userId == this.uid) {
                                    if (obj.val().member[Object.keys(obj.val().member)[i]].disabled != true)
                                        gm.push({ infokey: item.key, id: obj.key, name: obj.val().name, member: obj.val().member, sites: item.payload.val()['sites'], rates: obj.val().rates })
                                    else // 탈퇴된 일지는 인포값이랑 일지이름만 남김
                                        gm.push({ infokey: item.key, id: '', name: obj.val().name, member: '', sites: '', rates: '' })

                                    break;
                                }
                            }
                        }
                        else
                            gm.push({ id: obj.key, name: '[' + deletedworkrec + ']', member: [], rates: [], sites: [] });
                    }).then(rst => {
                        this.groupmenu = gm;
                        if (gm.length > 0) {
                            if (!this.groupselect) // 그룹선택 초기값 - 제일앞에 있는 그룹, 만약 탈퇴된 일지일경우 탈퇴값 넘김
                            {
                                this.groupselect = gm[0].id;
                                if (gm[0].id == '')
                                    this.removeinfokey = gm[0].infokey;
                            }
                            this.getsite(this.groupselect);
                        }
                        this.loadingdone = true;
                    })
                });
            }

        });
    }

    getmember() {
        //console.log('멤버호출')
        /*
        this.afDB.database.ref('/workgroup/' + this.groupselect + '/member').once('value').then(memitem=>{
            this.tempmember = [];
            memitem.forEach(mem=>{
                this.afDB.database.ref('/userProfile/' + mem.val().userId + '/name').once('value').then(item=>
                this.afDB.database.ref('/userProfile/' + mem.val().userId + '/email').once('value').then(rst=>
                    {
                    this.tempmember.push({key:mem.key, userId:mem.val().userId, manager: mem.val().manager, 
                        memberrate:mem.val().memberrate, ratename:mem.val().ratename, rename:mem.val().rename,
                        disabled:mem.val().disabled,
                        selected:mem.val().userId == this.uid, name:item.val(), email:rst.val()}) })
                )
            }
            )
            //console.log(this.tempmember);
        })
        
        /*
        this.afDB.list('/workgroup/' + this.groupselect + '/member').subscribe(memitem=>{
            this.tempmember = [];
            memitem.forEach(mem=>{
                this.afDB.database.ref('/userProfile/' + mem.userId + '/name').once('value').then(item=>
                this.afDB.database.ref('/userProfile/' + mem.userId + '/email').once('value').then(rst=>
                    {
                    this.tempmember.push({key:mem.$key, userId:mem.userId, manager: mem.manager, 
                        memberrate:mem.memberrate, ratename:mem.ratename, rename:mem.rename,
                        disabled:mem.disabled,
                        selected:mem.userId == this.uid, name:item.val(), email:rst.val()}) })
                )
            }
            )
            
        })
        
         this.member = <AngularFireList<any[]>> this.afDB.list('/category/'+this.groupselect + '/site/' + 
                                this.siteselect + '/member').map( (arr) => { 
                                        return arr.map((arr) => {
                                            this.tempmember = [];
                                            arr.userProfile = this.afDB.object('/userProfile/' + arr.userId);
                                            arr.userProfile.subscribe(item=>this.tempmember.push(item));
                                            return arr; 
                                        })
                                        });

        */
        //this.member
        //this.member =
        this.afDB.list('/workgroup/' + this.groupselect + '/member').snapshotChanges().pipe(map((arr) => {
            this.tempmember = [];
            return arr.map( (arr) => {
                let mem = arr.payload.val();
                this.afDB.database.ref('/userProfile/' + mem['userId'] + '/name').once('value').then(item =>
                    mem['name'] = item.val());
                this.afDB.database.ref('/userProfile/' + mem['userId'] + '/email').once('value').then(rst =>
                    mem['email'] = rst.val());
                mem['selected'] = mem['userId'] == this.uid;
                this.tempmember.push(mem)
                return;
            }
            )
            //.then(_=>{return mem})
        }
        )).subscribe(mem => {
            this.member = [];
            this.tempmember.forEach(m=>{
                this.member.push(m);
            })
        })
        //})


        // 사이트에 속한 멤버 로드
        /*
        this.member = <AngularFireList<any[]>> this.afDB.list('/workgroup/'+this.groupselect + '/member')
        .map( (arr) => { 
        return arr.map((arr) => {
            this.tempmember = [];
            //this.afDB.object('/userProfile/' + arr.userId + '/name').subscribe(item=>this.tempmember.push({userId:item.$key, name:item.$value}));
            arr.name = this.afDB.object('/userProfile/' + arr.userId + '/name');
            arr.selected = (arr.userId == this.uid); 
            arr.name.subscribe(item=>
                {
                this.afDB.object('/userProfile/' + arr.userId + '/email').subscribe(rst=>
                    {
                    this.tempmember.push({userId:arr.userId, name:item.$value, email:rst.$value}) })
                })
            return arr; 
        });
        });*/
        //this.member.subscribe();
    }

    memberslt() {
        //if(this.tempmember.length > 2)
        this.chksltstate();
        this.noselection = true;
        this.workersdatamode = false;
        this.selecteduserid = [];
        this.tempmember.forEach(mem => {
            if (mem.selected) {
                this.selecteduserid.push({ userId: mem.userId });
                this.noselection = false;
                if (mem.userId != this.uid) {
                    this.workersdatamode = true;
                }
            }
        })

        if (this.noselection) // 선택없음
        {
            this.workitems = [];
            if (document.getElementById('rst'))
                document.getElementById('rst').innerHTML = ''
            if (document.getElementById('divnodata'))
                document.getElementById('divnodata').style.display = 'block';
            this.calendarEvents = [];
            //this.workCalendar.fullCalendar('removeEvents', function () { return true; });
        }
        else if (this.workersdatamode) // 워커모드 로드
        {
            this.getallsite();
            this.getworkmembersdata();
        }
        else //본인 데이터만 로드
        {
            this.getworkdata();
            //this.workCalendar.eventDestroy;//.fullCalendar('removeEvents', function () { return true; });
            //this.calendarEvents = this.caldata;//.fullCalendar('renderEvents', this.caldata, true );
        }
    }

    chksltstate() {
        var allselected = true;
        this.tempmember.forEach(mem => {
            if (mem.selected != true) {
                allselected = false;
                return;
            }
        })
        if (allselected) {
            this.selectstr = 'Deselect all';
        }
        else {
            this.selectstr = 'Select all';
        }
    }
    selectall() {
        if (this.selectstr == 'Select all') {
            this.tempmember.forEach(mem => {
                mem.selected = true;
            })
            this.selectstr = 'Deselect all';
        }
        else {
            this.tempmember.forEach(mem => {
                mem.selected = false;
            })
            this.selectstr = 'Select all';
        }
        this.memberslt();
    }

    chksitesltstate() {
        var allselected = true;
        this.sitemenuworker.forEach(site => {
            if (site.selected != true) {
                allselected = false;
                return;
            }
        })
        if (allselected) {
            this.selectsitestr = 'Deselect all';
        }
        else {
            this.selectsitestr = 'Select all';
        }
    }

    selectallsiteworker() {
        if (this.selectsitestr == 'Select all') {
            this.sitemenuworker.forEach(site => {
                site.selected = true;
            })
            this.selectsitestr = 'Deselect all';
        }
        else {
            this.sitemenuworker.forEach(site => {
                site.selected = false;
            })
            this.caldataworkers = [];
            if (document.getElementById('rst'))
                document.getElementById('rst').innerHTML = ''
            if (document.getElementById('divnodata'))
                document.getElementById('divnodata').style.display = 'block';
            this.selectsitestr = 'Select all';
        }
        this.siteworkerslt();
    }
    siteworkerslt() {
        //if(this.sitemenuworker.length > 2)
        this.chksitesltstate();
        var noselection = true;
        //this.workersdatamode = false;
        this.selectedsiteidworker = [];
        this.sitemenuworker.forEach(site => {
            if (site.selected) {
                if (noselection)
                    noselection = false;
            }
        })


        if (noselection) // 선택없음
        {
            this.workersitems = [];
            if (document.getElementById('rst'))
                document.getElementById('rst').innerHTML = ''
            if (document.getElementById('divnodata'))
                document.getElementById('divnodata').style.display = 'block';
            //this.workCalendar.fullCalendar('removeEvents', function () { return true; });
        }
        else // 선택된 사이트들 로드
        {
            this.getworkmembersdata();
            //this.workCalendar.fullCalendar('removeEvents', function () { return true; });
            this.calendarEvents = this.caldataworkers;
            //this.workCalendar.fullCalendar('renderEvents', this.caldataworkers, true );
        }
    }
    getsite(id) {
        this.editmode = false;
        this.editmodemember = false;
        this.workersdatamode = false;

        this.groupselect = id;
        this.manager = false;
        if (id) {
            this.removeinfokey = '';
            this.groupmenu.forEach(gm => {
                if (gm.id == id) {
                    for (let i = 0; Object.keys(gm.member).length; i++) {
                        //console.log(gm.member[Object.keys(gm.member)[i]]);
                        if (gm.member[Object.keys(gm.member)[i]].userId == this.uid) {
                            if (gm.member[Object.keys(gm.member)[i]].manager)
                                this.manager = true;
                            if (gm.member[Object.keys(gm.member)[i]].memberrate)
                                this.memberrate = gm.member[Object.keys(gm.member)[i]].memberrate;
                            else
                                this.memberrate = 100;
                            return;
                        }
                    }
                }
            })

            if (this.manager)
            { this.getmember(); }

            this.afDB.list('/workinfo/' + this.uid, ref =>
                ref.orderByChild('id').equalTo(this.groupselect)
            ).snapshotChanges().subscribe(
                siteitem => {
                    this.sitemenu = [];
                    this.bgarray = [];
                    if (siteitem[0] && siteitem[0].payload.val()['sites']) {
                        for (let i = 0; i < Object.keys(siteitem[0].payload.val()['sites']).length; i++) {
                            let cursite = siteitem[0].payload.val()['sites'][Object.keys(siteitem[0].payload.val()['sites'])[i]];
                            this.sitemenu.push({ key: Object.keys(siteitem[0].payload.val()['sites'])[i], data: cursite });
                            this.bgarray.push({ key: Object.keys(siteitem[0].payload.val()['sites'])[i], color: this.rainbow(Object.keys(siteitem[0].payload.val()['sites']).length, i) });
                        }
                    }
                    this.getworkdata();
                })
        }
    }

    getallsite() {
        this.afDB.database.ref('/workgroup/' + this.groupselect + '/sites').once('value').then(site => {

            this.sitemenuworker = [];
            this.bgarrayworker = [];
            for (let i = 0; i < Object.keys(site.val()).length; i++) {
                this.sitemenuworker.push({ key: Object.keys(site.val())[i], selected: true, name: site.val()[Object.keys(site.val())[i]].name, rate: site.val()[Object.keys(site.val())[i]].rate });
                this.bgarrayworker.push({ key: Object.keys(site.val())[i], color: this.rainbow(Object.keys(site.val()).length, i) });
            }
        })
    }

    getworkdata() {

        /*this.afDB.list('/workdata/'+ this.groupselect, {query: {
             orderByChild: "userId",
             equalTo: this.uid 
         }}).subscribe(
 */


        // 워크데이터 
        this.afDB.database.ref('/workdata/' + this.groupselect).limitToLast(2000).
            orderByChild("userId").equalTo(this.uid).once('value').then(
            workitem => {
                this.caldata = [];
                workitem.forEach(work => {
                    let thisbg = '#dddddd';
                    let title = work.val().title;
                    this.bgarray.forEach(bg => {
                        if (work.val().sitekey == bg.key) {
                            thisbg = bg.color;
                            return;
                        }
                    })

                    this.sitemenu.forEach(site => {
                        if (site.key == work.val().sitekey)
                            title = site.data.nickname;
                    })

                    //work.val().backgroundColor =  thisbg; //this.bgarray[work.siteid].color;
                    this.caldata.push({
                        $key: work.key, ptype: work.val().ptype, rate: work.val().rate, siteid: work.val().siteid,
                        start: work.val().start, title: title, userId: work.val().userId, backgroundColor: thisbg, memo: work.val().memo
                    });
                })
                if (!this.startdate) {
                    var startstr;
                    if (this.selecteddate.getMonth() + 1 < 10)
                        startstr = this.selecteddate.getFullYear() + '-0' + (this.selecteddate.getMonth() + 1);
                    else
                        startstr = this.selecteddate.getFullYear() + '-' + (this.selecteddate.getMonth() + 1)
                    if (this.selecteddate.getDate() < 10)
                        startstr += '-0' + this.selecteddate.getDate()
                    else
                        startstr += '-' + this.selecteddate.getDate();
                    this.startdate = startstr;
                    this.enddate = startstr;
                }
                this.setdetail();

                if (this.workCalendar) {
                    //this.workCalendar.fullCalendar('removeEvents', function () { return true; });
                    this.calendarEvents = this.caldata;
                    //this.workCalendar.fullCalendar('renderEvents', this.caldata, true );
                }
            })

    }

    getworkmembersdata() {
        // 현재 선택된 날짜 기준 앞, 뒤 45일 데이터만 가져옴.
        var startstr, endstr;
        var datem3mths = new Date(new Date(this.startdate).getTime() - (1000 * 60 * 60 * 24) * 45);
        var datea3mths = new Date(new Date(this.startdate).getTime() + (1000 * 60 * 60 * 24) * 45);

        if (datem3mths.getMonth() + 1 < 10)
            startstr = datem3mths.getFullYear() + '-0' + (datem3mths.getMonth() + 1);
        else
            startstr = datem3mths.getFullYear() + '-' + (datem3mths.getMonth() + 1)
        if (datem3mths.getDate() < 10)
            startstr += '-0' + datem3mths.getDate()
        else
            startstr += '-' + datem3mths.getDate();

        if (datea3mths.getMonth() + 1 < 10)
            endstr = datea3mths.getFullYear() + '-0' + (datea3mths.getMonth() + 1);
        else
            endstr = datea3mths.getFullYear() + '-' + (datea3mths.getMonth() + 1)
        if (datea3mths.getDate() < 10)
            endstr += '-0' + datea3mths.getDate()
        else
            endstr += '-' + datea3mths.getDate();

        this.afDB.database.ref('/workdata/' + this.groupselect).orderByChild('start').startAt(startstr).endAt(endstr).once('value').then(
            workitem => {
                this.caldataworkers = [];
                workitem.forEach(work => {
                    var chkselection = false;
                    var chksiteselection = false;
                    // 중요!!! 전체선택이 된 상태에선 회원목록에 없는(그룹에서 삭제된 멤버라 할지라도) 함께 표시, 하나라도 제거된 선택이라면 해당하는것만 표시
                    if (this.selectstr == 'Deselect all')
                        chkselection = true;
                    else {
                        this.selecteduserid.forEach(uid => {
                            if (uid.userId == work.val().userId) {
                                chkselection = true;
                                return;
                            }
                        })
                    }
                    // 중요!!! 전체선택이 된 상태에선 사이트정보에 없는(그룹에서 삭제된 사이트라 할지라도) 사이트들도 함께 표시, 하나라도 제거된 선택이라면 해당하는것만 표시
                    if (this.selectsitestr == 'Deselect all')
                        chksiteselection = true;
                    else {
                        this.sitemenuworker.forEach(site => {
                            if (site.selected && site.key == work.val().siteid) {
                                chksiteselection = true;
                                return;
                            }
                        })
                    }
                    if (chkselection && chksiteselection) {
                        let thisbg = '#dddddd';
                        let title = work.val().title;
                        this.bgarrayworker.forEach(bg => {
                            if (work.val().siteid == bg.key) {
                                thisbg = bg.color;
                                return;
                            }
                        })
                        this.sitemenuworker.forEach(site => {
                            if (site.key == work.val().siteid)
                                title = site.name;
                        })
                        work.val().backgroundColor = thisbg;
                        var username = ' - ';
                        this.tempmember.forEach(mem => {
                            if (mem.userId == work.val().userId) {
                                if (mem.rename)
                                    username = mem.rename;
                                else if (mem.name)
                                    username = mem.name;
                                else
                                    username = mem.email;
                            }
                        })

                        var existed = -1;
                        for (let i = 0; i < this.caldataworkers.length; i++) {
                            // 같은날짜에 같은사이트가 있을땐 병합필요
                            if ((existed == -1) && (this.caldataworkers[i].siteid == work.val().siteid) && (this.caldataworkers[i].start == work.val().start)) {
                                existed = i;
                            }
                        }

                        if (existed > -1) {
                            this.caldataworkers[existed].data.push({ ptype: work.val().ptype, rate: work.val().rate, userId: work.val().userId, name: username, memo: work.val().memo })
                        }
                        else
                            this.caldataworkers.push({
                                $key: work.key, start: work.val().start, title: title, backgroundColor: thisbg, siteid: work.val().siteid,
                                data: [{ ptype: work.val().ptype, rate: work.val().rate, userId: work.val().userId, name: username, memo: work.val().memo }]
                            });
                    }
                })

                this.setdetailworkers();

                if (this.workCalendar) {
                    //this.workCalendar.fullCalendar('removeEvents', function () { return true; });
                    this.calendarEvents = this.caldataworkers;
                    //this.workCalendar.fullCalendar('renderEvents', this.caldataworkers, true );
                }
            })
        //this.bgarrayworker.push({title:cursite.nickname, color:this.rainbow(Object.keys(siteitem[0].sites).length, i)});
    }
    /*
       // 사이트 목록 로드
         this.afDB.list('/userProfile/'+ this.afAuth.auth.currentUser.uid + '/work', {query: {
                                             orderByChild: "id",
                                             equalTo: this.groupselect 
                                         }}).subscribe(
         siteitem => {
             console.log('워크변경됨-겟사이트로드');
             if(siteitem[0])
                 {
                     firebase.database().ref('/userProfile/'+ this.afAuth.auth.currentUser.uid + '/work/' + siteitem[0].$key + '/site').once('value').then(
                     upsites => {
                         firebase.database().ref('category/' + this.groupselect + '/site').once('value').then(ctsites=>{
                             this.sitemenu = [];
                             ctsites.forEach(ctsite => {
                                 upsites.forEach(upsite =>{
                                 if(ctsite.key == upsite.val()['id'])
                                 {
                                 if(ctsite.val()['name'])
                                 this.sitemenu.push({id:ctsite.key, name:ctsite.val()['name'], member:ctsite.val()['member']})
                                 else
                                 this.sitemenu.push({id:ctsite.key, name:'[삭제된 근무지]', member:[]})
                                 }
                                 });
                         })
                             if(this.sitemenu.length == 0)
                             {
                                 this.siteselect = '0';  
                             }
                             if(this.sitemenu[0] && this.siteselect == '0')
                                 {
                                 this.siteselect = this.sitemenu[0]['id'];  
                                 // 사이트에 속한 멤버 로드
                                 this.member = <AngularFireList<any[]>> this.afDB.list('/category/'+this.groupselect + '/site/' + 
                                 this.siteselect + '/member').map( (arr) => { 
                                         return arr.map((arr) => {
                                             this.tempmember = [];
                                             arr.userProfile = this.afDB.object('/userProfile/' + arr.userId);
                                             arr.userProfile.subscribe(item=>this.tempmember.push(item));
                                             return arr; 
                                         })
                                         });
                                 } 
                         })
                     }
                     )
                     this.getsitedata();                
                 }
             else{}
         });
   }
 */

    memberdismenu(member) {
        var displayname;
        if (member.rename)
            displayname = member.rename;
        else if (member.name)
            displayname = member.name;
        else
            displayname = member.email;
        let actionSheet = this.asCtrl.create({
            header: displayname + '(탈퇴회원)님 기록제거',
            buttons: [
                {
                    text: '완전삭제',
                    handler: () => {
                        let changerate = this.alertCtrl.create({
                            message: '정말 ' + displayname + '님의 기록을 삭제하시겠습니까?<br><br>삭제된 기록은 복원되지 않으며 회원목록에서도 영구 제거됩니다.',
                            buttons: [
                                {
                                    text: '취소',
                                    role: 'cancel'
                                },
                                {
                                    text: '확인',
                                    handler: () => {
                                        this.afDB.database.ref('/workdata/' + this.groupselect).orderByChild("userId")
                                            .equalTo(member.userId).once('value').then(
                                            workitem => {
                                                workitem.forEach(work => {
                                                    this.afDB.database.ref('/workdata/' + this.groupselect + '/' + work.key).remove();
                                                });
                                            }).then(_ => {
                                                this.afDB.database.ref('/workgroup/' + this.groupselect + '/member/' + member.$key).remove()
                                                    .then(_ => {
                                                        this.presentToast('bottom', displayname + '님의 데이터가 삭제되었습니다.', 3000)
                                                    })
                                            })
                                    }
                                }
                            ]
                        });
                        changerate.then(view => view.present())
                    }
                },
                {
                    text: '취소',
                    role: 'cancel'
                }
            ]
        });

        actionSheet.then(view => view.present())
    }
    membermenu(member) {
        var mgtext;
        var mgstate;
        if (member.manager) {
            mgtext = "일반회원으로 변경";
            mgstate = '매니저 - ';
        }
        else {
            mgtext = "매니저로 변경";
            mgstate = '';
        }

        var displayname;
        var titlestr;
        if (member.rename)
            displayname = member.rename;
        else if (member.name)
            displayname = member.name;
        else
            displayname = member.email;

        titlestr = displayname + '님 ';

        if (member.ratename && member.memberrate)
            titlestr += ' (' + mgstate + member.ratename + ', ' + member.memberrate + '%)';
        else if (member.memberrate)
            titlestr += ' (' + mgstate + member.memberrate + '%)';
        else
            titlestr += ' (' + mgstate + '등급없음)';

        let actionSheet = this.asCtrl.create({
            header: titlestr,
            buttons: [
                {
                    text: '표시될 이름변경',
                    handler: () => {
                        let changename = this.alertCtrl.create({
                            header: '표시이름 변경',
                            inputs: [
                                {
                                    name: 'name',
                                    placeholder: '표시 이름',
                                    value: displayname,
                                }
                            ],
                            buttons: [
                                {
                                    text: '취소',
                                    role: 'cancel'
                                },
                                {
                                    text: '확인',
                                    handler: (data) => {
                                        this.afDB.database.ref('/workgroup/' + this.groupselect + '/member/' + member.$key)
                                            .update({ rename: data.name });
                                        this.presentToast('bottom', '변경완료 하였습니다.', 3000)
                                    }
                                }
                            ]
                        });
                        changename.then(view => view.present())
                    }
                },
                {
                    text: mgtext,
                    handler: () => {
                        if (mgtext == '매니저로 변경') {
                            let tomanager = this.alertCtrl.create({
                                header: '매니저로 변경',
                                message: displayname + '님을 매니저로 지정하시겠습니까?',
                                buttons: [
                                    {
                                        text: '취소',
                                        role: 'cancel'
                                    },
                                    {
                                        text: '확인',
                                        handler: () => {
                                            this.afDB.database.ref('/workgroup/' + this.groupselect + '/member/' + member.$key).update({ manager: true });
                                            this.presentToast('bottom', displayname + '님이 매니저로 지정되었습니다.', 3000)
                                        }
                                    }
                                ]
                            });
                            tomanager.then(view => view.present())
                        }
                        else {
                            let tomanager = this.alertCtrl.create({
                                header: '일반회원 전환',
                                message: displayname + '님을 일반회원으로 변경하시겠습니까?',
                                buttons: [
                                    {
                                        text: '취소',
                                        role: 'cancel'
                                    },
                                    {
                                        text: '확인',
                                        handler: () => {
                                            this.afDB.database.ref('/workgroup/' + this.groupselect + '/member/' + member.$key).update({ manager: false });
                                            this.presentToast('bottom', displayname + '님이 일반회원으로 지정되었습니다.', 3000)
                                        }
                                    }
                                ]
                            });
                            tomanager.then(view => view.present())
                        }
                    }
                },
                {
                    text: '회원등급 변경',
                    handler: () => {
                        var memberrateinput = [];
                        this.groupmenu.forEach(item => {
                            if (item.id == this.groupselect) {
                                for (let i = 0; i < Object.keys(item.rates).length; i++) {
                                    memberrateinput.push({
                                        type: 'radio',
                                        label: item.rates[Object.keys(item.rates)[i]].name + ' (' + item.rates[Object.keys(item.rates)[i]].rate + '%)',
                                        value: item.rates[Object.keys(item.rates)[i]].name + '@#@' + item.rates[Object.keys(item.rates)[i]].rate
                                    });
                                }
                                return;
                            }
                        })
                        let changerate = this.alertCtrl.create({
                            message: displayname + '님의 새등급을 선택해주세요.',
                            inputs: memberrateinput,
                            buttons: [
                                {
                                    text: '취소',
                                    role: 'cancel'
                                },
                                {
                                    text: '확인',
                                    handler: (data) => {
                                        if (data) {
                                            this.afDB.database.ref('/workgroup/' + this.groupselect + '/member/' + member.$key).update({ ratename: data.split('@#@')[0], memberrate: parseFloat(data.split('@#@')[1]) })
                                                .then(_ => {
                                                    this.presentToast('bottom', '등급수정을 완료하였습니다.', 2000)
                                                })
                                        }
                                        else {
                                            this.presentToast('bottom', '등급을 선택해주세요.', 2000)
                                            changerate.then(view => view.present())
                                        }
                                    }
                                }
                            ]
                        });
                        changerate.then(view => view.present())
                    }
                },
                {
                    text: '내보내기',
                    handler: () => {
                        let changerate = this.alertCtrl.create({
                            message: '정말 ' + displayname + '님을 내보내시겠습니까?',
                            buttons: [
                                {
                                    text: '취소',
                                    role: 'cancel'
                                },
                                {
                                    text: '확인',
                                    handler: () => {
                                        this.afDB.database.ref('/workgroup/' + this.groupselect + '/member/' + member.$key).update({ disabled: true })
                                            .then(_ => {
                                                this.presentToast('bottom', displayname + '님을 내보냈습니다.', 2000)
                                            })
                                    }
                                }
                            ]
                        });
                        changerate.then(view => view.present())
                    }
                },
                {
                    text: '취소',
                    role: 'cancel'
                }
            ]
        });

        actionSheet.then(view => view.present())
    }
    swapmanager(name, uid)
    { }
    privatechat(name, uid)
    { }
    /*
     showshareinfo(name, uid)
     {
       let alert = this.alertCtrl.create({
         header:name + '님 정보',
         subHeader:'근무 2020/09/01부터 2020/10/31까지<br>디파짓 $240불',
         buttons: ['확인']
       });
       alert.then(view=>view.present())
     }
   
     removemember(name, uid){
         let removealert = this.alertCtrl.create({
                                       header:'사용자 내보내기',
                                       message: name + '님을 내보내시겠습니까?',
                                       buttons: [
                                           {
                                           text: '취소',
                                           role: 'cancel'
                                           },
                                           {
                                           text: '내보내기',
                                           handler: () => {
                                           let userremove = this.afDB.list('/category/' + this.groupselect + '/member', {query: {
                                               orderByChild: "userId",
                                               equalTo: uid 
                                           }});//.subscribe(<AngularFireObject>(item) => item.remove());
                                           userremove.subscribe((items)=>{
                                               if (items.length) {
                                               userremove.remove(items[0].$key).then(()=> 
                                                {this.sitemsg.push({
                                                   category:"공지",
                                                   createdDate: firebase.database.ServerValue.TIMESTAMP,
                                                   description: name + "님이 나가셨습니다.",
                                                   shareId:this.groupselect,
                                                   userId:this.afAuth.auth.currentUser.uid
                                               })
                                               this.presentToast('bottom',name+'님을 내보냈습니다.', 1000)}
                                               )}
                                           });
                                           }
                                           }
                                       ]
                                       }); 
                               removealert.then(view=>view.present())
     }*/


    addmember() {
        let alert = this.alertCtrl.create({
            header: '멤버 초대',
            message: "초대하실 회원의 계정을 입력해 주세요.",
            inputs: [
                {
                    name: 'value',
                    placeholder: '이메일주소'
                }
            ],
            buttons: [
                {
                    text: '취소'
                },
                {
                    text: '추가',
                    handler: data => {
                        var alreadymember = "";
                        if (data.value.indexOf('@') > 0) // 이메일
                        {
                            this.tempmember.forEach(item => {
                                if (item.email == data.value) {
                                    alreadymember = item.email;
                                    return;
                                }
                            })
                            if (!alreadymember)
                                this.afDB.database.ref('userProfile').orderByChild('email').equalTo(data.value).once('value').then(
                                    user => {
                                        if (!user.val()) {
                                            let erralert = this.alertCtrl.create({
                                                header: '사용자 없음',
                                                message: '다시 검색해 보시겠습니까?',
                                                buttons: [
                                                    {
                                                        text: '닫기',
                                                        role: 'cancel'
                                                    },
                                                    {
                                                        text: '재시도',
                                                        handler: () => {
                                                            this.addmember();
                                                        }
                                                    }
                                                ]
                                            });
                                            erralert.then(view => view.present())
                                        }
                                        else {
                                            var memberrateinput = [];
                                            this.groupmenu.forEach(item => {
                                                if (item.id == this.groupselect) {
                                                    for (let i = 0; i < Object.keys(item.rates).length; i++) {
                                                        memberrateinput.push({
                                                            type: 'radio',
                                                            label: item.rates[Object.keys(item.rates)[i]].name + ' (' + item.rates[Object.keys(item.rates)[i]].rate + '%)',
                                                            value: item.rates[Object.keys(item.rates)[i]].name + '@#@' + item.rates[Object.keys(item.rates)[i]].rate
                                                        });
                                                    }
                                                    return;
                                                }
                                            })

                                            let setrate = this.alertCtrl.create({
                                                header: '등급설정',
                                                message: '해당 회원의 등급을 설정해주세요.',
                                                inputs: memberrateinput,
                                                buttons: [
                                                    {
                                                        text: '취소',
                                                        role: 'cancel'
                                                    },
                                                    {
                                                        text: '완료',
                                                        handler: (data) => {
                                                            if (data) {
                                                                this.afDB.database.ref('/workinfo/' + Object.keys(user.val())[0]).push({
                                                                    id: this.groupselect
                                                                }).then(_ => {
                                                                    this.afDB.database.ref('/workgroup/' + this.groupselect + '/member').push({
                                                                        userId: Object.keys(user.val())[0], manager: false, ratename: data.split('@#@')[0], memberrate: parseFloat(data.split('@#@')[1])
                                                                    }).then(_ => {
                                                                        //this.getmember(); 
                                                                        this.presentToast('bottom', '멤버를 초대하였습니다.', 2000)
                                                                    })
                                                                });
                                                            }
                                                            else {
                                                                this.presentToast('bottom', '등급을 선택해주세요.', 2000)
                                                                setrate.then(view => view.present())
                                                            }
                                                            /*
                                                                firebase.database().ref('/message').push({
                                                                    category: 'worktool',
                                                                    type: 'msg',
                                                                    sendfrom: this.afAuth.auth.currentUser.uid,
                                                                    sendto: user[0].$key,
                                                                    option: this.groupselect,
                                                                    optionsub: this.siteselect,
                                                                    optiontype: 'invitework',
                                                                    title: this.groupname + '-' + this.sitename + ' 근무 채널에 초대합니다.',
                                                                    createdDate: firebase.database.ServerValue.TIMESTAMP
                                                                }).then(() => this.presentToast('bottom','멤버를 초대하였습니다.', 1000))*/
                                                        }
                                                    }
                                                ]
                                            });
                                            setrate.then(view => view.present())

                                        }
                                    }
                                );
                            else {
                                let erralert = this.alertCtrl.create({
                                    header: alreadymember + ' - 이미 추가됨',
                                    message: '다른 사람을 검색해 보시겠습니까?',
                                    buttons: [
                                        {
                                            text: '닫기',
                                            role: 'cancel'
                                        },
                                        {
                                            text: '추가검색',
                                            handler: () => {
                                                this.addmember();//alert.then(view=>view.present())
                                            }
                                        }
                                    ]
                                });
                                erralert.then(view => view.present())
                            }
                        }
                        else {
                            let erralert = this.alertCtrl.create({
                                header: '입력오류',
                                message: '이메일주소로 입력하셨나요?',
                                buttons: [
                                    {
                                        text: '닫기',
                                        role: 'cancel'
                                    },
                                    {
                                        text: '재시도',
                                        handler: () => {
                                            this.addmember();
                                        }
                                    }
                                ]
                            });
                            erralert.then(view => view.present())
                            /*
                            this.tempmember.forEach(item =>
                            {
                                if(item.name == data.value)
                                {
                                alreadymember = item.name; 
                                return;
                                }
                            })
                            if(!alreadymember)
                            this.afDB.database.ref('userProfile').orderByChild('name').equalTo(data.value).once('value').then
                            (user => 
                                {
                                if(!user.val())
                                {
                                    let erralert = this.alertCtrl.create({
                                        header:'사용자 없음',
                                        message: '다시 검색해 보시겠습니까?',
                                        buttons: [
                                            {
                                            text: '닫기',
                                            role: 'cancel'
                                            },
                                            {
                                            text: '다시검색',
                                            handler: () => {
                                                this.addmember();//alert.then(view=>view.present())
                                            }
                                            }
                                        ]
                                        }); 
                                erralert.then(view=>view.present())
                                }
                                else if(Object.keys(user.val()).length == 1)
                                {
                                    var memberrateinput = [];
                                    this.groupmenu.forEach(item => {
                                    if(item.id == this.groupselect)
                                        {
                                            for(let i = 0; i < Object.keys(item.rates).length ; i++)
                                            {
                                                memberrateinput.push({
                                                type:'radio', 
                                                label:item.rates[Object.keys(item.rates)[i]].name + ' (' + item.rates[Object.keys(item.rates)[i]].rate + '%)', 
                                                value:item.rates[Object.keys(item.rates)[i]].name + '@#@' + item.rates[Object.keys(item.rates)[i]].rate
                                                });
                                            }
                                            return;
                                        }
                                    })
                                    
                                    let setrate = this.alertCtrl.create({
                                        header:'등급설정',
                                        message: '해당 회원의 등급을 설정해주세요.',
                                        inputs:memberrateinput,
                                        buttons: [
                                            {
                                            text: '취소',
                                            role: 'cancel'
                                            },
                                            {
                                            text: '완료',
                                            handler: (data) => {
                                                if(data)
                                                {
                                                 this.afDB.database.ref('/workinfo/' + Object.keys(user.val())[0]).push({
                                                id:this.groupselect
                                                }).then(_=>{
                                                    this.afDB.database.ref('/workgroup/' + this.groupselect + '/member').push({
                                                    userId:Object.keys(user.val())[0], manager:false, ratename:data.split('@#@')[0], memberrate:parseFloat(data.split('@#@')[1])
                                                    }).then(_=>{
                                                        //this.getmember(); 
                                                        this.presentToast('bottom','멤버를 초대하였습니다.', 2000)
                                                    })
                                                });
                                                
                                                }
                                                else
                                                {
                                                    this.presentToast('bottom','등급을 선택해주세요.', 2000)
                                                    setrate.then(view=>view.present())
                                                }
                                          
                                            }
                                            }
                                        ]
                                        }); 
                                    setrate.then(view=>view.present())
                                }
                                else  // 중복된 실명이 2개이상 있을경우
                                {
                                    let erralert = this.alertCtrl.create({
                                        header:'이메일검색 필요',
                                        message: '<p>해당 이름으로 등록된 유저가 2인이상입니다.</p><p>이메일로 검색해주세요.</p>',
                                        buttons: [
                                            {
                                            text: '닫기',
                                            role: 'cancel'
                                            },
                                            {
                                            text: '확인',
                                            handler: () => {
                                                this.addmember();//alert.then(view=>view.present())
                                            }
                                            }
                                        ]
                                        }); 
                                erralert.then(view=>view.present())
                                }
                                }
                            );
                            else
                                {
                                let erralert = this.alertCtrl.create({
                                        header:alreadymember + ' - 이미 추가됨',
                                        message: '다른 사람을 검색해 보시겠습니까?',
                                        buttons: [
                                            {
                                            text: '닫기',
                                            role: 'cancel'
                                            },
                                            {
                                            text: '추가검색',
                                            handler: () => {
                                                this.addmember();//alert.then(view=>view.present())
                                            }
                                            }
                                        ]
                                        }); 
                                erralert.then(view=>view.present())
                                }*/
                        }
                    }
                }
            ]
        });
        alert.then(view => view.present())
    }

    /*
    
     selectedsitename(){
        this.sitemenu.forEach(site => {
              if(site.data.id == this.siteselect)
                {this.sitename = site.data.name; return;}
          });
      }
    
      getsitedata()
      {
         this.sitemsg =  <AngularFireList<any[]>>
         this.afDB.list('schedule', {query: {
              orderByChild: "siteId",
              equalTo: this.siteselect 
          }});
         this.selectedgroupname();
         this.selectedsitename();
         this.sitemsgdata = [];  
         this.sitemsg.map( (arr) => { 
                return arr.map((arr) => {
                    this.tempmember = [];
                    arr.userProfile = this.afDB.object('/userProfile/' + arr.userId);
                    arr.userProfile.subscribe(item=>this.tempmember.push(item));
                    return arr; 
                })
                } ).subscribe(sitedata => {
                    if(sitedata.length){
                    this.caldata = [];
                    //this.calendarPlugins.events.splice(0, this.calendarPlugins.events.length);
                    var totalroom = 0;
                    var currentroom = -1;
                    for(var i=0 ; i < sitedata.length; i++)
                    {
                        if(sitedata[i].category == '근무')
                            totalroom++;
                    }
                    for(var i=0 ; i < sitedata.length; i++)
                    {
                        if(sitedata[i].category == '근무') 
                           // ********** 중요: 풀캘린더 이벤트 종료일을 맞추기 위해 각 아이템의 종료일을 1일씩 추가 *********
                            {
                                var endadj = new Date(sitedata[i].enddate);
                                endadj.setDate(endadj.getDate()+1);
                                var endstr;
                                if(endadj.getMonth()+1 < 10)
                                    endstr = endadj.getFullYear() + '-0' + (endadj.getMonth()+1);
                                else
                                    endstr = endadj.getFullYear() + '-' + (endadj.getMonth()+1)
                                if(endadj.getDate() < 10)
                                    endstr += '-0'+endadj.getDate()
                                else
                                    endstr += '-'+endadj.getDate();
                                var bgcolor = this.rainbow(totalroom, currentroom++);
                                
                                this.caldata.push(
                        {title:sitedata[i].sitename + '] ' + sitedata[i].personname,start:sitedata[i].startdate,end:endstr,id:sitedata[i].$key,
                        userId:sitedata[i].userId,
                        backgroundColor:bgcolor}); 
                    }
                    }
                    this.sitemsgdata = sitedata;
                    this.calendarPlugins.events = this.caldata;
                    if(this.workCalendar)
                    {
                    this.workCalendar.fullCalendar('removeEvents', function () { return true; });
                    this.workCalendar.fullCalendar( 'renderEvents', this.caldata, true );
                    }
                }
                });
      }
      showdetail(key)
      {
          var data;
            for(var i=0; i < this.sitemsgdata.length; i++)
                if(this.sitemsgdata[i]["$key"] == key)
                    {data = this.sitemsgdata[i];}
    
        if(data.category == '근무')
        {
            let alert = this.alertCtrl.create({
            cssClass: 'custom-alert',
            title: '근무 항목',
            message: "<span style='font-size:1.2rem; color:black'>근무지 / 근무자 / 시작일 / 종료일 순서입니다.<br>*날짜형식은 2017-01-01에 맞아야합니다.</span>",
            inputs: [
                {
                name: 'sitename',
                value: data.sitename
                },
                {
                name: 'personname',
                value: data.personname
                },
                {
                name: 'startdate',
                value: data.startdate,
                type: 'date'
                },
                {
                name: 'enddate',
                value: data.enddate,
                type: 'date'
                },
                {
                name: 'description',
                value: data.description,
                placeholder: '본드/기타사항'
                },
            ],
            buttons: [
                {
                text: '삭제',
                cssClass: 'custombtnd',
                handler: data =>{
                    let removealert = this.alertCtrl.create({
                    message: data.personname + "님의 기록을 완전 삭제 하시겠습니까?",
                    buttons: [
                        {
                        text: '취소',
                        role: 'cancel'
                        },
                        {
                        text: '삭제',
                        handler: () => {
                            firebase.database().ref('/schedule/' + key).remove();
                            this.presentToast('bottom','근무정보를 삭제하였습니다.', 1000)
                            }
                        }
                    ]
                    });
                    removealert.present();           
                }
                },
                {
                text: '취소',
                cssClass: 'custombtnd'
                },
                {
                text: '+1주',
                cssClass: 'custombtn',
                handler: data => {
                    var tempend = new Date(data.enddate);
                    tempend.setDate(tempend.getDate()+7);
                    var endstr;
                    if((tempend.getMonth()+1) < 10)
                        endstr = tempend.getFullYear() + '-0' + (tempend.getMonth()+1);
                    else
                        endstr = tempend.getFullYear() + '-' + (tempend.getMonth()+1);
                    if(tempend.getDate() < 10)
                        endstr += '-0'+tempend.getDate()
                    else
                        endstr += '-'+tempend.getDate()
                    firebase.database().ref('/schedule/' + key).update({
                    enddate: endstr
                    });
                    //console.log(data.sitename + '/' + data.personname + '/' + data.startdate + '/' + data.enddate)
                    //console.log('Saved clicked');
                }
                },
                {
                text: '+2주',
                cssClass: 'custombtn',
                handler: data => {
                    var tempend = new Date(data.enddate);
                    tempend.setDate(tempend.getDate()+14);
                    var endstr;
                    if((tempend.getMonth()+1) < 10)
                        endstr = tempend.getFullYear() + '-0' + (tempend.getMonth()+1);
                    else
                        endstr = tempend.getFullYear() + '-' + (tempend.getMonth()+1);
                    if(tempend.getDate() < 10)
                        endstr += '-0'+tempend.getDate()
                    else
                        endstr += '-'+tempend.getDate()
                    firebase.database().ref('/schedule/' + key).update({
                    enddate: endstr
                    });
                }
                },
                {
                text: '수정',
                cssClass: 'custombtn',
                handler: data => {
                    firebase.database().ref('/schedule/' + key).update({
                    sitename: data.sitename,
                    personname: data.personname,
                    startdate: data.startdate,
                    enddate: data.enddate,
                    description: data.description
                    });
                    //console.log(key + ':' + data.sitename + '/' + data.personname + '/' + data.startdate + '/' + data.enddate)
                    //console.log('Saved clicked');
                }
                }
            ]
            });
        alert.present();   
        }
      }
      openDetail(workId){
          this.navCtrl.push('WorkDetailPage',{itemId:workId});
      }
    
      addGroup(){
          let alert = this.alertCtrl.create({
          title: '그룹 만들기',
          message: "그룹 닉네임을 입력해 주세요",
          inputs: [
            {
              name: 'name',
              placeholder: '닉네임'
            },
          ],
          buttons: [
            {
              text: '취소',
              handler: data => {
                //console.log('Cancel clicked');
              }
            },
            {
              text: '추가',
              handler: data => {
                var chksamename = false;
                this.groupmenu.forEach(item =>{
                    if(item.name == data.name)
                    { chksamename = true; return; }
                })  
                if(chksamename)
                {
                    // 이미 같은 이름의 근무가 있습니다.
                    let alert = this.alertCtrl.create({
                    title: '이름 중복',
                    message: '해당 이름이 이미 사용되고 있습니다.<br>다른 이름으로 만드시겠습니까?',
                    buttons: [
                        {
                        text: '아니요',
                        role: 'cancel',
                        handler: () => {
                        }
                        },
                        {
                        text: '재등록',
                        handler: () => {
                            this.addGroup();
                        }
                        }
                    ]
                    });
                    alert.present();
                }
                else
                {
                    firebase.database().ref('/category').push({
                    userId: this.afAuth.auth.currentUser.uid,
                    name: data.name,
                    type:'group',
                    createdDate: firebase.database.ServerValue.TIMESTAMP,
                    manager:[{userId:this.afAuth.auth.currentUser.uid}]
                    }).then(item=>{
                        this.groupselect = item.key;
                        this.groupname = data.name;
                       
                        firebase.database().ref('/category/' + this.groupselect + '/site').push({
                        name: '공지사항', member:[{userId:this.afAuth.auth.currentUser.uid}]
                        }).then(added=>{
                        firebase.database().ref('/userProfile/' + this.afAuth.auth.currentUser.uid + '/work/').push({id:item.key}).then(
                            obj=>{
                                firebase.database().ref('/userProfile/'+ this.afAuth.auth.currentUser.uid + '/work/' + obj.key + '/site').push({id:added.key});
                                    this.sitename = data.name;
                                }
                        );
                        this.sitemsg.push({
                        category:"공지",
                        createdDate: firebase.database.ServerValue.TIMESTAMP,
                        description: data.name + " 그룹채널을 만드셨습니다.\n우측 <ion-icon name='ios-settings'>를 눌러 근무지를 추가해 주세요.",
                        siteId:added.key,
                        userId:this.afAuth.auth.currentUser.uid
                        });
                        });
            })
            .then( newList => {
              this.selectedgroupname();
              this.presentToast('bottom','그룹정보를 등록하였습니다.', 1000)
            }, error => {
              //console.log(error);
            });
                //console.log('Saved clicked');
            }
            }
            }
          ]
        });
      alert.present();
      }
    
      editGroup(){
          var currentsel = this.groupselect;
          var loadeditem = this.afDB.object('/category/'+this.groupselect);
          let alert = this.alertCtrl.create({
          title: '그룹 수정',
          message: "그룹 닉네임을 입력해 주세요",
          inputs: [
            {
              name: 'name',
              placeholder: '닉네임',
              value: this.groupname //this.groupselect.name,
            },
          ],
          buttons: [
            {
              text: '취소',
              handler: data => {
                //console.log('Cancel clicked');
              }
            },
            {
              text: '수정',
              handler: data => {
                  loadeditem.update({
              name: data.name,
              })
            
            .then( newList => {
            this.groupname = data.name;
            this.afDB.list('userProfile/' + this.afAuth.auth.currentUser.uid + '/work').subscribe(groupitem=>{
            var gm = [];
            //console.log('그룹아이템로드-이름수정');
            groupitem.forEach(item => {
                firebase.database().ref('category/' + item.id).once('value').then(function(obj){
                if(obj.child('name'))
                    gm.push({id:obj.key, name:obj.child('name').val(), member:obj.child('member').val()})
                else
                    gm.push({id:obj.key, name:'[삭제된 채널]', member:[]})
                })
            this.groupmenu = gm;
            });
                 });
                this.presentToast('bottom','그룹정보를 수정하였습니다.', 1000)
            }, error => {
            });
            }
            }
          ]
        });
      alert.present(); 
      }
        
     delGroup(){
        let alert = this.alertCtrl.create({
          title: '삭제 확인',
          message: '해당 그룹을 삭제하시겠습니까?',
          buttons: [
            {
              text: '아니요',
              role: 'cancel',
              handler: () => {
              }
            },
            {
              text: '삭제',
              handler: () => {
                firebase.database().ref('/category/' + this.groupselect).remove().then(_=>
                   {
                     this.afDB.list('/userProfile/' + this.afAuth.auth.currentUser.uid 
                     + '/work', {query: {
                        orderByChild: "id",
                        equalTo: this.groupselect 
                    }}).subscribe(removeitem=> 
                        {
                        firebase.database().ref('/userProfile/' + this.afAuth.auth.currentUser.uid 
                        + '/work/' + removeitem[0]['$key']).remove()})
                     this.presentToast('bottom','그룹정보를 삭제하였습니다.', 1000)
                   }
                ).then(_=> 
                  {
                      if(this.groupmenu.length > 0)
                      this.groupselect = this.groupmenu[0].id;
                  })
              }
            }
          ]
        });
        alert.present();
      }
    
      addSite(){
          let alert = this.alertCtrl.create({
          title: '근무지 만들기',
          message: "근무지 닉네임을 입력해 주세요",
          inputs: [
            {
              name: 'name',
              placeholder: '닉네임'
            },
          ],
          buttons: [
            {
              text: '취소',
              handler: data => {
                //console.log('Cancel clicked');
              }
            },
            {
              text: '추가',
              handler: data => {
                var chksamename = false;
                this.sitemenu.forEach(site =>{
                    if(site.data.name == data.name)
                    { chksamename = true; return; }
                })
                if(chksamename)
                {
                    // 이미 같은 이름의 근무가 있습니다.
                    let alert = this.alertCtrl.create({
                    title: '이름 중복',
                    message: '해당 이름이 이미 사용되고 있습니다.<br>다른 이름으로 만드시겠습니까?',
                    buttons: [
                        {
                        text: '아니요',
                        role: 'cancel',
                        handler: () => {
                        }
                        },
                        {
                        text: '재등록',
                        handler: () => {
                            this.addSite();
                        }
                        }
                    ]
                    });
                    alert.present();
                }
                else
                {
                    firebase.database().ref('/category/' + this.groupselect + '/site').push({
                    name: data.name
                    }).then(added=>{
                        this.siteselect = added.key;
                        firebase.database().ref('/userProfile/'+ this.afAuth.auth.currentUser.uid + '/work').orderByChild('id')
                        .equalTo(this.groupselect).once('value').then
                        (item=>
                        {
                            firebase.database().ref('/userProfile/' + this.afAuth.auth.currentUser.uid + '/work/' 
                                                + Object.keys(item.val())[0] + '/site').push({id:added.key});
                            this.sitename = data.name;
                                            });
                         this.sitemsg.push({
                        category:"공지",
                        createdDate: firebase.database.ServerValue.TIMESTAMP,
                        description: data.name + " 근무지를 만드셨습니다.\n우측 <ion-icon name='ios-settings'>를 눌러 근무지를 추가해 주세요.",
                        siteId:added.getKey(),
                        userId:this.afAuth.auth.currentUser.uid
                        })
            })
            .then( newList => {
              this.siteadded = true;
              console.log('사이트추가되게 했다!')
              this.selectedgroupname();
              this.presentToast('bottom','사이트를 등록하였습니다.', 1000)
            }, error => {
              //console.log(error);
            });
            
                //console.log('Saved clicked');
            }
            }
            }
          ]
        });
      alert.present(); 
      }
      editSite(){
          var currentsel = this.groupselect;
          var loadeditem = this.afDB.object('/category/'+this.groupselect+'/site/'+this.siteselect);
          let alert = this.alertCtrl.create({
          title: '근무지 수정',
          message: "근무지 닉네임을 입력해 주세요",
          inputs: [
            {
              name: 'name',
              placeholder: '닉네임',
              value: this.sitename //this.groupselect.name,
            },
          ],
          buttons: [
            {
              text: '취소',
              handler: data => {
                //console.log('Cancel clicked');
              }
            },
            {
              text: '수정',
              handler: data => {
                  loadeditem.update({
              name: data.name,
              })
            
            .then( newList => {
              this.sitename = data.name;
              this.presentToast('bottom','근무지를 수정하였습니다.', 1000)
            }, error => {
              //console.log(error);
            });
                //console.log('Saved clicked');
              }
            }
          ]
        });
      alert.present(); 
      }
    
     delSite(){
        let alert = this.alertCtrl.create({
          title: '삭제 확인',
          message: '해당 근무지를 삭제하시겠습니까?',
          buttons: [
            {
              text: '아니요',
              role: 'cancel',
              handler: () => {
              }
            },
            {
              text: '삭제',
              handler: () => {
                firebase.database().ref('/category/' + this.groupselect + '/site/' + this.siteselect).remove().then(_=>
                   {
                     this.presentToast('bottom','그룹정보를 삭제하였습니다.', 1000)
                   }
                ).then(_=> 
                  {
                      this.selectedgroupname();
                  })
              }
            }
          ]
        });
        alert.present();
      }
    
      addchat(msg){
          firebase.database().ref('/schedule').push({
              userId: this.afAuth.auth.currentUser.uid,
              siteId: this.siteselect,
              category: '대화',
              description: msg,
              createdDate: firebase.database.ServerValue.TIMESTAMP
            })//_=>this.scrollToBottom());
      }
    */

    presentToast(position: string, message: string, duration: number) {
        let toast = this.toastCtrl.create({
            message: message,
            position: 'bottom',
            duration: duration
        });
        toast.then(view => view.present())
    }


    rainbow(numOfSteps, step) {
        // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
        // Adam Cole, 2011-Sept-14
        // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
        var r, g, b;
        var h = step / numOfSteps;
        var i = ~~(h * 6);
        var f = h * 6 - i;
        var q = 1 - f;
        switch (i % 6) {
            case 0: r = 1; g = f; b = 0; break;
            case 1: r = q; g = 1; b = 0; break;
            case 2: r = 0; g = 1; b = f; break;
            case 3: r = 0; g = q; b = 1; break;
            case 4: r = f; g = 0; b = 1; break;
            case 5: r = 1; g = 0; b = q; break;
        }
        //122이상의 밝은 컬러만 채택
        var c = "#" + ("00" + (~ ~((r * 45) + 210)).toString(16)).slice(-2) + ("00" + (~ ~((g * 45) + 210)).toString(16)).slice(-2) + ("00" + (~ ~((b * 45) + 210)).toString(16)).slice(-2);
        return (c);
    }

}