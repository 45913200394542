import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { GlobalVars } from './globalVars';
import { OrderByPipe } from '../pipe/orderbypipe';
import { map } from 'rxjs/operators';
import { Badge } from '@ionic-native/badge/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Http, Headers } from '@angular/http';
import { LevelupmodalComponent } from '../pages/support/levelupmodal/levelupmodal.component';
import { pointfunc } from '../providers/pointfunc';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
//import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { auth } from 'firebase/app';
/*import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed } from '@capacitor/core';
const { PushNotifications } = Plugins;
*/
@Injectable()
export class AuthData {
  signinuser: boolean;
  inited: boolean = false;
  constructor(public afAuth: AngularFireAuth, private gv: GlobalVars, private router: Router, private http: Http,
    private loadCtrl: LoadingController,
    private googlePlus: GooglePlus, private ngzone: NgZone,
    //private facebook: Facebook,
    private modalCtrl: ModalController, private pointfunc: pointfunc, private toastCtrl: ToastController,
    private fcm: FCM, private orderby: OrderByPipe, public badge: Badge) {
    //this.init();
  }

  //fcm 토큰 확인
  tkchk() {
    this.fcm.getToken().then(rst => {
      console.log('현재 토큰 : ' + rst)
    })
  }
  
  init() {
    // console.log('이닛')
    this.gv.loadingboard = true;
    if (!this.inited) {
      this.inited = true;
      this.afAuth.authState.subscribe(auth => {
        if (auth) {
          this.signinuser = true;
          //this.gv.uvf = false// firebase.auth().currentUser.emailVerified;
          this.gv.uid = firebase.auth().currentUser.uid;
          //this.gv.uid = 'Y1voasbr8gbym5hlFzOVeX7gtbC3';//l67Zm3Z7G1UndxBliLbclB8GNdn1'//PjAFwxK2HqezVLMgnxvf8l41UOR2';
          this.gv.uemail = firebase.auth().currentUser.email;
          firebase.database().ref('/userProfile/' + this.gv.uid + '/uvf').on('value', uvf => {
            this.gv.uvf = uvf.val() ? uvf.val() : false;
          })

          firebase.database().ref('/userProfile/' + this.gv.uid).once('value').then(data => {
            let user = data.val();
            if (!user || !user.nickname)
              this.router.navigate(['/register']);
            else {
              this.gv.setLoc(user.loc);
              if (user.lan)
                this.gv.setLan(user.lan);

              firebase.database().ref('/userProfile/' + this.gv.uid + '/sum').on('value', usersum => {
                if (usersum.val()) {
                  this.gv.uimg = usersum.val().img;
                  this.gv.unickname = usersum.val().nick;
                  this.gv.ulvl = usersum.val().lvl;
                  this.gv.getPrelevel().then(rst => {
                    if (rst && (rst < this.gv.ulvl))
                      this.showlevelup(this.gv.ulvl);
                    this.gv.setPrelevel(this.gv.ulvl);
                  })
                }
              })
              this.getlevel(user);
              firebase.database().ref('/userProfile/' + this.gv.uid + '/unread').on('value', unread => {
                if (unread.val()) {
                  this.gv.msgunread = unread.val().msg ? unread.val().msg : 0;
                  this.gv.alarmunread = unread.val().alarm ? unread.val().alarm : 0;
                  if (this.gv.cordova)
                    this.badge.set(this.gv.msgunread + this.gv.alarmunread);
                }
                else
                  if (this.gv.cordova)
                    this.badge.set(0);
              });
              this.gv.admin = user.admin ? true : false;
              this.gv.manager = user.manager ? user.manager : null;

              firebase.database().ref('/workinfo/' + this.gv.uid).once('value').then(
                work => {
                  if (work.val())
                    this.gv.hasworktool = true;
                  else
                    this.gv.hasworktool = false;
                })
              
                //console.log('코도바냐? '+this.gv.cordova)
              if (this.gv.cordova) {
                this.fcm.onTokenRefresh().subscribe(rst => {
                  if (this.gv.uid)
                    firebase.database().ref('/userProfile/' + this.gv.uid).update({ fcmtoken: rst });
                })
                this.fcm.getToken().then(rst => {
                  if (rst)
                    firebase.database().ref('/userProfile/' + this.gv.uid).update({ fcmtoken: rst });
                })
              }
              setTimeout(_ => {
                firebase.database().ref('/userProfile/' + this.gv.uid).update({ appver: this.gv.thisver });
              }, 5000)

              firebase.database().ref('/userData/' + this.gv.uid + '/state').once('value').then(val => {
                if (val.val()) {
                  if (val.val()['fcmreloadrequired'])
                    setTimeout(_ => {
                      console.log('..notification resetting..')
                      this.clearnoti();
                      setTimeout(_ => {
                        this.initnotiasdb();
                        firebase.database().ref('/userData/' + this.gv.uid + '/state').update({ fcmreloadrequired: null });
                      }, 1400);
                    }, 1400);
                  if (val.val()['cachecleanrequired']) {
                    console.log('..clear caching..')
                    firebase.database().ref('/userData/' + this.gv.uid + '/state').update({ cachecleanrequired: null });
                    this.gv.cleanstroage();
                  }
                }
              })

              //마지막로그인, 5초후 로그작성(회원 닉네임 가져오기 위함)
              //로그인 포인트 체크 및 지급
              setTimeout(_ => {
                this.setinitlog();
                this.chkloginpoint();
              }, 5000)

              firebase.database().ref('/userProfile/' + this.gv.uid + '/blockuser').on('value', block => {
                this.gv.blockusers = '';
                if (block.val())
                  Object.keys(block.val()).forEach(key => {
                    this.gv.blockusers = this.gv.blockusers ? this.gv.blockusers + '_' + block.val()[key].id : block.val()[key].id;
                  })
              })

              firebase.database().ref('/userData/' + this.gv.uid + '/favlist').on('value', fav => {
                this.gethomedata();
              })

            }
          })
        }
        else{ //로그인 하지않은 유저
          if (!this.gv.uid) {
            this.gv.restoreOnStorage('unauth_tid').then(tid => {
              if (tid) {
                this.gv.tid = tid;
                // console.log('tid = ' + this.gv.tid);
              } else {
                let t = new Date().getTime();
                let id = t.toString(36);
                this.gv.tid = id;
                // console.log('id = ' + id);
                this.gv.storeOnStorage('unauth_tid', id);
              }
            });
          }
          this.signinuser = false;

          if (this.gv.uid) {
            firebase.database().ref('/userProfile/' + this.gv.uid + '/level').off();
            firebase.database().ref('/userProfile/' + this.gv.uid + '/sum').off();
            firebase.database().ref('/userProfile/' + this.gv.uid + '/unread').off();
            firebase.database().ref('/userData/' + this.gv.uid + '/favlist').off();
            firebase.database().ref('/userProfile/' + this.gv.uid + '/uvf').off();
          }
          this.gv.uvf = null;
          this.gv.uid = null;
          this.gv.uimg = null;
          this.gv.manager = null;
          this.gv.admin = null;
          this.gv.hasworktool = null;
          this.gv.unickname = null;
          this.gv.utotal = null;
          this.gv.ulvl = null;
          this.gv.skipver = null;
          this.gv.ulvlcount = null;
          //this.gv.boardlist = [];
          this.gv.blockusers = null;
          this.gv.loadedfav = true;
          this.gv.loadingboard = false;
          this.setinitlog();
          this.gethomedata();
        }
      })
    }
  }

  init2() {
    this.gv.loadingboard = true;
    if (!this.inited) {
      this.inited = true;
      this.afAuth.authState.subscribe(auth => {
        if (auth) {
          console.log('loggedin')
          this.signinuser = true;
          //this.gv.uvf = false// firebase.auth().currentUser.emailVerified;
          this.gv.uid = firebase.auth().currentUser.uid;
          //this.gv.uid = 'Y1voasbr8gbym5hlFzOVeX7gtbC3';//l67Zm3Z7G1UndxBliLbclB8GNdn1'//PjAFwxK2HqezVLMgnxvf8l41UOR2';
          this.gv.uemail = firebase.auth().currentUser.email;

          firebase.database().ref('/userProfile/' + this.gv.uid + '/uvf').on('value', uvf => {
            this.gv.uvf = uvf.val() ? uvf.val() : false;
          })

          firebase.database().ref('/userProfile/' + this.gv.uid).once('value').then(data => {
            let user = data.val();
            if (!user || !user.nickname)
              this.router.navigate(['/register']);
            else {
              this.gv.setLoc(user.loc);
              if (user.lan)
                this.gv.setLan(user.lan);

              firebase.database().ref('/userProfile/' + this.gv.uid + '/sum').on('value', usersum => {
                if (usersum.val()) {
                  this.gv.uimg = usersum.val().img;
                  this.gv.unickname = usersum.val().nick;
                  this.gv.ulvl = usersum.val().lvl;
                  this.gv.getPrelevel().then(rst => {
                    if (rst && (rst < this.gv.ulvl))
                      this.showlevelup(this.gv.ulvl);
                    this.gv.setPrelevel(this.gv.ulvl);
                  })
                }
              })
              //this.gethomedata();
              this.getlevel(user);
              firebase.database().ref('/userProfile/' + this.gv.uid + '/unread').on('value', unread => {
                if (unread.val()) {
                  this.gv.msgunread = unread.val().msg ? unread.val().msg : 0;
                  this.gv.alarmunread = unread.val().alarm ? unread.val().alarm : 0;
                  if (this.gv.cordova)
                    this.badge.set(this.gv.msgunread + this.gv.alarmunread);
                }
                else
                  if (this.gv.cordova)
                    this.badge.set(0);
              });

              this.gv.admin = user.admin ? true : false;
              this.gv.manager = user.manager ? user.manager : null;

              firebase.database().ref('/workinfo/' + this.gv.uid).once('value').then(
                work => {
                  if (work.val())
                    this.gv.hasworktool = true;
                  else
                    this.gv.hasworktool = false;
                })

              //console.log('코도바냐? '+this.gv.cordova)
              if (this.gv.cordova) {
                this.fcm.onTokenRefresh().subscribe(rst => {
                  if (this.gv.uid)
                    firebase.database().ref('/userProfile/' + this.gv.uid).update({ fcmtoken: rst });
                })
                this.fcm.getToken().then(rst => {
                  if (rst)
                    firebase.database().ref('/userProfile/' + this.gv.uid).update({ fcmtoken: rst });
                })
              }
              setTimeout(_ => {
                firebase.database().ref('/userProfile/' + this.gv.uid).update({ appver: this.gv.thisver });
              }, 5000)

              firebase.database().ref('/userData/' + this.gv.uid + '/state').once('value').then(val => {
                if (val.val()) {
                  if (val.val()['fcmreloadrequired'])
                    setTimeout(_ => {
                      console.log('..notification resetting..')
                      this.clearnoti();
                      setTimeout(_ => {
                        this.initnotiasdb();
                        firebase.database().ref('/userData/' + this.gv.uid + '/state').update({ fcmreloadrequired: null });
                      }, 1400);
                    }, 1400);
                  if (val.val()['cachecleanrequired']) {
                    console.log('..clear caching..')
                    firebase.database().ref('/userData/' + this.gv.uid + '/state').update({ cachecleanrequired: null });
                    this.gv.cleanstroage();
                  }
                }
              })

              //마지막로그인, 5초후 로그작성(회원 닉네임 가져오기 위함)
              //로그인 포인트 체크 및 지급
              setTimeout(_ => {
                this.setinitlog();
                this.chkloginpoint();
              }, 5000)

              firebase.database().ref('/userProfile/' + this.gv.uid + '/blockuser').on('value', block => {
                this.gv.blockusers = '';
                if (block.val())
                  Object.keys(block.val()).forEach(key => {
                    this.gv.blockusers = this.gv.blockusers ? this.gv.blockusers + '_' + block.val()[key].id : block.val()[key].id;
                  })
              })
            }
          }).then(_ => {
            //if(this.router.url.includes('signin'))
            //   this.router.navigate([''])
          })
        }
        else {
          if (!this.gv.uid) {
            this.gv.restoreOnStorage('unauth_tid').then(tid => {
              if (tid) {
                this.gv.tid = tid;
                // console.log('tid = ' + this.gv.tid);
              } else {
                let t = new Date().getTime();
                let id = t.toString(36);
                this.gv.tid = id;
                // console.log('id = ' + id);
                this.gv.storeOnStorage('unauth_tid', id);
              }
            });
          }
          this.signinuser = false;

          if (this.gv.uid) {
            firebase.database().ref('/userProfile/' + this.gv.uid + '/level').off();
            firebase.database().ref('/userProfile/' + this.gv.uid + '/sum').off();
            firebase.database().ref('/userProfile/' + this.gv.uid + '/unread').off();
            firebase.database().ref('/userData/' + this.gv.uid + '/favlist').off();
            firebase.database().ref('/userProfile/' + this.gv.uid + '/uvf').off();
          }
          this.gv.uvf = null;
          this.gv.uid = null;
          this.gv.uimg = null;
          this.gv.manager = null;
          this.gv.admin = null;
          this.gv.hasworktool = null;
          this.gv.unickname = null;
          this.gv.utotal = null;
          this.gv.ulvl = null;
          this.gv.skipver = null;
          this.gv.ulvlcount = null;
          //this.gv.boardlist = [];
          this.gv.blockusers = null;
          this.gv.loadedfav = true;
          this.gv.loadingboard = false;

          //fcm 토큰 리프레시
          /*
          if(this.gv.cordova){
            this.fcm.getToken().then(rst=>{
              console.log('등록된 토픽 : ' + rst)
              var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://iid.googleapis.com/iid/info/' + rst + '?details=true';
              new Promise((resolve, reject)=>{
              let headers = new Headers();
                  headers.append('Content-Type', 'application/json');
                  headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');//'key=AIzaSyBt4016A8UYis-WbUWwXEQQseVJIHNs3p4');
                 
                  this.http.get(apiUrl, {headers: headers})
                      .subscribe(res => {
                      console.log(res);
                      if(res.rel && res.rel.topics)
                      {
                        let data = res.rel.topics;
                        Object.keys(data).forEach(item=>{
                          console.log(item + ': ' + data[item].addDate + ' 추가됨')
                        })
                      }
                      resolve(res);
                      }, (err) => {
                      console.log('정보확인 안됨')
                      console.log(err);
                      reject(err);
                      });
                  })
            })
          }*/


          //this.gethomedata();
          this.setinitlog();
          //this.gv.cleanstroage();
          //this.router.navigate(['signin']);
        }
      });
    }
  }

  gethomedata() {
    // this.gv.recentlist = [];
    // this.gv.recentlistpage = 0;
    // firebase.firestore().collection('post')
    //   //.where('location', '==', 'Cairns')//this.gv.loc)
    //   .orderBy('createdDate')
    //   .limitToLast(30)
    //   .get().then(rst => {
    //     rst.docs.forEach(d => {
    //       firebase.database().ref('/list/' + d.id + '/read').once('value').then(nn => {
    //                   let rr = nn.val() ? nn.val() : 0;
    //                 this.gv.recentlist.unshift(
    //         {
    //           $key: d.id, rcreatedDate: d.data().createdDate, data: d.data(), read:rr,
    //           haveread: this.gv.getrecent().filter(recent => { if (recent.itemid == d.id) return true; else return false; }).length > 0
    //         })
    //       })
          
    //     })
    //   this.gv.recentlistpage = Math.ceil(this.gv.recentlist.length / 6)
    // })

    if (this.gv.uid) {
      firebase.database().ref('/userData/' + this.gv.uid + '/favlist').once('value').then(fav => {
        if (!this.gv.norefresh) {
          let temp = [];
          let templist = [];
          let boardtemp = [];
          let count = 0;
          this.gv.ufavlist = [];
          
          if (fav.val()) {
            let favlist = [];
            fav.forEach(favitem => { if(favitem.val()&&favitem.val().chid) {temp.push(favitem.val().chid); favlist.push(favitem.val())} })
            count = Math.ceil(temp.length / 10);
            for (let i = 0; i < count; i++) {
              firebase.firestore().collection('channel')
                .where(firebase.firestore.FieldPath.documentId(), 'in', temp.slice((i * 10), (i + 1) * 10))
                .get().then(rst => {
                  rst.docs.forEach(d => {
                    let favdata = d.data();
                    let _fav = favlist.filter(f => { return f.chid == d.id }) ? favlist.filter(f => { return f.chid == d.id })[0] : [];
                    _fav.title = favdata.name;
                    _fav.img = favdata.imagethumb && favdata.imagethumb[0] && favdata.imagethumb[0].url ? favdata.imagethumb[0].url : null;
                    _fav.isMine = favdata.userId == this.gv.uid;
                    _fav.lastposting = favdata.recent ? favdata.recent : ''
                    this.gv.ufavlist.push(_fav);
                  })
                  this.gv.getHotLive(this.gv.ufavlist);
                })
                
              firebase.firestore().collection('post')
                .where('categoryId', 'in', temp.slice((i * 10), (i + 1) * 10))
                .orderBy('createdDate')
                .limitToLast(30)
                .get().then(rst => {
                  this.gv.boardlist = [];
                  this.gv.boardlistpage = 0;
                  let index = rst.docs.length
                  rst.docs.forEach(d => {
                    firebase.database().ref('/list/' + d.id + '/read').once('value').then(nn => {
                      d['read'] = nn.val() ? nn.val() : 0;
                      this.gv.boardlist.unshift(
                      {
                        $key: d.id, rcreatedDate: d.data().createdDate, data: d.data(),
                        haveread: this.gv.getrecent().filter(recent => { if (recent.itemid == d.id) return true; else return false; }).length > 0
                      })
                      if(i==(count-1) && --index == 0){
                    this.ngzone.run(_=>{
                      this.gv.boardlist = this.orderby.transform(this.gv.boardlist, 'data.createdDate', 'desc').splice(0,30)
                      this.gv.storeOnStorage('listdata', this.gv.boardlist);
                      this.gv.storeOnStorage('ufavlist', this.gv.ufavlist);
                      this.gv.chkchannelvisit();
                      this.gv.boardlistpage = Math.ceil(this.gv.boardlist.length / 6)
                      this.gv.loadingboard = false;
                    })

                    // this.gv.storeOnStorage('ufavlist', this.gv.ufavlist);
                    // this.gv.loadedfav = true;
                    // this.gv.chkchannelvisit();
                  }
                    })
                  })
                })
            }



            // let favindex = 0;
            // console.log(Object.keys(fav.val()).length);
            // Object.keys(fav.val()).forEach(fitem => {
            //   let f = fav.val()[fitem];
            //   f.favindex = favindex;
            //   favindex++;
            //   firebase.database().ref('/category/' + f.chid).once('value').then(chval => {
            //     let favdata = chval.val();
            //     if (!favdata)
            //       temp.push({ chid: f.chid, alarm: false, title: '삭제된채널', customtitle: f.customtitle })
            //     else {
            //       temp.push({
            //         chid: f.chid,
            //         alarm: f.alarm,
            //         favindex: f.favindex,
            //         loc: f.loc,
            //         isChecked: f.isChecked,
            //         customtitle: f.customtitle,
            //         title: favdata.name,
            //         // img: favdata.imagethumb[0].url,
            //         img: favdata.imagethumb && favdata.imagethumb[0] && favdata.imagethumb[0].url ? favdata.imagethumb[0].url : null,
            //         isMine: favdata.userId == this.gv.uid,
            //         lastposting: favdata.recent ? favdata.recent : ''
            //       });
            //       if (favdata.recent)
            //         Object.keys(favdata.recent).forEach(rkey => {
            //           templist.push({ $key: favdata.recent[rkey].itemid, rcreatedDate: favdata.recent[rkey].createdDate })
            //         })
            //     }

            //     count++;
            //     if (count == Object.keys(fav.val()).length) {
            //       boardtemp = this.orderby.transform(templist, 'rcreatedDate', 'desc').slice(0, 36);
            //       let tempcount = 0;
            //       this.gv.loadingboard = true;
            //       let boardlisttemp = [];
            //       let recentlist = this.gv.getrecent();
            //       let _item: any;

            //       boardtemp.map(data => {
            //         _item = {};
            //         firebase.database().ref('/list/' + data.$key).once('value').then(rst => {
            //           tempcount++;
            //           if (rst.val()) {
            //             // boardlisttemp.push({$key:data.$key, rcreatedDate:data.rcreatedDate, data:rst.val()})
            //             _item = { $key: data.$key, rcreatedDate: data.rcreatedDate, data: rst.val(), haveread: false };
            //             recentlist.forEach(recent => {
            //               if (recent.itemid == rst.key) {
            //                 _item.haveread = true;
            //               }
            //             });
            //             boardlisttemp.push(_item);
            //           }
            //         }).then(_ => {
            //           if (tempcount == boardtemp.length) {
            //             this.gv.boardlist = this.orderby.transform(boardlisttemp, 'rcreatedDate', 'desc').slice(0, 30)
            //             this.gv.storeOnStorage('listdata', this.gv.boardlist);
            //             this.gv.boardlistpage = Math.ceil(this.gv.boardlist.length / 6)
            //             this.gv.loadingboard = false;
            //             this.gv.ufavlist = this.orderby.transform(temp, 'favindex');
            //             this.gv.storeOnStorage('ufavlist', this.gv.ufavlist);
            //             this.gv.loadedfav = true;
            //             this.gv.chkchannelvisit();

            //             this.gv.getHotLive(this.gv.ufavlist);
            //           }
            //         })
            //       });
            //     }
            //   })
            // });
          } else {
            // console.log(this.gv.boardlist);
            this.gv.loadingboard = false;
            this.gv.loadedfav = true;
            this.gv.boardlist = [];
            this.gv.ufavlist = [];
            this.gv.boardlistpage = 0;
          }
          //this.gv.storeOnStorage('ufavlist',temp);
          /*
          if(this.gv.chklastvisit.length)
            this.gv.chkchannelvisit();
          else
            setTimeout(_=>{this.gv.chkchannelvisit();},1000)*/
        }
        else
          this.gv.norefresh = false;
      })
    }
    else {
      console.log('not login')
      // loc storage 있으면 가져오고 없으면 null
      this.gv.getlocinlocal().then(rst => {
        if (rst) {
          this.gv.loc = rst;
          // 지역값이 있을때는 즐겨찾기 기본값도 있다.
          firebase.database().ref('/app/setting/aus/' + this.gv.loc + '/' + this.gv.lan + '/fav').once('value').then(favs => {
            let fav = favs.val();
            this.gv.ufavlist = [];
            if (fav) {
              //console.log('ad fav')
              
              let temp = [];
              let templist = [];
              let boardtemp = [];
              let count = 0;
              let favindex = 0;

              fav.forEach(favitem => { temp.push(favitem.chid); });
              count = Math.ceil(temp.length / 10);
              for (let i = 0; i < count; i++) {
                firebase.firestore().collection('channel')
                  .where(firebase.firestore.FieldPath.documentId(), 'in', temp.slice((i * 10), (i + 1) * 10))
                  .get().then(rst => {
                    rst.docs.forEach(d => {
                      let favdata = d.data();
                      let _fav = fav.filter(f => { return f.chid == d.id }) ? fav.filter(f => { return f.chid == d.id })[0] : [];
                      _fav.title = favdata.name;
                      _fav.img = favdata.imagethumb && favdata.imagethumb[0] && favdata.imagethumb[0].url ? favdata.imagethumb[0].url : null;
                      _fav.isMine = favdata.userId == this.gv.uid;
                      _fav.lastposting = favdata.recent ? favdata.recent : ''
                      this.gv.ufavlist.push(_fav);
                    })
                    this.gv.getHotLive(this.gv.ufavlist);
                  })

                firebase.firestore().collection('post')
                  .where('categoryId', 'in', temp.slice((i * 10), (i + 1) * 10))
                  .orderBy('createdDate')
                  .limitToLast(30)
                  .get().then(rst => {

                    this.gv.boardlist = [];
                    this.gv.boardlistpage = 0;
                    let index = rst.docs.length
                    rst.docs.forEach(d => {
                      firebase.database().ref('/list/' + d.id + '/read').once('value').then(nn => {
                      d['read'] = nn.val() ? nn.val() : 0;})
                      this.gv.boardlist.unshift(
                        {
                          $key: d.id, rcreatedDate: d.data().createdDate, data: d.data(),
                          haveread: this.gv.getrecent().filter(recent => { if (recent.itemid == d.id) return true; else return false; }).length > 0
                        })
                        if(i==(count-1) && --index == 0){
                    this.ngzone.run(_=>{
                      this.gv.boardlist = this.orderby.transform(this.gv.boardlist, 'data.createdDate', 'desc').splice(0,30)
                      this.gv.storeOnStorage('listdata', this.gv.boardlist);
                      this.gv.storeOnStorage('ufavlist', this.gv.ufavlist);
                      this.gv.chkchannelvisit();
                      this.gv.boardlistpage = Math.ceil(this.gv.boardlist.length / 6)
                      this.gv.loadingboard = false;
                    })}
                    })
                  })
              }

              // Object.keys(fav).forEach(fitem => {
              //   let f = fav[fitem];
              //   f.favindex = favindex;
              //   favindex++;
              //   firebase.database().ref('/category/' + f.chid).once('value').then(chval => {
              //     let favdata = chval.val();
              //     if (!favdata)
              //       temp.push({ chid: f.chid, alarm: false, title: '삭제된채널', customtitle: f.customtitle })
              //     else {
              //       temp.push({
              //         chid: f.chid,
              //         alarm: f.alarm,
              //         favindex: f.favindex,
              //         loc: f.loc,
              //         isChecked: f.isChecked,
              //         customtitle: f.customtitle,
              //         title: favdata.name,
              //         img: favdata.imagethumb ? favdata.imagethumb[0].url : null,
              //         isMine: favdata.userId == this.gv.uid,
              //         lastposting: favdata.recent ? favdata.recent : ''
              //       });
              //       if (favdata.recent)
              //         Object.keys(favdata.recent).forEach(rkey => {
              //           templist.push({ $key: favdata.recent[rkey].itemid, rcreatedDate: favdata.recent[rkey].createdDate })
              //         })
              //     }

              //     count++;
              //     if (count == Object.keys(fav).length) {
              //       boardtemp = this.orderby.transform(templist, 'rcreatedDate', 'desc').slice(0, 36);
              //       let tempcount = 0;
              //       this.gv.loadingboard = true;
              //       let boardlisttemp = [];

              //       boardtemp.map(data => {
              //         firebase.database().ref('/list/' + data.$key).once('value').then(rst => {
              //           tempcount++;
              //           if (rst.val())
              //             boardlisttemp.push({ $key: data.$key, rcreatedDate: data.rcreatedDate, data: rst.val() })
              //         }).then(_ => {
              //           if (tempcount == boardtemp.length) {
              //             this.gv.boardlist = this.orderby.transform(boardlisttemp, 'rcreatedDate', 'desc').slice(0, 30)
              //             this.gv.boardlistpage = Math.ceil(this.gv.boardlist.length / 6)
              //             this.gv.loadingboard = false;

              //             this.gv.ufavlist = this.orderby.transform(temp, 'favindex', 'asc');
              //             this.gv.storeOnStorage('listdata', this.gv.boardlist);
              //             this.gv.storeOnStorage('ufavlist', this.gv.ufavlist);
              //             this.gv.loadedfav = true;
              //             this.gv.chkchannelvisit();

              //             this.gv.getHotLive(this.gv.ufavlist);
              //           }
              //         })
              //       });
              //     }
              //   })
              //   /*
              //   temp.push({
              //     chid: f.chid,
              //     alarm: f.alarm,
              //     loc: f.loc,
              //     isChecked:f.isChecked,
              //     customtitle: f.customtitle,
              //     title: firebase.database().ref('/category/' + f.chid + '/name').once('value').then(val => {
              //       return val.val()
              //     }),
              //     img: firebase.database().ref('/category/' + f.chid + '/imagethumb/0/url').once('value').then(val => {
              //       return val.val()
              //     }),
              //     isMine: firebase.database().ref('/category/' + f.chid + '/userId').once('value').then(val => {
              //       return val.val() == this.gv.uid
              //     }),
              //     lastposting: firebase.database().ref('/category/' + f.chid + '/recent').once('value')
              //   });
              //   firebase.database().ref('/category/' + f.chid + '/recent').once('value').then(val => {
              //     val.forEach(v => {
              //       let rec = v.val();
              //       templist.push({
              //         $key: rec.itemid,
              //         rcreatedDate: rec.createdDate
              //       })
              //     })
              //   }).then(_ => {
              //     count++;
              //     if (count == Object.keys(fav).length) {
              //       boardtemp = this.orderby.transform(templist, 'rcreatedDate', 'desc').slice(0, 36);
              //       let tempcount = 0;
              //       let boardlisttemp = [];
              //       boardtemp.map(data => {
              //         firebase.database().ref('/list/' + data.$key).once('value').then(rst => {
              //           tempcount++;
              //           if(rst.val())
              //           boardlisttemp.push({
              //             $key: data.$key,
              //             rcreatedDate: data.rcreatedDate,
              //             data: rst.val()
              //           })
              //         }).then(_ => {
              //           if (tempcount == boardtemp.length) {
              //             this.gv.boardlist = this.orderby.transform(boardlisttemp, 'rcreatedDate', 'desc').slice(0,30)
              //             this.gv.storeOnStorage('listdata',this.gv.boardlist);
              //             this.gv.boardlistpage = Math.ceil(this.gv.boardlist.length / 6);
              //             this.gv.loadingboard = false;
              //           }
              //         })
              //       });
              //     }
              //   })*/
              // })
            }
            else {
              this.gv.boardlist = [];
              this.gv.boardlistpage = 0;
              this.gv.ufavlist = null;
            }
          })
        }
        else {
          this.gv.loc = null;
          // 지역값이 없을때는 즐겨찾기 기본값이 없다.
          this.gv.loadedfav = true;
          this.gv.ufavlist = null;
          this.gv.boardlist = [];
          this.gv.boardlistpage = 0;
    
        }
      })
    }
  }



  setinitlog() {
    $.getJSON('https://ipapi.co/json/', data => {
      firebase.database().ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister')).update({
        locinfo: data['region'] + ' ' + data['country_name'], regioncode: data['region_code']
      })
    });
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
    firebase.database().ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister')).update({ nickname: this.gv.uid ? this.gv.unickname : '비회원(종합)', pltinfo: this.gv.pltinfo, appver: this.gv.thisver, loc: this.gv.loc ? this.gv.loc : 'First visit', lastlogin: firebase.database.ServerValue.TIMESTAMP });
    firebase.database().ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/visit').transaction(visit => {
      return (visit || 0) + 1;
    })

    firebase.database().ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event').once('value').then(event => {
      if (event.numChildren() > 15) {
        let count = event.numChildren() - 15;
        event.forEach(evt => {
          if (count > 0) {
            firebase.database().ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + evt.key).remove();
            count--;
          }
        })
      }
    })
  }

  async getlevel(user) {
    var cmttotal = 0, posttotal = 0, invitetotal = 0, up = 0, down = 0;
    if (user) {
      posttotal = user['write'] ? Object.keys(user['write']).length : 0;
      invitetotal = user['invite'] ? Object.keys(user['invite']).length : 0;
      cmttotal = user['comment'] ? Object.keys(user['comment']).length : 0;
      up = user['like'] ? Object.keys(user['like']).length : 0;
      down = user['dislike'] ? Object.keys(user['dislike']).length : 0;
    }
    firebase.database().ref('/level').once('value').then(lvls => {
      lvls.forEach(lv => {
        this.gv.leveldata.push(lv.val())
      });
    }).then(_ => {
      // 카운팅 설정
      firebase.database().ref('/userProfile/' + this.gv.uid + '/level').update({
        currentlevel: this.chklevelchange(posttotal, cmttotal, up, down, invitetotal),
        post: posttotal,
        comment: cmttotal,
        down: down,
        up: up,
        invite: invitetotal
      }).then(_ => { //첫 업데이트후 watching
        firebase.database().ref('/userProfile/' + this.gv.uid + '/level').on('value', level => {
          if (level.val()) {
            this.gv.ulvlcount = level.val();

            let postcount = this.gv.ulvlcount.post ? this.gv.ulvlcount.post : 0;
            let replycount = this.gv.ulvlcount.comment ? this.gv.ulvlcount.comment : 0;
            let likedcount = this.gv.ulvlcount.up ? this.gv.ulvlcount.up : 0;
            let dislikedcount = this.gv.ulvlcount.down ? this.gv.ulvlcount.down : 0;
            let invitecnt = this.gv.ulvlcount.invite ? this.gv.ulvlcount.invite : 0;
            let checkedlevel = this.chklevelchange(postcount, replycount, likedcount, dislikedcount, invitecnt);
            if (this.gv.ulvlcount.currentlevel != checkedlevel)
              firebase.database().ref('/userProfile/' + this.gv.uid + '/level').update({ currentlevel: checkedlevel })
          }
        })
        return true;
      })
    })
  }

  chklevelchange(posttotal, cmttotal, up, down, invitetotal) {
    var currentlevel;
    var postlvl = 0, cmtlvl = 0, likelvl = 0, invitelvl = 0;
    this.gv.leveldata.forEach(level => {
      if (!postlvl && level.post > posttotal)
        postlvl = level.lid;
      if (!cmtlvl && level.comment > cmttotal)
        cmtlvl = level.lid;
      if (!likelvl && level.updown > (up + down))
        likelvl = level.lid;
      if (!invitelvl && level.invite > invitetotal)
        invitelvl = level.lid;
      if (level.lid == 20) {
        if (level.post <= posttotal)
          postlvl = 21;
        if (level.comment <= cmttotal)
          cmtlvl = 21;
        if (level.updown <= (up + down))
          likelvl = 21;
        if (level.invite <= invitetotal)
          invitelvl = 21;
      }
    })
    currentlevel = Math.min(postlvl, cmtlvl, likelvl, invitelvl);
    return currentlevel - 1;
  }

  async showlevelup(level) {
    //console.log('레벨업!');
    firebase.database().ref('/point/' + this.gv.uid).orderByChild('pcode')
      .equalTo('levelup').once('value').then(rst => {
        var received = false;
        if (rst.val()) {
          rst.forEach(r => {
            //console.log(r.key);
            //console.log(r.val());
            if (r.val().level == level)
              received = true;
          })
        }
        if (!received) {
          firebase.database().ref('/point/' + this.gv.uid).push(
            {
              pcode: 'levelup', point: level * 50, level: level,
              createdDate: firebase.database.ServerValue.TIMESTAMP
            }).then(_ => {
              this.presentToast(level + ' 레벨 등업 포인트가 지급되었습니다.').then(_ => {
                this.pointfunc.showpointani(5);
              })
            })
          this.modalCtrl.create({
            component: LevelupmodalComponent,
            componentProps: <null>{ level: level, img: this.gv.uimg, nick: this.gv.unickname },
            cssClass: 'eventPopup'
          }).then(_ => _.present())
        }
      })
  }

  chkloginpoint() {
    if (this.gv.uid) {
      var received;
      let today = new Date();
      firebase.database().ref('/point/' + this.gv.uid).orderByChild('pcode')
        .equalTo('daily').limitToLast(5).once('value').then(rst => {
          //console.log(today.toLocaleDateString('ko-KR'))
          if (rst.val()) {
            received = false;
            rst.forEach(r => {
              if (r.val().day == today.toLocaleDateString('ko-KR'))
                received = true;
            })
          }
          else
            received = false;

          if (!received) {
            firebase.database().ref('/point/' + this.gv.uid).push(
              {
                pcode: 'daily', point: 5, day: today.toLocaleDateString('ko-KR'),
                createdDate: firebase.database.ServerValue.TIMESTAMP
              }).then(_ => {
                this.presentToast('출석 포인트가 지급되었습니다.').then(_ => {
                  this.pointfunc.showpointani(5);
                })
              })
          }
        })
    }
  }

  setnoti(type, topic) {
    if (this.gv.cordova) {
      if (type == 'sub')
        this.fcm.subscribeToTopic(topic);
      else if (type == 'unsub')
        this.fcm.unsubscribeFromTopic(topic);
    }
  }

  initnotiasdb() {
    if (this.gv.cordova && this.gv.uid) {
      firebase.database().ref('/userProfile/' + this.gv.uid + '/alarm').once('value').then(rst => {
        rst.forEach(fav => {
          if (fav.key == 'personal') {
            if (fav.val())
              this.setnoti('sub', this.gv.uid);
            //else
            //  this.setnoti('unsub', this.gv.uid);
          }

          if (fav.key == 'fav') {
            if (fav.val()) // 모든 즐겨찾기 새글알림 받기
            {
              firebase.database().ref('/userData/' + this.gv.uid + '/favlist').once('value').then(favitems => {
                favitems.forEach(favitem => {
                  this.setnoti('sub', favitem.val().chid)
                })
              })
            }
            else  // 설정된 즐겨찾기 새글알림만 받기
            {
              firebase.database().ref('/userData/' + this.gv.uid + '/favlist').once('value').then(favitems => {
                favitems.forEach(favitem => {
                  if (favitem.val().alarm)
                    this.setnoti('sub', favitem.val().chid)
                })
              })
            }
          }
        })
      })
    }
  }

  clearnoti() {
    if (this.gv.cordova) {
      this.fcm.getToken().then(rst => {
        var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://iid.googleapis.com/iid/info/' + rst + '?details=true';
        new Promise((resolve, reject) => {
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');//'key=AIzaSyBt4016A8UYis-WbUWwXEQQseVJIHNs3p4');

          this.http.get(apiUrl, { headers: headers })
            .subscribe(res => {
              //console.log('클리어노티피케이션~~~~~~')
              let result = JSON.parse(res['_body']);

              if (result.rel && result.rel.topics) {
                let data = result.rel.topics;
                Object.keys(data).forEach(item => {
                  if (item != 'ios' && item != 'android' && item != 'all') {
                    console.log(item + ': ' + data[item].addDate + ' unsubscribed')
                    this.setnoti("unsub", item);
                  }
                })
              }
              resolve(res);
            }, (err) => {
              console.log(err);
              reject(err);
            });
        })
      })
    }
  }

  isAuthenticated() {
    return this.signinuser;
  }

  loginUser(newEmail: string, newPassword: string): Promise<any> {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL); //영구로그인
    return firebase.auth().signInWithEmailAndPassword(newEmail, newPassword)
  }

  resetPassword(email: string): Promise<any> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<any> {
    this.gv.clearOnStorage('listdata');
    this.gv.clearOnStorage('ufavlist');
    return firebase.auth().signOut();
  }

  sendemailverification(user) {
    user.sendEmailVerification().then(function () {
      // Email sent.
      return true;
    }).catch(function (error) {
      // An error happened.
      console.log('failed to send a verification email')
      return false;
    });
  }

  registerUser(email: string, password: string, nickname: string, recommended?: string): Promise<any> {
    return firebase.auth().createUserWithEmailAndPassword(email, password).then((newUser) => {
      firebase.database().ref('/userProfile').child(newUser.user.uid).set({
        email: email,
        nickname: nickname,
        nicknamelower: nickname.toLowerCase().replace(/ /g, ''),
        recommended: recommended ? recommended : null,
        nicklimit: 1,
        createdDate: firebase.database.ServerValue.TIMESTAMP,
        level: { currentlevel: 1 },
        alarm: { personal: true, fav: true }
      });
      this.sendemailverification(newUser);
      let today = new Date();
      let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
      firebase.database().ref('/systemlog/' + (newUser.user.uid ? newUser.user.uid : 'unregister') + '/event/' + todaystr + '/regchacc').transaction(view => {
        return (view || 0) + 1;
      })
      firebase.database().ref('/point/' + newUser.user.uid).push({
        pcode: 'newreg', point: 50,
        createdDate: firebase.database.ServerValue.TIMESTAMP
      });
      firebase.database().ref('/userData/' + newUser.user.uid + '/state/receivedprepoint').set(true);
    });
  }

  async loading(rst) {
    const loadingPopup = await this.loadCtrl.create({
      spinner: 'crescent',
      duration: 2000,
      message: ''
    });

    if (rst == 'start')
      loadingPopup.present();
    else
      loadingPopup.dismiss();
  }
  fblogin() { }
  // {
  //   var existingEmail = null;
  //   var pendingCred = null;
  //   var provider = new firebase.auth.FacebookAuthProvider();
  //   if (this.gv.cordova) {
  //     this.facebook.login(['public_profile', 'email'])
  //       .then((rst: FacebookLoginResponse) => {
  //         this.loading('start');
  //         const facebookCredential = firebase.auth.FacebookAuthProvider
  //           .credential(rst.authResponse.accessToken);
  //         firebase.auth().signInWithCredential(facebookCredential)
  //           .then(success => {
  //             //인증완료
  //             this.loading('end');
  //             setTimeout(_ => { this.initnotiasdb(); }, 1200);
  //           })
  //           .catch(error => {
  //             this.loading('end');
  //             if (error.code == 'auth/account-exists-with-different-credential') {
  //               existingEmail = error.email;
  //               pendingCred = error.credential;
  //               // Lookup existing account’s provider ID.
  //               return firebase.auth().fetchProvidersForEmail(error.email)
  //                 .then(providers => {
  //                   if (providers.indexOf(firebase.auth.EmailAuthProvider.PROVIDER_ID) != -1) {
  //                     var password = window.prompt('기존에 사용하시던 계정(' + existingEmail + ')이 있으시군요. 본인 확인을 위해 기존계정의 비밀번호를 입력해주시겠어요? (최초1회)');
  //                     return firebase.auth().signInWithEmailAndPassword(existingEmail, password)
  //                       .then(user => {
  //                         setTimeout(_ => { this.initnotiasdb(); }, 1200);
  //                         return firebase.auth().currentUser.linkWithCredential(pendingCred);
  //                       }).catch(a => console.log(a));
  //                   } else if (providers.indexOf(firebase.auth.GoogleAuthProvider.PROVIDER_ID) != -1) {

  //                     return this.googlePlus.login({ 'webClientId': '442347281921-khq1u0i0vmpnbsqvab656brae5liavom.apps.googleusercontent.com' })
  //                       .then(result => {
  //                         const googleCredential = firebase.auth.GoogleAuthProvider
  //                           .credential(result.idToken);
  //                         firebase.auth().signInWithCredential(googleCredential)
  //                           .then(success => {
  //                             setTimeout(_ => { this.initnotiasdb(); }, 1200);
  //                             return firebase.auth().currentUser.linkWithCredential(pendingCred)
  //                           }).catch(err => { console.log('link errror') })
  //                       })
  //                   } else {
  //                     console.log('failed')
  //                   }
  //                 })
  //             }
  //             throw error;

  //           }
  //           )
  //       })

  //   }
  //   else
  //     firebase.auth().signInWithPopup(new auth.FacebookAuthProvider()).then(rst => {
  //       //console.log('성공')
  //       //페이스북 팝업 로그인 성공
  //       setTimeout(_ => { this.initnotiasdb(); }, 1200);
  //     }
  //     ).catch(error => {
  //       //console.log('실패')
  //       //console.log(JSON.stringify(error))  
  //       if (error.code == 'auth/account-exists-with-different-credential') {
  //         existingEmail = error.email;
  //         pendingCred = error.credential;
  //         // Lookup existing account’s provider ID.
  //         return firebase.auth().fetchProvidersForEmail(error.email)
  //           .then(providers => {
  //             if (providers.indexOf(firebase.auth.EmailAuthProvider.PROVIDER_ID) != -1) {
  //               var password = window.prompt('기존에 사용하시던 계정(' + existingEmail + ')이 있으시군요. 본인 확인을 위해 기존계정의 비밀번호를 입력해주시겠어요? (최초1회)');
  //               return firebase.auth().signInWithEmailAndPassword(existingEmail, password)
  //                 .then(rst => {
  //                   setTimeout(_ => { this.initnotiasdb(); }, 1200);
  //                   return rst.linkWithCredential(pendingCred);
  //                 });
  //             } else if (providers.indexOf(firebase.auth.GoogleAuthProvider.PROVIDER_ID) != -1) {
  //               var googProvider = new firebase.auth.GoogleAuthProvider();
  //               provider.setCustomParameters({ 'login_hint': existingEmail });
  //               return firebase.auth().signInWithPopup(googProvider).then(rst => {
  //                 setTimeout(_ => { this.initnotiasdb(); }, 1200);
  //                 return rst.user.linkWithCredential(pendingCred);
  //               });
  //             } else {
  //               console.log('other cred')
  //             }
  //           })
  //       }
  //       console.log(error);
  //       throw error;
  //     });
  // }

  kkologin() {

  }
  unlink() {
    firebase.auth().fetchSignInMethodsForEmail('hoxer80@gmail.com')
      .then(function (providers) {
        var existingEmail = 'hoxer80@gmail.com';
        var pendingCred = null;
        var password = window.prompt('해제계정 비밀번호를 입력해주세요 - ' + existingEmail);
        firebase.auth().signInWithEmailAndPassword(existingEmail, password)
          .then(user => {
            console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~해제계정~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
            console.log(user);
            user.user.unlink('google.com');
            user.user.unlink('facebook.com');

          });
      })
  }
  gglogin() {
    var existingEmail = null;
    var pendingCred = null;
    var provider = new firebase.auth.GoogleAuthProvider();
    if (this.gv.cordova) {
      /*provider.setCustomParameters({
        prompt: 'select_account'
      });*/
      this.googlePlus.logout(); // 멀티계정일 경우를 생각해서 기존에 채널에 로그인된 계정 로그아웃 후 로그인 시도(이렇게 해야 계정선택화면 나옴)
      //안드로이드인 경우 클라이언트 아이디 변경해줌
      //console.log(this.gv.android);
      this.googlePlus.login(
        { 'webClientId': '442347281921-khq1u0i0vmpnbsqvab656brae5liavom.apps.googleusercontent.com' })
        .then((rst) => {
          //로딩바 시작]
          this.loading('start');

          const googleCredential = firebase.auth.GoogleAuthProvider
            .credential(rst.idToken);
          firebase.auth().signInWithCredential(googleCredential)
            .then(success => {
              //로딩바 닫김
              this.loading('end');
              setTimeout(_ => { this.initnotiasdb(); }, 1200);
            }).catch(e => { console.log(e); this.loading('end'); });

        }).catch(error => {
          this.loading('end');
          if (error.code == 'auth/account-exists-with-different-credential') {
            existingEmail = error.email;
            pendingCred = error.accessToken;
            // Lookup existing account’s provider ID.
            return firebase.auth().fetchSignInMethodsForEmail(error.email)
              .then(providers => {
                if (providers.indexOf(firebase.auth.EmailAuthProvider.PROVIDER_ID) != -1) {
                  // 기존가입계정비번 입력창 (알랏폼으로)
                  var password = window.prompt('기존에 사용하시던 계정(' + existingEmail + ')이 있으시군요. 본인 확인을 위해 기존계정의 비밀번호를 입력해주시겠어요? (최초1회)');
                  return firebase.auth().signInWithEmailAndPassword(existingEmail, password)
                    .then(user => {
                      setTimeout(_ => { this.initnotiasdb(); }, 1200);
                      return firebase.auth().currentUser.linkWithCredential(pendingCred);
                    });
                } else if (providers.indexOf(firebase.auth.FacebookAuthProvider.PROVIDER_ID) != -1) {
                  // this.facebook.login(['public_profile', 'email'])
                  //   .then((rst: FacebookLoginResponse) => {
                  //     const facebookCredential = firebase.auth.FacebookAuthProvider
                  //       .credential(rst.authResponse.accessToken);
                  //     firebase.auth().signInWithCredential(facebookCredential)
                  //       .then(success => {
                  //         setTimeout(_ => { this.initnotiasdb(); }, 1200);
                  //         return firebase.auth().currentUser.linkWithCredential(pendingCred);
                  //       }).catch(e => console.log('link error'))
                  //   })
                } else {
                  console.log('failed')
                }
              })
          }
          throw error;
        });
    }
    else
      firebase.auth().signInWithPopup(new auth.GoogleAuthProvider())
        .then(rst => {
          //구글 팝업 로그인 성공
          setTimeout(_ => { this.initnotiasdb(); }, 1200);
        }
        ).catch(error => {
          if (error.code == 'auth/account-exists-with-different-credential') {
            existingEmail = error.email;
            pendingCred = error.credential;
            // Lookup existing account’s provider ID.
            return firebase.auth().fetchSignInMethodsForEmail(error.email)
              .then(providers => {
                if (providers.indexOf(firebase.auth.EmailAuthProvider.PROVIDER_ID) != -1) {
                  var password = window.prompt('기존에 사용하시던 계정(' + existingEmail + ')이 있으시군요. 본인 확인을 위해 기존계정의 비밀번호를 입력해주시겠어요? (최초1회)');
                  return firebase.auth().signInWithEmailAndPassword(existingEmail, password)
                    .then(rst => {
                      setTimeout(_ => { this.initnotiasdb(); }, 1200);
                      return rst.user.linkWithCredential(pendingCred);
                    });
                } else if (providers.indexOf(firebase.auth.FacebookAuthProvider.PROVIDER_ID) != -1) {
                  var facebookProvider = new firebase.auth.FacebookAuthProvider();
                  provider.setCustomParameters({ 'login_hint': existingEmail });
                  return firebase.auth().signInWithPopup(facebookProvider).then(rst => {
                    setTimeout(_ => { this.initnotiasdb(); }, 1200);
                    return rst.user.linkWithCredential(pendingCred);
                  });
                } else {
                  console.log('other cred')
                }
              })
          }
          console.log(error);
          throw error;
        });
  }



  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2000
    });
    toast.present();
  }

}

