import { NgModule,Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { OrderByPipe } from '../../../pipe/orderbypipe';
import { SearchPipeModule } from '../../../pipe/searchpipe.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {AutosizeModule} from 'ngx-autosize';
import { IonicModule } from '@ionic/angular';

import { MsgviewPage } from './msgview.page';

const routes: Routes = [
  {
    path: '',
    component: MsgviewPage
  }
];
//@Directive({ selector: 'ion-textarea[autosize]' })
@NgModule({
  imports: [
    CommonModule,
    FormsModule,AutosizeModule,
    IonicModule, TranslateModule, //,TimeAgoPipeModule,SearchPipeModule,TranslateModule,
    RouterModule.forChild(routes)
  ],
  providers:[OrderByPipe,TranslatePipe],
  declarations: [MsgviewPage]
})
export class MsgviewPageModule {}
