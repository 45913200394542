import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';

@Injectable()
export class Imgupload {

  images:any[] = [];
  imagethumbs:any[] = [];
  uploadedsum:number = 0;
  uploadtotal:number = 0;
  uploadingImg = null;

  constructor() {}

  imgnativeupload(file, folder){
    let path = `/${folder}/${Date.now() + file.name}`;        
    let storageRef = firebase.storage().ref();

    let paththumb = path.replace(file.name, "tb_" + file.name);
    var iRefThumb = storageRef.child(paththumb);
    var urltb;
    var iRef = storageRef.child(path);

    var ext = "";
    let i = file.name.lastIndexOf('.');
    if(i>0)
      ext = file.name.substring(i+1);
    var contType = 'image/jpeg';
    if(ext == 'png')
      contType = 'image/png';
    if(ext == 'gif')
      contType = 'image/gif';
    if(ext == 'bmp')
      contType = 'image/bmp';
    
    if(contType != 'image/jpeg' && file.size < 3145728)
    {
      var promise = new Promise(resolve=>{
      var reader = new FileReader();
      reader.onload = function (event: any) {
      iRef.put(event.target.result,{contentType: contType, cacheControl: 'public,max-age=86400'}).then(function(snapshot) {
        storageRef.child(path).getDownloadURL().then(url =>{ 
        resolve({path : path, url : url});
        })
      });
      }
      reader.readAsArrayBuffer(file);
      })
    }
    else
    {
      var promise = new Promise(resolve=>{
      this.createThumbnail(file,contType,
      result => {
      iRef.putString(result, firebase.storage.StringFormat.BASE64, {contentType: contType, cacheControl: 'public,max-age=86400'})
      .then((snapshot) => {
      storageRef.child(path).getDownloadURL().then(url =>{ 
      this.images.push({path : path, url : url});
      this.uploadedsum += 70;
      resolve({path : path, url : url});
      })
      })
      })
      })
    }

    var promisetb = new Promise(resolve=>{
    this.createThumbnailSmall(file,contType,
    result => {    
        iRefThumb.putString(result, firebase.storage.StringFormat.BASE64, {contentType: contType, cacheControl: 'public,max-age=86400'})
        .then((snapshot) => {
        storageRef.child(paththumb).getDownloadURL().then(url => {
        this.imagethumbs.push({path:paththumb, url:url});
        this.uploadedsum += 30;
        resolve({path : paththumb, url : url});
        })
        })
    })
    })
    return Promise.all([promise, promisetb])
  }

  imgupload(file, folder)
    {
        let path = `/${folder}/${Date.now() + file.name}`;        
        let storageRef = firebase.storage().ref();

        let paththumb = path.replace(file.name, "tb_" + file.name);
        var iRefThumb = storageRef.child(paththumb);
        var urltb;
        var iRef = storageRef.child(path);

        var ext = "";
        let i = file.name.lastIndexOf('.');
        if(i>0)
          ext = file.name.substring(i+1);
        var contType = 'image/jpeg';
        if(ext == 'png')
          contType = 'image/png';
        if(ext == 'gif')
          contType = 'image/gif';
        if(ext == 'bmp')
          contType = 'image/bmp';
        
        if(contType != 'image/jpeg' && file.size < 3145728)
        {
          var promise = new Promise(resolve=>{
          var reader = new FileReader();
          reader.onload = function (event: any) {
          iRef.put(event.target.result,{contentType: contType, cacheControl: 'public,max-age=86400'}).then(function(snapshot) {
            storageRef.child(path).getDownloadURL().then(url =>{ 
            resolve({path : path, url : url});
            })
          });
          }
          reader.readAsArrayBuffer(file);
          })
        }
        else
        {
          var promise = new Promise(resolve=>{
          this.createThumbnail(file,contType,
          result => {
          iRef.putString(result, firebase.storage.StringFormat.BASE64, {contentType: contType, cacheControl: 'public,max-age=86400'})
          .then((snapshot) => {
          storageRef.child(path).getDownloadURL().then(url =>{ 
          this.images.push({path : path, url : url});
          this.uploadedsum += 70;
          resolve({path : path, url : url});
          })
          })
          })
          })
        }

        var promisetb = new Promise(resolve=>{
        this.createThumbnailSmall(file,contType,
        result => {    
            iRefThumb.putString(result, firebase.storage.StringFormat.BASE64, {contentType: contType, cacheControl: 'public,max-age=86400'})
            .then((snapshot) => {
            storageRef.child(paththumb).getDownloadURL().then(url => {
            this.imagethumbs.push({path:paththumb, url:url});
            this.uploadedsum += 30;
            resolve({path : paththumb, url : url});
            })
            })
        })
        })
        return Promise.all([promise, promisetb])

    }

    imguploadsingleori(file, folder)
    {
        let path = `/${folder}/${Date.now() + file.name}`;        
        let storageRef = firebase.storage().ref();

        var iRef = storageRef.child(path);

        var ext = "";
        let i = file.name.lastIndexOf('.');
        if(i>0)
          ext = file.name.substring(i+1);
        var contType = 'image/jpeg';
        if(ext == 'png')
          contType = 'image/png';
        if(ext == 'gif')
          contType = 'image/gif';
        if(ext == 'bmp')
          contType = 'image/bmp';

        var promise = new Promise(resolve=>{
        this.createThumbnailOri(file,contType,
        result => {
        iRef.putString(result, firebase.storage.StringFormat.BASE64, {contentType: contType, cacheControl: 'public,max-age=86400'})
        .then((snapshot) => {
        storageRef.child(path).getDownloadURL().then(url =>{ 
        //this.images.push({url : url});
        resolve({path:path, url:url});
        })
        })
        })
        })
        return Promise.all([promise])
    }

    readfile(file)
    {
    return new Promise(resolve=>{
    var myReader:FileReader = new FileReader();
    myReader.onloadend = (e) => {
    this.uploadingImg = myReader.result;
    resolve();
    }
    myReader.readAsDataURL(file);
    })
    }

    createThumbnailOri(file, contType, callback) {
    this.getOrientation(file, orientation=>{
            this.generateFromImage(this.uploadingImg, null, null, 1, contType, orientation, data => {
            callback(data.split(',')[1]);
            });
    })
    }

    createThumbnail(file, contType, callback) {
    this.getOrientation(file, orientation=>{
            // 이미지가 2.5m 이상이면 가로세로 맥스 650,4000으로 제한해서 업로드, 이하이면 그대로 업로드
            if(file.size > 2572864)
            this.generateFromImage(this.uploadingImg, 650, 4000, 1, contType, orientation, data => {
            callback(data.split(',')[1]);
            });
            else
            this.generateFromImage(this.uploadingImg, null, null, 1, contType, orientation, data => {
            callback(data.split(',')[1]);
            });
    })  
    }
    createThumbnailSmall(file, contType, callback) {
    this.getOrientation(file, orientation=>{
            this.generateFromImage(this.uploadingImg, 180, 180, 1, contType, orientation, data => {
            callback(data.split(',')[1]);
            });
    })  
    }

    getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function (event: any) {
        var view = new DataView(event.target.result);

        if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

        var length = view.byteLength,
            offset = 2;

        while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;

            if (marker == 0xFFE1) {
                if (view.getUint32(offset += 2, false) != 0x45786966) {
                    return callback(-1);
                }
                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;

                for (var i = 0; i < tags; i++)
                    if (view.getUint16(offset + (i * 12), little) == 0x0112)
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
            else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    };

 generateFromImage(img, MAX_WIDTH: number, MAX_HEIGHT: number, quality: number = 1, contType, orientation, callback) {
    var canvas: any = document.createElement("canvas");
    var image = new Image();
 
    image.onload = () => {
      var width = image.width;
      var height = image.height;

      if(MAX_WIDTH && MAX_HEIGHT)
      {
      if (width > height) {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      } else {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      }
      }

      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
    if (4 < orientation && orientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (orientation) {
      case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
      case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
      case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
      case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
      case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
      case 7: ctx.transform(0, -1, -1, 0, height , width); break;
      case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
      default: break;
    }
    ctx.drawImage(image, 0, 0, width, height);
      var dataUrl = canvas.toDataURL(contType, quality);
      callback(dataUrl);
    }
    image.src = img;
  }

    delete(imgpath, imgthpath) {  
      this.images = [];
      this.imagethumbs = [];
      let storagePath = imgpath;
      if(storagePath)
      {
      firebase.storage().ref().child(storagePath).delete()
      .then(
          () => {
            //this.presentToast('bottom','기존 사진이 삭제되었습니다.');
             },
          (error) => console.error("Error deleting stored file",storagePath)
      ).catch()//_=>console.log('삭제할파일이없습니다.'));
      let storagePathThumb = imgthpath;
      firebase.storage().ref().child(storagePathThumb).delete()
      .then().catch()//_=>console.log('삭제할섬네일파일이없습니다.'));
    }
    }
}
