import { Component, OnInit } from '@angular/core';
import { GlobalVars } from '../../../providers/globalVars';
import { ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-firstvisitmodal',
  templateUrl: './firstvisitmodal.page.html',
  styleUrls: ['./firstvisitmodal.page.scss'],
})
export class FirstvisitmodalPage implements OnInit {

  //ltdropdown = false;
  p;
  constructor(public gv:GlobalVars, private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  set(city) {
    if(this.gv.uid){
      firebase.database().ref('/userProfile/' + this.gv.uid + '/loc').set(city);
    }
    else{
      this.gv.setlocinlocal(city)
    }
    this.gv.loc = city; 
    // this.p.refreshdata();
    if( this.p.thisfile == 'pointmain' || this.p.thisfile == 'servicemain' ) {
      this.p.doRefresh();
    } else {
      this.p.refreshdata() // home
    }
    setTimeout(_=> {
      this.modalCtrl.dismiss();
    }, 500);
  }
}
