import { Component, OnInit } from '@angular/core';
import { ToastController, NavController, ModalController, AlertController } from '@ionic/angular';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { GlobalVars } from '../../../providers/globalVars';
import { pointfunc } from '../../../providers/pointfunc';
import * as $ from 'jquery';
import * as firebase from 'firebase/app';
import { msgfunc } from '../../../providers/msgfunc';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-roulette2',
  templateUrl: './roulette2.page.html',
  styleUrls: ['./roulette2.page.scss'],
})
export class Roulette2Page implements OnInit {

  results:any = [];
  spincount = 0;
  userwin = 0;
  spinable = 0;
  
  totaluser = 0;
  totalcount = 0;
  totalwin = 0;

  nowspinning = false;
  prizepopup:any = false;
  prizeTaken = false;

  spined = false; //오늘 돌렸는지 안돌렸는지 
  onemore:boolean = false;

  //댓글 관련 
  loclan: any;
  newcomment: any = "";
  comment: AngularFireList<any[]>;
  commentList: any = [];
  loclancode;
  privatecmt:boolean = false;
  anonymouscmt:boolean = false;
  loaditemcnt:number = 5;

  closingdate:any;
  couponlimit:number;
  eventnotice:any;
  writerid;
  recmt;

  constructor(private navCtrl: NavController, private modalCtrl:ModalController, 
  private alertCtrl:AlertController,
  public afDB: AngularFireDatabase,
  public gv:GlobalVars,
  private pointfunc: pointfunc,
  private toastCtrl: ToastController,
  private msgfunc:msgfunc,
  private translate: TranslateService,
  private router: Router,
  private route: ActivatedRoute) { }

  ngOnInit() {
    this.chkspintoday();

    this.loclan = this.gv.loc + '_' + this.gv.lan;

    this.afDB.database.ref('/event/ongoing/').once('value').then(ongoing=>{
      if( ongoing.val() ) {
        if( ongoing.val().title != '' ) {
          this.closingdate = ongoing.val().closingdate;
        }
        if( ongoing.val().couponlimit )
          this.couponlimit = ongoing.val().couponlimit;
        if( ongoing.val().eventnotice ) {
          this.eventnotice = ongoing.val().eventnotice;
          //console.log(this.eventnotice.length);
          //console.log(this.eventnotice);
        }
      }
    });

    // this.afDB.database.ref('/point/').orderByChild('pcode')
    // .equalTo('이벤트 쿠폰 당첨point').limitToLast(50).once('value').then(rst=>{
    //   console.log(rst.val());
    // });
    

    const Roulette = function(imageElement, canvasElement, option) {
      var ctx;
      var image = imageElement;
      var canvas = canvasElement;
      var roulette = {
        time: 0.0, // ms
        angle: 0.0, // °
        velocity: 0.0, // °/ms
        spinning: false,
        spinComplete: false
      };
      var config = {
        step: 1000.0 / 60.0, // ms
        offset: 0.0, // °
        minVelocity: 0.0, // °
        maxVelocity: 1.0, // °/ms
        acceleration: 0.002, // °/ms^2
        deceleration: -0.001, // °/ms^2
        onRenderCallback: undefined,
        onStopCallback: undefined
      };
      if (option) {
        config.step = option.step||config.step;
        config.offset = option.offset||config.offset;
        config.minVelocity = option.minVelocity||config.minVelocity;
        config.maxVelocity = option.maxVelocity||config.maxVelocity;
        config.acceleration = option.acceleration||config.acceleration;
        config.deceleration = option.deceleration||config.deceleration;
        config.onRenderCallback = option.onRender||config.onRenderCallback;
        config.onStopCallback = option.onStop||config.onStopCallback;
      }
    
      var time = function() {
        return roulette.time;
      };
      var angle = function() {
        return roulette.angle;
      };
      var velocity = function() {
        return roulette.velocity;
      };
      var spinning = function() {
        return roulette.spinning;
      };
      var spinComplete = function() {return roulette.spinComplete;};
    
      var reset = function() {
        roulette.time = 0.0;
        roulette.angle = config.offset;
        roulette.velocity = config.minVelocity;
        roulette.spinning = false;
        roulette.spinComplete = true;
        render(roulette.angle);
      };
    
      var start = function() {
        if (roulette.spinning) {
          console.log("[Roulette] Already Started");
        } else {
          // console.log("[Roulette] Start");
          roulette.spinning = true;
          roulette.spinComplete = false;
          startRoulette();
        }
      };
    
      var startRoulette = function () {
        if (!roulette.spinning) {
          stopRoulette();
        } else {
          render(roulette.angle);
    
          roulette.angle += roulette.velocity * config.step;
          roulette.velocity += config.acceleration * config.step;
          if (roulette.velocity >= config.maxVelocity) roulette.velocity = config.maxVelocity;
          // console.log("roulette.velocity = " + roulette.velocity);
    
          setTimeout(function() {
            roulette.time += config.step;
            // console.log("roulette.time = " + roulette.time);
            startRoulette();
          }, config.step);
        }
      };
    
      var bounded = function(angle) {
        var newAngle = angle % 360;
        if (newAngle < 0) newAngle += 360;
        return newAngle;
      };
    
      var stop = function(targetAngle?) {
        if (!roulette.spinning) {
          console.log("[Roulette] Already Stopped");
        } else if (targetAngle) {
          if (config.deceleration >= 0) {
            // console.log("[Roulette] Unexpected deceleration value : " + config.deceleration);
            config.deceleration = -0.001;
            roulette.spinning = false;
          } else {
            // console.log("[Roulette] Stop at " + targetAngle);
    
            var diffVelocity = config.minVelocity - roulette.velocity;
            var plusVelocity = config.minVelocity + roulette.velocity;
            var originAngle, changeAngle, futureAngle, adjustAngle;
    
            originAngle = bounded(roulette.angle);
            changeAngle = (diffVelocity / (2 * config.deceleration)) * (plusVelocity - config.deceleration * config.step);
            futureAngle = originAngle + changeAngle;
            adjustAngle = bounded(targetAngle - futureAngle);
            futureAngle += adjustAngle;
            // changeAngle = futureAngle - originAngle;
            changeAngle = (futureAngle - originAngle) % 1080;
    
            config.deceleration = diffVelocity * plusVelocity / (2 * changeAngle + config.step * diffVelocity);
            roulette.spinning = false;

            // console.log(diffVelocity +' / '+ plusVelocity +' / '+ originAngle +' / '+ changeAngle +' / '+ futureAngle +' / '+ adjustAngle +' / '+ futureAngle +' / '+ changeAngle +' / '+ config.deceleration);
            // console.log("targetAngle = " + targetAngle);
          }
        } else {
          // console.log("[Roulette] Stop");
          roulette.spinning = false;
        }

        var isComplete = setInterval(_=>{
          // console.log(roulette.velocity);
          if( roulette.velocity <= 0) {
            clearInterval(isComplete);
            roulette.spinComplete = true;
          }
        },10);
      };

    
      var stopRoulette = function() {
        if (roulette.velocity <= config.minVelocity) {
          roulette.velocity = config.minVelocity;
          if (config.onStopCallback) config.onStopCallback({
            time: roulette.time,
            angle: roulette.angle,
            velocity: roulette.velocity,
            spinning: roulette.spinning,
            step: config.step
          });
        } else {
          render(roulette.angle);
    
          roulette.angle += roulette.velocity * config.step;
          roulette.velocity += config.deceleration * config.step;
          if (roulette.velocity <= config.minVelocity) roulette.velocity = config.minVelocity;
    
          setTimeout(function() {
            roulette.time += config.step;
            stopRoulette();
          }, config.step);
        }
    
        /* Initial Render */
        render(roulette.angle);
      };
    
      var render = function(degrees) {
        if (config.onRenderCallback) config.onRenderCallback({
          time: roulette.time,
          angle: roulette.angle,
          velocity: roulette.velocity,
          spinning: roulette.spinning,
          step: config.step,
          spinComplete: roulette.spinComplete
        });
        if (canvas.getContext) {
          ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.save();
          ctx.translate(canvas.width/2, canvas.height/2);
          ctx.rotate(degrees * Math.PI / 180);
          ctx.drawImage(image, 0, 0, image.width, image.height, -canvas.width/2, -canvas.height/2, canvas.width, canvas.height);
          ctx.restore();
        }
      };
    
      render(roulette.angle);
    
      return {
        time: time,
        angle: angle,
        velocity: velocity,
        spinning: spinning,
        reset: reset,
        start: start,
        stop: stop,
        spinComplete: spinComplete
      }
    };


    // ---------------------------------



    $(document).ready(_=> {
      var update = function(tag, data) {
        $('#roulette-time').text('Roulette Time : ' + data.time + 'ms');
        $('#roulette-angle').text('Roulette Angle : ' + data.angle%360 + '°');
        $('#roulette-velocity').text('Roulette Angular Velocity : ' + data.velocity + '°/' + data.step + 'ms');
        $('#roulette-spinning').text('Roulette Spinning : ' + data.spinning);
      };

      var roulette = Roulette(
        document.getElementById('image'),
        document.getElementById('canvas'),
        {
          onRender: function(data) {
            update("Render", data);
          },
          onStop: function(data) {
            update("Stopped", data);
          }
        }
      );

      $('#roulette-reset').click(roulette.reset);
      $('#roulette-start').click(roulette.start);
      $('#roulette-stop').click(_=> {
        roulette.stop();
      });
      $('#roulette-stop-angle').click(function() {
        roulette.stop($('#roulette-stop-angle-value').val());
      })

      // $('#spinbtn').on('click', function(){});
      $('#spinbtn').click(_=>{
        if( !this.gv.uid ) {
          this.requestsignin();
          return false;
        }

        let roulettekey;
        if( this.spined == false || this.onemore == true ) { //오늘 돌린적 없거나, 한바퀴더 라면 공짜로 
          //this.pointfunc.addpoint(this.gv.uid, 'roulettejoin', -0, this.onemore?'한 바퀴 더!':'일일 무료 참가', null);
          firebase.database().ref('/point/' + this.gv.uid).push({
            pcode:'roulettejoin', point:-0, createdDate:firebase.database.ServerValue.TIMESTAMP,
            title:this.onemore?'한 바퀴 더!':'일일 무료 참가'
          }).then(run=>{
            roulettekey = run.key
          })
        } else if ( this.spined == true && this.spinable >= 1 ){
          //this.pointfunc.addpoint(this.gv.uid, 'roulettejoin', -100, null, null);
          firebase.database().ref('/point/' + this.gv.uid).push({
            pcode:'roulettejoin', point:-100, createdDate:firebase.database.ServerValue.TIMESTAMP
          }).then(run=>{
            roulettekey = run.key
          })
        } else if ( this.spinable < 1 ) {
          this.presentToast('포인트가 부족합니다. 채널 활동으로 포인트를 모을 수 있어요!');
          return false;
        } else {
          console.log('error');
          return false
        }
        this.onemore = false;

        setTimeout(_=>{ 
          this.spincounting();
        },50);

        this.nowspinning = true;
        this.prizeTaken = false;

        roulette.start();

        let pick = Math.floor(Math.random() * 100) + 1;
        let stopangle = Math.floor(Math.random()*360) + 1;
        let prize = '';
        if( (99 <= pick && pick <= 100) && 
            (!this.couponlimit || this.couponlimit <= 0) ) {
            pick = Math.floor(Math.random() * 98) + 1;
        } // couponlimit 이 0 이면 쿠폰 안나오게 1~98 범위로 다시 돌림
        if( 1 <= pick && pick <= 30 ) { // 30%
          prize = '50포인트';
          stopangle = (Math.floor(Math.random() * 44) + 68) * -1; 
          // 정중앙 90 / 범위 67.5 ~ 112.5 / 픽스 68 ~ 112 (실제 이미지랑 맞는 범위)
        } else if( 31 <= pick && pick <= 60 ) { // 25% -> 30%
          prize = '꽝!';
          stopangle = (Math.floor(Math.random() * 43) + 24.5) * -1;
          // 45 / 22.5 ~ 67.5 / 24.5 ~ 67.5 (실제 이미지랑 맞는 범위)
        } else if( 61 <= pick && pick <= 80 ) { // 25% -> 20%
          prize = '에어팟 프로 응모권';
          stopangle = (Math.floor(Math.random() * 44) + 113) * -1;
          // 135 / 112.5 ~ 157.5 / 113 ~ 157 (실제 이미지랑 맞는 범위)
        } else if( 81 <= pick && pick <= 90 ) { // 10%
          prize = '한바퀴 더!';
          stopangle = (Math.floor(Math.random() * 46) + 337.5) * -1;
          // 360 / 337.5 ~ 22.5 / 337.5 ~ 23.5(383.5) (실제 이미지랑 맞는 범위)
        } else if( 91 <= pick && pick <= 98 ) { // 8%
          prize = '200포인트';
          stopangle = (Math.floor(Math.random() * 44) + 202.5) * -1;
          // 225 / 202.5 ~ 247.5 / 202.5 ~ 246.5 (실제 이미지랑 맞는 범위)
        } else if( 99 <= pick && pick <= 100 ) { // 2%
          prize = '한인마트 $10 쿠폰';
          stopangle = (Math.floor(Math.random() * 44) + 157.5) * -1;
          // 180 / 157.5 ~ 202.5 / 157.5 ~ 201.5 (실제 이미지랑 맞는 범위)
        } else {
          prize = '없음';
          stopangle = 0;
        }
        setTimeout(_=>{
          roulette.stop(stopangle);
          
          let isSpinComplete;
          var checkSpinComplete = setInterval(_=>{
            isSpinComplete = roulette.spinComplete();
            // console.log(isSpinComplete);
            if( isSpinComplete && !this.prizeTaken ) {
              this.prizepopup = prize;
              this.prizeTaken = true;
              this.afDB.database.ref('/event/NewYearRoulette2').push({
                prize: prize,
                pointid: roulettekey?roulettekey:null,
                createdDate: firebase.database.ServerValue.TIMESTAMP,
                uid: this.gv.uid
              }).then(_=>{
                this.nowspinning = false;

                switch(prize) {
                  case '한바퀴 더!':
                    // this.pointfunc.addpoint(this.gv.uid, '행운의 룰렛 한바퀴 더 100', 100, null, null);
                    this.onemore = true;
                    setTimeout(_=>{
                      $(".prizepopup").trigger('click');
                      this.spincounting();
                      $(".startbtn").trigger('click');
                    }, 1500);
                    break;
                  case '200포인트':
                    this.pointfunc.addpoint(this.gv.uid, 'rouletteprize', 200, null, null);
                    setTimeout(_=>{
                      this.presentToast('200포인트가 지급되었습니다.');
                      this.spincounting();
                    },300);
                    break;
                  case '50포인트':
                    this.pointfunc.addpoint(this.gv.uid, 'rouletteprize', 50, null, null);
                    setTimeout(_=>{
                      this.presentToast('50포인트가 지급되었습니다.');
                      this.spincounting();
                    },300);
                    break;
                  case '한인마트 $10 쿠폰':
                    firebase.database().ref('/userData/' + this.gv.uid + '/pointitem').push({
                      pitemid: '-LviY4_1gtO4xsp4Na1_', // pitemid
                      point: 0, 
                      createdDate: firebase.database.ServerValue.TIMESTAMP, 
                      used: false, 
                      state:'done'
                    }).then(add=>{
                      this.afDB.database.ref('/event/ongoing/couponlimit').transaction(limit => {
                        return (limit || 0) - 1;
                      });
                      var lvltxt;
                      this.translate.get('Level'+this.gv.ulvl).subscribe(res=>{
                        lvltxt = res;
                      })
                      firebase.database().ref('/pmalldata/-LviY4_1gtO4xsp4Na1_/' + add.key).set({
                        uid: this.gv.uid, 
                        createdDate: firebase.database.ServerValue.TIMESTAMP, 
                        used: false, 
                        title: lvltxt //lvltxt
                      });
            
                      firebase.database().ref('/point/' + this.gv.uid).push({
                        pcode: '이벤트 쿠폰 당첨', 
                        point: -0, 
                        itemid: add.key, 
                        createdDate: firebase.database.ServerValue.TIMESTAMP
                      }).then(_=>{
                        firebase.database().ref('/userData/' + this.gv.uid + '/pointitem/' + add.key).update({state:'done'});
                        this.msgfunc.pitemsoldnoti( '-LviY4_1gtO4xsp4Na1_', 'pitem', '당첨됨]' + this.gv.unickname, 
                        this.gv.unickname + '님이 ' + ' 이벤트 쿠폰에 당첨 되었습니다.', 'NjQizqLkBINRT9LF9oHjmMing403');
                        // pitemsoldnoti( pitemId, type, 게시자 노티용타이틀, 게시자 노티용메시지, 게시자id);
                        // this.router.navigate(['/tabs/pm/purchaseconfirm']);
                      })
            
                      this.spincounting();
                    })
                    break;
                  default:
                  setTimeout(_=>{
                    this.spincounting();
                  },300);
                  break;
                }
                //this.prizeTaken = true;
                clearInterval(checkSpinComplete);
              });
            }

          },1000);
            
        },3000);
        
      });

      $(".prizepopup").click(_=>{
        this.spincounting();
        this.prizepopup = false;
      });

      $('#testbutton').click(_=>{
        let prize = '한인마트 $10 쿠폰';
        let stopangle = (Math.floor(Math.random() * 44) + 157.5) * -1;
        // 180 / 157.5 ~ 202.5 / 157.5 ~ 201.5 (실제 이미지랑 맞는 범위)
        this.afDB.database.ref('/event/ongoing/couponlimit').transaction(limit => {
          return (limit || 0) - 1;
        })
        if( !this.couponlimit || this.couponlimit <= 0 ) {
          return false;
        }
        firebase.database().ref('/userData/' + this.gv.uid + '/pointitem').push({
          pitemid: '-LviY4_1gtO4xsp4Na1_', // pitemid
          point: 0, 
          createdDate: firebase.database.ServerValue.TIMESTAMP, 
          used: false, 
          state:'done'
        }).then(add=>{
          
          var lvltxt;
          this.translate.get('Level'+this.gv.ulvl).subscribe(res=>{
            lvltxt = res;
          })
          firebase.database().ref('/pmalldata/-LviY4_1gtO4xsp4Na1_/' + add.key).set({
            uid: this.gv.uid, 
            createdDate: firebase.database.ServerValue.TIMESTAMP, 
            used: false, 
            title: lvltxt //lvltxt
          });

          firebase.database().ref('/point/' + this.gv.uid).push({
            pcode: '이벤트 쿠폰 당첨', 
            point: -0, 
            itemid: add.key, 
            createdDate: firebase.database.ServerValue.TIMESTAMP
          }).then(_=>{
            firebase.database().ref('/userData/' + this.gv.uid + '/pointitem/' + add.key).update({state:'done'});
            this.msgfunc.pitemsoldnoti( '-LviY4_1gtO4xsp4Na1_', 'pitem', '당첨됨]' + this.gv.unickname, this.gv.unickname + '님이 ' + ' 이벤트 쿠폰에 당첨 되었습니다.', 'NjQizqLkBINRT9LF9oHjmMing403');
            // pitemsoldnoti( pitemId, type, 게시자 노티용타이틀, 게시자 노티용메시지, 게시자id);
            // this.router.navigate(['/tabs/pm/purchaseconfirm']);
          })

          this.spincounting();
        })

      });

      $('#testbutton2').on('click', _=>{
        /*
        if( this.spined == false || this.onemore == true ) { //오늘 돌린적 없거나, 한바퀴더 라면 공짜로 
          this.pointfunc.addpoint(this.gv.uid, 'roulettejoin', -0, this.onemore?'한 바퀴 더!':'일일 무료 참가', null);
          this.onemore = false;
        } else if ( this.spined == true && this.spinable >= 1 ){
          this.pointfunc.addpoint(this.gv.uid, 'roulettejoin', -100, null, null);
        } else if ( this.spinable < 1 ) {
          this.presentToast('포인트가 부족합니다. 채널 활동으로 포인트를 모을 수 있어요!');
          return false;
        } else {
          console.log('error');
          return false
        }
        setTimeout(_=>{ 
          this.spincounting();
        },50);
        this.nowspinning = true;
        roulette.start();
        
        let prize = '한바퀴 더!';
        let stopangle = (Math.floor(Math.random() * 46) + 337.5) * -1;
        
        setTimeout(_=>{
          roulette.stop(stopangle);
          setTimeout(_=>{
            this.prizepopup = prize;

            this.afDB.database.ref('/event/NewYearRoulette2').push({
              prize: prize,
              uid: this.gv.uid
            }).then(_=>{
              this.nowspinning = false;

              switch(prize) {
                case '한바퀴 더!':
                  // this.pointfunc.addpoint(this.gv.uid, '행운의 룰렛 한바퀴 더 100', 100, null, null);
                  this.onemore = true;
                  setTimeout(_=>{
                    $(".prizepopup").trigger('click');
                    this.spincounting();
                    $(".startbtn").trigger('click');
                  }, 1500);
                  break;
                default:
                setTimeout(_=>{
                  this.spincounting();
                },300);
                break;
              }
            });
          },2000);
        },3000);*/
      });

    });

    this.spincounting();
    
    this.getData();
  }














  chkspintoday(){
    let today = new Date();
    // firebase.database().ref('/point/' + this.gv.uid).orderByChild('pcode')
    // .equalTo('행운의 룰렛 참여').limitToLast(5).once('value').then(rst=>{
    firebase.database().ref('/point/' + this.gv.uid).orderByChild('pcode')
      .equalTo('roulettejoin').limitToLast(5).once('value').then(rst=>{
      //console.log(today.toLocaleDateString('ko-KR'))
      if(rst.val())
      {
        this.spined = false;
        rst.forEach(r=>{
          // console.log(new Date(r.val().createdDate).toLocaleDateString('ko-KR'));
          if(new Date(r.val().createdDate).toLocaleDateString('ko-KR') == today.toLocaleDateString('ko-KR'))
            this.spined = true;
        })
      }
      else 
      this.spined = false;
    });
  }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2200
    });
    toast.present();
  }

  spincounting() {
    this.chkspintoday();

    this.afDB.database.ref('/userProfile/' + this.gv.uid + '/totalcount/point').once('value').then(point=>{
      if(point.val()) {
        this.spinable = Math.floor(point.val()/100); // 참여 가능 횟수
      }
    });
    this.afDB.database.ref('/event/NewYearRoulette2').orderByChild('uid').equalTo(this.gv.uid).once('value').then(spincount=>{
      if(spincount.val())
        this.spincount = Object.keys(spincount.val()).length; // 참여한 횟수
    });
    this.afDB.database.ref('/event/NewYearRoulette2').orderByChild('uid').equalTo(this.gv.uid).once('value').then(userwin=>{
      let templist = [];
      userwin.forEach(win=>{
        if(win.val().prize == '에어팟 프로 응모권')
          templist.push(win.val());
      });
      this.userwin = templist.length; // 에어팟 프로 응모 횟수
    });

    
    this.afDB.database.ref('/event/NewYearRoulette2').once('value').then(total=>{
      let templist = [];
      if( total.val() ) {
        total.forEach(item=>{
          if( templist.indexOf(item.val().uid) < 0 ) templist.push(item.val().uid);
        });
        this.totaluser = templist.length;
      }
      //this.totaluser = Object.keys(total.val()).length; // 총 참여 유저 수
    });
    this.afDB.database.ref('/event/NewYearRoulette2').once('value').then(total=>{
      if( total.val() )
        this.totalcount = Object.keys(total.val()).length; // 총 참여 수
    });
    this.afDB.database.ref('/event/NewYearRoulette2').orderByChild('prize').equalTo('에어팟 프로 응모권').once('value').then(count=>{
      if(count.val())
        this.totalwin = Object.keys(count.val()).length; // 총 에어팟 응모 수
    });
  }

  close(){
    this.modalCtrl.dismiss();
    //this.navCtrl.navigateBack('/');
  }

  ionViewDidEnter(){
    setTimeout(_=>{
      // console.log('reset!');
      $('#roulette-reset').trigger('click');
    },1000);
  }


  getData() {
    this.comment = this.afDB.list('/event/comment');
    this.comment.snapshotChanges().subscribe(Items => {
      this.commentList = Items.map((Items) => {
        return Items;
      }).map((Items) => {
        return Items;
      }
      );
    });
    // let today = new Date();
    // let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
    // setTimeout(_ => {
    //   this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/view').transaction(view => {
    //     return (view || 0) + 1;
    //   })
    // }, 4000)
  }


  bad_chkback(str) { // 욕 필터링 , 스크립트 아이프레임도 필터링
    if (str == '')
      return false;
    var badword;
    this.translate.get('badword').subscribe(res => {
      badword = res;
    });
    var YokList = badword.replace(/\'/g, '').split(",");
    var Tmp = str;
    for (var i = 0; i < YokList.length; i++) {
      var tempRegex = new RegExp(YokList[i], "ig");
      Tmp = Tmp.replace(tempRegex, '*'.repeat(YokList[i].length))
    }
    return Tmp;
  }

  requestsignin() {
    var signinmsg;
    this.translate.get('requiredsignin').subscribe(res => {
      signinmsg = res;
    });
    this.presentsigninToast(signinmsg);
  }
  async presentsigninToast(message: string) {
    let login;
    this.translate.get('Sign In').subscribe(res => { login = res });
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      buttons:[{
        text:login,
        handler:()=>{
          this.login();
        }
      }],
      duration: 2000
    });
    return await toast.present();
  }
  async login() {
    // this.router.navigate(['/tabs/pfmenu']);
    //this.navCtrl.navigateForward('/signin');
    this.modalCtrl.dismiss().then(_=>{
      this.router.navigate(['/tabs/pfmenu']);
    });
  }

  addcomment() {
    if (this.gv.uid) {
      if (this.newcomment.length < 2) {
        var minletters;
        this.translate.get('Please fill in more than 2 letters').subscribe(res => {
          minletters = res;
        });
        this.presentToast(minletters);
      }
      else {
        this.newcomment = this.bad_chkback(this.newcomment);
        this.comment.push([{
          userId: this.gv.uid,
          lvl: this.gv.ulvl,
          nick: this.gv.unickname, //anonymous
          img: this.gv.uimg?this.gv.uimg:'', //anonymous
          anonymous: this.anonymouscmt,
          private: this.privatecmt,
          content: this.privatecmt?'비밀댓글 입니다.':this.newcomment,
          realcontent: this.privatecmt?this.newcomment:'',
          createdDate: firebase.database.ServerValue.TIMESTAMP,
        }]).then(addeditem => {
          var cmted;
          this.translate.get('Posted the comment').subscribe(res => {
            cmted = res;
          });
          this.presentToast(cmted);
          this.newcomment = '';
        })
      }
    }
    else
      this.requestsignin();
  }

  async delcomment(key) {
    var chkdel, confirmdel, cancelbtn, delbtn, deledmsg;

    this.translate.get('Do you want to delete this comment').subscribe(res => {
      confirmdel = res;
    });
    this.translate.get('Deleted the comment').subscribe(res => {
      deledmsg = res;
    });

    this.translate.get('Confirm to delete').subscribe(res => {
      chkdel = res;
    });
    this.translate.get('delete').subscribe(res => {
      delbtn = res;
    });
    this.translate.get('Cancel').subscribe(res => {
      cancelbtn = res;
    });

    const alert = await this.alertCtrl.create({
      header: chkdel,
      message: confirmdel,
      buttons: [
        {
          text: cancelbtn,
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: delbtn,
          handler: () => {
            this.afDB.database.ref('/event/comment/' + key).once('value').then(cmt=>{ // 삭제하는 댓글
              this.comment.remove(key).then(_ => {
                this.presentToast(deledmsg);
                });
              });
          }
        }
      ]
    });
    return await alert.present();
  }


  doInfinite(infiniteScroll) {
    this.loaditemcnt = this.loaditemcnt+5;
    infiniteScroll.target.complete();
  }

  isprivatecmt() {
    this.privatecmt = !this.privatecmt;
    if( this.privatecmt )
      this.presentToast('댓글이 작성자와 본인에게만 노출됩니다.');
    else 
      this.presentToast('댓글이 모두에게 공개됩니다.');
  }
  isanonymouscmt() {
    this.anonymouscmt = !this.anonymouscmt;
    if( this.anonymouscmt )
      this.presentToast('익명으로 댓글을 답니다.');
    else 
      this.presentToast('닉네임을 노출하여 댓글을 답니다.');
  }


  strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  
  editcomment(cmtkey, content) {
    if (content.length < 2) {
      var minletters;
      this.translate.get('Please fill in more than 2 letters').subscribe(res => {
        minletters = res;
      });
      this.presentToast(minletters);
      
    } else {

      content = this.bad_chkback(content);
      firebase.database().ref('/event/comment/' + cmtkey).update({
        content: this.strip(content)
        // realcontent: privatecmt?this.strip(content) : '',
        // images: images ? images : '',
        // imagethumbs: thumbs ? thumbs : '',
        // attachedlink: attachedlinktarget ? document.getElementById(attachedlinktarget).innerHTML : ''
      });
      var editdonemsg;
      this.translate.get('Edited the comment').subscribe(res => {
        editdonemsg = res;
      });
      this.presentToast(editdonemsg);
      
    }
  }

  htmlupload(a,b){}
  

}
