import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { EnterPipeModule } from '../../../../pipe/enterpipe.module';
import { IonicModule } from '@ionic/angular';
import { TimeAgoPipeModule } from '../../../../pipe/time-ago-pipe.module';
import { PitemdetailPage } from './pitemdetail.page';
import { SafeHtmlPipeModule } from '../../../../pipe/safehtmlpipe.module';
import { OrderByPipeModule } from '../../../../pipe/orderbypipe.module';
import { MatchPipeModule } from '../../../../pipe/matchpipe.module';
import { ChcatPipeModule } from '../../../../pipe/chcatpipe.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

// import { ChlistPage } from '../../chlist/chlist.page';
import { SelectchannelComponent } from '../../../../component/selectchannel/selectchannel.component';

const routes: Routes = [
  {
    path: '',
    component: PitemdetailPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,EnterPipeModule,TimeAgoPipeModule,SafeHtmlPipeModule,
    OrderByPipeModule, MatchPipeModule, ChcatPipeModule, TranslateModule,
    RouterModule.forChild(routes)
  ],
  declarations: [PitemdetailPage, SelectchannelComponent],
  entryComponents: [SelectchannelComponent],
  providers: [TranslatePipe]
})
export class PitemdetailPageModule {}
