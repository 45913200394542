import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { TypeKrNamePipe } from '../../../pipe/tkrnamepipe';
import { TypeNamePipeModule } from '../../../pipe/typenamepipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
//import { LevelinfoPageModule } from '../levelinfo/levelinfo.module';
import { MylevelPage } from './mylevel.page';

const routes: Routes = [
  {
    path: '',
    component: MylevelPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,OrderByPipeModule,TypeNamePipeModule,TimeAgoPipeModule,
    IonicModule,TranslateModule,RoundProgressModule,//LevelinfoPageModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe,TypeKrNamePipe],
  declarations: [MylevelPage]
})
export class MylevelPageModule {}
