import { NgModule } from '@angular/core';
import { BlockchkPipe } from './blockchkpipe';

@NgModule({
  declarations: [
    BlockchkPipe,
  ],
  imports: [
    
  ],
  exports: [
    BlockchkPipe
  ]
})
export class BlockchkPipeModule {}
