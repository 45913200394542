import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase} from '@angular/fire/database';
import { NavController,AlertController,ModalController,ToastController } from '@ionic/angular';
import { Imgupload } from '../../../providers/imgupload';
import { GlobalVars } from '../../../providers/globalVars';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-setlocal',
  templateUrl: './setlocal.page.html',
  styleUrls: ['./setlocal.page.scss'],
})
export class SetlocalPage implements OnInit {

  loclan;
  ad:any[] = [];
  banner:any[] = [];
  service:any[] = [];
  manager:any[] = [];
  category:any = '광고';
  categories:any = [{name:'광고'},{name:'서비스'},{name:'매니저'},{name:'상단배너'}];
  slt:any;
  sltadv:any;
  datenow:any = new Date();

  adimg:any;
  serviceimg:any;
  bannerimg:any;
  index:any;
  sltservice;
  key;
  constructor(private imgupload: Imgupload,public alertCtrl: AlertController,public gv: GlobalVars,
   private toastCtrl:ToastController,
   private modalCtrl:ModalController, public afAuth: AngularFireAuth,
   private navCtrl:NavController, public afDB: AngularFireDatabase) { }

  ngOnInit() {
      this.loclan = this.gv.getLocLan();
      if(this.gv.manager)
        this.getdata();
  }

  getdata(){
    this.afDB.database.ref('/localmanage/' + this.loclan).once('value').then(data=>{
      let setting = data.val();
      if(setting)
      {
        //this.service = setting['banner'];
        setting['banner'].forEach(item=>{
          this.banner.push(item);
        })
        setting['ad']['home'].forEach(item=>{
          this.ad.push(item);
        })
        setting['service'].forEach(item=>{
          this.service.push(item);
        })
        setting['manager'].forEach(item=>{
          this.manager.push(item);
        })
      }
    })
  }

  chkdate(date){
    let enddate = new Date(date);
    if(enddate < this.datenow)
      return false;
    else
      return true;
  }

  selectcat(cat){
    this.category = cat.name;
  }

  async delconfirm(i,name){
    const alert = await this.alertCtrl.create({
    message: '[' + name + '] 항목을 삭제하시겠습니까?',
    buttons: [
      {
        text: '취소',
        role: 'cancel'
      },{
        text: '확인',
        handler: () => {
          if(this.category == '광고')
          {this.ad.splice(i, 1)}
          if(this.category == '서비스')
          {this.service.splice(i, 1)}  
          if(this.category == '매니저')
          {this.manager.splice(i, 1)}
          if(this.category == '상단배너')
          {this.banner.splice(i, 1)}
          
        }
      }
    ]});
    await alert.present();
  }

  addmanager(){
    let alert = this.alertCtrl.create({
            header:'멤버 초대',
            message: "초대하실 회원을 입력해 주세요.",
            inputs: [
                {
                name: 'value',
                placeholder: '닉네임 또는 이메일주소'
                }
            ],
            buttons: [
                {
                text: '취소'
                },
                {
                text: '추가',
                handler: data => {
                    var alreadymanager = "";
                    if(data.value.indexOf('@') > 0) // 이메일
                    {
                        this.manager.forEach(item =>
                        {
                            if(item.email == data.value)
                                {
                                alreadymanager = item.email; 
                                return;
                                }
                        })
                        if(!alreadymanager)
                        this.afDB.database.ref('userProfile').orderByChild('email').equalTo(data.value).once('value').then(
                        user => 
                            {
                            if(!user.val())
                            {
                                let erralert = this.alertCtrl.create({
                                    header:'사용자 없음',
                                    message: '다시 검색해 보시겠습니까?',
                                    buttons: [
                                        {
                                        text: '닫기',
                                        role: 'cancel'
                                        },
                                        {
                                        text: '재시도',
                                        handler: () => {
                                            this.addmanager();
                                        }
                                        }
                                    ]
                                    }); 
                            erralert.then(view=>view.present())
                            }
                            else
                            {
                                this.manager.unshift({uid:Object.keys(user.val())[0],nickname:user.val()[Object.keys(user.val())[0]].nickname})
                            }
                            }
                        );
                        else
                            {
                            let erralert = this.alertCtrl.create({
                                    header:alreadymanager + ' - 이미 추가됨',
                                    message: '다른 사람을 검색해 보시겠습니까?',
                                    buttons: [
                                        {
                                        text: '닫기',
                                        role: 'cancel'
                                        },
                                        {
                                        text: '추가검색',
                                        handler: () => {
                                            this.addmanager();
                                        }
                                        }
                                    ]
                                    }); 
                            erralert.then(view=>view.present())
                            }
                    }
                    else
                    {
                        this.manager.forEach(item =>
                        {
                            if(item.nickname == data.value)
                            {
                            alreadymanager = item.nickname; 
                            return;
                            }
                        })
                        if(!alreadymanager)
                        this.afDB.database.ref('userProfile').orderByChild('nickname').equalTo(data.value).once('value').then
                        (user => 
                            {
                            if(!user.val())
                            {
                                let erralert = this.alertCtrl.create({
                                    header:'사용자 없음',
                                    message: '다시 검색해 보시겠습니까?',
                                    buttons: [
                                        {
                                        text: '닫기',
                                        role: 'cancel'
                                        },
                                        {
                                        text: '다시검색',
                                        handler: () => {
                                            this.addmanager();
                                        }
                                        }
                                    ]
                                    }); 
                            erralert.then(view=>view.present())
                            }
                            else 
                            {
                                this.manager.unshift({uid:Object.keys(user.val())[0],nickname:user.val()[Object.keys(user.val())[0]].nickname})
                            }
                            }
                        );
                        else
                            {
                            let erralert = this.alertCtrl.create({
                                    header:alreadymanager + ' - 이미 추가됨',
                                    message: '다른 사람을 검색해 보시겠습니까?',
                                    buttons: [
                                        {
                                        text: '닫기',
                                        role: 'cancel'
                                        },
                                        {
                                        text: '추가검색',
                                        handler: () => {
                                            this.addmanager();
                                        }
                                        }
                                    ]
                                    }); 
                            erralert.then(view=>view.present())
                            }
                    }
                }
                }
            ]
            });
        alert.then(view=>view.present())
  }

  addbanner(){
    this.banner.unshift({bgurl:'',linkurl:'',name:'',point:0,show:true});
  }

  addadv(){
    this.ad.unshift({advorder:0, enddate:'', id:'', img:'', show:'all', 
    startdate:'',title:''});
  }

  addservice(){
    this.service.unshift({detail:{btmcat:'',btmlabel:'',btnname:'',chid:'',title:'',topcat:'',toplabel:''},
    imgurl:'',label:'',linkurl:'',name:'',show:true});
  }
  reordermanager(ev){
    this.manager = ev.detail.complete(this.manager);
  }
  reorderbanner(ev){
    this.banner = ev.detail.complete(this.banner);
  }
  reorderadv(ev){
    this.ad = ev.detail.complete(this.ad);
  }
  reorderservice(ev){
    this.service = ev.detail.complete(this.service);
  }

  upload(index){
    this.index = index;
    document.getElementById('imgfile').click();
  }
  async htmlupload(fileinput:HTMLInputElement) { 
      //if(this.images.length > 0 && this.imagethumbs.length > 0)
      //  this.imgupload.delete(this.images[0].path, this.imagethumbs[0].path);
      var file:File = fileinput.files[0];
      let folder;
      if(this.category == '상단배너')
      {folder='banners'}
      if(this.category == '광고')
      {folder='advertise'}
      if(this.category == '서비스')
      {folder='services'} 
      await this.imgupload.readfile(file).then(rst=>
      {
      this.imgupload.imguploadsingleori(file, folder)
      .then(imgrst=>{
        if(this.category == '상단배너')
        {this.banner[this.index].bgurl = imgrst[0]['url']}
        if(this.category == '광고')
        {this.ad[this.index].img = imgrst[0]['url']}
        if(this.category == '서비스')
        {this.service[this.index].imgurl = imgrst[0]['url']}
        
      })
      }) 
  }

  update(){
    this.ad.map(adv=>{
      if(adv.show)
        adv.show = 'all';
      return adv
    })
    let serindex = 0;
    this.service.map(ser=>{
      if(!ser.linkurl || ser.linkurl.indexOf('/servicedetail/') == 0)
        ser.linkurl = '/servicedetail/'+serindex+'/' + this.loclan;
      serindex++;
      return ser
    })

    this.afDB.database.ref('/localmanage/' + this.loclan + '/ad/home').set(this.ad);
    this.afDB.database.ref('/localmanage/' + this.loclan + '/banner').set(this.banner);
    this.afDB.database.ref('/localmanage/' + this.loclan + '/service').set(this.service);
    this.presentToast('업데이트가 반영되었습니다.');
    this.close();
  }

  close(){
    this.modalCtrl.dismiss();
  }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
    message: message,
    position: 'bottom',
    duration: 2200
    });
    toast.present();
    }
}
