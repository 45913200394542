import { NgModule } from '@angular/core';
import { SearchResultPipe } from './searchresultpipe';

@NgModule({
  declarations: [
    SearchResultPipe,
  ],
  imports: [
    
  ],
  exports: [
    SearchResultPipe
  ]
})
export class SearchResultPipeModule {}
