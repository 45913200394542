import { Component, OnInit } from '@angular/core';
import { NavController,LoadingController, AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { GlobalVars } from '../../../providers/globalVars';

@Component({
  selector: 'app-listupdate',
  templateUrl: './listupdate.page.html',
  styleUrls: ['./listupdate.page.scss'],
})
export class ListUpdatePage implements OnInit {
  
  items:any = [];
  counttxt:string = "카운트 시작";
  varinterval:any;
  timeterm:number = 5;
  rpcount:number = 20;
  lastlimit:string = '30';
  curcount:number = 0;
  constructor(public navCtrl: NavController, 
    public modalCtrl: ModalController, 
    public loadingCtrl: LoadingController, public afDB: AngularFireDatabase,private gv:GlobalVars,
    public translate: TranslateService, public alertCtrl: AlertController) {
    
    if(this.gv.uid != 'NjQizqLkBINRT9LF9oHjmMing403')
      this.modalCtrl.dismiss().catch(_=>{
      this.navCtrl.navigateBack('');
      })
    else
        this.navCtrl.pop();
  }
  ngOnInit() {
  }
  countup(){
      if(this.counttxt == '카운트 시작')
        {
            this.afDB.list('/list', ref=> ref.limitToLast(parseInt(this.lastlimit)))
            .snapshotChanges().subscribe(itemlist=>{
                        this.items = itemlist;
                       // console.log(this.items.length);
                })
            console.log(this.items.length);
            console.log('카운트를 시작하겠습니다.');
            this.curcount = 0;
            this.counttxt = '<<< 카운트 진행중 >>>';
            var inttime;
            this.varinterval = setInterval(rst=> {
               inttime = Math.floor(this.timeterm*60*1000 + Math.random() * this.timeterm*60*1000/2);
               let itemindex =  Math.floor(Math.random() * parseInt(this.lastlimit));
               let timenow = new Date();
              if(this.items[itemindex])
              {
               console.log(timenow.getHours() + ':' + timenow.getMinutes() + ' ' + timenow.getSeconds() + ']' 
               + this.items[itemindex].$key + '>>' + this.items[itemindex]['title'] 
               + this.items[itemindex]['read'] + '>>' + parseInt(this.items[itemindex]['read']+1)
               + ' 업데이트 완료!!');  
               this.afDB.database.ref('/list/' + this.items[itemindex].$key).update({read:parseInt(this.items[itemindex]['read']+1)});
              
               let randomvisit = Math.random();
               if(randomvisit > 0.8)
              {
                this.afDB.database.ref('/category/' + this.items[itemindex].categoryId + '/chinfo').child('visit').transaction(visit=>{
                  console.log('방문수가 증가되었습니다. -' + ((visit || 0) + 1))
                  return (visit || 0) + 1;
                })
              }
              }
              else
                {console.log('항목키없음');console.log(this.items[itemindex])}

               if(this.rpcount > this.curcount+1)
                this.curcount++;
               else
                {
                clearInterval(this.varinterval);
                console.log('카운트가 모두 완료되었습니다.');
                this.counttxt = '카운트 시작';
                }

            },  Math.floor(this.timeterm*60*1000 + Math.random() * this.timeterm*60*1000/2));
        }
      else
        {
            clearInterval(this.varinterval);
            console.log('카운트 종료됨...');
            this.counttxt = '카운트 시작';
        }
  }

  startcount(){

  }


  presentAlert(title) {
    var okmsg;
    this.translate.get('Confirm').subscribe(res=>{
      okmsg = res;
    });
    let alert = this.alertCtrl.create({
      message: title,
      buttons: [okmsg]
    }).then(_=>{_.present()})
  }
}
