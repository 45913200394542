import { NgModule } from '@angular/core';
import { TypeNamePipe } from './typenamepipe';

@NgModule({
  declarations: [
    TypeNamePipe,
  ],
  imports: [
    
  ],
  exports: [
    TypeNamePipe
  ]
})
export class TypeNamePipeModule {}
