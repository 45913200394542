import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.page.html',
  styleUrls: ['./closed.page.scss'],
})
export class ClosedPage implements OnInit {
  pltinfo;
  constructor() { }

  ngOnInit() {
    if(`${this.pltinfo}` != 'undefined') 
    {
    //console.log(this.pltinfo);
    }
    document.body.addEventListener('keyup', (event) => {
    if (event.keyCode === 27) { // ESCAPE key
      event.preventDefault();
      event.stopImmediatePropagation();
    }})
  }

  updateapp()
    {
      //console.log('업데이트');
      if(this.pltinfo == 'ios')
        window.open('https://itunes.apple.com/us/app/채널/id1343026996?l=ko&ls=1&mt=8', '_system','location=no');
        //this.iab.create("https://itunes.apple.com/us/app/채널/id1343026996?l=ko&ls=1&mt=8", '_blank');
      else if(this.pltinfo == 'android')
        window.open('market://details?id=com.ionicframework.channel201800', '_system','location=no');
        //this.iab.create("market://details?id=com.ionicframework.channel201800", "_system");
      else
        alert('스토어로 연결하지 못했습니다. 앱을 재설치해주세요.')
    }

}
