import {Component,OnInit,ViewChild,ElementRef} from '@angular/core';
import {NavController,ModalController,AlertController,ToastController} from '@ionic/angular';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {Imgupload} from '../../../providers/imgupload';
import {TranslateService} from '@ngx-translate/core';
import {SingleDataSet,Label} from 'ng2-charts';
import {ChartOptions,ChartType} from 'chart.js';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {SetaccountPage} from '../setaccount/setaccount.page';

import {GlobalVars} from '../../../providers/globalVars';
import {ChmainPage} from '../../../pages/general/chmain/chmain.page';
import {ChviewPage} from '../../../pages/general/chview/chview.page';
import {map} from 'rxjs/operators';
import { OrderByPipe } from '../../../pipe/orderbypipe';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
})
export class AccountPage implements OnInit {
  user: any = [];
  userthumbimg: any;
  userlvl: any;
  uid: any;
  loading: boolean = false;
  profilePicture: any;
  profileThumbPicture: any;
  nickvail: any;
  changenickmsg: any;
  loaded: any = true;
  pieChartPlugins: any;
  userchann: any;

  tab1: any;
  tab2: any;
  cont1: any;
  cont2: any;

  favlist: any;

  actlist: any[] = [];
  datenow: any = new Date().getTime();
  templist: any[] = [];
  recentlist: any[] = [];
  // uid: any;
  msgshowcount: any = 10;
  recentshowcount: any = 10;
  lastdate: any = null;
  type;
  listtype: any;
  dbname: any;
  title: any;
  editable: boolean = false;

  writelen: any;
  commentlen: any;
  likelen: any;
  invitelen: any;
  lengthArr: any[] = [];

  usernick: any;
  userbio: any;

  @ViewChild('nameinput') namefield;
  @ViewChild('biofield') biofield;
  nicklimit: any;

  writtenpost: any;
  actchannLabel: any[] = [];
  actchannArr: any[] = [];
  commucount: any = 0;
  placecount: any = 0;
  sharecount: any = 0;
  workcount: any = 0;
  tradecount: any = 0;
  talentcount: any = 0;

  memid;
  selfview:boolean = false;
  memberId:any;
  editmode;

  useremail: any = '';

  chnpost = false; //채널과 작성글 비공개여부
  actgraph = false; //그래프 비공개여부
  constructor(public modalCtrl: ModalController, public afAuth: AngularFireAuth, public afDB: AngularFireDatabase,
    public alertCtrl: AlertController, public toastCtrl: ToastController, private imgupload: Imgupload, 
    public translate: TranslateService, public navCtrl: NavController, public gv: GlobalVars, private orderby:OrderByPipe
  ) {}

  

  ngOnInit() {
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
     
    if (`${this.memid}` != 'undefined') {
      this.memberId = `${this.memid}`;
      this.uid = this.gv.uid;
      if (this.memberId == this.uid) {
        this.afDB.database.ref('/systemlog/' + (this.uid ? this.uid : 'unregister') + '/event/' + todaystr + '/myinfo').transaction(view => {
          return (view || 0) + 1;
        })
      }
      else {
        this.afDB.database.ref('/systemlog/' + (this.uid ? this.uid : 'unregister') + '/event/' + todaystr + '/meminfo').transaction(view => {
          return (view || 0) + 1;
        })
      }
    }
    else {
      this.selfview = true;
      this.uid = this.memberId = this.gv.uid;
      this.afDB.database.ref('/systemlog/' + (this.uid ? this.uid : 'unregister') + '/event/' + todaystr + '/myinfo').transaction(view => {
        return (view || 0) + 1;
      })
    }
    this.getUserdata();
    this.getPostdata();
    this.tab1 = 'active';


    if (this.gv.uid) {
      this.afDB.database.ref('/userProfile/' + this.gv.uid + '/email').once('value').then(email => {
        if (email.val()) this.useremail = email.val();
      });
    }

    
    this.afDB.database.ref('/userData/' + this.memberId + '/private/chnpost').once('value').then(chnpost=>{
      if( chnpost.val() ) this.chnpost = chnpost.val();
      else this.chnpost = false;
    });
    this.afDB.database.ref('/userData/' + this.memberId + '/private/actgraph').once('value').then(actgraph=>{
      if( actgraph.val() ) this.actgraph = actgraph.val();
      else this.chnpost = false;
    });
    
  }

  getPostdata(infi ? ) {
    this.afDB.database.ref('/userProfile/' + this.memberId + '/write')
      .orderByChild('createdDate').endAt(this.lastdate ? this.lastdate - 1 : 9999999999999)
      .limitToLast(this.msgshowcount).once('value').then(rst => {
        if (rst.val() && rst.val()[Object.keys(rst.val())[0]].createdDate)
          this.lastdate = rst.val()[Object.keys(rst.val())[0]].createdDate;

        this.templist = [];
        rst.forEach(item => {
          // console.log(this.templist);
          this.templist.unshift({
            loclancode: item.val().loclancode ? item.val().loclancode : 'cairns_kr',
            commentid: item.val().commentid ? item.val().commentid : null,
            itemid: item.val().itemid,
            createdDate: item.val().createdDate
          })
        })
      }).then(_ => {
        this.templist.map(data => {
          var listdir = this.gv.getListDir(data.loclancode);
          this.afDB.database.ref(listdir + '/' + data.itemid).once('value').then(val => {
            data.data = val.val()
            if (!val.val())
              data.data = {
                title: '작성자에 의해 삭제된 글입니다.',
                deleted: true
              };
          });
          this.actlist.push(data);
        })
      }).then(_ => {
        if (infi)
          infi.target.complete();
      })
  }

  doRefreshMsg(infiniteScroll) {
    this.getPostdata(infiniteScroll);
  }


  getUserdata() {
    this.afDB.database.ref('/userProfile/' + this.memberId + '/sum').once('value').then(data => {
      this.user = data.val();
      this.userlvl = data.val().lvl;
      this.usernick = data.val().nick;
    })

    this.afDB.database.ref('/userProfile/' + this.memberId + '/level').once('value').then(data => {
      let d = data.val()
      this.lengthArr = [d.post, d.comment, (d.up?d.up:0)+(d.down?d.down:0), d.invite*5];
    })

    //폴라에어리어 그래프(활동타입)용 데이터 [게시글, 댓글, 좋아요, 초대] - 유저 전체에서 담으니 오류남
    /*
    this.afDB.database.ref('/userProfile/' + this.uid + '/write').once('value').then(data => {
      if (data.val()) this.writelen = Object.keys(data.val()).length;
      else this.writelen = 0;
      this.lengthArr.push(this.writelen);
    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/comment').once('value').then(data => {
      if (data.val()) this.commentlen = Object.keys(data.val()).length;
      else this.commentlen = 0;
      this.lengthArr.push(this.commentlen);
    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/like').once('value').then(data => {
      if (data.val()) this.likelen = Object.keys(data.val()).length;
      else this.likelen = 0;
      this.lengthArr.push(this.likelen);
    })
    this.afDB.database.ref('/userProfile/' + this.uid + '/invite').once('value').then(data => {
      if (data.val()) this.invitelen = Object.keys(data.val()).length;
      else this.invitelen = 0;
      this.lengthArr.push(this.invitelen * 5);
    })*/

    //도넛 그래프(활동분야)용 데이터 [채널, 구인, 쉐어, 매매] - 유저 전체에서 담으니 오류남
    //[ ] 활동한 채널명으로 표시하기(기본채널 포함) 상위 4개 노출, 나머지 기타 -190531
    // this.afDB.database.ref('/userProfile/' + this.memberId + '/write').once('value').then(data => {
    //   this.writtenpost = data.val();
    //   console.log(this.writtenpost);
    //   if( this.writtenpost != null ) {
    //     let keys: string[] = Object.keys(this.writtenpost)
    //     keys.map((key: string) => {
    //       switch(this.writtenpost[key].type) {
    //         case 'community': this.commucount++; break;
    //         case 'place': this.placecount++; break;
    //         case 'house': this.sharecount++; break;
    //         case 'work': this.workcount++; break;
    //         case 'trade': this.tradecount++; break;
    //         //case 'talent': this.talentcount++; break;
    //         default: break;
    //       }
    //     });
    //   }
    //   return this.actchannArr.push(this.commucount, this.sharecount, this.placecount, this.workcount, this.tradecount);
    // });
    this.afDB.database.ref('/userProfile/' + this.memberId + '/write').once('value').then(data => {
      this.writtenpost = data.val();
      let result = [];
      if( this.writtenpost != null ) {
        let keys: string[] = Object.keys(this.writtenpost)
        keys.map((key: string) => {
          if(this.writtenpost[key].chid){
            let exist = false;
            result.forEach(r=>{
              if(r.chid == this.writtenpost[key].chid)
                {
                  r.count = (r.count?r.count:0) + 1;
                  exist = true;
                }
            })
            if(!exist)
              result.push({chid:this.writtenpost[key].chid,count:1});
          }
        });
      }
      result = this.orderby.transform(result, 'count', 'desc');
      // console.log(result);

      let othercount = 0;
      if(result.length > 4)
      for(let i = 4; i < result.length; i++)
          othercount = othercount + result[i].count;
      
      result = result.slice(0,4);
      // this.doughnutChartLabels = ['','','','','기타'];
      //this.doughnutChartLabels = [];
      let templabels = [];
      // for(let i = 0; i < ((result.length < 4)?result.length:4); i++){
      for(let i = 0; i < result.length; i++){
        templabels[i] = '';
        this.afDB.database.ref('/category/' + result[i].chid + '/name').once('value').then(name=>{
          if( name.val() != null ) 
            templabels[i] = name.val();
        })
      }

      if(result.length && othercount) templabels[result.length] = '기타';
      for(let i = 0; i < result.length; i++ ){
        this.actchannArr.push(result[i].count?result[i].count:0);
      }
      this.doughnutChartLabels = templabels;


      this.actchannArr.push(othercount);
      // return this.actchannArr.push(result[0].count?result[0].count:0, result[1].count?result[1].count:0, result[2].count?result[2].count:0, result[3].count?result[3].count:0, othercount);
      // console.log(this.doughnutChartLabels);
      // console.log(this.actchannArr);
      return this.actchannArr;
    });


    
    if(this.selfview)
    {
      this.afDB.database.ref('/userProfile/' + this.uid + '/nicklimit').on('value',data => {
      this.nicklimit = data.val();})
      this.afDB.database.ref('/userProfile/' + this.uid + '/mypage/introstr').on('value',data => {
      this.userbio = data.val();})
    }
    else{
      this.afDB.database.ref('/userProfile/' + this.memberId + '/mypage/introstr').once('value').then(data => {
      this.userbio = data.val();})
    }


    this.afDB.list('/userData/' + this.memberId + '/favlist').snapshotChanges()
      .pipe(map((arr) => {
        return arr
      })).pipe(map((data) => {
        return data.map((data) => {
          this.afDB.database.ref('/category/' + data.payload.val()['chid'] + '/name').once('value').then(val => { //console.log('읽음 변경됨'); 
            data.payload.val()['title'] = val.val()
          });
          this.afDB.database.ref('/category/' + data.payload.val()['chid'] + '/imagethumb/0/url').once('value').then(val => { //console.log('읽음 변경됨'); 
            data.payload.val()['img'] = val.val();
          });
          this.afDB.database.ref('/category/' +  data.payload.val()['chid'] + '/userId').once('value').then(val => 
          { 
          if(this.selfview && val.val() == this.uid)
            data.payload.val()['isMine'] = true;
          else if(!this.selfview && val.val() == this.memid)
            data.payload.val()['isMine'] = true;
          else
            data.payload.val()['isMine'] = false;
          });
          return data.payload.val();
        })
      }))
      .subscribe(items => {
        if (items.length) {
          this.favlist = items;
          // console.log(this.favlist);
        }
      });
  }

  

  editname(user, ip) {
    if (this.nicklimit > 0) {
      if (user.nameedit) {
        user.nameedit = false;
      } else {
        user.nameedit = true;
        setTimeout(_ => {
          ip.setFocus();
        }, 50);
      }
    } else {
      this.presentToast('닉네임 변경 횟수가 부족 합니다.');
    }
  }

  removefield(ip) {
    // console.log(nameinput.el.value);
    ip.el.value = '';
    setTimeout(_ => {
      ip.setFocus()
    }, 50);
  }

  confirmName(event, ip): void {
    if(this.selfview)
    {
    if (event.keyCode === 13 || event.type == 'click') {
      // console.log("this.user.nick = '" + this.usernick + "'");
      // console.log("ip.el.value = '" + ip.el.value + "'");
      if (ip.el.value == '') {
        ip.el.value = this.usernick;
        this.user.nameedit = false;
        this.presentToast('닉네임을 입력 해주세요.');
      } else if(ip.el.value == this.usernick) {
        // console.log('변경 불가: 비었거나 똑같음. 원래닉으로 덮어쓰고 편집 취소');
        ip.el.value = this.usernick;
        this.user.nameedit = false;
        this.presentToast('이전 이름과 똑같습니다.');
      } else if(2 > ip.el.value.length || ip.el.value.length > 12) {
        ip.el.value = this.usernick;
        this.user.nameedit = false;
        this.presentToast('닉네임이 너무 짧거나 깁니다.');
      } else {
        console.log('변경 진행');
        if (!(this.bad_chk(ip.el.value))) {
          // console.log('변경 진행');
          this.afDB.database.ref('/userProfile').orderByChild('nicknamelower').equalTo(ip.el.value.toLowerCase()).once('value').then(nick => {
            if (nick.val()) {
              this.nickvail = false;
              var inuse;
              this.translate.get('already in used').subscribe(res => {
                inuse = res;
              });
              this.presentToast(ip.el.value + inuse);
            } else {
              this.nickvail = true;
              this.usernick = ip.el.value;
              this.afDB.database.ref('/userProfile/' + this.uid + '/nickname').set(ip.el.value);
              this.afDB.database.ref('/userProfile/' + this.uid + '/nicknamelower').set(ip.el.value.toLowerCase());
              this.afDB.database.ref('/userProfile/' + this.uid + '/nicklimit').set(this.nicklimit - 1);
              this.user.nick = ip.el.value;
              this.user.nameedit = false;
            }
          })
        } else {
          this.presentToast(ip.el.value + '는 사용 불가능한 이름 입니다.');
        }

      }
    }
  }
  }

  bad_chk(str) { // 욕 필터링 , 스크립트 아이프레임도 필터링
    if (str == '') return true;
    var badword, chword, pattern_regex, pattern, emoji_regex, emojis;
    // this.translate.get('badword').subscribe(res => {badword = res});
    // this.translate.get('chword').subscribe(res => {chword = res});
    badword = "'개새끼','개색기','개색끼','개자식','개보지','개자지','개년','개걸래','개걸레','지랄','씨발','씨팔','씹년','씹새끼','씨부랄','씨바랄','씹창','씹탱','씹보지','씹자지','씨방세','씨방새','씨펄','시펄','십탱','섹스','씨박','썅','쌍놈','쌍넘','싸가지','쓰벌','씁얼','상넘이','상놈의','상놈이','상놈을','좆','좃','졸라','존나','존만한','같은년','넣을년','버릴년','부랄년','바랄년','미친년','니기미','니미씹','니미씨','니미럴','니미랄','후레아들','호로새끼','후레자식','후래자식','후라들년','후라들넘','빠구리','sex','fuck','shit','병신'";
    chword = "'admin','system','관리','채널','channel'";
    // pattern_regex = /^[a-zA-Zㄱ-힣0-9]*$/;
    // 중국어 한자 // [\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DB5\u4E00-\u9FEF\uF900-\uFA6D\uFA70-\uFAD9\U00020000-\U0002A6D6\U0002A700-\U0002B734\U0002B740-\U0002B81D\U0002B820-\U0002CEA1\U0002CEB0-\U0002EBE0\U0002F800-\U0002FA1D]
    pattern_regex = '^[a-zA-Zㄱ-힣0-9一-龠ぁ-ゞァ-ヶ々〆〤]*$'; // 영문, 국문, 숫자, 한자, 일문, (중문은 따로 안넣었는데 한자에 간체가 포함되는듯?)
    pattern = str.match(pattern_regex);
    if( pattern == null ) {
      console.log("pattern = " + pattern);
      return true; // str을 match 했을때, 정규표현식 외 문자가 있으면 null이 찍힘
    }
    // emoji_regex = '(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])';
    // emojis = str.toLowerCase().match(emoji_regex);
    // var YokList = (badword + "," + chword + "," + emojis).replace(/\'/g, '').split(",");
    var YokList = (badword + "," + chword).replace(/\'/g, '').split(",");
    var Tmp;
    for (var i = 0; i < YokList.length; i++) {
      Tmp = str.toLowerCase().indexOf(YokList[i]);
      if (Tmp >= 0) {
        console.log("YokList[i] = " + YokList[i]);
        return true;
      }
    }
    //루프를 돌고난 후에도 정상아웃하면 욕없음
    return false;
  }

  confirmBio(user, event, bio): void {
    // console.log(bio.el.value);
    if(this.selfview)
    this.afDB.database.ref('/userProfile/' + this.uid + '/mypage/introstr').set(bio.el.value);

  }

  public chknickname(nickName: string) {
    this.afDB.database.ref('/userProfile').orderByChild('nicknamelower')
    .equalTo(nickName.toLowerCase()).once('value').then(nick => {
      if (nick.val()) {
        this.nickvail = false;
        var inuse;
        this.translate.get('already in used').subscribe(res => {
          inuse = res;
        });
        this.presentToast(nickName + inuse);
      } else
        this.nickvail = true;
    })
  }



  /*
    public changenickname()
    {
      this.translate.get('Please fill in new nick name').subscribe(res=>{
        this.changenickmsg = res;
        });
      var cancelbtn, confirmbtn;
      this.translate.get('Confirm').subscribe(res=>{
      confirmbtn = res;
      });
      this.translate.get('Cancel').subscribe(res=>{
        cancelbtn = res;
      });
      let alert = this.alertCtrl.create({
      message: this.changenickmsg,
      inputs: [{name: 'ipnickname', id: 'ipnickname'}
      ],
      buttons: [
          {
          text: cancelbtn,
          role: 'cancel'
          },
          {
          text: confirmbtn,
          handler: (data) => {
                          if(this.bad_chk(data.ipnickname))
                          {
                            var badwordsmsg, confirmbtn;
                            this.translate.get('Confirm').subscribe(res=>{
                            confirmbtn = res;
                            });
                            this.translate.get('thereisawordcannotbeused').subscribe(res=>{
                              badwordsmsg = res;
                            });
                            let alertnono = this.alertCtrl.create({
                                  message: badwordsmsg,
                                  buttons: [confirmbtn]
                                });
                                alertnono.present();
                                return;
                          }
                          else
                            {
                                this.chknickname(data.ipnickname);
                                if(this.nickvail)
                                  {
                                    this.afDB.database.ref('/userProfile/' + this.uid).update({nickname:data.ipnickname, nicknamelower:data.ipnickname.toLowerCase(), nicklimit:this.nicklimit-1});
                                    var chnagednickname;
                                    this.translate.get('nicknamehaschanged').subscribe(res=>{
                                    chnagednickname = res;
                                    });
                                    this.presentToast('bottom',  data.ipnickname + chnagednickname);  
                                  }      
                                else
                                  {
                                    var nickinuse, retrybtn, cancelbtn, tryother;
                                    this.translate.get('nicknameinuse').subscribe(res=>{
                                    nickinuse = res;
                                    });
                                  this.translate.get('Retry').subscribe(res=>{
                                    retrybtn = res;
                                    });
                                    this.translate.get('Cancel').subscribe(res=>{
                                    cancelbtn = res;
                                    });
                                    this.translate.get('Would you like to retry with other nick name').subscribe(res=>{
                                      tryother = res;
                                    });
                                    let erralert = this.alertCtrl.create({
                                            title: nickinuse,
                                            message: tryother,
                                            buttons: [
                                                {
                                                text: cancelbtn,
                                                role: 'cancel'
                                                },
                                                {
                                                text: retrybtn,
                                                handler: () => {
                                                    this.changenickname();//alert.present();
                                                }
                                                }
                                            ]
                                            }); 
                                    erralert.present();
                                  }

                            }
                            }
          }
      ]
      });
      alert.present().then(v => {
      let id = <HTMLInputElement>document.getElementById('ipnickname');
       if (id) {
        var timeout = null;
        id.addEventListener('keyup', (e:Event) => {
          clearTimeout(timeout);
          timeout = setTimeout(()=>
          this.chknickname(id.value), 500)
        });
     }
    });
    }*/

  async htmlupload(fileinput: HTMLInputElement) {
    if (fileinput.files) {
      var file: File = fileinput.files[0];

      this.loading = true;

      await this.imgupload.readfile(file).then(rst => {
        this.imgupload.imgupload(file, 'profile')
          .then(imgrst => {
            this.afDB.database.ref('/userProfile/' + this.uid + '/profilePicture').set({
              path: imgrst[0]['path'],
              url: imgrst[0]['url']
            });
            this.afDB.database.ref('/userProfile/' + this.uid + '/profileThumbPicture').set({
              path: imgrst[1]['path'],
              url: imgrst[1]['url']
            });
          })
      })
    }
  }

  remove(a,b){}

  async removeimg() {
    let alert = await this.alertCtrl.create({
      message: '프로필 사진을 삭제하시겠습니까?',
      buttons: [{
        text: '취소',
        role: 'cancel'
      }, {
        text: '예',
        handler: () => {
          let mainimg = this.afDB.database.ref('/userProfile/' + this.uid + '/profilePicture');
          let thumbimg = this.afDB.database.ref('/userProfile/' + this.uid + '/profileThumbPicture');

          mainimg.once('value').then(mi => {
            if (mi.val().path)
              firebase.storage().ref().child(mi.val().path).delete()
          }).then(_ => mainimg.remove())
          thumbimg.once('value').then(ti => {
            if (ti.val().path)
              firebase.storage().ref().child(ti.val().path).delete()
          }).then(_ => thumbimg.remove())
          this.user.img = '';
          this.presentToast('프로필 사진이 삭제되었습니다.');

          //this.afDB.database.ref('/userProfile/' + this.uid + '/profilePicture').remove();
          //this.afDB.database.ref('/userProfile/' + this.uid + '/profileThumbPicture').remove();

        }
      }]
    });
    return await alert.present();
  }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2000
    });
    return await toast.present();
  }


  async openchannel(chkey) {
    const modal = await this.modalCtrl.create({
      component: ChmainPage,
      componentProps: < null > {
        chkey: chkey, modal:true
      },
      cssClass: 'modalfull'
    });
    return await modal.present();
  }

  async showpage(id, loclancode) {
    const modal = await this.modalCtrl.create({
      component: ChviewPage,
      componentProps: < null > {
        id: id,
        ignore: true,
        loclancode: loclancode, 
        modal:true
      },
      cssClass: 'modalfull'
    });
    return await modal.present();
  }

  async showmodal(page) {
    let pagename;
    let type = null;
    switch (page) {
      case 'setaccount':
        pagename = SetaccountPage;
        break;
    }

    const modal = await this.modalCtrl.create({
      component: pagename,
      componentProps: < null > {
        mv: true,
        type: type
      },
      cssClass: 'modalfull'
    });
    return await modal.present();
  }



  // ------------------------------------------- charts
  // Doughnut

  // public doughnutChartLabels: Label[] = ['채널', '쉐어', '장소', '구인', '매매'];
  public doughnutChartLabels: any[] = [];
  // public doughnutChartData: number[] = [3, 5, 1, 3];
  public doughnutChartData: number[] = this.actchannArr;
  public doughnutChartType: ChartType = 'doughnut';
  // public pieChartLegend = true;
  // public pieChartPlugins = [pluginDataLabels];
  public doughnutChartColors = [{
    backgroundColor: ['rgba(138,165,227,1)', 'rgba(100,221,197,1)', 'rgba(255,208,118,1)', 'rgba(255, 142, 124, 1)', '#dddddd'],
  }, ];


  // PolarArea
  public polarAreaChartLabels: Label[] = ['게시물', '댓글', '좋아요', '초대'];
  // public polarAreaChartData: SingleDataSet = [10, 12, 51, 0];
  // public polarAreaChartData: SingleDataSet = this.lengthArr;
  public polarAreaChartData: number[] = this.lengthArr;
  public polarAreaLegend = true;

  public polarAreaChartType: ChartType = 'polarArea';
  public polarAreaColors = [{
    backgroundColor: ['rgba(138,165,227,1)', 'rgba(100,221,197,1)', 'rgba(255,208,118,1)', 'rgba(255, 142, 124, 1)'],
  }, ];

  // public polarAreaChartOptions: any = {
  //   responsive: false,
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //       font: {
  //         size: 11,
  //       }
  //     }
  //   }
  // };

  // events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent,
    active: {} []
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent,
    active: {} []
  }): void {
    console.log(event, active);
  }

  public options: any = {
    layout: {
      padding: {
        // left: 20,
        // right: 20
      }
    },
    legend: { 
      position: 'bottom',
      labels: {
        padding: 20,
        boxWidth: 12
      }
    }
  }

  slideOpt = {
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  };


  setprivate(val) {
    // console.log(event.detail.checked);
    // this.afDB.database.ref('/userData/' + this.uid + '/private').set(event.detail.checked);
  }

  setPrivate (target){
    if( target == 'chnpost' ) {
      this.chnpost = true;
      this.afDB.database.ref('/userData/' + this.uid + '/private/chnpost').set(true);
      this.presentToast('채널 및 작성글을 비공개 처리합니다.');
    } else if( target == 'actgraph' ) {
      this.actgraph = true;
      this.afDB.database.ref('/userData/' + this.uid + '/private/actgraph').set(true);
      this.presentToast('내 활동 그래프를 비공개 처리합니다.');
    }
  }
  setPublic (target){
    if( target == 'chnpost' ) {
      this.chnpost = false;
      this.afDB.database.ref('/userData/' + this.uid + '/private/chnpost').set(false);
      this.presentToast('채널 및 작성글을 모두에게 공개합니다.');
    } else if( target == 'actgraph' ) {
      this.actgraph = false;
      this.afDB.database.ref('/userData/' + this.uid + '/private/actgraph').set(false);
      this.presentToast('내 활동 그래프를 모두에게 공개합니다.');
    }
  }


}