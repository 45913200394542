import { Component, OnInit, Input } from '@angular/core';
import { PopoverController,NavParams } from '@ionic/angular';

@Component({
  selector: 'app-membermenu',
  templateUrl: './membermenu.component.html',
  styleUrls: ['./membermenu.component.scss']
})
export class MembermenuComponent implements OnInit {
selfview;
constructor(public popover:PopoverController, private navParams:NavParams){
  
}
ngOnInit()
    {}
submit(val){
  this.popover.dismiss({value:val});
}
}
