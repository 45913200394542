import { Component, HostListener, OnInit, Optional, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVars } from '../../../providers/globalVars';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NavController, PopoverController, ModalController, IonRouterOutlet, AlertController, ToastController, Platform, IonContent } from '@ionic/angular';
import { ChmenuComponent } from '../../../component/chmenu/chmenu.component';
import { InfomodalComponent } from './infomodal/infomodal.component';
import { PostPage } from '../post/post.page';
import { ChPage } from '../../admin/ch/ch.page';
import { ChaddPage } from '../chadd/chadd.page';
import { SearchPage } from '../search/search.page';
import { ChviewPage } from '../chview/chview.page';
import { sharefunc } from '../../../providers/sharefunc';
import { msgfunc } from '../../../providers/msgfunc';
import 'rxjs/add/operator/map';
import { filter } from 'rxjs/operators';
import { MetaService } from 'ng2-meta';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { OrderByPipe } from '../../../pipe/orderbypipe';
import { Http } from '@angular/http';
import * as xml2js from "xml2js";

@Component({
  selector: 'app-chmain',
  templateUrl: './chmain.page.html',
  styleUrls: ['./chmain.page.scss'],
})
export class ChmainPage implements OnInit {
  loaddone: boolean = false;
  category: any[] = [];
  chinfo: any;
  postform: any = null;

  chname: any;
  chdesc: any;

  currentuid: any;
  mych: boolean = false;
  fav: boolean = false;
  list;
  listitem: any[] = [];
  placehelp: any[] = [];
  premiumstate: boolean = false;
  viewtype: string;
  authState: boolean = false;
  selectedcatid: string = "";
  selectedcatname: string = "";
  blockuser: string = '';


  requestreload: any = false;
  timenow: any = new Date().getTime();
  places: any[] = [];
  itemsetting: any;

  typemainlist: any[] = [];
  typesublist: any[] = [];
  typemain: any = "All";
  typesub: any = "All";
  loaditemcnt: number = 10;
  randomrec: any;
  innerWidth: any;
  ch: any;
  ordertype: any = 'createdDate';
  menuselect: any = 'all';

  itemloc: any;

  imagethumbs: any = [];
  tabview: any = false;
  chkey;
  chowner: any;

  scrVal: any;
  lastScrVal: any;
  modal;
  searchId: any = '';

  loading: boolean = true;

  mapview: any; // 맵뷰 타입 : place or house 차후 추가
  nearme: any = { eat: 0, travel: 0, living: 0, shopping: 0, culture: 0 };
  nearmestr: any = {};
  nearmetypemain: any[] = [];
  nearchecked: boolean = false;
  geoblocked: boolean = false;
  postxt: string;
  randompos: any[] = [];
  noposincity: boolean = false;
  loclan: any;
  slideOpts = {
    autoplay: { delay: 5000 }, speed: 500, loop: true,
    zoom: false,
    // on: {
    //   beforeInit() {
    //     const swiper = this;
    //     swiper.classNames.push(`${swiper.params.containerModifierClass}flip`);
    //     swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
    //     const overwriteParams = {
    //       slidesPerView: 1,
    //       slidesPerColumn: 1,
    //       slidesPerGroup: 1,
    //       watchSlidesProgress: true,
    //       spaceBetween: 0,
    //       virtualTranslate: true,
    //     };
    //     swiper.params = Object.assign(swiper.params, overwriteParams);
    //     swiper.originalParams = Object.assign(swiper.originalParams, overwriteParams);
    //   },
    //   setTranslate() {
    //     const swiper = this;
    //     const { $, slides, rtlTranslate: rtl } = swiper;
    //     for (let i = 0; i < slides.length; i += 1) {
    //       const $slideEl = slides.eq(i);
    //       let progress = $slideEl[0].progress;
    //       if (swiper.params.flipEffect.limitRotation) {
    //         progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
    //       }
    //       const offset$$1 = $slideEl[0].swiperSlideOffset;
    //       const rotate = -180 * progress;
    //       let rotateY = rotate;
    //       let rotateX = 0;
    //       let tx = -offset$$1;
    //       let ty = 0;
    //       if (!swiper.isHorizontal()) {
    //         ty = tx;
    //         tx = 0;
    //         rotateX = -rotateY;
    //         rotateY = 0;
    //       } else if (rtl) {
    //         rotateY = -rotateY;
    //       }

    //       $slideEl[0].style.zIndex = -Math.abs(Math.round(progress)) + slides.length;

    //       if (swiper.params.flipEffect.slideShadows) {
    //         // Set shadows
    //         let shadowBefore = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
    //         let shadowAfter = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
    //         if (shadowBefore.length === 0) {
    //           shadowBefore = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'left' : 'top'}"></div>`);
    //           $slideEl.append(shadowBefore);
    //         }
    //         if (shadowAfter.length === 0) {
    //           shadowAfter = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'right' : 'bottom'}"></div>`);
    //           $slideEl.append(shadowAfter);
    //         }
    //         if (shadowBefore.length) shadowBefore[0].style.opacity = Math.max(-progress, 0);
    //         if (shadowAfter.length) shadowAfter[0].style.opacity = Math.max(progress, 0);
    //       }
    //       $slideEl
    //         .transform(`translate3d(${tx}px, ${ty}px, 0px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
    //     }
    //   },
    //   setTransition(duration) {
    //     const swiper = this;
    //     const { slides, activeIndex, $wrapperEl } = swiper;
    //     slides
    //       .transition(duration)
    //       .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
    //       .transition(duration);
    //     if (swiper.params.virtualTranslate && duration !== 0) {
    //       let eventTriggered = false;
    //       // eslint-disable-next-line
    //       slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
    //         if (eventTriggered) return;
    //         if (!swiper || swiper.destroyed) return;

    //         eventTriggered = true;
    //         swiper.animating = false;
    //         const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
    //         for (let i = 0; i < triggerEvents.length; i += 1) {
    //           $wrapperEl.trigger(triggerEvents[i]);
    //         }
    //       });
    //     }
    //   }
    // }
  };


  thisservice: any[] = [];
  togglemyposts = false;
  titletoggle: boolean = false;
  @ViewChild(IonContent) content: IonContent;

  colorlist = [
    ['#ffd494', '#f6b37f', '#fd835f', '#ff7660', '#f68282', '#f68399', '#ea83c2', '#c481d8', '#a882e8', '#8b90e8'],
    ['#889ae9', '#90aaf5', '#8db7f6', '#6b9eea', '#70b8e6', '#75d3eb', '#75d3eb', '#55e1c8', '#55e19a', '#a7eb88'],
    ['#bde566', '#d2e05f', '#e0e476', '#eddb5f', '#f7ba5b', '#f79d5b', '#f78b5b', '#f7785b', '#fc6555', '#fc6555']
  ];
  colorpick = Math.floor(Math.random() * this.colorlist.length);

  opennotice: boolean = false;

  recentlist;
  postuid;
  constructor(public translate: TranslateService, public gv: GlobalVars, public afDB: AngularFireDatabase, public popoverCtrl: PopoverController,
    @Optional() private outlet: IonRouterOutlet, public afAuth: AngularFireAuth, private route: ActivatedRoute,
    private alertCtrl: AlertController, private toastCtrl: ToastController, private plt: Platform, private metaService: MetaService,
    public router: Router, public navCtrl: NavController, private orderby: OrderByPipe, private msgfunc: msgfunc,
    private http: Http,private fs:AngularFirestore,
    private geolocation: Geolocation, public modalCtrl: ModalController, private sharefunc: sharefunc) {
    this.innerWidth = window.innerWidth;
    this.ch = this.route.snapshot.paramMap.get('ch');
    if (this.route.snapshot.paramMap.get('tab'))
      this.tabview = this.route.snapshot.paramMap.get('tab');
    this.gv.getLoc()
    this.gv.getLan()

    //탭은 onViewDidEnter가 아이오닉에서 자체 예외처리하므로 수동으로 설정
    //탭이 아닐시는 onViewDidEnter에서 실행
    //if(this.tabview)
    //this.onViewEnter();
    //console.log(this.gv.getListDir())

    //if(this.router.url.includes('/tabs/'))
    //  this.tabview = true;
  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.loclan = this.gv.getLoc() + '_' + this.gv.getLan();
    if (`${this.chkey}` != 'undefined')
      this.ch = `${this.chkey}`;

    //데이터 바인딩할때 ios의 push animation이 깜박이므로 getdata 딜레이 줌.
    //if(this.plt.is('ios') && !this.tabview)
    //    setTimeout(_=>this.chkpublic(),380);
    //else
    // console.log(JSON.stringify(this.gv.chPredata))
    this.chkpublic();
    this.currentuid = this.gv.uid;

    setTimeout(_=>{this.gv.chPredata=null},1000)

    if (this.gv.ufavlist) {
      this.gv.ufavlist.forEach(fav => {
        if (fav.chid == this.ch) {
          // console.log(fav.chid);
          this.fav = true;
        }
      })
    }
  }


  /*onViewEnter() {
    this.router.events
    .pipe(
     filter(event => event instanceof NavigationStart)
    ).subscribe(p => {
      if(this.gv.chreloadrequest)
      {
      this.list.unsubscribe();
      this.chkpublic();
      this.gv.chreloadrequest = false;
      }
    })
  }*/

  ionViewWillEnter() {
    this.loading = true;
    setTimeout(_ => this.loading = false, 380);
  }
  ionViewDidEnter() {
    // console.log(this.recentlist);
    if(this.listitem.length)
    {
      for(let i = 0; i < this.listitem.length; i++)
      {
        // console.log(this.listitem[i]['$key'])
        this.listitem[i].haveread = this.chkread(this.listitem[i]['$key']);
        // console.log(this.listitem[i].haveread)
      }
    }
    // else console.log('no listitem')
    if (this.gv.chreloadrequest) {
      if (this.list)
        this.list.unsubscribe();
      this.chkpublic();
      this.gv.chreloadrequest = false;
    }
    this.afDB.database.ref('/category/' + this.ch).once('value').then(view => {
      let data = view.val();
      if (data.mapview)
        this.mapview = data.mapview;
      if (data) {
        this.itemloc = data.loc;
        this.chname = data.name;
        this.chdesc = data.description;
        // console.log('chmain.page viewdidenter = ' + this.chname);
        // console.log('chmain.page viewdidenter = ' + this.chdesc);
        // console.log(this.chname +' / '+ this.chdesc);
        this.metaService.setTitle(this.chname + ' - ' + this.chdesc);
        this.metaService.setTag('og:title', this.chname + ' | Channel');
        this.metaService.setTag('og:description', this.chdesc);
        if (data.images)
          this.metaService.setTag('og:image', data.images[0].url);
        this.chowner = data.userId;
        /*if( data.userId ) {
          this.afDB.database.ref('/userProfile/' + data.userId).once('value').then(user=>{
            if(user.val())
              this.chowner = user.val();
              this.chowner.userId = data.userId;
          });
        }*/
      }
      if (this.mapview) {
        this.getnearst();
      }
    });
  }

  share() {
    if (this.gv.cordova) {
      let chlink, chshare;
      this.translate.get('ChannelLink').subscribe(res => { chlink = res });
      this.translate.get('Share a Channel').subscribe(res => { chshare = res });
      this.sharefunc.share(this.chname, chlink, 'https://channel.cafe/chmain/' + this.ch, this.currentuid, chshare)
        .then(rst => {
          if (rst) {
            let today = new Date();
            let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
            this.afDB.database.ref('/systemlog/' + (this.currentuid ? this.currentuid : 'unregister') + '/event/' + todaystr + '/chshare').transaction(view => {
              return (view || 0) + 1;
            })
          }
        })
    }
    else
      this.translate.get('Copied URL on clipboard.').subscribe(res => {
        this.presentToast(res);
      });

  }
  adminedit() {
    if (this.modal) {
      this.modalCtrl.create({
        component: ChPage,
        cssClass: 'modalfull',
        componentProps: <null>{ ch: this.ch, modal: true }
      }).then(modal => {
        modal.present();
      });
    }
    else
      this.router.navigate(['/admin/ch/' + this.ch]);
  }
  edit() {
    if (this.modal)
      this.editonModal();
    else
      this.router.navigate(['/chadd/' + this.ch]);
    //this.navCtrl.navigateForward(['/chadd/' + this.ch]);
  }
  async editonModal() {
    this.modalCtrl.create({
      component: ChaddPage,
      cssClass: 'modalfull',
      componentProps: <null>{ chkey: this.ch }
    }).then(modal => {
      modal.present();
    });
  }

  delete() {
    if (!this.listitem.length)
      this.deletemsg();
    else {
      this.translate.get("Can't delete because CH has post(s).").subscribe(res => {
        this.presentToast(res);
      });
    }
  }

  async deletemsg() {
    let hd, msg, cancel, confirm;
    this.translate.get("Once you delete Channel, it won't recover. Are you sure to delete?").subscribe(res => { msg = res });
    this.translate.get('Channel Delete confirm').subscribe(res => { hd = res });
    this.translate.get('Cancel').subscribe(res => { cancel = res });
    this.translate.get('Confirm').subscribe(res => { confirm = res });
    const alert = await this.alertCtrl.create({
      header: hd,
      message: msg,
      buttons: [
        {
          text: cancel,
          role: 'cancel'
        },
        {
          text: confirm,
          handler: () => {
            //this.fs.collection('post', ref=>ref.orderBy('categoryId').where)
            this.fs.collection('post', ref=>ref.where("categoryId", "==", this.ch).limit(1))
              .get().subscribe(item => {
                if (item.docs.length)
                  this.translate.get("Can't delete because CH has post(s).").subscribe(res => {
                    this.presentToast(res);
                  });
                else {
                  this.afDB.database.ref('/category/' + this.ch).remove().then(_ => {
                    this.translate.get("Channel has deleted.").subscribe(res => {
                      this.presentToast(res);
                    });
                    this.close();
                  });
                }
              })

          }
        }
      ]
    });
    return await alert.present();
  }

  async showmenu(ev) {
    let predata = [];
    predata['id'] = this.ch;
    //if(this.gv.cordova)
    predata['showshare'] = true;
    if (this.currentuid == this.chowner || this.gv.manager)
      predata['owner'] = true;

    const popover = await this.popoverCtrl.create({
      component: ChmenuComponent,
      componentProps: <null>predata,
      //mode:'ios',
      cssClass: 'popovermenu',
      event: ev,
      showBackdrop: false
    });
    popover.onDidDismiss().then(rst => {
      if (rst.data) {
        switch (rst.data.value) {
          case 'share':
            this.share();
            break;
          case 'edit':
            this.edit();
            break;
          case 'delete':
            this.delete();
            break;
          case 'adminedit':
            this.adminedit();
            break;
          default: break;
        }
      }
    })
    return await popover.present();
  }

  favclick() {
    if (this.currentuid) {
      this.afDB.database.ref('/userData/' + this.currentuid + '/favlist').once('value').then(fav => {
        let exist = '';
        //let isChecked;
        if (fav.val()) {
          fav.forEach(favitem => {
            if (favitem.val().chid == this.ch) {
              exist = favitem.key;
              //isChecked = favitem.val().isChecked;
            }
          })
          if (exist) {
            /*
          if(isChecked)
          {
            this.alertCtrl.create({
            header: '메뉴에 있는 항목입니다.',
            message: '해당 채널은 상단메뉴에 지정된 항목입니다.<br>즐겨찾기를 해제하시면 상단메뉴에서도 제외됩니다.<br><br>즐겨찾기 해제하시겠습니까?',
            buttons: [
              {
                text: '취소',
                role: 'cancel'
              },
              {
                text: '확인',
                handler: () => {
                  this.afDB.database.ref('/userData/' + this.currentuid + '/favlist/' + exist).remove().then(_=>{
                  this.presentToast('즐겨찾기 채널에서 삭제되었습니다.');
                  })
                  this.fav = false;
                }
              }
            ] 
          }).then(_=>_.present())
          }
          else
          {*/
            this.afDB.database.ref('/userData/' + this.currentuid + '/favlist/' + exist).remove().then(_ => {
              this.translate.get("Removed Channel from favorite CH").subscribe(res => {
                this.presentToast(res);
              });
            })
            this.fav = false;
            //}
          }
          else {
            this.afDB.database.ref('/userData/' + this.currentuid + '/favlist').push({
              chid: this.ch, alarm: true,
              loc: this.itemloc,
              isChecked: true
            }).then(_ => {
              this.translate.get("Added Channel to favorite CH").subscribe(res => {
                this.presentToast(res);
              });
            })
            this.fav = true;
          }
        }
        else {
          let data = [];
          data.push({
            chid: this.ch, alarm: true,
            loc: this.itemloc,
            isChecked: true
          });
          /*
          this.afDB.database.ref('/app/defaulttab/' + this.loclan).once('value').then(dffav => {
            dffav.forEach(fav => {
              if (!fav.val().default)
                data.push({
                  title: fav.val().name,
                  isChecked:true,
                  chid:fav.val().val.substr(8).replace('/t','')
                });
            })*/

          this.afDB.database.ref('/userData/' + this.currentuid + '/favlist').set(data)
            .then(_ => {
              this.translate.get("Added Channel to favorite CH").subscribe(res => {
                this.presentToast(res);
              });
            })
          this.fav = true;
          //})
        }
      })
    }
    else {
      this.translate.get("Please log in to proceed").subscribe(res => {
        this.presentToast(res);
      });
    }
  }

  chkpublic() {
    this.afDB.database.ref('/category/' + this.ch + '/publiccommunity').once('value').then(pub => {
      let publiccommunity = pub.val();
      if (publiccommunity) {
        this.getdata();
      } else {
        let prepwd;
        if (this.currentuid) {
          this.afDB.database.ref('/userProfile/' + this.currentuid + '/community').once('value').then(savedcom => {
            if (savedcom.val())
              savedcom.forEach(element => {
                if (element.val().id == this.ch)
                  prepwd = element.val().pwd
              });
          }).then(_ => {
            this.afDB.database.ref('/category/' + this.ch + '/password').once('value').then(pwd => {
              if (pwd.val() == prepwd) {
                this.getdata();
              } else if (prepwd)
                this.callpwdalert(this.ch, true);
              else
                this.callpwdalert(this.ch);
            })
          })
        }

        else {
          this.callpwdalert(this.ch)
        }
      }
    })
  }

  async callpwdalert(id, type?) {
    var fillinpwd, leave, pwdchanged, donebl, pwdnotmatched, retry;
    this.translate.get('Please fill in the password').subscribe(res => {
      fillinpwd = res;
    });
    this.translate.get('Leave').subscribe(res => {
      leave = res;
    });
    this.translate.get('Password has changed new pwd required').subscribe(res => {
      pwdchanged = res;
    });
    this.translate.get('Doneblank').subscribe(res => {
      donebl = res;
    });
    this.translate.get('Password does not match').subscribe(res => {
      pwdnotmatched = res;
    });
    this.translate.get('Retry').subscribe(res => {
      retry = res;
    });
    var msg = fillinpwd;
    if (type)
      msg = pwdchanged;
    let alert = await this.alertCtrl.create({
      message: msg,
      inputs: [{ name: 'pwd' }],
      buttons: [
        {
          text: leave,
          role: 'cancel',
          handler: () => {
            this.navCtrl.back();
          }
        },
        {
          text: donebl,
          handler: (data) => {
            this.afDB.database.ref('/category/' + this.ch + '/password').once('value').then(pwd => {
              if (pwd.val() == data.pwd) {
                // 비밀번호가 일치하면 해당 비밀번호 저장
                if (this.currentuid) {
                  var existitem = false;
                  var comkey;
                  this.afDB.database.ref('/userProfile/' + this.currentuid + '/community').once('value').then(com => {
                    if (com.val())
                      com.forEach(element => {
                        if (element.val().id == id) {
                          comkey = element.key;
                          existitem = true;
                        }
                      });
                  }).then(rst => {
                    if (existitem) // 기존에 존재하면 업데이트, 아니면 푸시
                      this.afDB.database.ref('/userProfile/' + this.currentuid + '/community/' + comkey).update({ pwd: data.pwd });
                    else
                      this.afDB.database.ref('/userProfile/' + this.currentuid + '/community').push({ id: id, pwd: data.pwd });
                  })
                }
                this.getdata();
              }
              else {
                this.alertCtrl.create({
                  message: pwdnotmatched,
                  buttons: [
                    {
                      text: leave,
                      role: 'cancel',
                      handler: () => {
                        this.navCtrl.back();
                      }
                    },
                    {
                      text: retry,
                      handler: (data) => {
                        this.callpwdalert(id);
                      }
                    }]
                }).then(_ => _.present())
              }
            })


          }
        }
      ]
    });
    return alert.present();
  }

  filtermyposts() {
    if (this.searchId) {
      this.searchId = '';
      this.togglemyposts = false;
    } else {
      this.searchId = this.currentuid;
      this.togglemyposts = true;
    }
  }

  checkTime(i) {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
  }
  showtimer() {
    var today = new Date();
    var h = today.getHours();
    var m = today.getMinutes();
    var s = today.getSeconds();
    m = this.checkTime(59 - m);
    s = this.checkTime(59 - s);
    document.getElementById('spanendtxt').innerHTML = (23 - h) + ":" + m + ":" + s;
    var t = setTimeout(_ => this.showtimer(), 1000);
  }

  chkread(key){
    let chk = '';
    for(let i = 0; i < this.recentlist.length; i++)
    {if(this.recentlist[i].itemid == key)
    { chk = 'haveread'; break;}}
    return chk;
  }

  getdata() {

    // 아래 테스트용
    //this.ch = '-Lj-yCBRYPNBImL4KYv0';

    // 위까지 지워야함

    //방문확인 시작.. 채널이름이 있으면 존재하는 채널로 규정
    this.afDB.database.ref('/category/' + this.ch + '/name').once('value').then(name => {
      if (name.val()) {
        let recentvisit = this.gv.getvisitedch();
        let visited = false;
        let todaychkstr = new Date().toLocaleDateString();
        recentvisit = recentvisit.slice(0, 100);
        recentvisit.forEach(visititem => {
          if ((new Date(visititem.createdDate).toLocaleDateString() == todaychkstr) && (visititem.chid == this.ch))
            visited = true;
        })
        if (!visited) {
          this.afDB.database.ref('/category/' + this.ch + '/chinfo/visit').transaction(current => {
            return (current || 0) + 1;
          })
          this.afDB.database.ref('/category/' + this.ch + '/chinfo/visitdata/' + todaychkstr.replace(/. /g, '-').replace('.', '') + '/visit').transaction(current => {
            return (current || 0) + 1;
          }).then(_ => {
            //  30개 기록만 저장
            this.afDB.database.ref('/category/' + this.ch + '/chinfo/visitdata').once('value').then(visitday => {
              if (Object.keys(visitday).length > 30)
                this.afDB.database.ref('/category/' + this.ch + '/chinfo/visitdata/' + Object.keys(visitday)[0]).remove();
            })
          })
        }
        this.gv.addvisitedch(this.ch);
      }
    })

    //방문확인 끝
    this.requestreload = true;
    this.gv.chreloadrequest = false;

    this.randomrec = 1 + Math.floor(Math.random() * 5);
    this.afDB.database.ref('/category/' + this.ch).once('value').then(rst=>{
      this.chinfo = rst.val();
    })
    //object('/category/' + this.ch).snapshotChanges();
    this.afDB.database.ref('/category/' + this.ch + '/groupid').once('value').then(rst => {
      if (rst.val() && rst.val().split('_').length > 1) {
        this.getservice(rst.val().split('_')[0] + '_' + rst.val().split('_')[1]);
      }
    })
    this.afDB.database.ref('/category/' + this.ch + '/chtype').once('value').then(view => {
      if (view.val()) {
        this.viewtype = view.val();
        //console.log("chtype true - this.viewtype = " + this.viewtype);
        if (this.viewtype == 'easyview')
          this.afDB.database.ref('/category/' + this.ch + '/viewsetting').once('value').then(viewsetting => {
            this.itemsetting = viewsetting.val() ? viewsetting.val() : null;
          })
        if (this.viewtype == 'doublecat')
          this.afDB.database.ref('/category/' + this.ch + '/viewsetting').once('value').then(viewsetting => {
            if (viewsetting.val() && viewsetting.val().orderrandom)
              this.ordertype = 'rateorder';
            else
              this.ordertype = 'createdDate';
          })
        /*else if(this.viewtype == 'groupbuying')
          this.showtimer();*/
      } else {
        this.viewtype = 'short';
        //console.log("chtype false - this.viewtype = " + this.viewtype);
      }
    }).then(_ => {
      // this.chlistdir = '/testlist';
      this.recentlist = this.gv.getrecent();
      this.list = this.fs.collection('post', 
        ref=>ref.where("categoryId","==",this.ch)//.orderBy("createdDate", "desc").limit(30)
      ).snapshotChanges()
        .subscribe(Items => {
        this.loaddone = true;
        if (this.requestreload) {
          this.listitem = Items.map((Items) => {
            return Items;
          }).map((_Items) => {
            let Items = _Items.payload.doc.data();
            Items['$key']=_Items.payload.doc.id;
            Items['haveread'] = this.chkread(_Items.payload.doc.id);
            if ((this.timenow - Items['createdDate']) / 1000 / 60 / 60 / 24 < 7) {
              Items['rateorder'] = 500;
            } else if (Items['rateorder']) {
              Items['rateorder'] = (Math.random() * 120) + Items['rateorder']
            } else
              Items['rateorder'] = Math.random() * 120
            if (Items['recorder']) {
              Items['recorder'] = (Math.random() * 120) + Items['recorder']
            } else {
              Items['recorder'] = Math.random() * 120
            }
            Items['timediff'] = (new Date().getTime() - Items['createdDate']) / 1000 / 60 / 60 / 24;

            if (Items['itemtype'] && !Items['category'])
              Items['category'] = Items['itemtype'];
            //if(!Items.shortdesc && Items['formdata'] && Items['formdata'].fieldlist['2'] &&Items['formdata'].fieldlist['2'].value)
            //  Items.shortdesc = Items['formdata'].fieldlist['2'].value;
            this.afDB.database.ref('/userProfile/' + Items['userId'] + '/sum').once('value').then(nn => {
              // console.log(nn.val())
              Items['userimg'] = nn.val()['img'] ? nn.val()['img'] : null;
              Items['nickname'] = nn.val()['nick'];
            });
            this.afDB.database.ref('/list/' + _Items.payload.doc.id + '/read').once('value').then(nn => {
              Items['read'] = nn.val() ? nn.val() : 0;
            });
            if (this.viewtype == 'doublecat') {
              if (!Items['typemain']) {
                Items['typemain'] = Items['category'].split(' - ')[0];
                Items['typesub'] = Items['category'].split(' - ')[1] ? Items['category'].split(' - ')[1] : '';
              }
              //if(!Items.shortdesc)
              //  Items.shortdesc = Items['formdata'].fieldlist['2'].value
            }
            if (this.viewtype == 'groupbuying') {
              Items['finishsoon'] = false;
              Items['isGoods'] = false;
              // console.log(Items);
              if (Items['formdata'] && Items['formdata'].fieldlist) {
                Items['isGoods'] = true;
                if (Items['timediff'] < 150 && Items['formdata'].fieldlist[6]) {
                  let todaystr;
                  var today = new Date();
                  var d = today.getDate();
                  var m = today.getMonth() + 1;
                  var y = today.getFullYear();

                  var montharray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                  if (Items['formdata'].fieldlist[6].value.split(',').length > 1) // 영어
                  {
                    todaystr = montharray[m - 1] + '/' + d;
                    if (Items['formdata'].fieldlist[6].value.split(',')[1] == todaystr)
                      Items['finishsoon'] = true;
                  } else {
                    let monthstr, daystr;
                    this.translate.get('Monthstr').subscribe(res => { monthstr = res });
                    this.translate.get('Daystr').subscribe(res => { daystr = res });
                    todaystr = m + monthstr + d + daystr;
                    if (Items['formdata'].fieldlist[6].value.split('(')[0] == todaystr) {
                      Items['finishsoon'] = true;
                    }

                    var endM = Items['formdata'].fieldlist[6].value.split('월')[0];
                    var endD = Items['formdata'].fieldlist[6].value.split('월')[1].split('일')[0];

                    var createdY = new Date(Items['createdDate']).getFullYear();
                    var createdM = new Date(Items['createdDate']).getMonth() + 1;
                    var endY = createdY;
                    if (createdM > endM) endY = createdY + 1;
                    Items['closediff'] = (today.getTime() - new Date(montharray[endM - 1] + ' ' + endD + ' ' + endY).getTime()) / 1000 / 60 / 60 / 24;
                  }
                }
              }
            }
            /*
            if(Items['category'].split(' - ').length > 1) {
              console.log(Items.$key);
              console.log(Items['category'].split(' - ')[0])
              console.log(Items['category'].split(' - ')[1])
              this.afDB.database.ref(this.chlistdir + '/' + Items.$key).update({'typemain':Items['category'].split(' - ')[0]})
              this.afDB.database.ref(this.chlistdir + '/' + Items.$key).update({'typesub':Items['category'].split(' - ')[1]})
            }*/
            return Items;
          });
          // console.log(this.listitem);
          // console.log('this.typemain = ' + this.typemain);
          // console.log('this.typesub = ' + this.typesub);
          this.requestreload = false;
          if (this.mapview)
            this.getnearst();
        }
      });
      let today = new Date();
      let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
      this.afDB.database.ref('/systemlog/' + (this.currentuid ? this.currentuid : 'unregister') + '/event/' + todaystr + '/chmain').transaction(view => {
        return (view || 0) + 1;
      })
    });

    //this.getinsta();
  }
  //instadata:any = [];
  openPage(data) {
    // console.log(data);
  }
  /*
  getinsta(){
    this.http.get("https://stark-sands-51177.herokuapp.com/http://fetchrss.com/rss/5e1296b28a93f83f588b45675e12968b8a93f85b588b4567.xml")
    .subscribe(res => {
      xml2js.parseString(res.text(),  (err, result)=> {
        console.log(result);
        result.rss.channel[0].item.forEach(item=>{
          this.instadata.push({
            title:item.title[0],
            link:item.link[0],
            description:item.description[0],
            createdDate:new Date(item.pubDate[0]).getTime(),
            imgurl:item['media:content']?item['media:content'][0].$.url:null,
            writer:item['dc:creator'][0]
          })
      });
      });  
    }, err=>{console.log(err)})
  }*/

  jumppos(slide) {
    slide.getActiveIndex().then(index => {
      if (index == 0)
        index = this.randompos.length;
      else if (index > this.randompos.length)
        index = 1;
      this.gonearme(this.randompos[index - 1].typemain ? this.randompos[index - 1].typemain : '');
    })
  }
  gonearme(typemain?) {
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
    this.afDB.database.ref('/systemlog/' + (this.currentuid ? this.currentuid : 'unregister') + '/event/' + todaystr +
      (typemain != 'house' ? '/mapplace' : '/maphouse')).transaction(view => {
        return (view || 0) + 1;
      })
    this.gv.param['mapdata'] = this.listitem;
    this.router.navigate(['/listmap', { type: this.mapview, center: this.postxt, typemain: (typemain ? typemain : '') }]);
  }

  getnearst() {
    this.geolocation.getCurrentPosition().then((position) => {
      this.geoblocked = false;
      this.translate.get('Near me').subscribe(res => { this.postxt = res });
      //this.postxt = "내 근방";
      this.searchnearme(position.coords.latitude, position.coords.longitude);
    }, (err) => {
      this.geoblocked = true;
      //this.nearchecked = true;
      this.translate.get('In City').subscribe(res => { this.postxt = res });
      //this.postxt = "시티";
      if (this.gv.loc == 'brisbane')
        this.searchnearme(-27.46794, 153.02809);
      else
        this.searchnearme(-16.922992, 145.773275);
    })
  }
  searchnearme(lat, lan) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0;
    var curlat = lat
    var curlng = lan

    this.nearme = { eat: 0, travel: 0, living: 0, shopping: 0, culture: 0 };
    var p_eat, p_travel, p_living, p_shopping, p_culture
    this.translate.get('Eat').subscribe(res => {
      p_eat = res;
    });
    this.translate.get('Trip').subscribe(res => {
      p_travel = res;
    });
    this.translate.get('Living').subscribe(res => {
      p_living = res;
    });
    this.translate.get('Shopping').subscribe(res => {
      p_shopping = res;
    });
    this.translate.get('Culture').subscribe(res => {
      p_culture = res;
    });
    this.listitem.forEach(item => {

      if ((item.lat && item.lng) || (item.formdata && item.formdata['fieldlist']['4']))// || (this.datenow - item.createdDate)/1000/60/60/24 < 50)
      {
        if (item.formdata['fieldlist']['4']) {
          item.lat = item.formdata['fieldlist']['4'].lat;
          item.lng = item.formdata['fieldlist']['4'].lng;
        }
        let distancecalc = 0.5 - c((curlat - item.lat) * p) / 2 +
          c(item.lat * p) * c(curlat * p) *
          (1 - c((curlng - item.lng) * p)) / 2;
        var distance = 12742 * Math.asin(Math.sqrt(distancecalc));
        //console.log(item.title + ":" + Math.round(distance * 1000) + 'm')
        if (distance <= 0.5) {
          this.nearmestr = { p_eat, p_travel, p_living, p_shopping, p_culture };
          switch (item.typemain) {
            case p_eat: this.nearme.eat++; break;
            case p_travel: this.nearme.travel++; break;
            case p_living: this.nearme.living++; break;
            case p_shopping: this.nearme.shopping++; break;
            case p_culture: this.nearme.culture++; break;
            //default:;
          }
          //var randomno = Math.floor(Math.random() * 5);
          /*
         this.randompos[0] = p_eat + ' ' + this.nearme.eat; this.nearmetypemain[0] = p_eat;
         this.randompos[1] = p_travel + ' ' + this.nearme.travel; this.nearmetypemain[1] = p_travel;
         this.randompos[2] = p_living + ' ' + this.nearme.living; this.nearmetypemain[2] = p_living;
         this.randompos[3] = p_shopping + ' ' + this.nearme.shopping; this.nearmetypemain[3] = p_shopping;
         this.randompos[4] = p_culture + ' ' + this.nearme.culture; this.nearmetypemain[4] = p_culture;
         /*
           /*
           switch(item.val().type){
           case 'place': this.nearme.place++; break;
           case 'house': this.nearme.house++; break;
           case 'work': this.nearme.work++; break;
           default:this.nearme.other++; break;
           }*/
        }
      }
      //}
    })

    if (this.nearme.eat)
      this.randompos.push({ icon: 'map_eat.png', txtcolor: '#ff9069', txt: p_eat + ' ' + this.nearme.eat, typemain: p_eat, order: Math.random() * 100 });
    if (this.nearme.travel)
      this.randompos.push({ icon: 'map_travel.png', txtcolor: '#62b1ff', txt: p_travel + ' ' + this.nearme.travel, typemain: p_travel, order: Math.random() * 100 });
    if (this.nearme.living)
      this.randompos.push({ icon: 'map_living.png', txtcolor: '#ffd271', txt: p_living + ' ' + this.nearme.living, typemain: p_living, order: Math.random() * 100 });
    if (this.nearme.shopping)
      this.randompos.push({ icon: 'map_shopping.png', txtcolor: '#b36bd6', txt: p_shopping + ' ' + this.nearme.shopping, typemain: p_shopping, order: Math.random() * 100 });
    if (this.nearme.culture)
      this.randompos.push({ icon: 'map_culture.png', txtcolor: '#5a82e0', txt: p_culture + ' ' + this.nearme.culture, typemain: p_culture, order: Math.random() * 100 });
    this.nearchecked = true;
    let citystr;
    this.translate.get('In City').subscribe(res => { citystr = res });
    if (this.postxt == citystr && this.randompos.length == 0)
      this.noposincity = true;
    if (!this.noposincity && !this.geoblocked && !this.randompos.length) {
      this.postxt = citystr;
      if (this.gv.loc == 'brisbane')
        this.searchnearme(-27.46794, 153.02809);
      else
        this.searchnearme(-16.922992, 145.773275);
    }
    else
      this.randompos = this.orderby.transform(this.randompos, 'order', 'desc');
  }

  async modalChannInfo(name, desc, imgval) {
    this.modalCtrl.create({
      component: InfomodalComponent,
      cssClass: 'eventPopup',
      componentProps: <null>{
        name: name,
        desc: desc,
        imgval: imgval
      }
    }).then(modal => {
      modal.present();
    });
  }

  gosearch() {

  }

  post(ch) {
    if (this.modal) {
      this.modalCtrl.create({
        component: PostPage,
        cssClass: 'modalfull',
        componentProps: <null>{
          chid: ch,
          modal: true
        }
      }).then(modal => {
        modal.present();
      });
    }
    else
      this.navCtrl.navigateForward('/post/' + ch);
  }

  openSearch() {
    if (this.modal) {
      this.modalCtrl.create({
        component: SearchPage,
        cssClass: 'modalfull',
        componentProps: <null>{
          id: this.ch,
          modal: true
        }
      }).then(modal => {
        modal.present();
      });
    }
    else {
      let parent = this.router.url.substring(0, this.router.url.indexOf('/chmain'));
      this.router.navigate([parent + '/search/' + this.ch])
    }
  }

  openDetail(item) {
    this.gv.postPredata = {key:item.$key,data:item}
    if (this.modal) {
      this.modalCtrl.create({
        component: ChviewPage,
        cssClass: 'modalfull',
        componentProps: <null>{
          id: item.$key,
          modal: true
        }
      }).then(modal => {
        modal.present();
      });
    }
    else {
      let parent = this.router.url.substring(0, this.router.url.indexOf('/chmain'));
      this.router.navigate([parent + '/chview/' + item.$key])
    }
  }

  async reupload(key, title) {
    var displaytopmsg, updatedone, upstr, cancelstr;
    this.translate.get('displaytopmsg').subscribe(res => {
      displaytopmsg = res;
    });
    this.translate.get('updatedone').subscribe(res => {
      updatedone = res;
    });
    this.translate.get('displaytop').subscribe(res => {
      upstr = res;
    });
    this.translate.get('Cancel').subscribe(res => {
      cancelstr = res;
    });

    let alert = await this.alertCtrl.create({
      message: displaytopmsg,
      buttons: [
        {
          text: cancelstr,
          role: 'cancel',
        },
        {
          text: upstr,
          handler: data => {
            this.afDB.database.ref('/list/' + key).
              update({ createdDate: firebase.database.ServerValue.TIMESTAMP, read: 0, replycount: 0 }).then(_ => {
                // 최근게시물 설정
                this.afDB.database.ref('/category/' + this.ch + '/recent').once('value').then(rec => {
                  rec.forEach(recent => {
                    if (recent.val().itemid == key)
                      this.afDB.database.ref('/category/' + this.ch + '/recent/' + recent.key).remove();
                  })
                  if (rec.val() && Object.keys(rec).length > 29) {
                    //최근 판물 30개 꽉 차있으면 마지막항목 슬라이스 & 최근항목푸시
                    this.afDB.database.ref('/category/' + this.ch + '/recent/' + Object.keys(rec)[0]).remove();
                    this.afDB.database.ref('/category/' + this.ch + '/recent').push({ title: title, itemid: key, createdDate: firebase.database.ServerValue.TIMESTAMP });
                  }
                  else {
                    //최근 게시물 30개 없다면 최근항목푸시
                    this.afDB.database.ref('/category/' + this.ch + '/recent').push({ title: title, itemid: key, createdDate: firebase.database.ServerValue.TIMESTAMP });
                  }
                })
                //알람설정 필요
                var commsg, newcommsg;
                this.translate.get('Community').subscribe(res => {
                  commsg = res;
                });
                this.translate.get('New post on Community').subscribe(res => {
                  newcommsg = res;
                });
                this.msgfunc.sendnotionlywithcondition(commsg + '] ' + this.chname
                  , title, newcommsg, this.chname + ': ' + title, 'view'
                  , key, "'" + this.ch + "' in topics || '" + this.loclan + "_recent" + "' in topics");
              })
            if (this.list)
              this.list.unsubscribe();
            this.getdata();
            this.presentToast(updatedone);
          }
        }
      ]
    });
    alert.present();
  }

  close() {
    if (`${this.modal}` != 'undefined') {
      this.modalCtrl.dismiss();
    }
    else if (this.outlet && this.outlet.canGoBack()) { this.outlet.pop() }
    //this.navCtrl.pop().then(_=>{console.log(this.navCtrl..url)}).catch(_=>{this.navCtrl.navigateBack('/')});}
    else
      this.navCtrl.navigateBack('/');
    /*
    this.modalCtrl.dismiss().catch(_=>
      this.navCtrl.back()
    )*/
  }
  doInfinite(infiniteScroll) {
    this.loaditemcnt = this.loaditemcnt + 10;
    infiniteScroll.target.complete()
  }

  doRefresh(refresh) {
    if (this.list)
      this.list.unsubscribe();
    this.chkpublic();
    setTimeout(() => {
      refresh.target.complete()
    }, 1500)
  }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2200
    });
    toast.present();
  }

  logScrolling(event) {
    if (event.detail.scrollTop >= 300) {
      this.titletoggle = true;
    } else this.titletoggle = false;
  }

  getservice(loclan) {
    this.afDB.database.ref('/localmanage/' + loclan + '/service')
      .once('value').then(items => {
        this.thisservice = [];
        items.forEach(item => {
          let service = item.val();
          if (service.detail.chid == this.ch) {
            // console.log(service);
            this.thisservice.push({
              show: service.show,
              imgurl: service.imgurl,
              name: service.name,
              chid: service.detail.chid,
              label: service.label,
              topcat: service.detail.topcat,
              items: this.afDB.database.ref('/list').orderByChild('categoryId').equalTo(service.detail.chid).once('value').then(rst => {
                let data = [];
                rst.forEach(r => {
                  let serviceitem = r.val();
                  if (serviceitem.category == service.detail.topcat)
                    data.unshift({
                      key: r.key,
                      img: serviceitem.imagethumb ? serviceitem.imagethumb[0].url : '',
                      title: serviceitem.title
                    });
                })
                return data;
              })
            });
            // console.log(this.thisservice);
          }
        })
      })
  }

  blockDefault(e) {
    e.stopPropagation();
  }
}