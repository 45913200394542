import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { EnterPipeModule } from '../../../pipe/enterpipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { BlockchkPipeModule } from '../../../pipe/blockchkpipe.module';
import { SearchPipeModule } from '../../../pipe/searchpipe.module';
import { SafeHtmlPipeModule } from '../../../pipe/safehtmlpipe.module';
import { SafeHtmlPipe } from '../../../pipe/safehtmlpipe';
import { LinkWebPipeModule } from '../../../pipe/linkwebpipe.module';
import { LinkAttachPipeModule } from '../../../pipe/linkattachpipe.module';
import { LinkImgFullPipeModule } from '../../../pipe/linkimgfullpipe.module';
import { CategoryPipeModule } from '../../../pipe/categorypipe.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { DayPipeModule } from '../../../pipe/daypipe.module';
import { TypeKrNamePipeModule } from '../../../pipe/tkrnamepipe.module';
import { ChviewPage } from './chview.page';
import { SigninPageModule } from '../../auth/signin/signin.module';
import { RatingModule } from 'ng-starrating';
import { RemovehtmltagPipe } from '../../../pipe/removehtmltag';
const routes: Routes = [
  {
    path: '',
    component: ChviewPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,TranslateModule,TypeKrNamePipeModule,RatingModule,CategoryPipeModule,
    OrderByPipeModule,EnterPipeModule,TimeAgoPipeModule,BlockchkPipeModule,SearchPipeModule,DayPipeModule,
    LinkImgFullPipeModule,LinkWebPipeModule,LinkAttachPipeModule,SafeHtmlPipeModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe,SafeHtmlPipe,RemovehtmltagPipe],
  declarations: [ChviewPage]
})
export class ChviewPageModule {}
