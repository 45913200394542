import { Component, OnInit } from '@angular/core';
import { NavParams,ActionSheetController,ToastController,NavController,ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireDatabase} from '@angular/fire/database';
import { GlobalVars } from '../../../providers/globalVars';
@Component({
  selector: 'app-ch',
  templateUrl: './ch.page.html',
  styleUrls: ['./ch.page.scss'],
})
export class ChPage implements OnInit {
  chForm : FormGroup;
  ch:any;
  chname:any;
  typemain:any='form';
  sltedform:any;
  roles:any[]=[];
  forms:any[]=[];
  modal;
  //fieldlist:any[]=[];
  constructor(public afDB: AngularFireDatabase, public actionSheetCtrl: ActionSheetController,public nav:NavController,
   public gv: GlobalVars, private toastCtrl: ToastController,
   private modalCtrl: ModalController, private route: ActivatedRoute,public fb: FormBuilder) {
    this.ch = this.route.snapshot.paramMap.get('id');
    this.chForm = fb.group({
        })  
   }

  ngOnInit() {
    if(this.gv.manager)
    this.afDB.database.ref('/category/' + this.ch).once('value').then(data=>{
      this.chname = data.val().name;
      if(data.val().forms)
        {
        this.forms = data.val().forms;
        this.sltedform = 0;
        }
    })
  }

  findmember(){}
  removerole(i){}

  addform(){
    this.forms.push({name:'', fieldlist:[]}); 
    this.sltedform = this.forms.length - 1;
  }
   async addfield(form){
        let field = form.fieldlist;
        const actionSheet = await this.actionSheetCtrl.create({
        header : '입력필드 추가',
        buttons: [
        {
            text: '일반',
            handler: () => {
            field.push({name:'', type:'normal', ph:'', mustfill:false});
            }
        },
        {
            text: '상세내용',
            handler: () => {
            field.push({name:'상세내용', type:'textarea', ph:'', mustfill:false});
            }
        },
        {
            text: '전화번호',
            handler: () => {
            field.push({name:'전화번호', type:'contact', ph:'연락처 또는 연락방법을 기입해주세요.', mustfill:false});
            }
        },
        {
            text: '주소',
            handler: () => {
            field.push({name:'주소', type:'address', ph:'', mustfill:false});
            }
        },
        {
            text: '선택형',
            handler: () => {
            field.push({name:'', type:'selection', ph:'', mustfill:false});
            }
        },
        {
            text: '날짜',
            handler: () => {
            field.push({name:'날짜', type:'date', ph:'날짜를 선택해주세요.', mustfill:false, time:true, maxday:null});
            }
        },
        {
            text: '사진(상단비치됨)',
            handler: () => {
            if(field.length == 0 || field[0].type != 'photo')
              field.unshift({name:'', type:'photo', ph:'', mustfill:false});
            else
              this.presentToast('bottom','사진타입은 하나만 추가하실수 있습니다.');
            }
        }
        ]
        });
        return await actionSheet.present();
   }
  
   removefield(index)
   {
     this.forms[this.sltedform].fieldlist.splice(index, 1);
   }
  close(){
    if(`${this.modal}` != 'undefined') 
      {
      this.modalCtrl.dismiss();
      }
    else
      this.nav.back();
  }
   update(){
     if(this.gv.manager)
     this.afDB.database.ref('/category/' + this.ch).update({
            forms: this.forms
            //managers: this.managers,
          }).then( _ => {
          this.presentToast('bottom','서비스가 수정되었습니다.');
          this.close();
        }, error => {
        });
   }

edittemp(){
  
  //let predata = {"fieldlist":{"0":{"mustfill":true,"name":"제목","ph":"제목","type":"normal","titlefield":"-"},"1":{"mustfill":false,"name":"방종류","ph":"","type":"sp4","sfield":{"0":{"items":{"0":{"text":"트윈룸","value":"트윈룸"},"1":{"text":"더블룸","value":"더블룸"},"2":{"text":"싱글룸","value":"싱글룸"},"3":{"text":"마스터룸","value":"마스터룸"},"4":{"text":"기타","value":"기타"}},"ph":"방 종류","name":"방 종류"},"1":{"maxday":60,"ph":"입주일 선택","time":false,"name":"입주 가능일"},"2":{"items":{"0":{"text":"남자 1명"},"1":{"text":"여자 1명"},"2":{"text":"남자 2명"},"3":{"text":"여자 2명"},"4":{"text":"성별무관 2명"},"5":{"text":"커플"},"6":{"text":"기타"}},"lbl":"인원","ph":"구인종류","name":"인원"},"3":{"items":{"0":{"text":"$70"},"1":{"text":"$75"},"2":{"text":"$80"},"3":{"text":"$85"},"4":{"text":"$90"},"5":{"text":"$95"},"6":{"text":"$100"},"7":{"text":"$105"},"8":{"text":"$110"},"9":{"text":"$115"},"10":{"text":"$120"},"11":{"text":"$125"},"12":{"text":"$130"},"13":{"text":"$135"},"14":{"text":"$140"},"15":{"text":"$145"},"16":{"text":"$150"},"17":{"text":"$160"},"18":{"text":"$170"},"19":{"text":"$180"},"20":{"text":"$190"},"21":{"text":"$200"},"22":{"text":"$210"},"23":{"text":"$220"},"24":{"text":"$230"},"25":{"text":"$240"},"26":{"text":"$250"},"27":{"text":"기타"}},"lbl":"주당(1인기준)","ph":"금액선택","name":"주당(1인기준)"}},"addtext":"방 추가"},"2":{"mustfill":true,"name":"상세내용","ph":"상세 설명","type":"textarea"},"3":{"mustfill":false,"name":"주소","ph":"주소","type":"address"},"4":{"mustfill":false,"name":"전화번호","ph":"연락처","type":"contact"}},"name":"쉐어집정보","imgattach":true}
  //
  //this.forms.unshift(predata);
  //console.log(this.forms);
}
//"forms":{"0":{"fieldlist":{"0":{"mustfill":true,"name":"제목","ph":"제목","type":"normal","titlefield":"-"},"1":{"mustfill":false,"name":"방종류","ph":"","type":"sp4","sfield":{"0":{"items":{"0":{"text":"트윈룸","value":"트윈룸"},"1":{"text":"더블룸","value":"더블룸"},"2":{"text":"싱글룸","value":"싱글룸"},"3":{"text":"마스터룸","value":"마스터룸"},"4":{"text":"기타","value":"기타"}},"ph":"방 종류","name":"방 종류"},"1":{"maxday":60,"ph":"입주일 선택","time":false,"name":"입주 가능일"},"2":{"items":{"0":{"text":"남자 1명"},"1":{"text":"여자 1명"},"2":{"text":"남자 2명"},"3":{"text":"여자 2명"},"4":{"text":"성별무관 2명"},"5":{"text":"커플"},"6":{"text":"기타"}},"lbl":"인원","ph":"구인종류","name":"인원"},"3":{"items":{"0":{"text":"$70"},"1":{"text":"$75"},"2":{"text":"$80"},"3":{"text":"$85"},"4":{"text":"$90"},"5":{"text":"$95"},"6":{"text":"$100"},"7":{"text":"$105"},"8":{"text":"$110"},"9":{"text":"$115"},"10":{"text":"$120"},"11":{"text":"$125"},"12":{"text":"$130"},"13":{"text":"$135"},"14":{"text":"$140"},"15":{"text":"$145"},"16":{"text":"$150"},"17":{"text":"$160"},"18":{"text":"$170"},"19":{"text":"$180"},"20":{"text":"$190"},"21":{"text":"$200"},"22":{"text":"$210"},"23":{"text":"$220"},"24":{"text":"$230"},"25":{"text":"$240"},"26":{"text":"$250"},"27":{"text":"기타"}},"lbl":"주당(1인기준)","ph":"금액선택","name":"주당(1인기준)"}},"addtext":"방 추가"},"2":{"mustfill":true,"name":"상세내용","ph":"상세 설명","type":"textarea"},"3":{"mustfill":false,"name":"주소","ph":"주소","type":"address"},"4":{"mustfill":false,"name":"전화번호","ph":"연락처","type":"contact"}},"name":"쉐어집정보","imgattach":true}}
   
    async presentToast(position,message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: position,
      duration: 1500
    });
    return await toast.present();
  }
}
