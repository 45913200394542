
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChmenuComponent } from './chmenu.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,TranslateModule,
    RouterModule.forChild([{ path: '', component: ChmenuComponent }])
  ],
  providers:[TranslatePipe],
  declarations: [ChmenuComponent],
    exports: [
        ChmenuComponent
    ]
})
export class ChmenuComponentModule {
}
