import { Component, OnInit } from '@angular/core';
import { ModalController,LoadingController} from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { Imgupload } from '../../../providers/imgupload';
//import * as firebase from 'firebase/app';
@Component({
  selector: 'app-selectimg',
  templateUrl: './selectimg.page.html',
  styleUrls: ['./selectimg.page.scss'],
})
export class SelectimgPage implements OnInit {
  imglist:any[] = ['fileupload'];
  rows:any = 1;
  Arr = Array; 
  images:any[] = [];
  imagethumbs:any[] = [];
  constructor(
    public modalCtrl: ModalController,private imgupload: Imgupload,
    //public navParams: NavParams,
    public afDB: AngularFireDatabase, private loadingCtrl:LoadingController
  ) {}

  ngOnInit() {
  //this.setitem();
      this.afDB.database.ref('/bgimg').once('value').then(rst=>{
      let temp = [];
      rst.forEach(item=>{
        temp.unshift(item.val().url);
      })
      this.rows = Math.ceil((temp.length+1)/3);
      this.imglist = this.imglist.concat(temp);
    })
  }

  async htmlupload(fileinput:HTMLInputElement) { 
    let loadingPopup = await this.loadingCtrl.create({
        spinner: 'crescent'
      });
    loadingPopup.present();

      if(this.images.length > 0 && this.imagethumbs.length > 0)
        this.imgupload.delete(this.images[0].path, this.imagethumbs[0].path);
      this.images = [];
      this.imagethumbs = [];
      var filelength = fileinput.files.length;
      for(var i = 0; i < filelength; i++)
      {
      var file:File = fileinput.files[i];
      await this.imgupload.readfile(file).then(rst=>
      {
      this.imgupload.imgupload(file, 'bgusers')
      .then(imgrst=>{
        loadingPopup.dismiss();
        this.closemodal(imgrst[0]['url']);
       // this.images.push({path:imgrst[0]['path'], url:imgrst[0]['url']});
       // this.imagethumbs.push({path:imgrst[1]['path'], url:imgrst[1]['url']});
      })
      })
      }  
  }
  /* 다운로드 주소 가져오기.
  async setitem(){
    for(let i = 10; i < 93; i++)
    await firebase.storage().ref('/bgsample/chaddbg/' + i + '.jpg').getDownloadURL().then(rst=>{
    firebase.database().ref('/bgimg/' + i + '/url').set(rst);
  })
  }*/


  closemodal(imgurl) {
    this.modalCtrl.dismiss({bgimage:imgurl});
  }

}
