import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavParams, ModalController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-imgmodal',
  templateUrl: './imgmodal.page.html',
  styleUrls: ['./imgmodal.page.scss'],
})
export class ImgmodalPage implements OnInit {
 
  @ViewChild('slider', { read: ElementRef })slider: ElementRef;
  @ViewChild(IonSlides) slides: IonSlides;
  imglist: any[] = [];
  index:any;
  activatedindex:any;
  imgmodalOpt = {
    initialSlide:1,
    pager: true,
    zoom: {
      maxRatio: 3
    }
  };
  hidePrev:any = false;
  hideNext:any = false;
 
  constructor(private navParams: NavParams, private modalController: ModalController) { }
 
  ngOnInit() {
    this.imglist = this.navParams.get('img');
    this.index = this.navParams.get('index');
    //console.log(this.index);
    //console.log(this.imglist);
    
    this.imgmodalOpt.initialSlide = this.index;
    if( this.index >= 0 && this.index >= (this.imglist.length - 1) ) {
      this.hidePrev = true; 
      this.hideNext = true;
    } else if( this.index >= 0 ) {
      this.hidePrev = true;
    } else if( this.index >= (this.imglist.length - 1) ) {
      this.hideNext = true;
    }
  }
 
  zoom(zoomIn: boolean) {
    let zoom = this.slider.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }

  prevslide() {
    this.slides.slidePrev();
  }
  nextslide() {
    this.slides.slideNext();
  }
 
  close() {
    this.modalController.dismiss();
  }


  slideChanged() {
    this.hidePrev = false;
    this.hideNext = false;
    this.slides.isBeginning().then(result=>{ 
      if(result) this.hidePrev = !this.hidePrev;
    });
    this.slides.isEnd().then(result=>{ 
      if(result) this.hideNext = !this.hideNext;
    });
  }

}
