import { NgModule } from '@angular/core';
import { MatchPipe } from './matchpipe';

@NgModule({
  declarations: [
    MatchPipe,
  ],
  imports: [
    
  ],
  exports: [
    MatchPipe
  ]
})
export class MatchPipeModule {}
