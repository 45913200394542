import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { OrderByPipe } from '../../../pipe/orderbypipe';
import { Http } from "@angular/http";
import { GlobalVars } from '../../../providers/globalVars';

import * as firebase from 'firebase/app';
@Component({
  selector: 'app-mailsender',
  templateUrl: './mailsender.page.html',
  styleUrls: ['./mailsender.page.scss'],
})
export class MailsenderPage implements OnInit {
  editorConfig: AngularEditorConfig;
  newchlist:any[]=[];
  newpointitems:any[]=[];
  favposts:any[]=[];
  advlist:any[]=[];
  category:any="";
  content:any;
  title:any;

  shownewch:boolean;
  shownewpm:boolean;
  showfav:boolean;


  constructor(private modalCtrl:ModalController, private orderby:OrderByPipe, private gv:GlobalVars,
    private http:Http,private actionsheetCtrl:ActionSheetController) {
    this.editorConfig = {
    editable: true,
    spellcheck:false,
    translate: 'no' ,
    defaultFontSize: '7',
    height:'100%',//'calc(100vh - 200px)',
    showToolbar:false,
    placeholder:'내용을 작성해 주세요.',
    customClasses: [ // optional
        {
            name: "quote",
            class: "quote",
        }
    ]
    }
		this.content = `
		<div>
		<img src="https://firebasestorage.googleapis.com/v0/b/localnews-e350b.appspot.com/o/mailing%2Flogo.png?alt=media&token=c72ea063-7d0d-4eb7-9450-98f7ed38b30f" alt="CHANNEL" style="margin: 0 auto; width:48px;">
		
						<p style="font-size:20px; margin: 0 0 10px;">안녕하세요, #채널유저#님</p>
						<p style="color:#333333; font-size:17px;">
							채널에서 2020년 출발과 함께 브리즈번 서비스 개시하며 
              브리즈번 지역신문에 게제, 페북/인스타/유튜브 등 소셜 홍보 등 
              온/오프라인 마케팅이 활발히 진행 중에 있습니다.<br />
							<br />
							이와 함께 3월15일까지 신년 행운의 룰렛 이벤트가 진행되고 있습니다.
              경품에 <b><u>애플 에어팟 프로, 한인마트 $10쿠폰, 채널포인트</u></b>가 있으니
              매일 매일 접속하시고, 활동하셔서 기회를 잡아보세요!
							<br /><br/>
							아래는 최신 채널 소식입니다^^
						</p>
							<h2
							style="margin: 30px 0 15px; font-size:25px; font-weight:normal; text-align:center; line-height: 1; color:#000086;">
							새로 개설된 채널
						</h2>
						<p style="color:#333333; font-size:17px;">
							<img src="https://firebasestorage.googleapis.com/v0/b/localnews-e350b.appspot.com/o/mailing%2Fsc1.png?alt=media&token=4bc9a992-9e12-4e89-87f6-d78ca95812d6">
						</p>
					
						<h2
							style="margin: 30px 0 15px; font-size:25px; font-weight:normal; text-align:center; line-height: 1; color:#000086;">
							새로 나온 포인트 몰 상품
						</h2>
						<p style="color:#333333; font-size:17px;">
							<img src="https://firebasestorage.googleapis.com/v0/b/localnews-e350b.appspot.com/o/mailing%2Fsc2.png?alt=media&token=ed9a67b8-47bc-4a53-b404-79744fb5caae">
						</p>
						<h2
							style="margin: 30px 0 15px; font-size:25px; font-weight:normal; text-align:center; line-height: 1; color:#000086;">
							최근 인기 글
						</h2>
						<p style="color:#333333; font-size:17px;">
							<img src="https://firebasestorage.googleapis.com/v0/b/localnews-e350b.appspot.com/o/mailing%2Fsc3.png?alt=media&token=500af838-d37e-4a29-af41-20f64a1c81a4">
						</p>
				
						<a href="https://www.facebook.com/ChannelCairns" target="_blank"
							style="font-size:20px; color:#333333;">Facebook</a>
						<span style="margin: 0 10px;">|</span>
						<a href="https://www.instagram.com/channel.cafe" target="_blank"
							style="font-size:20px; color:#333333;">Instagram</a>
				
		<p style="color: #888; font-weight: 300; text-align: center; padding: 10px 0;">
			본 메일은 발신전용 입니다.
		</p>
	</div>`
   }

  ngOnInit() {
    firebase.database().ref('/category').orderByChild('createdDate').limitToLast(10) //startAt(new Date().getTime())
    .once('value').then(rst=>{
      rst.forEach(ch=>{
        this.newchlist.unshift(ch.val());
      })
    })

    
    firebase.database().ref('/pmall').orderByChild('state').equalTo('confirm').limitToLast(10) //startAt(new Date().getTime())
    .once('value').then(rst=>{
      rst.forEach(ch=>{
        this.newpointitems.unshift(ch.val());
      })
    })

    firebase.database().ref('/list').orderByChild('createdDate').limitToLast(1000) //startAt(new Date().getTime())
    .once('value').then(rst=>{

      rst.forEach(ch=>{
        this.favposts.push(ch.val());
      })
      this.favposts = this.orderby.transform(this.favposts,'read','desc').slice(0,40);
    })
  }

  close(){
    this.modalCtrl.dismiss();
  }
  
  async showcategory(){
    let sltitem = [];
    sltitem.push({text:'전체회원',handler:()=>{this.category = '전체회원';}});
    sltitem.push({text:'브리즈번',handler:()=>{this.category = '브리즈번';}});
    sltitem.push({text:'케언즈',handler:()=>{this.category = '케언즈';}});
    sltitem.push({text:'기타지역',handler:()=>{this.category = '기타지역';}});
    
    const actionsheet = await this.actionsheetCtrl.create({
      header: '발간대상 선택',
      buttons: sltitem
    });
    return await actionsheet.present();
}
	addsubtitle(){}
	addround(){}
	addstrong(){}
	addnormal(){}
  addImg(){}
  submitForm(){
		if(this.gv.admin)
		{
    let dest;
		//if(this.category == '전체회원')
		/*
    firebase.database().ref('/subscribe').orderByChild('loc').equalTo('brisbane').once('value').then(rst=>{
      rst.forEach(r=>{
        let user = r.val();
        this.sendemail(user.email,user.nickname);
			})
			*/
			this.sendemail('hoxer80@gmail.com','Sunz');
    }//)}
  }
  sendemail(dest,nickname){
    console.log(dest+'/'+ nickname);
    this.http
    .post(
    'https://stark-sands-51177.herokuapp.com/https://us-central1-localnews-e350b.cloudfunctions.net/emailsend', {
    from: "채널소식 <noreply@channel.cafe>",
    dest: dest,
    subject:this.title.replace(/#채널유저#/ig, nickname),
    content: this.content.replace(/#채널유저#/ig, nickname)
    })
    .subscribe(data => {
    if(data)
    {
      console.log(JSON.stringify(data));
      console.log('발송 완료');
    }
    else
    {
      console.log('실패')
      console.log(data);
    }
    })
  }

}
