import { NgModule } from '@angular/core';
import { DayPipe } from './daypipe';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
@NgModule({
  declarations: [
    DayPipe,
  ],
  imports: [
    TranslateModule
  ],
  providers:[TranslatePipe],
  exports: [
    DayPipe
  ]
})
export class DayPipeModule {}
