import { Component, OnInit } from '@angular/core';
import { NavController,NavParams,ToastController,AlertController,ModalController} from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addworktool',
  templateUrl: './addworktool.page.html',
  styleUrls: ['./addworktool.page.scss'],
})
export class AddworktoolPage implements OnInit {
messageForm : FormGroup;
 workplaces: any[] = []; 
 workrates: any[] = [];
 preworkplaces: any[] = [];
 preworkrates: any[] = [];
 page:number = 1;
 wgid;
 title;
 removeable:any = false;


 //workplacevalid: boolean = false;
    constructor(public navCtrl: NavController, public fb: FormBuilder,public afDB: AngularFireDatabase, 
      public alertCtrl: AlertController,  private translate: TranslateService,
      private toastCtrl: ToastController, public afAuth: AngularFireAuth, public modalCtrl: ModalController) {
       this.messageForm = fb.group({
          'name' : [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(20)])],
      })
      
      let basicrate, basicwp, titletxt;
      this.translate.get('Base rate').subscribe(res=>{basicrate = res;})
      this.translate.get('Base work place').subscribe(res=>{basicwp = res;})
      this.translate.get('Add new work board').subscribe(res=>{titletxt = res;})
      this.title = titletxt;
      this.workrates = [{key:'', data:{name:basicrate, rate:'100'}}];
      this.workplaces = [{key:'', data:{name:basicwp, rate:''}}];
      
    }

  ngOnInit() {
    if(this.wgid)
      {
      let titleedit;
      this.translate.get('Edit work board').subscribe(res=>{titleedit = res;})
      this.title = titleedit;
      //this.wgid = navParams.get('wgid');
      this.afDB.database.ref('/workgroup/' + this.wgid).once('value').then(datasnap=>{
        var data = datasnap.val();
        this.messageForm.controls['name'].setValue(data['name']);
        if(data['member'] && Object.keys(data['member']).length < 2)
          this.removeable = true;

        if(data['rates'])
        {
          this.workrates = [];
          for(let i = 0; i < Object.keys(data['rates']).length; i++)
            this.workrates.push({key:Object.keys(data['rates'])[i], data:data['rates'][Object.keys(data['rates'])[i]]});
          this.preworkrates = this.workrates;
        }
        //this.workrates = data['rates'];
        if(data['sites'])
        {
          //this.preworkplaces = data['sites'];
          this.workplaces = [];
          for(let i = 0; i < Object.keys(data['sites']).length; i++)
            this.workplaces.push({key:Object.keys(data['sites'])[i], data:data['sites'][Object.keys(data['sites'])[i]]});
        }
      })
      }
  }
/*
  onkeyup(e)
  {
    console.log(document.getElementById('divworkplace').innerText)
    if(document.getElementById('divworkplace').innerText.trim() != "")
      this.workplacevalid = true;
    else
      this.workplacevalid = false;    
  }*/

    addworkrate()
   {
     this.workrates.push({key:"", data:{name:"", rate:""}});
   }

    addworkplace()
   {
     this.workplaces.push({key:"", data:{name:"", rate:""}});
   }
    removeworkrate(index)
   {
     if(this.workrates.length <= 1)
      {
        var msg;
        /*this.translate.get('Community must have more than 1 category').subscribe(res=>{
        msg = res;
        });*/
        msg = '최소한 1개이상의 급여등급을 등록해주세요!'
        alert(msg);
      }
     else
     this.workrates.splice(index, 1);
   }

   removeworkplace(index)
   {
     if(this.workplaces.length <= 1)
      {
        var msg;
        /*this.translate.get('Community must have more than 1 category').subscribe(res=>{
        msg = res;
        });*/
        msg = 'C'
        alert(msg);
      }
     else
     this.workplaces.splice(index, 1);
   }

   remove(){
     let removealert = this.alertCtrl.create({
            header: '근무일지 삭제',
            message: '<p>근무일지를 삭제하시면 모든회원의 근무기록도 삭제되며 복구가 불가능 합니다.</p><p>정말 삭제하시겠습니까?</p>',
            buttons: [
                {
                text: '취소',
                role: 'cancel'
                },
                {
                text: '삭제하기',
                handler: () => {
                    // 근무기록 모두 삭제
                    this.afDB.database.ref('/workdata/' + this.wgid).remove().then(_=>{
                    // 회원모두 탈퇴
                    this.afDB.database.ref('/workgroup/' + this.wgid + '/member').once('value').then(members=>{
                      members.forEach(member => {
                        this.afDB.database.ref('/workinfo/' + member.val().userId).orderByChild('id').equalTo(this.wgid)
                        .once('value').then(wi=>{
                          wi.forEach(workinfo => {
                            this.afDB.database.ref('/workinfo/' + member.val().userId + '/' + workinfo.key).remove()
                          });
                        })
                      });
                    }).then(_=>{
                      // 근무일지 삭제
                    this.afDB.database.ref('/workgroup/' + this.wgid).remove().then(_=>{
                      this.presentToast('bottom','근무일지를 삭제하였습니다.', 3000);
                      this.navCtrl.pop();
                    });
                    })
                    })
                }
                }
            ]
            }); 
    removealert.then(view=>view.present())
     
   }
    
   submitForm(value: any):void{
    if(this.wgid)
    {
      //근무일지 수정
      this.afDB.database.ref('/workgroup/' + this.wgid)
      .update({name: value.name}).then(_ => {
       
          this.afDB.database.ref('/workgroup/' + this.wgid + '/sites').once('value').then(rst=>{
            this.preworkplaces = [];
            rst.forEach(site=>{
              this.preworkplaces.push({key:site.key, data:site.val()});
            })
          }).then(_=>
            {
              //사이트 체크
              //삭제된거 추려내고 시작

              this.preworkplaces.forEach(pwp=>{
                var itemexist = false;
                this.workplaces.forEach(nwp=>{
                  if(!itemexist)
                  {
                    if(pwp.key == nwp.key)
                      itemexist = true; 
                  }
                })
                if(!itemexist)
                  this.afDB.database.ref('/workgroup/' + this.wgid + '/sites/' + pwp.key).remove();
              })

              this.workplaces.forEach(wp=>{
                var exist = false; // 존재확인
                var updated = false; // 변경확인
                this.preworkplaces.forEach(prewp=>{
                  if(!exist)
                  {
                  if(prewp.key == wp.key)
                    {
                      exist = true;
                      if(prewp.data.name == wp.data.name && prewp.data.rate == wp.data.rate)
                        updated = false;
                      else
                        {
                        updated = true;
                        }
                    }
                  }
                })
                if(!exist) //추가된 항목이면 푸시
                {
                  this.afDB.database.ref('/workgroup/' + this.wgid + '/sites/').push({name:wp.data.name, rate:wp.data.rate});
                }
                else if(updated) //변경된 항목이면 업데이트
                {
                  this.afDB.database.ref('/workgroup/' + this.wgid + '/sites/' + wp.key).update({name:wp.data.name, rate:wp.data.rate});
                }
                else  // 기존에 있고 변경 안된항목 - 처리필요없음
                {}
              })
            })

            
          this.afDB.database.ref('/workgroup/' + this.wgid + '/rates').once('value').then(rst=>{
            this.preworkrates = [];
            rst.forEach(site=>{
              this.preworkrates.push({key:site.key, data:site.val()});
            })
          }).then(_=>{
            //레이트 체크
            this.workrates.forEach(wr=>{
              var exist = false; // 존재확인
              var updated = false; // 변경확인
              this.preworkrates.forEach(prewr=>{
                if(prewr.key == wr.key)
                  {
                    exist = true;
                    if(prewr.data.name == wr.data.name && prewr.data.rate == wr.data.rate)
                      updated = false;
                    else
                      updated = true;
                    return false;
                  }
              })
              if(!exist) //추가된 항목이면 푸시
              {
                this.afDB.database.ref('/workgroup/' + this.wgid + '/rates/').push({name:wr.data.name, rate:wr.data.rate});
              }
              else if(updated) //변경된 항목이면 업데이트
              {
                this.afDB.database.ref('/workgroup/' + this.wgid + '/rates/' + wr.key).update({name:wr.data.name, rate:wr.data.rate});
              }
              else  // 기존에 있고 변경 안된항목 - 처리필요없음
              {}
            })
          })
            
          }, error => {
            //console.log(error);
          }).then(_=>{
            this.presentToast('bottom','근무일지를 수정하였습니다.', 3000);
            this.navCtrl.pop();
          });
    } 
    else
    {
    this.afDB.database.ref('/workgroup').push({
    userId: firebase.auth().currentUser.uid,
    name: value.name,
    type:'worktool',
    createdDate: firebase.database.ServerValue.TIMESTAMP
    }).then( workitem => {
        this.workplaces.forEach(wp=>{
          this.afDB.database.ref('/workgroup/' + workitem.key + '/sites/').push({name:wp.data.name, rate:wp.data.rate})
        })
        this.workrates.forEach(wr=>{
          this.afDB.database.ref('/workgroup/' + workitem.key + '/rates/').push({name:wr.data.name, rate:wr.data.rate});
        })
        
        this.afDB.database.ref('/workinfo/' + firebase.auth().currentUser.uid)
        .push({id:workitem.key});

        this.afDB.database.ref('/workgroup/' + workitem.key + '/member/')
        .push({manager:true, userId:firebase.auth().currentUser.uid})
        .then(
            obj=>{
                   this.presentToast('bottom','근무일지를 생성하였습니다.', 3000);
                   var memberrateinput = [];
                   this.workrates.forEach(item=>{
                      memberrateinput.push({
                      type:'radio', 
                      label:item.data.name + ' (' + item.data.rate + '%)', 
                      value:item.data.name + '@#@' + item.data.rate
                      });
                   })
                    
                   let setrate = this.alertCtrl.create({
                    header: '등급설정',
                    message: '나의 등급을 설정해주세요.',
                    inputs:memberrateinput,
                    buttons: [
                        {
                        text: '취소',
                        role: 'cancel'
                        },
                        {
                        text: '완료',
                        handler: (data) => {
                            if(data)
                            {
                              
                              firebase.database().ref('/workgroup/' + workitem.key + '/member/' + obj.key).update({ratename:data.split('@#@')[0], memberrate:data.split('@#@')[1]});
                                this.presentToast('bottom','설정이 완료되었습니다.', 3000);
                                this.navCtrl.pop();
                            }
                            else
                            {
                                firebase.database().ref('/workgroup/' + workitem.key + '/member/' + obj.key).update({memberrate:100});
                                this.presentToast('bottom','선택하시지 않아 기본값(100%)으로 선택되었습니다.', 3000)
                                this.navCtrl.pop();
                            }
                            }
                        }
                    ]
                    }); 
                    setrate.then(view=>view.present())
                }
        );

        }, error => {
          //console.log(error);
        });

    } 
  }
   presentToast(position: string,message: string ,duration: number) {
    let toast = this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: duration
    });
    toast.then(view=>view.present())
  }
   closeModal() {
    this.modalCtrl.dismiss();
  }


}
