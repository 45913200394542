import { Component, OnInit } from '@angular/core';
import { ModalController,AlertController,ToastController } from '@ionic/angular';
import { AngularFireDatabase,AngularFireList } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVars } from '../../../providers/globalVars';

@Component({
  selector: 'app-blockuser',
  templateUrl: './blockuser.page.html',
  styleUrls: ['./blockuser.page.scss'],
})
export class BlockuserPage implements OnInit {
  editlist:any;
  uid:any;
  blocklist:any = [];
  loadingdone:boolean = false;
  constructor(public modalCtrl: ModalController, public gv:GlobalVars,public translate: TranslateService,
  public alertCtrl: AlertController, public toastCtrl: ToastController,
  public afAuth: AngularFireAuth, public afDB: AngularFireDatabase) { }

  ngOnInit() {
    this.uid = this.gv.uid;
    this.afDB.list('/userProfile/' + this.uid  + '/blockuser')
    .snapshotChanges().subscribe(item => {
                item.map(user=>{
                  user.payload.val()['nickname'] = this.afDB.object('/userProfile/'+user.payload.val()['id']+'/nickname')
                  return user;
                })
                this.blocklist = item.reverse();
                this.loadingdone=true;
           });
  }

  async removeblock(name, key)
  {
    var blockmsg, cancelbtn, releasebtn, released;
    this.translate.get('release to block this user').subscribe(res=>{
     blockmsg = res;
    });
    this.translate.get('Cancel').subscribe(res=>{
      cancelbtn = res;
    });
    this.translate.get('has released from blocked').subscribe(res=>{
      released = res;
    });
    this.translate.get('Release').subscribe(res=>{
      releasebtn = res;
    });
    let alert = await this.alertCtrl.create({
                //title: '이동 확인',
                message: name + blockmsg,
                buttons: [
                    {
                    text: cancelbtn,
                    role: 'cancel'
                    },
                    {
                    text: releasebtn,
                    handler: () => {
                      this.afDB.database.ref('/userProfile/'+this.uid+'/blockuser/' + key).remove().then(_=>
                      this.presentToast('bottom', name + released)
                      )}
                    }
                ]
                });
                alert.present();
  }
  doInfi(infiniteScroll){
     infiniteScroll.target.complete();
    }
  async presentToast(position: string,message: string) {
    let toast = await this.toastCtrl.create({
    message: message,
    position: 'bottom',
    duration: 2200
    });
    toast.present();
    }

}
