import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { sharefunc } from '../../../providers/sharefunc';
import { GlobalVars } from '../../../providers/globalVars';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
@Component({
  selector: 'app-invite',
  templateUrl: './invite.page.html',
  styleUrls: ['./invite.page.scss'],
})
export class InvitePage implements OnInit {
  public backgroundImage: any = "./assets/chprom.png";
  public subject: string;
  public message: string;
  public kakaoAppName: string = 'com.kakao.talk';
  nickname:any;
  name;
  constructor(public modalCtrl: ModalController,private sharefunc:sharefunc,private afAuth:AngularFireAuth,
    private afDB:AngularFireDatabase,
    public gv:GlobalVars, private toastCtrl:ToastController
  ) { }

  ngOnInit() {
  if(`${this.name}` != 'undefined')
   this.nickname = `${this.name}`
  }

  share(){
      if(this.gv.uid)
      {
      this.sharefunc.shareapp(this.gv.uid).then(rst=>{
        if(rst)
        {
        let today = new Date();
        let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
        this.afDB.database.ref('/systemlog/' + this.gv.uid + '/event/' + todaystr + '/appshare').transaction(view=>{
          return (view || 0) + 1;
        })
        }
      })
      }
      else
      {
        this.sharefunc.shareapp().then(rst=>{
        if(rst)
        {
        let today = new Date();
        let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
        this.afDB.database.ref('/systemlog/' + ('unregister') + '/event/' + todaystr + '/appshare').transaction(view=>{
          return (view || 0) + 1;
        })
        }
      })
      }
  }

  shareaddress()  {
  const el = document.createElement('textarea');
  el.value = 'https://channel.cafe/promo.html';
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  this.presentToast('주소가 복사되었습니다.');
};

    async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 2200
    });
    return await toast.present();
  }
  

}
