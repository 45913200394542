import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Channel } from './app.component';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
// import { NgxsModule } from '@ngxs/store';

// translate
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { SafeHtmlPipeModule } from './pipe/safehtmlpipe.module';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import {GlobalVars} from './providers/globalVars';
import { AuthGuard } from './providers/auth-guard';
import {AuthData} from './providers/auth-data';
import { msgfunc } from './providers/msgfunc';
import { pointfunc } from './providers/pointfunc';
import { sharefunc } from './providers/sharefunc';
import { Badge } from '@ionic-native/badge/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DragulaModule } from 'ng2-dragula';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RatingModule } from 'ng-starrating';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { PagemenuComponentModule } from './component/pagemenu/pagemenu.module';
import { MembermenuComponentModule } from './component/membermenu/membermenu.module';
import { ChmenuComponentModule } from './component/chmenu/chmenu.module';
import { ChlistmodalPageModule } from './pages/support/chlistmodal/chlistmodal.module';
import { DiscountdetailComponent } from './pages/support/discountdetail/discountdetail.component';
import { GetpointmodalComponent } from './pages/support/getpointmodal/getpointmodal.component';

import { DoublecatmodalPageModule } from './pages/support/doublecatmodal/doublecatmodal.module';
import { MessagePageModule } from './pages/menu/message/message.module';
import { AlarmPageModule } from './pages/menu/alarm/alarm.module';
import { NicknamePageModule } from './pages/menu/nickname/nickname.module';
import { BlockuserPageModule } from './pages/menu/blockuser/blockuser.module';
import { PwdchangePageModule } from './pages/menu/pwdchange/pwdchange.module';
import { NoticePageModule } from './pages/menu/notice/notice.module';
import { QnaPageModule } from './pages/menu/qna/qna.module';
import { VersionPageModule } from './pages/menu/version/version.module';
import { PointinfoPageModule } from './pages/menu/pointinfo/pointinfo.module';
import { MypointPageModule } from './pages/menu/mypoint/mypoint.module';
import { ListmodalPageModule } from './pages/menu/listmodal/listmodal.module';
import { FavchlistPageModule } from './pages/menu/favchlist/favchlist.module';
import { MybucketPageModule } from './pages/support/mybucket/mybucket.module';
import { AccountPageModule } from './pages/menu/account/account.module';
import { MylevelPageModule } from './pages/menu/mylevel/mylevel.module';
import { SetnotiPageModule } from './pages/menu/setnoti/setnoti.module';
import { SetaccountPageModule } from './pages/menu/setaccount/setaccount.module';
import { WorktoolPageModule } from './pages/custom/worktool/worktool/worktool.module';
import { BasicpopupPageModule } from './pages/general/post/basicpopup/basicpopup.module';
import { InapplinkPageModule } from './pages/general/post/inapplink/inapplink.module';
import { AddmapPageModule } from './pages/general/post/addmap/addmap.module';
import { SelectimgPageModule } from './pages/support/selectimg/selectimg.module';
import { InvitePageModule } from './pages/menu/invite/invite.module';
//import { LabsPageModule } from './pages/menu/labs/labs.module';
import { MenupanPageModule } from './pages/general/chview/menupan/menupan.module';
import { SetlocalPageModule } from './pages/admin/setlocal/setlocal.module';
import { SystemlogPageModule } from './pages/admin/systemlog/systemlog.module';
import { PitemdetailPageModule } from './pages/general/point/pitemdetail/pitemdetail.module';
import { ListUpdatePageModule } from './pages/auth/listupdate/listupdate.module';
import { MailsenderPageModule } from './pages/admin/mailsender/mailsender.module';

import { SelectchitemPageModule } from './pages/support/selectchitem/selectchitem.module';
import { ClosedPageModule } from './pages/auth/closed/closed.module';

import { ChviewPageModule } from './pages/general/chview/chview.module';
import { ChmainPageModule } from './pages/general/chmain/chmain.module';

import { ChaddPageModule } from './pages/general/chadd/chadd.module';
import { SearchPageModule } from './pages/general/search/search.module';
import { ChPageModule } from './pages/admin/ch/ch.module';

import { PostPageModule } from './pages/general/post/post.module';
import { MsgviewPageModule } from './pages/general/msgview/msgview.module';
import {AutosizeModule} from 'ngx-autosize';
//import { MatLinkPreviewModule } from '@angular-material-extensions/link-preview';
import { ImgmodalPageModule } from './pages/support/imgmodal/imgmodal.module';
import { FirstvisitmodalPageModule } from './pages/support/firstvisitmodal/firstvisitmodal.module';

import { SearchPipeModule } from './pipe/searchpipe.module';
import { OrderByPipeModule } from './pipe/orderbypipe.module';
import { BlockchkPipeModule } from './pipe/blockchkpipe.module';
import { TimeAgoPipeModule } from './pipe/time-ago-pipe.module';
import { EnterPipeModule } from './pipe/enterpipe.module';
import { DayPipeModule } from './pipe/daypipe.module';
import { SearchResultPipeModule } from './pipe/searchresultpipe.module';

import { LevelupmodalComponent } from './pages/support/levelupmodal/levelupmodal.component';
//import { SelectchannelComponent } from './component/selectchannel/selectchannel.component';
import { Roulette2PageModule } from './pages/custom/roulette2/roulette2.module';

//import { AdMob } from "@ionic-native/admob-plus/ngx";
export function setTranslateLoader(http: HttpClient) {
 return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const config = { 
   apiKey: 'AIzaSyCtzX0Q4oKe-t1RILlELiKVEejrfH0oQCg',
   authDomain: 'localnews-e350b.firebaseapp.com', 
   databaseURL: 'https://localnews-e350b.firebaseio.com/', 
   projectId: 'localnews-e350b', 
   storageBucket: 'localnews-e350b.appspot.com', 
   messagingSenderId: '442347281921' 
};


@NgModule({
  declarations: [Channel,DiscountdetailComponent,LevelupmodalComponent,GetpointmodalComponent],
  entryComponents: [DiscountdetailComponent,LevelupmodalComponent,GetpointmodalComponent],
  imports: [BrowserModule, IonicModule
    .forRoot({backButtonText: '', swipeBackEnabled: true, 
    mode:'ios'
    //,navAnimation:iosTransitionAnimation
    }), AppRoutingModule,HammerModule,
  IonicStorageModule.forRoot(),//MatLinkPreviewModule.forRoot(),
  AngularFireModule.initializeApp(config),
  // NgxsModule.forRoot([], {
  //   developmentMode: !environment.production
  // }),
  BrowserAnimationsModule,AccountPageModule,MylevelPageModule,ImgmodalPageModule,
  AngularFireDatabaseModule,AngularFireAuthModule,SearchPipeModule,OrderByPipeModule,BlockchkPipeModule,TimeAgoPipeModule,
  DragulaModule.forRoot(),HttpClientModule,PagemenuComponentModule,MembermenuComponentModule,AlarmPageModule,MessagePageModule,ChmenuComponentModule,
  ChlistmodalPageModule,NicknamePageModule, BlockuserPageModule, PwdchangePageModule, NoticePageModule, QnaPageModule, VersionPageModule,
  PointinfoPageModule, MypointPageModule,ListmodalPageModule,ListUpdatePageModule,MybucketPageModule,FavchlistPageModule,WorktoolPageModule,AddmapPageModule,
  PostPageModule,AutosizeModule,MsgviewPageModule,RatingModule,BasicpopupPageModule,SelectimgPageModule,SetlocalPageModule,SystemlogPageModule,
  InapplinkPageModule,DoublecatmodalPageModule,SetnotiPageModule,ClosedPageModule,SetaccountPageModule,EnterPipeModule,
  SearchPageModule,InvitePageModule,SelectchitemPageModule,Roulette2PageModule,MailsenderPageModule,
  SearchResultPipeModule,HttpModule,
  ChPageModule, ChaddPageModule,MenupanPageModule,DayPipeModule,SafeHtmlPipeModule,ChviewPageModule,ChmainPageModule,FirstvisitmodalPageModule,PitemdetailPageModule,
  TranslateModule.forRoot({
    loader: {
    provide: TranslateLoader,
    useFactory: (setTranslateLoader),
    deps: [HttpClient]
    }
    }),
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    InAppBrowser,AuthData,AuthGuard,FCM,pointfunc,GooglePlus,
    GlobalVars,msgfunc,sharefunc,Badge,SocialSharing,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslatePipe
  ],
  bootstrap: [Channel]
})
export class AppModule {}
