import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import {
  NavController, ModalController, AlertController, PopoverController, IonRouterOutlet,
  ToastController, ActionSheetController
} from '@ionic/angular';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import { msgfunc } from '../../../providers/msgfunc';
import { pointfunc } from '../../../providers/pointfunc';
import { sharefunc } from '../../../providers/sharefunc';
import { GlobalVars } from '../../../providers/globalVars';
import { PagemenuComponent } from '../../../component/pagemenu/pagemenu.component';
import { MembermenuComponent } from '../../../component/membermenu/membermenu.component';
import { SigninPage } from '../../auth/signin/signin.page';
import * as firebase from 'firebase/app';
import { ImgmodalPage } from '../../support/imgmodal/imgmodal.page';
import { PostPage } from '../../general/post/post.page';
import { MenupanPage } from './menupan/menupan.page';
import { AccountPage } from '../../menu/account/account.page';
import { MetaService } from 'ng2-meta';
import { ChmainPage } from '../../general/chmain/chmain.page';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PitemdetailPage } from '../point/pitemdetail/pitemdetail.page';
import { OrderByPipe } from '../../../pipe/orderbypipe';
import { SafeHtmlPipe } from '../../../pipe/safehtmlpipe';
import { RemovehtmltagPipe } from '../../../pipe/removehtmltag';

import { Imgupload } from '../../../providers/imgupload';
import { BasicpopupPage } from '../post/basicpopup/basicpopup.page';
import { InapplinkPage } from '../post/inapplink/inapplink.page';

import { Observable,Subscription } from 'rxjs';


@Component({
  selector: 'app-chview',
  templateUrl: './chview.page.html',
  styleUrls: ['./chview.page.scss'],
})
export class ChviewPage implements OnInit {
  //channeldata: any={};
  commentid: any;
  rate: any = null;
  readcount;
  newcomment: any = "";
  newcommentrp: any = "";
  favorite: boolean = false;
  commentbox: boolean = false;
  commentedit: boolean = false;
  currentcomment: AngularFireList<any[]>;
  userId: any;
  //*********** Variables for fading header **************//
  showToolbar: boolean = false;
  transition: boolean = false;
  itemId: any;
  item: any;//Observable<any>;
  itemdesc: any;// AngularFireObject<any[]>;
  //writerprofile: AngularFireObject<any[]>;
  writername: any;//AngularFireObject<any[]>;
  writerid: any;
  writerlvl: any;
  writerimg: any;//AngularFireObject<any[]>;
  writerloc: any;
  writerloccode: any;
  imageSrc: any;
  comment: any;
  commentList: any = [];
  blockuser: string = '';
  mapStyle: any = "style=element:geometry.fill%7Csaturation:-60%7Cweight:2";
  createdDate: any;// = new Date().getTime();
  thumbimg: any;
  imglist: any = [];
  communityid: any;
  innerWidth: any;
  mapWidth: number = window.innerWidth;
  mapHeight: number = 200;
  deleted: boolean = false;
  blocked: boolean = false;
  privateitem: boolean = false;
  privatenoconfirm: boolean = true;
  showcategory: boolean = false;
  showclosebtn: boolean = false;
  chlistdir: any;
  usernickname: any;

  eventurl: any = '';
  eventshow: boolean = false;
  urlreg: any = '';
  urlchecked: boolean = false;
  pointdata: any = [];
  pointtotal: number = 0;
  eventdone: number = 0;
  invitecount: number = 0;
  writecount: number = 0;
  cmtcount: number = 0;
  eventstart: any;
  replied: number = 0;
  chkdevice: boolean = false;

  commenttype: any = null;
  menu: any;
  discountinfo: any[] = [];
  slideOpts = {
    pagination: {
      /*
      el: '.swiper-num',
      clickable: true,
        renderBullet: function (index, className) {
          return '<span class=\"' + className + '\">' + (index + 1) + '</span>';
        }*/
      el: '.swiper-pagination',
      clickable: true,
    }
  }
  id;
  ignore;
  loclancode;
  modal;

  chname: any;
  postname: any;
  carditems: any;
  isclosed: any;
  shortdesc: any;
  chimg: any;
  state: any;
  //advurl:any;

  advlist: any[] = [];

  images: any[] = [];
  imagethumbs: any[] = [];
  recmt: any[] = [];
  attachedlink: any;
  showadv: boolean = false;
  @ViewChild('attachlinkA') attachlinkA;

  list: Subscription;
  listitem: any[] = [];
  timenow = new Date().getTime();
  otherpostlist: any[] = [];
  itemtypemain;

  isnotice: boolean = false;
  chowner: any;
  chtype: any;
  thumbclickable: boolean = true;
  isprivatecmt: boolean = false;
  isanonymouscmt: boolean = false;

  choptions: any;
  boardoption: any;

  adbannerOpt = {
    autoplay: { delay: 6000 },
    speed: 500,
    // loop: this.advlist.length > 1 ? true : false, 
    loop: false,
    slidesPerView: 1,
    // slidesPerView: this.advlist.length >= 2 ? 2 : 1,
    slidesPerGroup: 1,
    // centeredSlides: true,
    breakpoints: {
      576: {
        slidesPerView: this.advlist.length >= 2 ? 2 : 1
        // spaceBetween: 10
      },
      992: {
        slidesPerView: 
        this.advlist.length >= 3 ? 3 : this.advlist.length >= 2 ? 2 : 1
        // spaceBetween: 20
      },
      1200: {
        slidesPerView: 
        this.advlist.length >= 4 ? 4 : this.advlist.length >= 3 ? 3 : this.advlist.length >= 2 ? 2 : 1
        // slidesPerView: this.advlist.length >= 2 ? 2 : 1
        // spaceBetween: 30
      }
    }
  }

  data;
  showprecmt;
  wname;

  constructor(private route: ActivatedRoute, public alertCtrl: AlertController, private toastCtrl: ToastController, public http: Http,
    @Optional() private outlet: IonRouterOutlet, public popoverCtrl: PopoverController, public navCtrl: NavController, public modalCtrl: ModalController, public msgfunc: msgfunc,
    public translate: TranslateService, public gv: GlobalVars, public afDB: AngularFireDatabase, public afAuth: AngularFireAuth,
    private metaService: MetaService, private sharefunc: sharefunc, private pointfunc: pointfunc, private router: Router,
    private actionsheetCtrl: ActionSheetController, private imgupload: Imgupload, private orderby: OrderByPipe, private safehtml: SafeHtmlPipe,
    private removehtmltag: RemovehtmltagPipe
    ,private fs:AngularFirestore
    // , private statusBar: StatusBar
  ) {
    // this.ch = this.route.snapshot.paramMap.get('ch');
    this.itemId = this.route.snapshot.paramMap.get('id');
    this.data = this.route.snapshot.paramMap.get('data');
    // this.chlistdir = '/test/post';

    // let status bar overlay webview
    // this.statusBar.overlaysWebView(true);
    // set status bar to white
    // this.statusBar.backgroundColorByHexString('#ffffff');
  }

  /*
  domyfunc(){

    this.afDB.database.ref('/list').orderByChild('type').equalTo('place').once('value').then(list=>{
      list.forEach(item=>{
        let it = item.val();
        this.afDB.database.ref('/list/'+item.key).update({liketype:'hide', commenttype:'feedback'})
      })
    })
     this.afDB.database.ref('/list').orderByChild('type').equalTo('house').once('value').then(list=>{
      list.forEach(item=>{
        let it = item.val();
        this.afDB.database.ref('/list/'+item.key).update({liketype:'hide'})
      })
    })
     this.afDB.database.ref('/list').orderByChild('type').equalTo('work').once('value').then(list=>{
      list.forEach(item=>{
        let it = item.val();
        this.afDB.database.ref('/list/'+item.key).update({liketype:'hide'})
      })
    })
     this.afDB.database.ref('/list').orderByChild('type').equalTo('trade').once('value').then(list=>{
      list.forEach(item=>{
        let it = item.val();
        this.afDB.database.ref('/list/'+item.key).update({liketype:'hide'})
      })
    })
     this.afDB.database.ref('/list').orderByChild('type').equalTo('talent').once('value').then(list=>{
      list.forEach(item=>{
        let it = item.val();
        this.afDB.database.ref('/list/'+item.key).update({liketype:'hide'})
      })
    })
  }*/

  ngOnInit() {
    // setTimeout(_=>{
    //   this.afDB.database.ref('/list').orderByKey().startAt('-M3o3YKu4QXEhirdqG9p').once('value').then(rst=>{
    //     rst.forEach(r=>{
    //       let data = r.val();
    //       if(data.comment){
    //         Object.keys(data.comment).forEach(commentkey=>{
    //           if(data.comment[commentkey])
    //           console.log(r.key + ' Start')
    //           this.fs.collection('post').doc(r.key).collection('comment').doc(commentkey).set(data.comment[commentkey])
              
    //         })
    //       }
    //     })
    //   })  
    // },3000)
    /*
        firebase.database().ref('/userProfile').orderByChild('loc').equalTo('cairns').once('value').then(u=>{
          u.forEach(user=>{
            if(user.key != 'GWJcBZmdATVzY9h3WqBzs0dQbFS2')
            {
              this.msgfunc.sendnotionly('엘르미용실 할인쿠폰을 받으셨습니다.', '할인쿠폰을 받으셨습니다.','엘르미용실 할인쿠폰을 받으셨습니다.',
              'view', '-M33t9-hlGg2emhjsWVP', user.key);
            
            }
            })
        })*/
    /*
    let user=[];
    this.afDB.database.ref('/backup/point').once('value').then(rst=>{
      rst.forEach(r=>{
        let data = r.val();
        let exist = false;
        user.forEach(u=>{
          if(u.uid == data.uid)
            {
              exist = true;
              u.point += data.point;
            }
        })
        if(!exist)
          user.push({uid:data.uid, point:data.point})
        //console.log(r.val())
      })
    }).then(_=>{
      setTimeout(t=>{
        user = this.orderby.transform(user, 'point');
        user.forEach(cc=>{
          this.afDB.database.ref('/userProfile/'+cc.uid+'/nickname').once('value').then(o=>{
            console.log(o.val()+ ':'+(-cc.point)+'포인트 적용됨 >>>' + '('+cc.uid+')');
          })
        })
        
      },3000) //console.log(user);
    })
   /*
   this.afDB.database.ref('/list/-M-vzbtfWmD7ACSn3g6F').once('value').then(rst=>{

      let newdata = rst.val().description.replace(/<br><table border/ig,'<table border').replace(/max-width: 810px/ig,'width: 100%')
      console.log(rst.val().description)
      this.afDB.database.ref('/list/-M-vzbtfWmD7ACSn3g6F').update({description:newdata})
    })*/
    //코코스 링크제거
    /*
    this.afDB.database.ref('/list/-M00vOHG6IpA8Z8c9CvD').once('value').then(rst=>{
      let newdata = rst.val().description.replace(/padding: 0px 40px;/ig,'padding: 0px 0px')
      console.log(rst.val().description)
      this.afDB.database.ref('/list/-M00vOHG6IpA8Z8c9CvD').update({tempdesc:rst.val().description, description:newdata})
    })*/
    //console.log(new Date('Thu, 09 Jan 2020 20:01:08 +0000')) //다음카페는 날짜정보데이터형식이 달라서 다른형태로 받아와야할듯. 아님 제목으로 비교.
    //console.log(new Date('Fri, 27 Dec 2019 07:00:47 +0000'))

    //this.domyfunc()
    /*this.afDB.database.ref('/adv').set({});
    
    let addata = [];
    this.afDB.database.ref('/localmanage/cairns_kr/ad/home').once('value').then(rst=>{
      rst.forEach(ad=>{
        //if(ad.val().title == '채널 광고 문의')
        addata.push({route:'/chview/'+ad.val().id, target:'post@202001@cairns_kr', img:ad.val().img, show:true, uid:this.gv.uid, createdDate:new Date().getTime(), prioritypoint:1000})
      })
      addata.forEach(t=>{
        this.afDB.database.ref('/adv/banner').push(t)
      })
    })
    */
    /*
    firebase.database().ref('/func/feed').push({
      category:'쉐어하우스',
      chid:'-LZhq_q-3Nxjhca5kQ8i',
      bindrequire:'dc',
      communityname:'쉐어',
      startindex:3,
      createdDate:new Date().getTime(),
      endDate:new Date().getTime()+(1000*60*60*24*365),
      url:'http://fetchrss.com/rss/5e1296b28a93f83f588b45675e1571c68a93f85c0b8b4567.xml',
      //userId:'KVHCH7VrZWQIxh87Vr9OwRbL4ZD3'
    })*/

    /*
    {
      category:'',
      chid:'-LtYhVLPorx2H1x1VxK2',
      communityname:'하나원 EDU & TOUR',
      createdDate:new Date().getTime(),
      endDate:new Date().getTime()+(1000*60*60*24*365),
      url:'man',
      userId:''
    }*/

    //setTimeout(_ => { this.showadv = true }, 1000)
    if (`${this.id}` != 'undefined')
      this.itemId = `${this.id}`;
    if (`${this.ignore}` != 'undefined')
      this.ignore = `${this.ignore}`;
    if (`${this.modal}` != 'undefined')
      this.modal = `${this.modal}`;
    if (`${this.loclancode}` != 'undefined')
    //   this.chlistdir = this.gv.getListDir(`${this.loclancode}`);
    // else {
    //   if (this.route.snapshot.paramMap.get('loclan'))
    //     this.chlistdir = this.gv.getListDir(this.route.snapshot.paramMap.get('loclan'));
    //   else
    //     this.chlistdir = this.gv.getListDir();
    // }

    //this.loaddata(); 
    /*setTimeout(_=> {
      this.advurl = "https://firebasestorage.googleapis.com/v0/b/localnews-e350b.appspot.com/o/advertise%2F1562217165002채널광고_main_01.jpg?alt=media&token=bff07e9a-a409-4582-937d-9a89eead0644";
    }, 1000);*/

    /*
    setTimeout(_ => {
    this.afDB.database.ref('/localmanage/' + this.gv.loc + '_' + this.gv.lan + '/ad/home').once('value').then(items => {

      let timenow = new Date().getTime();
      this.advlist = [];
      if(items.val())
      items.val().forEach(item => {
        if (item.show == 'all' && (timenow > new Date(item.startdate).getTime()
          && timenow < new Date(item.enddate).getTime())) {
          item.orderrate = Math.floor(Math.random() * item.advorder);
          this.advlist.push(item);
        }
      })
      this.advlist = this.orderby.transform(this.advlist, 'orderrate', 'desc');
      if(this.advlist.length) this.showadv = true //console.log(this.advlist);
    });},(this.gv.loc&&this.gv.lan)?300:5000)
    */

    console.log(this.gv.postPredata);
    // this.afDB.database.ref('/list/'+this.itemId).once('value').then(rst=>{
    //   console.log(rst.val())
    //   this.fs.collection('post').doc(this.itemId).update(rst.val())
    // })
    
    if(this.gv.postPredata && this.gv.postPredata.key == this.itemId)
    {
      this.loaddata(this.gv.postPredata.data)
    }
    else
      this.loaddata();

    this.gv.boardlist.forEach(item => {
      if (item.$key == this.itemId) {
        item.haveread = true;
      }
    })
    this.gv.storeOnStorage('listdata', this.gv.boardlist);
  }

  getadv() {
    this.advlist = [];
    let now = new Date();
    let targetstart = 'post@' +
      now.getFullYear() +
      (((now.getMonth() + 1) < 10) ? '0' + (now.getMonth() + 1) : '' + now.getMonth()) + '@' + this.communityid;

    this.afDB.database.ref('/adv/banner').orderByChild('target').equalTo(targetstart).once('value').then(items => {
      items.forEach(item => {
        if (item.val().show) {
          let orderrate = Math.floor(Math.random() * item.val().prioritypoint);
          this.advlist.push({ img: item.val().img, url: item.val().route, orderrate: orderrate });
        }
      })

      if (this.advlist.length > 0) {
        setTimeout(_ => { this.showadv = true }, 600)
        this.advlist = this.orderby.transform(this.advlist, 'orderrate', 'desc').slice(0, 5);
      }
      if (this.advlist.length < 2 && !this.modal) {
        setTimeout(_ => { this.showadv = true }, 600);
        this.advlist.push({ url: '/pitemdetail/-LrRdv_EBNt47INniaxj', orderrate: 0, adtext: '채널에 광고하기' });
      }

      this.adbannerOpt.slidesPerView = 1;
      // this.adbannerOpt.slidesPerView = this.advlist.length >= 2 ? 2 : 1;
      this.adbannerOpt.breakpoints = {
        576: {
          slidesPerView: this.advlist.length >= 3 ? 2 : 1
          // spaceBetween: 10
        },
        992: {
          slidesPerView: this.advlist.length >= 3 ? 3 : this.advlist.length >= 2 ? 2 : 1
          // spaceBetween: 20
        },
        1200: {
          slidesPerView: this.advlist.length >= 4 ? 4 : this.advlist.length >= 3 ? 3 : this.advlist.length >= 2 ? 2 : 1
          // spaceBetween: 30
        }
      }
    });
  }



  jumpadv(slide, i) {
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
    this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/adclick').transaction(view => {
      return (view || 0) + 1;
    })
    if (this.advlist[i].url.indexOf('/chview') > -1 || this.advlist[i].url.indexOf('/chmain') > -1) {
      if (!this.modal) {
        let parent = this.router.url.substring(0, this.router.url.indexOf(this.itemId));
        this.navCtrl.navigateForward([parent + this.advlist[i].url])
      }
      else {
        if (this.advlist[i].url.indexOf('/chview') > -1) {
          // console.log(this.advlist[i].url);
          // console.log(this.advlist[i].url.substr(this.advlist[i].url.indexOf('/chview')+7));
          this.openmodalchview(this.advlist[i].url.substr(this.advlist[i].url.indexOf('/chview') + 7));
        }
        else
          this.openmodalchmain();
      }
      this.navCtrl.navigateForward([this.advlist[i].url]);
    }
    else {
      if (!this.modal) {
        this.gv.aditem = { key: this.communityid, name: this.chname, desc: this.shortdesc, category: null, img: this.chimg };
        this.navCtrl.navigateForward([this.advlist[i].url]);
      }
    }
    /*
    slide.getActiveIndex().then(index => {
      if (index == 0)
        index = this.advlist.length;
      else if (index > this.advlist.length)
        index = 1;

      /* 업데이트후 아래로 대체 *-/
      /*
      if(!this.modal){
        let parent = this.router.url.substring(0, this.router.url.indexOf(this.itemId));
          this.router.navigate([parent + this.advlist[index - 1].id])
      }
      else
        this.openmodalchview(this.advlist[index - 1].id);
      *-/

      // 이하 업데이트 후 필요한것 
      if (this.advlist[index - 1].url.indexOf('/chview') > -1 || this.advlist[index - 1].url.indexOf('/chmain') > -1)
      {
        if (!this.modal) {
          let parent = this.router.url.substring(0, this.router.url.indexOf(this.itemId));
          this.navCtrl.navigateForward([parent + this.advlist[index - 1].url])
        }
        else
          {
            if(this.advlist[index - 1].url.indexOf('/chview') > -1)
              {
              // console.log(this.advlist[index - 1].url);
              // console.log(this.advlist[index - 1].url.substr(this.advlist[index - 1].url.indexOf('/chview')+7));
              this.openmodalchview(this.advlist[index - 1].url.substr(this.advlist[index - 1].url.indexOf('/chview')+7));
              }
            else
              this.openmodalchmain();
          }
        this.navCtrl.navigateForward([this.advlist[index - 1].url]); 
      } 
      else
        {
          if (!this.modal) {
            this.gv.aditem = {key: this.communityid, name:this.chname, desc:this.shortdesc, category:null, img:this.chimg};
            this.navCtrl.navigateForward([this.advlist[index - 1].url]);
          }
        }
        
    })
    */
  }

  ionViewDidEnter() {
    //this.gv.chreloadrequest = true;
    //this.loaddata();
  }
  close() {
    if (`${this.modal}` != 'undefined') {
      this.modalCtrl.dismiss();
    }
    else if (this.outlet && this.outlet.canGoBack()) {
      this.outlet.pop();
    }
    //this.outlet.pop() }
    else {
      this.navCtrl.navigateBack('/');
    }
  }

  mapclick(str) {
    if (str.split('*#*').length == 3)
      this.map(str.split('*#*')[0], str.split('*#*')[1], str.split('*#*')[2])
  }
  async linkclick(str) {
    if (str.indexOf("//") == -1) {
      this.modalCtrl.create({
        component: ChviewPage,
        cssClass: 'modalfull',
        componentProps: <null>{ id: str.split('*#*')[0], modal: true, loclancode: str.split('*#*')[1] ? str.split('*#*')[1] : null }
      }).then(modal => {
        modal.present();
      });
    }
    else {
      window.open(str, '_blank', 'EnableViewPortScale=yes');
    }
  }

  gochmain(category?) {
    if (!this.modal) {
      let parent = this.router.url.substring(0, this.router.url.indexOf('/chview'));
      this.navCtrl.navigateForward([parent + '/chmain/' + this.communityid])
    }
    else {
      this.openmodalchmain();
    }
  }

  gochview(item) {
    this.gv.postPredata = {key:item.$key,data:item.data}
    if (!this.modal) {
      let parent = this.router.url.substring(0, this.router.url.indexOf('/chview'));
      this.navCtrl.navigateForward([parent + '/chview/' + item.$key])
    }
    else {
      this.openmodalchview(item.$key);
    }
  }
  async openmodalchview(key) {
    this.modalCtrl.create({
      component: ChviewPage,
      cssClass: 'modalfull',
      componentProps: <null>{ id: key, modal: true }
    }).then(modal => {
      modal.present();
    });
  }

  async openmodalchmain() {
    this.modalCtrl.create({
      component: ChmainPage,
      cssClass: 'modalfull',
      componentProps: <null>{ chkey: this.communityid, modal: true }
    }).then(modal => {
      modal.present();
    });
  }

  async openmodalpm(itemid) {
    this.modalCtrl.create({
      component: PitemdetailPage,
      cssClass: 'modalfull',
      componentProps: <null>{ id: itemid, modal: true }
    }).then(modal => {
      modal.present();
    });
  }

  async filldata(element)
  {
      this.item = element;
      element['comment'] = [];
      this.commentList = [];//element['comment']
      await this.fs.collection('post').doc(this.itemId).collection('comment').get().subscribe(rst=>{
        console.log(rst.docs.length)
        rst.forEach(r=>{
          let commentdata;
          commentdata = r.data();
          commentdata.$key = r.id;
          this.afDB.database.ref('/userProfile/' + commentdata['userId'] + '/sum')
            .once('value').then(rst=>{
              let data;
              if(rst.val())
              {
                data = rst.val();
                data['nick'] = !commentdata['anonymous'] ? data['nick'] : '익명의채널러';
                data['img'] = !commentdata['anonymous'] ? data['img'] : '';
                data['lvl'] = !commentdata['anonymous'] ? data['lvl'] : '';
                data['realnick'] = data['nick'];
              }
              commentdata['sum'] = data;
            })
          if (commentdata['likes']) {
            let tempArr = [];
            commentdata['ilike'] = false;
            for (let i = 0; i < Object.keys(commentdata['likes']).length; i++) {
              if (commentdata['likes'][Object.keys(commentdata['likes'])[i]] == true) {
                tempArr.push(Object.keys(commentdata['likes'])[i]);
                if (
                  Object.keys(commentdata['likes'])[i] == this.gv.uid ||
                  Object.keys(commentdata['likes'])[i] == this.gv.tid
                ) {
                  commentdata['ilike'] = true;
                }
              }
            }
            commentdata['likecount'] = tempArr.length;
          }
          if (commentdata['loc']) {
            switch (commentdata['loc']) {
              case 'sydney': commentdata['loccode'] = 'SYD'; break;
              case 'melbourne': commentdata['loccode'] = 'MEL'; break;
              case 'brisbane': commentdata['loccode'] = 'BNE'; break;
              case 'perth': commentdata['loccode'] = 'PER'; break;
              case 'cairns': commentdata['loccode'] = 'CNS'; break;
              case 'otherloc': commentdata['loccode'] = 'ETC'; break;
            }
          } 
          this.commentList.push(commentdata);
          element['comment'].push(commentdata)
        })
        console.log(this.commentList)
      })
      // if(element['comment'])
      // Object.keys(element['comment']).map(key=>{
      //   let Items = element['comment'][key];
      //   Items.$key = key;
      //     if (Items['likes']) {
      //         let tempArr = [];
      //         Items['ilike'] = false;
      //         for (let i = 0; i < Object.keys(Items['likes']).length; i++) {
      //           if (Items['likes'][Object.keys(Items['likes'])[i]] == true) {
      //             tempArr.push(Object.keys(Items['likes'])[i]);
      //             if (
      //               Object.keys(Items['likes'])[i] == this.gv.uid ||
      //               Object.keys(Items['likes'])[i] == this.gv.tid
      //             ) {
      //               Items['ilike'] = true;
      //             }
      //           }
      //         }
      //         Items['likecount'] = tempArr.length;
      //       }
            
      //       //Items['sum'] = 
      //       this.afDB.database.ref('/userProfile/' + Items['userId'] + '/sum')
      //       .once('value').then(rst=>{
      //         let data;
      //         if(rst.val())
      //         {
      //           data = rst.val();
      //           data['nick'] = !Items['anonymous'] ? data['nick'] : '익명의채널러';
      //           data['img'] = !Items['anonymous'] ? data['img'] : '';
      //           data['lvl'] = !Items['anonymous'] ? data['lvl'] : '';
      //           data['realnick'] = data['nick'];
      //         }
      //         Items['sum'] = data;
      //       })
      //       if (Items['loc']) {
      //         switch (Items['loc']) {
      //           case 'sydney': Items['loccode'] = 'SYD'; break;
      //           case 'melbourne': Items['loccode'] = 'MEL'; break;
      //           case 'brisbane': Items['loccode'] = 'BNE'; break;
      //           case 'perth': Items['loccode'] = 'PER'; break;
      //           case 'cairns': Items['loccode'] = 'CNS'; break;
      //           case 'otherloc': Items['loccode'] = 'ETC'; break;
      //         }
      //       } 
      //   this.commentList.push(Items)
      // })        
      
      this.itemtypemain = element.category;
      this.isnotice = element.notice;
      if (element.loc) {
        switch (element.loc) {
          case 'sydney': this.writerloccode = 'SYD'; break;
          case 'melbourne': this.writerloccode = 'MEL'; break;
          case 'brisbane': this.writerloccode = 'BNE'; break;
          case 'perth': this.writerloccode = 'PER'; break;
          case 'cairns': this.writerloccode = 'CNS'; break;
          case 'otherloc': this.writerloccode = 'ETC'; break;
        }
      }

      if (!this.ignore && element) {
        this.gv.addrecent(this.itemId, this.gv.loc + '_' + this.gv.lan);
        this.gv.addvisitedch(element['categoryId'], element['createdDate']);
      }
      //set meta tag
      if (element) {
        if (element.communityname) {
          this.chname = element.communityname;
        } else {
          switch (element.type) {
            case "house": this.chname = "쉐어"; break;
            case "trade": this.chname = "매매"; break;
            case "work": this.chname = "구인"; break;
            case "place": this.chname = "장소"; break;
          }
        }
        this.postname = element.title;
        this.metaService.setTitle(this.postname + ' - ' + this.chname);
        this.metaService.setTag('og:title', this.postname + ' - ' + this.chname + '| Channel');
      }

      if (!element || !element['createdDate']) { this.deleted = true }
      else if (element['private'] && this.privatenoconfirm) {
        this.privateitem = true;
        this.communityid = element['categoryId'];
        this.afDB.database.ref('/category/' + element['categoryId']).once('value').then(catrst => {
          var prepwd;
          if (this.gv.uid) {
            this.afDB.database.ref('/userProfile/' + this.gv.uid + '/community').once('value').then(savedcom => {
              if (savedcom.val())
                savedcom.forEach(element => {
                  if (element.val().id == this.communityid)
                    prepwd = element.val().pwd
                });
            }).then(_ => {
              if (catrst.child('password').val() == prepwd) {
                this.privatenoconfirm = false;
                this.loaddata();
              }
              else if (prepwd)
                this.callpwdalert(catrst, this.communityid, true);
              else
                this.callpwdalert(catrst, this.communityid);
            })
          }

          else
            this.callpwdalert(catrst, this.communityid)
        })
      }
      else {
        //var readcount = parseInt(element['read']);
        // this.afDB.database.ref(this.chlistdir + '/' + this.itemId).child('read').transaction(read => {
        //   return (read || 0) + 1;
        // })
        this.readcount = element['read'];
        const increment = firebase.firestore.FieldValue.increment(1);
        this.fs.collection('post').doc(this.itemId).update({read:increment})

        //this.desctxt = element['description'];
        this.communityid = element['categoryId'];
        this.createdDate = element['createdDate'];
        this.state = element['state'];
        if (this.state == 'close')
          this.translate.get("This post(AD) is closed").subscribe(res => {
            this.presentToast(res);
          });
        this.commenttype = element['commenttype'];
        if (!this.commenttype && element['type'] == 'place')
          this.commenttype = 'feedback';

        let tagdesc = '';
        this.itemdesc = element['description'];
        tagdesc = this.removehtmltag.transform(this.itemdesc);

        if (element['comment']) {
          Object.keys(element['comment']).forEach(k => {
            if (!element['comment'][k].private && element['comment'][k].content) {
              tagdesc += element['comment'][k].content;
            }
          })
        }
        this.metaService.setTag('og:description', tagdesc);

        //this.writerprofile = this.afDB.object('/userProfile/'+ element['userId']);
        this.writerid = element['userId']; //this.afDB.object('/userProfile/'+ element['userId']);
        //console.log(this.writerid);
        if (!this.writerloccode) {
          this.afDB.database.ref('/userProfile/' + this.writerid + '/loc').once('value').then(rst => {
            var element = rst.val();
            switch (element) {
              case 'sydney': this.writerloccode = 'SYD'; break;
              case 'melbourne': this.writerloccode = 'MEL'; break;
              case 'brisbane': this.writerloccode = 'BNE'; break;
              case 'perth': this.writerloccode = 'PER'; break;
              case 'cairns': this.writerloccode = 'CNS'; break;
              case 'otherloc': this.writerloccode = 'ETC'; break;
            }
          });
        }
        // this.afDB.database.ref('/userProfile/' + element['userId'] + '/loc').once('value').then(loc => {
        //   loc.val()?this.writerloc = loc.val():''
        // });
        this.afDB.database.ref('/userProfile/' + element['userId'] + '/sum').once('value').then(sum => {
          if (sum.val()) {
            this.writername = sum.val().nick;
            this.writerimg = sum.val().img;
            this.writerlvl = sum.val().lvl;
          }
        })
        //this.writerimg = this.afDB.object('/userProfile/'+ element['userId'] + '/profileThumbPicture');
        if (element['imagethumb'])// && element['images'].length != this.imglist.length)
          this.thumbimg = element['imagethumb'][0]['url'];
        if (element['images']) {
          this.imglist = [];
          element['images'].forEach(img => {
            this.imglist.push(img);
          });
          if (this.imglist.length > 0)
            this.metaService.setTag('og:image', this.imglist[0].url);
        }

        if (element['menu'])
          this.menu = element['menu'];

        if (this.gv.blockusers && this.gv.blockusers.includes(this.writerid))
          this.blocked = true;
      }

      // this.afDB.database.ref('/category/' + this.communityid + '/userId').once('value').then(_=>{ this.chowner = _.val() });
      // this.afDB.database.ref('/category/' + this.communityid + '/type').once('value').then(_=>{ this.chtype = _.val() });
      this.afDB.database.ref('/category/' + this.communityid + '/').once('value').then(_ => {
        if (_.val()) {
          let _ch = _.val();
          this.chowner = _ch.userId;
          if (_ch.type) {
            this.chtype = _ch.type;
          } else {
            switch (_ch.name) {
              // type 지정이 가능하면 이거 없어도됨.
              case '쉐어': this.chtype = 'share'; break;
              case '장소': this.chtype = 'place'; break;
              case '구인': this.chtype = 'work'; break;
              case '매매': this.chtype = 'trade'; break;
              default: this.chtype = 'community';
            }
          }
          this.shortdesc = _.val().shortdesc;
          this.chimg = _.val().imagethumb?_.val().imagethumb[0].url:null;
          if (_ch.options) this.choptions = _ch.options;
        }
      });
      this.otherpost(element);
      this.getadv();

      this.afDB.database.ref('/category/' + this.communityid + '/options').once('value').then(options => {
        let data = options.val();
        if (data) {
          this.choptions = data;
        }
      });
      this.afDB.database.ref('/category/' + this.communityid + '/category/').once('value').then(board => {
        this.boardoption = [];
        board.forEach(item => {
          let data = item.val();
          if (data.name == this.itemtypemain) {
            this.boardoption.privatecmt = data.privatecmt ? data.privatecmt : false;
            this.boardoption.anonymouscmt = data.anonymouscmt ? data.anonymouscmt : false;
          }
        })
      });
  }

  loaddata(data?) {
    if(data)
      this.filldata(data);
    else
      this.fs.collection('post').doc(this.itemId).get().subscribe(rst => {
        var element = rst.data();
        this.filldata(element);
      });
    // this.afDB.object(this.chlistdir + '/' + this.itemId + '/read').snapshotChanges().subscribe(rst=>{
    //   this.readcount = rst.payload.val()
    // });
    // console.log(this.readcount)
    this.comment = this.fs.collection('post').doc(this.itemId).collection('comment');
    //this.comment = this.afDB.list(this.chlistdir + '/' + this.itemId + '/comment');
    
    // this.comment.snapshotChanges().subscribe(Items => {
    //   this.commentList = Items.map((Items) => {
    //     return Items;
    //   }).map((_Items) => {
    //     let Items = _Items.payload.val();
    //     Items['$key'] = _Items.key;
    //     // this.afDB.database.ref('/userProfile/' + Items['userId'] + '/loc').once('value').then(loc => {
    //     //   loc.val()?Items.uloc = loc.val():''
    //     // });
    //     if (Items['likes']) {
    //       let tempArr = [];
    //       Items['ilike'] = false;
    //       for (let i = 0; i < Object.keys(Items['likes']).length; i++) {
    //         if (Items['likes'][Object.keys(Items['likes'])[i]] == true) {
    //           tempArr.push(Object.keys(Items['likes'])[i]);
    //           if (
    //             Object.keys(Items['likes'])[i] == this.gv.uid ||
    //             Object.keys(Items['likes'])[i] == this.gv.tid
    //           ) {
    //             Items['ilike'] = true;
    //           }
    //         }
    //       }
    //       Items['likecount'] = tempArr.length;
    //     }
        
    //     //Items['sum'] = 
    //     this.afDB.database.ref('/userProfile/' + Items['userId'] + '/sum')
    //     .once('value').then(rst=>{
    //       if(rst.val())
    //       {
    //         data = rst.val();
    //         data['nick'] = !Items['anonymous'] ? data['nick'] : '익명의채널러';
    //         data['img'] = !Items['anonymous'] ? data['img'] : '';
    //         data['lvl'] = !Items['anonymous'] ? data['lvl'] : '';
    //         data['realnick'] = data['nick'];
    //       }
    //       Items['sum'] = data;
    //     })
    //     // .snapshotChanges().map(sum=>{
    //     //   let data;
    //     //   if (sum.payload.val()) {
    //     //     data = sum.payload.val();
    //     //     data['nick'] = !Items['anonymous'] ? data['nick'] : '익명의채널러';
    //     //     data['img'] = !Items['anonymous'] ? data['img'] : '';
    //     //     data['lvl'] = !Items['anonymous'] ? data['lvl'] : '';
    //     //     data['realnick'] = data['nick'];
    //     //   }
    //     //   return data;
    //     // });
        
    //     // this.afDB.database.ref('/userProfile/' + Items['userId'] + '/sum').once('value').then(sum => {
    //     //   if (sum.val()) {
    //     //     Items.nick = !Items['anonymous'] ? sum.val().nick : '익명의채널러';
    //     //     Items.img = !Items['anonymous'] ? sum.val().img : '';
    //     //     Items.lvl = !Items['anonymous'] ? sum.val().lvl : '';
    //     //     Items.realnick = sum.val().nick;
    //     //   }
    //     // })
    //     if (Items['loc']) {
    //       switch (Items['loc']) {
    //         case 'sydney': Items['loccode'] = 'SYD'; break;
    //         case 'melbourne': Items['loccode'] = 'MEL'; break;
    //         case 'brisbane': Items['loccode'] = 'BNE'; break;
    //         case 'perth': Items['loccode'] = 'PER'; break;
    //         case 'cairns': Items['loccode'] = 'CNS'; break;
    //         case 'otherloc': Items['loccode'] = 'ETC'; break;
    //       }
    //     } 
    //     // else {
    //     //   this.afDB.database.ref('/userProfile/' + Items['userId'] + '/loc').once('value').then(rst => {
    //     //     var element = rst.val();
    //     //     switch (element) {
    //     //       case 'sydney': Items['loccode'] = 'SYD'; break;
    //     //       case 'melbourne': Items['loccode'] = 'MEL'; break;
    //     //       case 'brisbane': Items['loccode'] = 'BNE'; break;
    //     //       case 'perth': Items['loccode'] = 'PER'; break;
    //     //       case 'cairns': Items['loccode'] = 'CNS'; break;
    //     //       case 'otherloc': Items['loccode'] = 'ETC'; break;
    //     //     }
    //     //   });
    //     // }


    //     //   Items.userProfile = this.afDB.object('/userProfile/'+Items['userId']);
    //     //   Items.recmt = this.afDB.list(this.chlistdir+'/'+this.itemId+'/comment/'+ Items.$key + '/recomment').map( (cmt) => { return cmt } ).map((cmt) => 
    //     // { return cmt.map((cmt) => {cmt.userProfile = this.afDB.object('/userProfile/'+cmt.userId); return cmt})} );;
    //     return Items;
    //   }
    //   );
    // })
    if (this.gv.uid) {
      this.afDB.database.ref('/alram/' + this.gv.uid).orderByChild('id').equalTo(this.itemId).once('value').then(item => {
        if (item.val()) {
          item.forEach(element => {
            if (!element.val().read)
              this.afDB.database.ref('/alram/' + this.gv.uid + '/' + element.key).update({ read: true })
          });
        }
      })
    }
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
    setTimeout(_ => {
      this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/view').transaction(view => {
        return (view || 0) + 1;
      })
    }, 4000)


  }


  // loaddata1() {
  //   //this.chlistdir = '/lists/brisbane_kr';
  //   //this.item = this.afDB.object(this.chlistdir + '/' + this.itemId).valueChanges();
  //   //this.itemdesc = this.afDB.object(this.chlistdir+'/'+this.itemId + '/description');
  //   this.afDB.database.ref(this.chlistdir + '/' + this.itemId).once('value').then(rst => {
  //     var element = rst.val();
  //     this.item = element;
  //     this.itemtypemain = element.category;
  //     this.isnotice = element.notice;
  //     if (element.loc) {
  //       switch (element.loc) {
  //         case 'sydney': this.writerloccode = 'SYD'; break;
  //         case 'melbourne': this.writerloccode = 'MEL'; break;
  //         case 'brisbane': this.writerloccode = 'BNE'; break;
  //         case 'perth': this.writerloccode = 'PER'; break;
  //         case 'cairns': this.writerloccode = 'CNS'; break;
  //         case 'otherloc': this.writerloccode = 'ETC'; break;
  //       }
  //     }

  //     if (!this.ignore && element) {
  //       this.gv.addrecent(this.itemId, this.gv.loc + '_' + this.gv.lan);
  //       this.gv.addvisitedch(element['categoryId'], element['createdDate']);
  //     }
  //     //set meta tag
  //     if (element) {
  //       if (element.communityname) {
  //         this.chname = element.communityname;
  //       } else {
  //         switch (element.type) {
  //           case "house": this.chname = "쉐어"; break;
  //           case "trade": this.chname = "매매"; break;
  //           case "work": this.chname = "구인"; break;
  //           case "place": this.chname = "장소"; break;
  //         }
  //       }
  //       this.postname = element.title;
  //       this.metaService.setTitle(this.postname + ' - ' + this.chname);
  //       this.metaService.setTag('og:title', this.postname + ' - ' + this.chname + '| Channel');
  //     }

  //     if (!element || !element['createdDate']) { this.deleted = true }
  //     else if (element['private'] && this.privatenoconfirm) {
  //       this.privateitem = true;
  //       this.communityid = element['categoryId'];
  //       this.afDB.database.ref('/category/' + element['categoryId']).once('value').then(catrst => {
  //         var prepwd;
  //         if (this.gv.uid) {
  //           this.afDB.database.ref('/userProfile/' + this.gv.uid + '/community').once('value').then(savedcom => {
  //             if (savedcom.val())
  //               savedcom.forEach(element => {
  //                 if (element.val().id == this.communityid)
  //                   prepwd = element.val().pwd
  //               });
  //           }).then(_ => {
  //             if (catrst.child('password').val() == prepwd) {
  //               this.privatenoconfirm = false;
  //               this.loaddata();
  //             }
  //             else if (prepwd)
  //               this.callpwdalert(catrst, this.communityid, true);
  //             else
  //               this.callpwdalert(catrst, this.communityid);
  //           })
  //         }

  //         else
  //           this.callpwdalert(catrst, this.communityid)
  //       })
  //     }
  //     else {
  //       //var readcount = parseInt(element['read']);
  //       this.afDB.database.ref(this.chlistdir + '/' + this.itemId).child('read').transaction(read => {
  //         return (read || 0) + 1;
  //       })

  //       //this.desctxt = element['description'];
  //       this.communityid = element['categoryId'];
  //       this.createdDate = element['createdDate'];
  //       this.state = element['state'];
  //       if (this.state == 'close')
  //         this.translate.get("This post(AD) is closed").subscribe(res => {
  //           this.presentToast(res);
  //         });
  //       this.commenttype = element['commenttype'];
  //       if (!this.commenttype && element['type'] == 'place')
  //         this.commenttype = 'feedback';

  //       let tagdesc = '';
  //       this.itemdesc = element['description'];
  //       tagdesc = this.removehtmltag.transform(this.itemdesc);

  //       if (element['comment']) {
  //         Object.keys(element['comment']).forEach(k => {
  //           if (!element['comment'][k].private && element['comment'][k].content) {
  //             tagdesc += element['comment'][k].content;
  //           }
  //         })
  //       }
  //       this.metaService.setTag('og:description', tagdesc);

  //       //this.writerprofile = this.afDB.object('/userProfile/'+ element['userId']);
  //       this.writerid = element['userId']; //this.afDB.object('/userProfile/'+ element['userId']);
  //       if (!this.writerloccode) {
  //         this.afDB.database.ref('/userProfile/' + this.writerid + '/loc').once('value').then(rst => {
  //           var element = rst.val();
  //           switch (element) {
  //             case 'sydney': this.writerloccode = 'SYD'; break;
  //             case 'melbourne': this.writerloccode = 'MEL'; break;
  //             case 'brisbane': this.writerloccode = 'BNE'; break;
  //             case 'perth': this.writerloccode = 'PER'; break;
  //             case 'cairns': this.writerloccode = 'CNS'; break;
  //             case 'otherloc': this.writerloccode = 'ETC'; break;
  //           }
  //         });
  //       }
  //       // this.afDB.database.ref('/userProfile/' + element['userId'] + '/loc').once('value').then(loc => {
  //       //   loc.val()?this.writerloc = loc.val():''
  //       // });
  //       this.afDB.database.ref('/userProfile/' + element['userId'] + '/sum').once('value').then(sum => {
  //         if (sum.val()) {
  //           this.writername = sum.val().nick;
  //           this.writerimg = sum.val().img;
  //           this.writerlvl = sum.val().lvl;
  //         }
  //       })
  //       //this.writerimg = this.afDB.object('/userProfile/'+ element['userId'] + '/profileThumbPicture');
  //       if (element['imagethumb'])// && element['images'].length != this.imglist.length)
  //         this.thumbimg = element['imagethumb'][0]['url'];
  //       if (element['images']) {
  //         this.imglist = [];
  //         element['images'].forEach(img => {
  //           this.imglist.push(img);
  //         });
  //         if (this.imglist.length > 0)
  //           this.metaService.setTag('og:image', this.imglist[0].url);
  //       }

  //       if (element['menu'])
  //         this.menu = element['menu'];

  //       if (this.gv.blockusers && this.gv.blockusers.includes(this.writerid))
  //         this.blocked = true;
  //     }

  //     // this.afDB.database.ref('/category/' + this.communityid + '/userId').once('value').then(_=>{ this.chowner = _.val() });
  //     // this.afDB.database.ref('/category/' + this.communityid + '/type').once('value').then(_=>{ this.chtype = _.val() });
  //     this.afDB.database.ref('/category/' + this.communityid + '/').once('value').then(_ => {
  //       if (_.val()) {
  //         let _ch = _.val();
  //         this.chowner = _ch.userId;
  //         if (_ch.type) {
  //           this.chtype = _ch.type;
  //         } else {
  //           switch (_ch.name) {
  //             // type 지정이 가능하면 이거 없어도됨.
  //             case '쉐어': this.chtype = 'share'; break;
  //             case '장소': this.chtype = 'place'; break;
  //             case '구인': this.chtype = 'work'; break;
  //             case '매매': this.chtype = 'trade'; break;
  //             default: this.chtype = 'community';
  //           }
  //         }
  //         this.shortdesc = _.val().shortdesc;
  //         this.chimg = _.val().imagethumb[0].url;
  //         if (_ch.options) this.choptions = _ch.options;
  //       }
  //     });
  //     this.otherpost(element);
  //     this.getadv();

  //     this.afDB.database.ref('/category/' + this.communityid + '/options').once('value').then(options => {
  //       let data = options.val();
  //       if (data) {
  //         this.choptions = data;
  //       }
  //     });
  //     this.afDB.database.ref('/category/' + this.communityid + '/category/').once('value').then(board => {
  //       this.boardoption = [];
  //       board.forEach(item => {
  //         let data = item.val();
  //         if (data.name == this.itemtypemain) {
  //           this.boardoption.privatecmt = data.privatecmt ? data.privatecmt : false;
  //           this.boardoption.anonymouscmt = data.anonymouscmt ? data.anonymouscmt : false;
  //         }
  //       })
  //     });

  //   });






  //   // 이하 지울예정  
  //   /*
  //   this.afDB.database.ref('/discount').orderByChild('id').equalTo(this.itemId).once('value').then
  //     (discounts=>{
  //       discounts.forEach(discount => {
  //       if(discount.val().ptype == 'zday')
  //       {
  //         if(new Date().getTime() >= new Date(discount.val().startdate).getTime() && new Date().getTime() < new Date(discount.val().enddate).getTime() + 1000*60*60*24)
  //           this.discountinfo.push(discount.val());
  //       }
  //       else if(discount.val().ptype == 'weekly')
  //         this.discountinfo.push(discount.val());
  //       else if(discount.val().ptype == 'time')
  //          this.discountinfo.push(discount.val());
  //       });
        
  //     })
  //     this.afDB.database.ref(this.chlistdir+'/'+this.itemId+'/channelid').once('value').then(chid=>{
  //       if(chid.val())
  //         {
  //           this.afDB.database.ref('/category/'+chid.val()).once('value').then(ch=>{
  //             this.channeldata = {key:ch.key, typemain:ch.val().typemain, imagethumb:ch.val().imagethumb, name:ch.val().name, replycount:ch.val().replycount, 
  //             read:ch.val().read, createdDate:ch.val().createdDate, type:ch.val().type, userId:ch.val().userId, 
  //              description:ch.val().description,
  //              recenttitle:this.afDB.list('/category/' + ch.key + '/recent')
  //             };
  //           })
  //         }
  //     })*/
  //   //// 위 지워야함


  //   this.comment = this.afDB.list(this.chlistdir + '/' + this.itemId + '/comment');
    
  //   this.comment.snapshotChanges().subscribe(Items => {
  //     this.commentList = Items.map((Items) => {
  //       return Items;
  //     }).map((_Items) => {
  //       let Items = _Items.payload.val();
  //       Items['$key'] = _Items.key;
  //       // this.afDB.database.ref('/userProfile/' + Items['userId'] + '/loc').once('value').then(loc => {
  //       //   loc.val()?Items.uloc = loc.val():''
  //       // });
  //       if (Items['likes']) {
  //         let tempArr = [];
  //         Items['ilike'] = false;
  //         for (let i = 0; i < Object.keys(Items['likes']).length; i++) {
  //           if (Items['likes'][Object.keys(Items['likes'])[i]] == true) {
  //             tempArr.push(Object.keys(Items['likes'])[i]);
  //             if (
  //               Object.keys(Items['likes'])[i] == this.gv.uid ||
  //               Object.keys(Items['likes'])[i] == this.gv.tid
  //             ) {
  //               Items['ilike'] = true;
  //             }
  //           }
  //         }
  //         Items['likecount'] = tempArr.length;
  //       }
        
  //       Items['sum'] = this.afDB.object('/userProfile/' + Items['userId'] + '/sum').snapshotChanges().map(sum=>{
  //         let data;
  //         if (sum.payload.val()) {
  //           data = sum.payload.val();
  //           data['nick'] = !Items['anonymous'] ? data['nick'] : '익명의채널러';
  //           data['img'] = !Items['anonymous'] ? data['img'] : '';
  //           data['lvl'] = !Items['anonymous'] ? data['lvl'] : '';
  //           data['realnick'] = data['nick'];
  //         }
  //         return data;
  //       });
        
  //       // this.afDB.database.ref('/userProfile/' + Items['userId'] + '/sum').once('value').then(sum => {
  //       //   if (sum.val()) {
  //       //     Items.nick = !Items['anonymous'] ? sum.val().nick : '익명의채널러';
  //       //     Items.img = !Items['anonymous'] ? sum.val().img : '';
  //       //     Items.lvl = !Items['anonymous'] ? sum.val().lvl : '';
  //       //     Items.realnick = sum.val().nick;
  //       //   }
  //       // })
  //       if (Items['loc']) {
  //         switch (Items['loc']) {
  //           case 'sydney': Items['loccode'] = 'SYD'; break;
  //           case 'melbourne': Items['loccode'] = 'MEL'; break;
  //           case 'brisbane': Items['loccode'] = 'BNE'; break;
  //           case 'perth': Items['loccode'] = 'PER'; break;
  //           case 'cairns': Items['loccode'] = 'CNS'; break;
  //           case 'otherloc': Items['loccode'] = 'ETC'; break;
  //         }
  //       } 
  //       // else {
  //       //   this.afDB.database.ref('/userProfile/' + Items['userId'] + '/loc').once('value').then(rst => {
  //       //     var element = rst.val();
  //       //     switch (element) {
  //       //       case 'sydney': Items['loccode'] = 'SYD'; break;
  //       //       case 'melbourne': Items['loccode'] = 'MEL'; break;
  //       //       case 'brisbane': Items['loccode'] = 'BNE'; break;
  //       //       case 'perth': Items['loccode'] = 'PER'; break;
  //       //       case 'cairns': Items['loccode'] = 'CNS'; break;
  //       //       case 'otherloc': Items['loccode'] = 'ETC'; break;
  //       //     }
  //       //   });
  //       // }


  //       //   Items.userProfile = this.afDB.object('/userProfile/'+Items['userId']);
  //       //   Items.recmt = this.afDB.list(this.chlistdir+'/'+this.itemId+'/comment/'+ Items.$key + '/recomment').map( (cmt) => { return cmt } ).map((cmt) => 
  //       // { return cmt.map((cmt) => {cmt.userProfile = this.afDB.object('/userProfile/'+cmt.userId); return cmt})} );;
  //       return Items;
  //     }
  //     );
  //   })
  //   if (this.gv.uid) {
  //     this.afDB.database.ref('/alram/' + this.gv.uid).orderByChild('id').equalTo(this.itemId).once('value').then(item => {
  //       if (item.val()) {
  //         item.forEach(element => {
  //           if (!element.val().read)
  //             this.afDB.database.ref('/alram/' + this.gv.uid + '/' + element.key).update({ read: true })
  //         });
  //       }
  //     })
  //   }
  //   let today = new Date();
  //   let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
  //   setTimeout(_ => {
  //     this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/view').transaction(view => {
  //       return (view || 0) + 1;
  //     })
  //   }, 4000)
  // }

  otherpost(elementdata) {    
    this.listitem = [];
    this.fs.collection('post', ref=>ref.where('categoryId','==',this.communityid)
    .where('category', '==', this.itemtypemain)
    .orderBy('createdDate')
    .where('createdDate','<',elementdata['createdDate']).limitToLast(2)
    )
    .get().subscribe(rst=>{
      rst.docs.forEach(doc=>{
        this.otherpostlist.unshift(
          { $key: doc.id, data:doc.data() });
      })
      this.otherpostlist.unshift(
      { $key: this.itemId, data:elementdata });
    })

    this.fs.collection('post', ref=>ref.where('categoryId','==',this.communityid)
    .where('category', '==', this.itemtypemain)
    .orderBy('createdDate')
    .where('createdDate','>',elementdata['createdDate']).limit(2)
    )
    .get().subscribe(rst=>{
     
      rst.docs.forEach(doc=>{
        this.otherpostlist.unshift(
          { $key: doc.id, data:doc.data() });
      })
      
    })
    

    

    // this.afDB.database.ref(this.chlistdir).orderByChild("categoryId").equalTo(this.communityid).once('value').then(rst => {
    //   console.log('갯수>>'+rst.numChildren());
    //   rst.forEach(data => {
    //     let item = data.val();
    //     if (item.category == this.itemtypemain)
    //       this.listitem.push({ key: data.key, createdDate: item.createdDate, userId: item.userId, title: item.title, replycount: item.replycount });
    //   })
    //   this.otherpostlist = [];
    //   let _templist = this.listitem;
    //   _templist = this.orderby.transform(_templist, 'createdDate', 'desc');
    //   let currentidx = _templist.map(function (e) { return e.createdDate; }).indexOf(indexDate);
    //   for (let i = -2; i < 3; i++) {
    //     if (_templist[currentidx + i] != undefined)
    //       this.otherpostlist.push(_templist[currentidx + i]);
    //   }
    // })
  }

  closeitem(card, idx, sidx, items?) {
    // card.isclosed = true;
    // console.log(this.afDB.database.ref(this.chlistdir+'/'+this.itemId+'/formdata/fieldlist/'+idx+'/value/'+sidx));
    // console.log(card + ' / ' + idx + ' / ' + sidx);
    if (items) {
      let _idx = items.findIndex((e) => { return e == card });
      this.fs.collection('post').doc(this.itemId).set({
        ['formdata.fieldlist.' + idx + '.value.' + _idx + '.isclosed']:true
      }, {merge: true})
      //this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/formdata/fieldlist/' + idx + '/value/' + _idx + '/isclosed').set(true);
    }
  }
  openitem(card, idx, sidx, items?) {
    if (items) {
      let _idx = items.findIndex((e) => { return e == card });
      this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/formdata/fieldlist/' + idx + '/value/' + _idx + '/isclosed').set(false);
    }
  }

  async presentbucketToast(message: string) {
    let show;
    this.translate.get('show').subscribe(res => { show = res });
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      buttons: [{
        text: show,
        handler: () => {
          this.navCtrl.navigateForward('/mybucket');
        }
      }],
      duration: 2000
    });

    return await toast.present();
  }

  notice() {
    // console.log(this.afDB.database.ref('/list/'+this.itemId+'/notice'));
    // this.afDB.database.ref('/list/'+this.itemId).update({notice:true})
    // this.afDB.database.ref('/list/'+this.itemId+'/notice').set(true);
    this.isnotice = !this.isnotice;
    this.afDB.database.ref('/list/' + this.itemId + '/notice').set(this.isnotice);
    if (this.isnotice) {
      // this.translate.get("Post has transfered").subscribe(res => {this.presentToast(res);});
      this.presentToast('공지글로 설정 되었습니다.');
    } else {
      this.presentToast('공지글 설정이 해제되었습니다.');
    }
  }
  share() {
    if (this.gv.cordova) {
      let chlink, psshare;
      this.translate.get('ChannelLink').subscribe(res => { chlink = res });
      this.translate.get('Share a Post').subscribe(res => { psshare = res });
      this.sharefunc.share(this.postname, chlink, 'https://channel.cafe/chview/' + this.itemId, this.gv.uid, psshare).then(rst => {
        if (rst) {
          let today = new Date();
          let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
          this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/postshare').transaction(view => {
            return (view || 0) + 1;
          })
        }
      })
    }
    else
      this.translate.get("Copied URL on clipboard.").subscribe(res => {
        this.presentToast(res);
      });
  }
  copytext(str) {
    /* 카피는 유저 클릭이 트리거 되야함. 이벤트 가져올것 레퍼런스 https://stackoverflow.com/questions/33321095/cannot-use-document-execcommandcopy-from-developer-console
    const el = document.createElement('textarea');
    el.value = str;
    console.log(el.value);
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    console.log('copied:' + str)*/
  }

  store() {
    if (this.gv.uid) {
      this.afDB.database.ref('/userData/' + this.gv.uid + '/mybucket/items').orderByChild('itemid').equalTo(this.itemId).once('value').then(rst => {
        if (rst.val()) {
          this.translate.get("Stored already").subscribe(res => {
            this.presentToast(res);
          });
        }
        else {
          this.afDB.database.ref('/userData/' + this.gv.uid + '/mybucket/items')
            .push({ itemid: this.itemId, loclancode: this.gv.loc + '_' + this.gv.lan }).then(_ => {
              this.translate.get("Stored").subscribe(res => {
                this.presentToast(res);
              });
              this.afDB.database.ref('/userData/' + this.gv.uid + '/mybucket').child('uncheck').transaction(uncheck => {
                return (uncheck || 0) + 1;
              })
            })
        }

      })
    }
    else
      this.requestsignin();
  }

  edit() {
    if (this.modal || this.gv.android)
      this.editonModal();
    else
      this.navCtrl.navigateForward(['/post/' + this.communityid + '/' + this.itemId]);
  }

  move() {
    this.afDB.database.ref('/category/' + this.communityid + '/category').once('value').then(cats => {
      let categorylist = [];
      cats.forEach(cat => {
        categorylist.push(
          { text: cat.val().name, handler: () => { this.updatecat(cat.val().name) } }
        );
      })
      let board;
      this.translate.get('ChannelLink').subscribe(res => { board = res });
      this.actionsheetCtrl.create({
        header: 'Category to move',
        buttons: categorylist
      }).then(_ => _.present())
    }
    )
  }

  updatecat(catname) {
    this.afDB.database.ref(this.chlistdir + '/' + this.itemId).update([{ category: catname }]).then(_ => {
      this.translate.get("Post has transfered").subscribe(res => {
        this.presentToast(res);
      });
      this.gv.chreloadrequest = true;
    });
  }

  async editonModal() {
    this.modalCtrl.create({
      component: PostPage,
      cssClass: 'modalfull',
      componentProps: <null>{ ch: this.communityid, item: this.itemId }
    }).then(modal => {
      modal.present();
    });
  }

  async delete() {
    let hd, msg, cancel, confirm;
    this.translate.get("Once you delete posting, it won't recover. Are you sure to delete?").subscribe(res => { msg = res });
    this.translate.get('Posting Delete confirm').subscribe(res => { hd = res });
    this.translate.get('Cancel').subscribe(res => { cancel = res });
    this.translate.get('Confirm').subscribe(res => { confirm = res });

    const alert = await this.alertCtrl.create({
      header: hd,
      message: msg,
      buttons: [
        {
          text: cancel,
          role: 'cancel'
        },
        {
          text: confirm,
          handler: () => {
            this.afDB.database.ref('/userProfile/' + this.gv.uid + '/write')
              .orderByChild('itemid').equalTo(this.itemId).once('value')
              .then(removeitem => {
                removeitem.forEach(element => {
                  this.afDB.database.ref('/userProfile/' + this.gv.uid + '/write/' + element.key).remove();
                });
                this.pointfunc.addpoint(this.writerid, 'postingremove', -10, this.itemId, null);
              })
            this.fs.collection('post').doc(this.itemId).delete().then(_ => {
              this.afDB.database.ref('/category/' + this.communityid + '/recent').orderByChild('itemid')
                .equalTo(this.itemId).once('value').then(item => {
                  item.forEach(it => {
                    this.afDB.database.ref('/category/' + this.communityid + '/recent/' + it.key).remove();
                  })
                })
              this.translate.get("Posting deleted.").subscribe(res => {
                this.presentToast(res);
              });
              this.gv.chreloadrequest = true;
              this.close();
            }
            );
          }
        }
      ]
    });
    return await alert.present();
  }

  changestate() {
    if (this.state == 'close') {
      this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/state').set('active');
      this.state = 'active';
      this.translate.get("Resume post AD").subscribe(res => {
        this.presentToast(res);
      });
      this.gv.chreloadrequest = true;
    }
    else if (this.state == 'active') {
      this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/state').set('close');
      this.state = 'close';
      this.translate.get("Closed post AD").subscribe(res => {
        this.presentToast(res);
      });
      this.gv.chreloadrequest = true;
    }
  }

  async openPreview(index) {
    this.modalCtrl.create({
      component: ImgmodalPage,
      cssClass: 'imgmodal',
      componentProps: <null>{
        index: index,
        img: this.imglist
      }
    }).then(modal => {
      modal.present();
    });
  }

  async openPreviewSingle(src) {
    this.modalCtrl.create({
      component: ImgmodalPage,
      cssClass: 'imgmodal',
      componentProps: <null>{
        img: [{ url: src }]
      }
    }).then(modal => {
      modal.present();
    });
  }


  async showplacemenu() {
    const modal = await this.modalCtrl.create({
      component: MenupanPage,
      cssClass: 'modalfull',
      componentProps: <null>{
        menu: this.menu, manager: this.gv.manager
      }
    })
    modal.onDidDismiss().then(m => {
      if (m.data && m.data.menu) {
        this.afDB.database.ref(this.chlistdir + '/' + this.itemId).update([{ menu: m.data.menu }]).then(_ => {
          this.translate.get("Menu has updated").subscribe(res => {
            this.presentToast(res);
          });
          this.menu = m.data.menu;
        })
      }
    })
    return await modal.present();
  }

  async showmenu(ev) {
    let predata = [];

    //if(this.gv.cordova)
    predata['showshare'] = true;
    //메뉴추가있는지 
    //if(this.menu)
    predata['id'] = this.itemId;
    predata['menu'] = this.menu;

    if (this.gv.uid == this.writerid || this.gv.manager) {
      let adactive, adclose;
      this.translate.get('Resume AD').subscribe(res => { adactive = res });
      this.translate.get('Close AD').subscribe(res => { adclose = res });
      predata['owner'] = true;
      if (this.state == 'close') {
        predata['custom'] = adactive;
      } else if (this.state == 'active') {
        predata['custom'] = adclose;
      }
    }

    // console.log("this.gv.uid = " + this.gv.uid);
    // console.log("this.chowner = " + this.chowner);
    if ((this.gv.uid != null && this.gv.uid) == this.chowner || this.gv.manager) {
      // console.log("this.chtype = " + this.chtype);
      // console.log(this.choptions);
      if (this.choptions && this.choptions.chnotice) {
        // if ( this.chtype == 'community' ) { //기본 채널 이외의 채널만 공지 글 가능하게 (임시) - 191118 / 해제(주석처리) - 200304
        let noticeon, noticeoff;
        this.translate.get('공지설정').subscribe(res => { noticeon = res });
        this.translate.get('공지해제').subscribe(res => { noticeoff = res });
        if (this.isnotice == true) {
          predata['notice'] = noticeoff;
        } else {
          predata['notice'] = noticeon;
        }
        // }
      }

    }

    const popover = await this.popoverCtrl.create({
      component: PagemenuComponent,
      componentProps: <null>predata,
      //mode:'ios',
      cssClass: 'popovermenu',
      event: ev,
      showBackdrop: false
    });
    popover.onDidDismiss().then(rst => {
      if (rst.data) {
        switch (rst.data.value) {
          case 'notice':
            this.notice();
            break;
          case 'share':
            this.share();
            break;
          case 'store':
            this.store();
            break;
          case 'edit':
            this.edit();
            break;
          case 'move':
            this.move();
            break;
          case 'delete':
            this.delete();
            break;
          case 'changestate':
            this.changestate();
          default: break;
        }
      }
    })
    return await popover.present();
  }

  async showprofile(key) {
    this.modalCtrl.create({
      component: AccountPage,
      cssClass: 'modalfull',
      componentProps: <null>{ memid: key }
    }).then(modal => {
      modal.present();
    });
  }

  sendmsg(name, key) {
    var sendmsg, cancelbtn, sendbtn, phmsg, okmsg, containbadword;
    this.translate.get('Send a message').subscribe(res => {
      sendmsg = res;
    });
    this.translate.get('Cancel').subscribe(res => {
      cancelbtn = res;
    });
    this.translate.get('Send').subscribe(res => {
      sendbtn = res;
    });
    this.translate.get('Please fill in the message').subscribe(res => {
      phmsg = res;
    });
    this.translate.get('Confirm').subscribe(res => {
      okmsg = res;
    });
    this.translate.get('Message contains inappropriate words').subscribe(res => {
      containbadword = res;
    });
    this.alertCtrl.create({
      message: name + ' > ' + sendmsg,
      //message: "<span style='font-size:1.2rem; color:black'></span>",
      inputs: [
        {
          name: 'message',
          type: 'text'
        }
      ],
      buttons: [
        {
          text: cancelbtn,
          role: 'cancel',
        },
        {
          text: sendbtn,
          handler: msgdata => {
            if (!msgdata.message) {
              let alert = this.alertCtrl.create({
                message: phmsg,
                buttons: [okmsg]
              });
              this.sendmsg(name, key);
              return;
            }
            /*else if(this.bad_chk(msgdata.message))
            {
              let alert = this.alertCtrl.create({
              message: containbadword,
              buttons: [okmsg]
            });
            alert.present();
            return;
            }*/
            else {
              msgdata.message = this.bad_chkback(msgdata.message);
              this.msgfunc.sendmsg(this.gv.uid, key, msgdata.message, name);
              var sentmsg;
              this.translate.get('Message has sent').subscribe(res => {
                sentmsg = res;
              });
              let today = new Date();
              let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
              this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/msgsend').transaction(view => {
                return (view || 0) + 1;
              })
              this.presentToast(sentmsg);
            }

          }
        }
      ]
    }).then(_ => _.present())
  }

  async blockuserfunc(name, key) {
    let hd, msg, cancel, confirm;
    this.translate.get('Block user confirm').subscribe(res => { hd = res });
    this.translate.get('blocked this user. Are you sure to block?').subscribe(res => { msg = res });
    this.translate.get('Cancel').subscribe(res => { cancel = res });
    this.translate.get('Confirm').subscribe(res => { confirm = res });

    const alert = await this.alertCtrl.create({
      header: hd,
      message: name + msg,
      buttons: [
        {
          text: cancel,
          role: 'cancel'
        },
        {
          text: confirm,
          handler: () => {
            this.afDB.database.ref('/userProfile/' + this.gv.uid + '/blockuser')
              .orderByChild('id').equalTo(key).once('value')
              .then(user => {
                if (user.val()) {
                  this.translate.get("Blocked already").subscribe(res => {
                    this.presentToast(res);
                  });
                }
                else {
                  this.afDB.database.ref('/userProfile/' + this.gv.uid + '/blockuser').push({
                    id: key, createdDate: firebase.database.ServerValue.TIMESTAMP, name: name
                  }).then(_ => {
                    this.translate.get("Block has done.").subscribe(res => {
                      this.presentToast(res);
                    });
                    let today = new Date();
                    let todaystr = today.getFullYear() + '-' + (today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
                    this.afDB.database.ref('/systemlog/' + (this.gv.uid ? this.gv.uid : 'unregister') + '/event/' + todaystr + '/userblock').transaction(view => {
                      return (view || 0) + 1;
                    })
                    this.close();
                  })
                }
              })
          }
        }
      ]
    });
    return await alert.present();
  }

  async writermenu(evt, name, key) {
    if (this.gv.uid) {
      let predata = { name: name, uid: key, type: 'board' };
      //console.log(predata);
      const popover = await this.popoverCtrl.create({
        component: MembermenuComponent,
        componentProps: <null>predata,
        cssClass: 'popoverwriter',
        event: evt,
        showBackdrop: false
      });
      popover.onDidDismiss().then(rst => {
        if (rst.data) {
          switch (rst.data.value) {
            case 'showprofile':
              this.showprofile(key);
              break;
            case 'sendmsg':
              this.sendmsg(name, key);
              break;
            case 'blockuser':
              this.blockuserfunc(name, key);
              break;
            default: break;
          }
        }
      })
      return await popover.present();
    }
    else
      this.requestsignin();
  }

  requestsignin() {
    var signinmsg;
    this.translate.get('requiredsignin').subscribe(res => {
      signinmsg = res;
    });
    this.presentsigninToast(signinmsg);
  }
  async presentsigninToast(message: string) {
    let login;
    this.translate.get('Sign In').subscribe(res => { login = res });
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      buttons: [{
        text: login,
        handler: () => {
          this.login();
        }
      }],
      duration: 2000
    });

    return await toast.present();
  }

  async login() {
    this.router.navigate(['/tabs/pfmenu']);
    //this.navCtrl.navigateForward('/signin');
  }


  strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  addcomment(writerid, postcreatddate, images?, thumbs?, attachedlink?) {
    if (this.gv.uid) {
      if (this.newcomment.length < 2) {
        var minletters;
        this.translate.get('Please fill in more than 2 letters').subscribe(res => {
          minletters = res;
        });
        this.presentToast(minletters);
      }
      else {
        this.newcomment = this.bad_chkback(this.newcomment);
        this.comment.add({
          userId: this.gv.uid,
          private: this.isprivatecmt ? this.isprivatecmt : false,
          anonymous: this.isanonymouscmt ? this.isanonymouscmt : false,
          content: this.isprivatecmt ? '비밀댓글 입니다.' : this.newcomment,
          realcontent: this.isprivatecmt ? this.newcomment : '',
          rate: this.rate,
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          images: images ? images : '',
          imagethumbs: thumbs ? thumbs : '',
          attachedlink: attachedlink ? attachedlink : '',
          loc: this.gv.loc
        }).then(addeditem => {
          console.log(addeditem);
          this.images = [];
          this.imagethumbs = [];
          this.attachedlink = null;
          if (writerid != this.gv.uid) {
            this.pointfunc.addpoint(this.gv.uid, 'comment', 2, this.itemId, addeditem.id);
            this.pointfunc.addpoint(this.writerid, 'gotreply', 2, this.itemId, addeditem.id);
          }
          else {
            this.pointfunc.addpoint(this.gv.uid, 'comment', 0, this.itemId, addeditem.id);
            this.pointfunc.addpoint(this.writerid, 'gotreply', 0, this.itemId, addeditem.id);
          }
          this.afDB.database.ref('/userProfile/' + this.gv.uid + '/comment').push({
            itemid: this.itemId, loclancode: this.gv.loc + '_' + this.gv.lan,
            commentid: addeditem.id, createdDate: firebase.database.ServerValue.TIMESTAMP
          });

          if (writerid != this.gv.uid)
            this.msgfunc.commentnoti(this.itemId, this.gv.loc + '_' + this.gv.lan, this.newcomment, writerid, false);

          var cmted;
          this.translate.get('Posted the comment').subscribe(res => {
            cmted = res;
          });
          this.presentToast(cmted);

          if (this.commenttype == 'feedback') {
            var itemcnt = 0;
            var totalrate = 0;
            var rateorder = 0;
            firebase.database().ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value', item => {
              item.forEach(cnt => {
                totalrate += cnt.child('rate').val();
                if (cnt.child('rate').val())
                  itemcnt++;
                switch (cnt.child('rate').val()) {
                  case 5:
                    rateorder = rateorder + 2;
                    break;
                  case 4:
                    rateorder = rateorder + 1;
                    break;
                  case 3:
                    //rateorder = rateorder +2;
                    break;
                  case 2:
                    rateorder = rateorder - 1;
                    break;
                  case 1:
                    rateorder = rateorder - 2;
                    break;
                }
                return false;
              })
              firebase.database().ref(this.chlistdir + '/' + this.itemId).update
                ({ rate: this.precisionRound(totalrate / itemcnt, 1), replycount: itemcnt, rateorder: rateorder })
            }
            ).catch(_ =>
              firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ rate: 0, replycount: 0, rateorder: 0 }))
          }
          else {
            var itemcnt = 0;
            firebase.database().ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value', item => {
              item.forEach(cnt => {
                if (postcreatddate < cnt.val().createdDate)
                  itemcnt++;
                return false;
              })
              firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ replycount: itemcnt })
            })
          }
          this.newcomment = '';
        })
      }
    }
    else
      this.requestsignin();
  }
  editcomment(key, cmtkey, content, images?, thumbs?, attachedlinktarget?, isprivatecmt?) {
    if (content.length < 2) {
      var minletters;
      this.translate.get('Please fill in more than 2 letters').subscribe(res => {
        minletters = res;
      });
      this.presentToast(minletters);
    }
    else {
      content = this.bad_chkback(content);
      firebase.database().ref(this.chlistdir + '/' + key + '/comment/' + cmtkey).update({
        content: isprivatecmt ? '비밀 댓글 입니다.(수정됨)' : this.strip(content),
        realcontent: isprivatecmt ? this.strip(content) : '',
        images: images ? images : '',
        imagethumbs: thumbs ? thumbs : '',
        attachedlink: attachedlinktarget ? document.getElementById(attachedlinktarget).innerHTML : '',
        loc: this.gv.loc
      });
      var editdonemsg;
      this.translate.get('Edited the comment').subscribe(res => {
        editdonemsg = res;
      });
      this.presentToast(editdonemsg)
    }
  }
  async delcomment(key) {
    var chkdel, confirmdel, cancelbtn, delbtn, deledmsg;
    if (this.commenttype == 'feedback') {
      this.translate.get('Do you want to delete this review').subscribe(res => {
        confirmdel = res;
      });
      this.translate.get('Deleted the review').subscribe(res => {
        deledmsg = res;
      });
    }
    else {
      this.translate.get('Do you want to delete this comment').subscribe(res => {
        confirmdel = res;
      });
      this.translate.get('Deleted the comment').subscribe(res => {
        deledmsg = res;
      });
    }
    this.translate.get('Confirm to delete').subscribe(res => {
      chkdel = res;
    });
    this.translate.get('delete').subscribe(res => {
      delbtn = res;
    });
    this.translate.get('Cancel').subscribe(res => {
      cancelbtn = res;
    });

    const alert = await this.alertCtrl.create({
      header: chkdel,
      message: confirmdel,
      buttons: [
        {
          text: cancelbtn,
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: delbtn,
          handler: () => {
            this.afDB.database.ref('/userProfile/' + this.gv.uid + '/comment').orderByChild('commentid').equalTo(key).once('value')
              .then(removeitem => {
                removeitem.forEach(element => {
                  this.afDB.database.ref('/userProfile/' + this.gv.uid + '/comment/' + element.key).remove();
                });
              })

            this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value').then(_ => {
              let cmts = _.val();
              let recmtArr = [];
              recmtArr = Object.keys(cmts).filter((item) => {
                if (cmts[item].recmtId == key) {
                  return { item: cmts[item].recmtId }
                }
              });
              // console.log(recmtArr);
              if (recmtArr.length > 0) { // 해당 댓글의 대댓글 있음. 고로 댓글 값은 남기고 내용, 이미지 삭제
                this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/comment/' + key).update({
                  images: null,
                  imagethumbs: null,
                  attachedlink: null,
                  content: '',
                  deleted: true
                }).then(_ => {
                  this.presentToast(deledmsg);
                  this.pointfunc.addpoint(this.gv.uid, 'commentremove', -2, this.itemId, key);
                  this.pointfunc.addpoint(this.writerid, 'gotreplyremove', -2, this.itemId, key);
                  var itemcnt = 0;

                  if (this.commenttype == 'feedback') {
                    var totalrate = 0;
                    var rateorder = 0;
                    firebase.database().ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value', item => {
                      item.forEach(cnt => {
                        totalrate += cnt.child('rate').val();
                        if (cnt.child('rate').val())
                          itemcnt++;
                        switch (cnt.child('rate').val()) {
                          case 5:
                            rateorder = rateorder + 2;
                            break;
                          case 4:
                            rateorder = rateorder + 1;
                            break;
                          case 3:
                            //rateorder = rateorder +2;
                            break;
                          case 2:
                            rateorder = rateorder - 1;
                            break;
                          case 1:
                            rateorder = rateorder - 2;
                            break;
                        }
                        return false;
                      })
                    }).then(_ =>
                      firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ rate: this.precisionRound(totalrate / itemcnt, 1), replycount: itemcnt, rateorder: rateorder })).catch(_ =>
                        firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ rate: 0, replycount: 0, rateorder: 0 })
                      );
                  }
                  else {
                    firebase.database().ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value', item => {
                      item.forEach(cnt => {
                        if (this.createdDate < cnt.val().createdDate && !cnt.val().deleted)
                          itemcnt++;
                        return false;
                      })
                    })
                      .then(_ => {
                        firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ replycount: itemcnt })
                      })
                      .catch(_ => {
                        firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ replycount: 0 })
                      });
                  }
                });
              } else { // 해당 댓글의 대댓글 없으니까 댓글이던 대댓글이던 걍 지움.
                this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/comment/' + key).once('value').then(cmt => { // 삭제하는 댓글
                  let _cmt = cmt.val();
                  this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/comment/' + _cmt.recmtId + '/deleted/').once('value').then(bool => { // 삭제하는 댓글의 상위 댓글
                    let _bool = bool.val();
                    this.comment.remove(key).then(_ => {
                      this.afDB.database.ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value').then(_ => { // 같은 상위 댓글을 가진 다른 댓글을 선택하기 위한 모든 댓글
                        let cmts = _.val();
                        let recmtArr = [];
                        recmtArr = Object.keys(cmts).filter((item) => {
                          if (cmts[item].recmtId == _cmt.recmtId) {
                            return { reitem: cmts[item].recmtId }
                          }
                        });
                        // console.log(recmtArr);
                        if (bool.val() == true && !recmtArr.length) {
                          // console.log('상위 댓글이 삭제된 상태 && 같은 댓글을 상위로 하는 대댓글이 없음: 상위 댓글 삭제');
                          this.comment.remove(_cmt.recmtId);
                        }
                      });
                      this.presentToast(deledmsg);
                      this.pointfunc.addpoint(this.gv.uid, 'commentremove', -2, this.itemId, key);
                      this.pointfunc.addpoint(this.writerid, 'gotreplyremove', -2, this.itemId, key);
                      var itemcnt = 0;

                      if (this.commenttype == 'feedback') {
                        var totalrate = 0;
                        var rateorder = 0;
                        firebase.database().ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value', item => {
                          item.forEach(cnt => {
                            totalrate += cnt.child('rate').val();
                            if (cnt.child('rate').val())
                              itemcnt++;
                            switch (cnt.child('rate').val()) {
                              case 5:
                                rateorder = rateorder + 2;
                                break;
                              case 4:
                                rateorder = rateorder + 1;
                                break;
                              case 3:
                                //rateorder = rateorder +2;
                                break;
                              case 2:
                                rateorder = rateorder - 1;
                                break;
                              case 1:
                                rateorder = rateorder - 2;
                                break;
                            }
                            return false;
                          })
                        }).then(_ =>
                          firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ rate: this.precisionRound(totalrate / itemcnt, 1), replycount: itemcnt, rateorder: rateorder })).catch(_ =>
                            firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ rate: 0, replycount: 0, rateorder: 0 })
                          );
                      }
                      else {
                        firebase.database().ref(this.chlistdir + '/' + this.itemId + '/comment/').once('value', item => {
                          item.forEach(cnt => {
                            if (this.createdDate < cnt.val().createdDate && !cnt.val().deleted)
                              itemcnt++;
                            return false;
                          })
                        }).then(_ =>
                          firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ replycount: itemcnt })).catch(_ =>
                            firebase.database().ref(this.chlistdir + '/' + this.itemId).update({ replycount: 0 })
                          );
                      }
                    });
                  });
                });
              }
            });

          }
        }
      ]
    });
    return await alert.present();
  }

  recomment(fid, cmtkey, recmtkey, replyuser, content, towho, images?, thumbs?, attachedlinktarget?, isParentPrivate?) {
    console.log(attachedlinktarget);
    if (content.length < 2) {
      var minletters;
      this.translate.get('Please fill in more than 2 letters').subscribe(res => {
        minletters = res;
      });
      this.presentToast(minletters);
    }
    /*
    else if(this.bad_chk(content))
    {
      var badmsg, okbtn;
      this.translate.get('Comment contains inappropriate words').subscribe(res=>{
        badmsg = res;
      });
      this.translate.get('Confirm').subscribe(res=>{
        okbtn = res;
      });
      let alert = this.alertCtrl.create({
            message: badmsg,
            buttons: [okbtn]
          });
          alert.present();
          return;
    }*/
    else {
      let recmtPrivate = isParentPrivate;
      content = this.bad_chkback(content);
      if (replyuser && replyuser != '') content = '@' + replyuser + ' \n' + content;
      firebase.database().ref(this.chlistdir + '/' + fid + '/comment').push({
        createdDate: firebase.database.ServerValue.TIMESTAMP,
        private: recmtPrivate ? recmtPrivate : null,
        content: recmtPrivate ? '비밀댓글 입니다.' : this.strip(content),
        realcontent: recmtPrivate ? this.strip(content) : '',
        userId: this.gv.uid,
        recmtId: cmtkey,
        rerecmtId: recmtkey,
        images: images ? images : '',
        imagethumbs: thumbs ? thumbs : '',
        attachedlink: attachedlinktarget ? document.getElementById(attachedlinktarget).innerHTML : '',
        loc: this.gv.loc
      }).then(addeditem => {
        if (towho != this.gv.uid) {
          this.pointfunc.addpoint(this.gv.uid, 'comment', 2, this.itemId, addeditem.key);
          this.pointfunc.addpoint(this.writerid, 'gotreply', 2, this.itemId, addeditem.key);
        }
        else {
          this.pointfunc.addpoint(this.gv.uid, 'comment', 0, this.itemId, addeditem.key);
          this.pointfunc.addpoint(this.writerid, 'gotreply', 0, this.itemId, addeditem.key);
        }

        this.afDB.database.ref('/userProfile/' + this.gv.uid + '/comment').push({
          itemid: fid, loclancode: this.gv.loc + '_' + this.gv.lan,
          commentid: addeditem.key, createdDate: firebase.database.ServerValue.TIMESTAMP
        });
      });
      if (towho != this.gv.uid)
        this.msgfunc.commentnoti(fid, this.gv.loc + '_' + this.gv.lan, content, towho, true);

      var cmted;
      this.translate.get('Posted the comment').subscribe(res => {
        cmted = res;
      });
      this.presentToast(cmted);
      var itemcnt = 0;
      firebase.database().ref(this.chlistdir + '/' + fid + '/comment/').once('value', item => {
        item.forEach(cnt => {
          itemcnt++;
          return false;
        })
      }).then(_ =>
        firebase.database().ref(this.chlistdir + '/' + fid).update({ replycount: itemcnt }));
    }
  }
  
  // editcomment(key, cmtkey, content, images?, thumbs?, attachedlinktarget?,isprivatecmt?) {
  likecomment(key) {
    console.log('likecomment');
    if (this.gv.uid) {
      this.likeCmtAction(key, this.gv.uid);
    } else if(this.gv.tid) {
      this.likeCmtAction(key, this.gv.tid);
    } else {
      this.presentToast('비회원 좋아요에 문제가 있어요😭');
    }
    // else {
    //   this.gv.restoreOnStorage('unauth_tid').then(tid => {
    //     if (tid) {
    //       this.likeCmtAction(key, tid);
    //     }
    //   });
    // }
  }

  likeCmtAction(key, uid){
    this.afDB.database.ref('/list/' + this.itemId + '/comment/' + key + '/likes').once('value').then(likes => {
      if (likes.val()) {
        let likeArr = likes.val();
        let len = Object.keys(likeArr).length;
        let idx = Object.keys(likeArr).indexOf(uid);
        if (idx > -1 && likeArr[uid] == true) {
          this.afDB.database.ref('/list/' + this.itemId + '/comment/' + key + '/likes/' + uid).set(false);
        } else {
          // this.afDB.database.ref('/list/' + this.itemId + '/comment/' + key + '/likes').child('/'+len).set(this.gv.uid);
          this.afDB.database.ref('/list/' + this.itemId + '/comment/' + key + '/likes/' + uid).set(true);
        }
      } else {
        this.afDB.database.ref('/list/' + this.itemId + '/comment/' + key + '/likes/' + uid).set(true);
      }
    });
  }
  
  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 1000
    });
    return await toast.present();
  }

  callpwdalert(item, id, type?) {
    var fillinpwd, leave, pwdchanged, donebl, pwdnotmatched, retry;
    this.translate.get('Please fill in the password').subscribe(res => {
      fillinpwd = res;
    });
    this.translate.get('Leave').subscribe(res => {
      leave = res;
    });
    this.translate.get('Password has changed new pwd required').subscribe(res => {
      pwdchanged = res;
    });
    this.translate.get('Doneblank').subscribe(res => {
      donebl = res;
    });
    this.translate.get('Password does not match').subscribe(res => {
      pwdnotmatched = res;
    });
    this.translate.get('Retry').subscribe(res => {
      retry = res;
    });

    var msg = fillinpwd;
    if (type)
      msg = pwdchanged;
    this.alertCtrl.create({
      message: msg,
      inputs: [{ name: 'pwd' }],
      buttons: [
        {
          text: leave,
          role: 'cancel',
          handler: () => {
            this.close();
          }
        },
        {
          text: donebl,
          handler: (data) => {
            if (item.child('password').val() == data.pwd) {
              // 비밀번호가 일치하면 해당 비밀번호 저장
              if (this.gv.uid) {
                var existitem = false;
                var comkey;
                this.afDB.database.ref('/userProfile/' + this.gv.uid + '/community').once('value').then(com => {
                  if (com.val())
                    com.forEach(element => {
                      if (element.val().id == id) {
                        comkey = element.key;
                        existitem = true;
                      }
                    });
                }).then(rst => {
                  if (existitem) // 기존에 존재하면 업데이트, 아니면 푸시
                    this.afDB.database.ref('/userProfile/' + this.gv.uid + '/community/' + comkey).update({ pwd: data.pwd });
                  else
                    this.afDB.database.ref('/userProfile/' + this.gv.uid + '/community').push({ id: id, pwd: data.pwd });
                })
              }
              this.privatenoconfirm = false;
              this.loaddata();
            }
            else {
              this.alertCtrl.create({
                message: pwdnotmatched,
                buttons: [
                  {
                    text: leave,
                    role: 'cancel',
                    handler: () => {
                      this.close();
                      //this.navCtrl.pop();
                    }
                  },
                  {
                    text: retry,
                    handler: (data) => {
                      this.callpwdalert(item, id);
                      //this.setdata(id);
                    }
                  }]
              }).then(alertwrong =>
                alertwrong.present())
            }
          }
        }
      ]
    }).then(alert => alert.present());
  }

  site(passedWeb) {
    //this.iab.create(passedWeb, '_blank');
    if (passedWeb.indexOf('http:') == 0 || passedWeb.indexOf('https:') == 0)
      window.open(passedWeb, '_system', 'location=no');
    else
      window.open('http://' + passedWeb, '_system', 'location=no');
  }

  call(numbers) {
    let no;
    numbers = numbers.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\+/g, '').replace(/\//g, ',');
    if (numbers.split(',').length > 1) {
      let btns = [];
      numbers.split(',').forEach(num => {
        btns.push({ text: num, handler: () => { this.call(num) } })
      })
      var whichno;
      this.translate.get('Which number do you want to contact').subscribe(res => { whichno = res; });
      this.actionsheetCtrl.create({
        header: whichno,
        buttons: btns
      }).then(_ => _.present())
    }
    else {
      no = numbers;
      if (no.indexOf('04') == 0 || no.indexOf('614') == 0) {
        let callstr, smsstr;
        this.translate.get('Call').subscribe(res=>{callstr = res;});
        this.translate.get('SMS').subscribe(res=>{smsstr = res;});
        this.actionsheetCtrl.create({
          header: no,
          buttons: [{
            text: callstr,
            icon: 'call',
            cssClass: 'attach_chpost_icon',
            handler: () => {
              window.open('tel:' + no, '_system', 'location=no');
            }
          }, {
            text: smsstr,
            icon: 'mail',
            cssClass: 'attach_exurl_icon',
            handler: () => {
              if (this.chtype == 'place')
                window.open('sms:' + no, '_system', 'location=no');
              else {
                let callstart, callend;
                this.translate.get('callstrstart').subscribe(res => { callstart = res; });
                this.translate.get('callstrend').subscribe(res => { callend = res; });
                let bodytxt = encodeURI(callstart + this.postname + callend);
                if (this.gv.ios)
                  window.open('sms:' + no + '&body=' + bodytxt, '_system', 'location=no');
                else
                  window.open('sms:' + no + '?&body=' + bodytxt, '_system', 'location=no');
              }
            }
          }]
        }).then(rst => { rst.present() })
      }
      else
        window.open('tel:' + no, '_system', 'location=no');
    }
  }



  map(addr, lat, lng) {
    if (this.gv.android)
      window.open('geo:0,0?q=' + lat + ',' + lng, '_system', 'location=no');
    else if (this.gv.ios)
      window.open('maps://?q=' + lat + ',' + lng, '_system', 'location=no');
    else {
      let addstr = addr.replace(' ', '+').replace(',', '%2C');
      window.open('https://www.google.com/maps/search/?api=1&query=' + addstr, '_system', 'location=no')
    }
  }
  //https://www.google.com/maps/@?api=1&map_action=map&-16.922306,145.763222
  //https://www.google.com/maps/@-16.9233758,145.7633982&markers=icon:https://channel.cafe/assets/icon/pin.png%7Clabel:%7C{{item.lat}},{{item.lng}}
  //=icon:https://firebasestorage.googleapis.com/v0/b/localnews-e350b.appspot.com/o/mapmarker.png?alt=media&token=b007a57a-f305-454b-b1fa-e2b8cab9f7ba
  bad_chk(str) { // 욕 필터링 , 스크립트 아이프레임도 필터링
    if (str == '')
      return false;
    var badword;
    this.translate.get('badword').subscribe(res => {
      badword = res;
    });
    var YokList = badword.replace(/\'/g, '').split(",");
    var Tmp;
    for (var i = 0; i < YokList.length; i++) {
      Tmp = str.toLowerCase().indexOf(YokList[i]);
      if (Tmp >= 0) {
        return true;
      }
    }
    //루프를 돌고난 후에도 정상아웃하면 욕없음
    return false;
  }

  bad_chkback(str) { // 욕 필터링 , 스크립트 아이프레임도 필터링
    if (str == '')
      return false;
    var badword;
    this.translate.get('badword').subscribe(res => {
      badword = res;
    });
    var YokList = badword.replace(/\'/g, '').split(",");
    var Tmp = str;
    for (var i = 0; i < YokList.length; i++) {
      var tempRegex = new RegExp(YokList[i], "ig");
      Tmp = Tmp.replace(tempRegex, '*'.repeat(YokList[i].length))
    }
    return Tmp;
  }
  precisionRound(number, precision) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }


  onStarChange($event) {
    //this.rateSel = true;
    //this.rate = $event;
  }

  thumbup(key, writerid) {
    this.thumbclickable = false;
    if (this.gv.uid) {
      if (this.gv.uid == writerid) {
        // 본인의 글은 추천할수 없습니다.
        var cantliked;
        this.translate.get('Cant liked because of your posting').subscribe(res => {
          cantliked = res;
        });
        this.presentToast(cantliked);
        this.thumbclickable = true;
      }
      else {
        this.afDB.database.ref('/userProfile/' + this.gv.uid + '/like').orderByChild('itemid').equalTo(key).once('value')
          .then(item => {
            if (item.val()) {
              var alreadyliked;
              this.translate.get('Already liked').subscribe(res => {
                alreadyliked = res;
              });
              this.presentToast(alreadyliked);
              this.thumbclickable = true;
            }
            else {
              this.afDB.database.ref('/userProfile/' + this.gv.uid + '/dislike').orderByChild('itemid').equalTo(key).once('value')
                .then(disitem => {
                  if (disitem.val()) {
                    var alreadydisliked;
                    this.translate.get('Cannot like because you disliked this already').subscribe(res => {
                      alreadydisliked = res;
                    });
                    this.presentToast(alreadydisliked);
                    this.thumbclickable = true;
                  }

                  else {
                    firebase.database().ref('/userProfile/' + this.gv.uid + '/like').push(
                      { type: 'community', loclancode: this.gv.loc + '_' + this.gv.lan, createdDate: firebase.database.ServerValue.TIMESTAMP, itemid: key }
                    );
                    firebase.database().ref(this.chlistdir + '/' + key + '/up').once('value').then(item => {
                      if (item.val()) {
                        firebase.database().ref(this.chlistdir + '/' + key).update({ up: parseInt(item.val()) + 1 })
                      }
                      else
                        firebase.database().ref(this.chlistdir + '/' + key).update({ up: 1 });
                    })
                    firebase.database().ref('/category/' + this.communityid + '/chinfo/liked').once('value').then(item => {
                      if (item.val()) {
                        firebase.database().ref('/category/' + this.communityid + '/chinfo').update({ liked: parseInt(item.val()) + 1 })
                      }
                      else
                        firebase.database().ref('/category/' + this.communityid + '/chinfo').update({ liked: 1 });
                    })

                    var liked;
                    this.translate.get('Liked').subscribe(res => {
                      liked = res;
                    });
                    this.presentToast(liked);
                    this.pointfunc.addpoint(this.gv.uid, 'updown', 1, key, 'liked');
                    this.pointfunc.addpoint(writerid, 'gotliked', 1, key, null, true);
                    this.msgfunc.likednoti(key, this.gv.loc + '_' + this.gv.lan, 'community', this.gv.unickname, writerid);
                    this.thumbclickable = true;
                  }
                })
            }
          })
      }
    }
    else {
      this.requestsignin();
      this.thumbclickable = true;
    }
  }


  thumbdown(key, writerid) {
    this.thumbclickable = false;
    if (this.gv.uid) {
      if (this.gv.uid == writerid) {
        var cantdisliked;
        this.translate.get('Cant disliked because of your posting').subscribe(res => {
          cantdisliked = res;
        });
        this.presentToast(cantdisliked);
        this.thumbclickable = true;
      }
      else {
        this.afDB.database.ref('/userProfile/' + this.gv.uid + '/dislike').orderByChild('itemid').equalTo(key).once('value')
          .then(item => {
            if (item.val()) {
              var alreadydisliked;
              this.translate.get('Already disliked').subscribe(res => {
                alreadydisliked = res;
              });
              this.presentToast(alreadydisliked);
              this.thumbclickable = true;
            }
            else {
              this.afDB.database.ref('/userProfile/' + this.gv.uid + '/like').orderByChild('itemid').equalTo(key).once('value')
                .then(likeitem => {
                  if (likeitem.val()) {
                    var alreadyliked;
                    this.translate.get('Cannot dislike because you liked this already').subscribe(res => {
                      alreadyliked = res;
                    });
                    this.presentToast(alreadyliked);
                    this.thumbclickable = true;
                  }
                  else {
                    firebase.database().ref('/userProfile/' + this.gv.uid + '/dislike').push(
                      { type: 'community', loclancode: this.gv.loc + '_' + this.gv.lan, createdDate: firebase.database.ServerValue.TIMESTAMP, itemid: key }
                    );

                    firebase.database().ref(this.chlistdir + '/' + key + '/down').once('value').then(item => {
                      if (item.val()) {
                        firebase.database().ref(this.chlistdir + '/' + key).update({ down: parseInt(item.val()) + 1 })
                      }
                      else
                        firebase.database().ref(this.chlistdir + '/' + key).update({ down: 1 });
                    })

                    var disliked;
                    this.translate.get('Disliked').subscribe(res => {
                      disliked = res;
                    });
                    this.pointfunc.addpoint(this.gv.uid, 'updown', 1, key, 'disliked');
                    this.presentToast(disliked);
                    this.thumbclickable = true;
                  }
                })
            }
          })
      }
    }
    else {
      this.requestsignin();
      this.thumbclickable = true;
    }
  }

  openFullImageSingle(src) { }



  async htmlupload(fileinput: HTMLInputElement, target?) {
    //if(this.images.length > 0 && this.imagethumbs.length > 0)
    //  this.imgupload.delete(this.images[0].path, this.imagethumbs[0].path);
    //this.images = [];
    //this.imagethumbs = [];
    var filelength = fileinput.files.length;
    for (var i = 0; i < filelength; i++) {
      var file: File = fileinput.files[i];
      if (target) {
        if (!target.images) target.images = [];
        if (!target.imagethumbs) target.imagethumbs = [];
        target.images.push({ url: 'loading', path: file.name });
        target.imagethumbs.push({ url: 'loading', path: file.name });
      } else {
        if (!this.images) this.images = [];
        if (!this.imagethumbs) this.imagethumbs = [];
        this.images.push({ url: 'loading', path: file.name });
        this.imagethumbs.push({ url: 'loading', path: file.name });
      }

      await this.imgupload.readfile(file).then(rst => {
        this.imgupload.imgupload(file, 'commentimg').then(imgrst => {
          // console.log("i = " + i);
          // console.log("fileinput.files.length-1 = " + (fileinput.files.length-1));


          if (target) {
            if (!target.images) target.images = [];
            if (!target.imagethumbs) target.imagethumbs = [];
            // target.images.push({ path: imgrst[0]['path'], url: imgrst[0]['url'] });
            // target.imagethumbs.push({ path: imgrst[1]['path'], url: imgrst[1]['url'] });
            for (let j = 0; j < target.imagethumbs.length; j++) {
              if (target.images[j].url == 'loading'
                && imgrst[0]['path'].indexOf(target.imagethumbs[j].path) > -1) {
                target.images.splice(j, 1, { path: imgrst[0]['path'], url: imgrst[0]['url'] });
                target.imagethumbs.splice(j, 1, { path: imgrst[1]['path'], url: imgrst[1]['url'] });
              }
            }
          } else {
            // this.images.push({ path: imgrst[0]['path'], url: imgrst[0]['url'] });
            // this.imagethumbs.push({ path: imgrst[1]['path'], url: imgrst[1]['url'] });
            for (let j = 0; j < this.imagethumbs.length; j++) {
              if (this.images[j].url == 'loading'
                && imgrst[0]['path'].indexOf(this.imagethumbs[j].path) > -1) {
                this.images.splice(j, 1, { path: imgrst[0]['path'], url: imgrst[0]['url'] });
                this.imagethumbs.splice(j, 1, { path: imgrst[1]['path'], url: imgrst[1]['url'] });
              }
            }
          }
        })
      })
    }
  }

  async openPreviewCmt(images, index) {
    this.modalCtrl.create({
      component: ImgmodalPage,
      cssClass: 'imgmodal',
      componentProps: <null>{
        index: index,
        img: images
      }
    }).then(modal => {
      modal.present();
    });
  }

  removeimg(i, target?) {
    if (!target) {
      let tempimgurl = this.imagethumbs[i].url;
      this.imgupload.delete(this.images[i].path, this.imagethumbs[i].path);
      this.imagethumbs.splice(i, 1);
      this.images.splice(i, 1);
    } else {
      let tempimgurl = target.imagethumbs[i].url;
      this.imgupload.delete(target.images[i].path, target.imagethumbs[i].path);
      target.imagethumbs.splice(i, 1);
      target.images.splice(i, 1);
    }
  }
  removelink(comment, target?) {
    if (!comment) {
      // let tempimgurl = this.imagethumbs[i].url;
      // this.imgupload.delete(this.images[i].path, this.imagethumbs[i].path);
      // this.imagethumbs.splice(i, 1); 
      // this.images.splice(i, 1); 
      this.attachedlink = null;
      document.getElementById('attachlinkA').innerHTML = null;
    } else {
      comment.attachedlink = null;
      document.getElementById(target).innerHTML = null;
    }
  }

  async addImg(target?) {
    //console.log(target);
    if (target) {
      document.getElementById(target).click();
    } else {
      document.getElementById('imgfiles').click();
    }
  }

  async addurlcmt(target?) {
    const modal = await this.modalCtrl.create({
      component: BasicpopupPage,
      cssClass: 'basicmodal radius',
      backdropDismiss: true,
      componentProps: {
        isComment: true
      }
    });
    //console.log("target = " + target);
    modal.onDidDismiss().then(m => {
      if (m.data && m.data.tag) {
        // console.log(m.data.tag);
        if (target) {
          document.getElementById(target).innerHTML = m.data.tag;
        } else {
          document.getElementById('attachlinkA').innerHTML = m.data.tag;
          this.attachedlink = m.data.tag;
        }
      }
    })
    return await modal.present();
  }


  async addinapplink(target?) {
    target ? console.log(target) : '';
    const modal = await this.modalCtrl.create({
      component: InapplinkPage,
      cssClass: 'ph-custom-modal',
      backdropDismiss: true,
      componentProps: {
        isComment: true
      }
    });
    modal.onDidDismiss().then(m => {
      // if(m.data && m.data.tag) this.content += '<br>' + m.data.tag + '<br><br>';
      if (m.data && m.data.tag) {
        let _tags = this.safehtml.transform(m.data.tag)['changingThisBreaksApplicationSecurity'];
        if (target) {
          document.getElementById(target).innerHTML = m.data.tag;
          // document.getElementById(target).innerHTML = m.data.tag;
          // console.log(document.getElementById(target).innerHTML);
        } else {
          document.getElementById('attachlinkA').innerHTML = m.data.tag;
          this.attachedlink = m.data.tag;
        }
      }
    })
    return await modal.present();
  }


  async addlink(target?) {
    let linktype, ch, web;
    this.translate.get('Link type').subscribe(res => { linktype = res });
    this.translate.get('Channel Post').subscribe(res => { ch = res });
    this.translate.get('Web Url').subscribe(res => { web = res });
    const actionsheet = await this.actionsheetCtrl.create({
      header: linktype,
      buttons: [{
        text: ch,
        icon: 'newspaper',
        cssClass: 'attach_chpost_icon',
        handler: () => {
          this.addinapplink(target);
        }
      }, {
        text: web,
        icon: 'link',
        cssClass: 'attach_exurl_icon',
        handler: () => {
          this.addurlcmt(target);
        }
      }]
    });
    return await actionsheet.present();
  }

  toggleprivatecmt() {
    this.isprivatecmt = !this.isprivatecmt;
    if (this.isprivatecmt)
      this.presentToast('댓글이 작성자와 본인에게만 공개됩니다.');
    else
      this.presentToast('댓글이 모두에게 공개 됩니다.');
  }
  toggleanonymouscmt() {
    this.isanonymouscmt = !this.isanonymouscmt;
    if (this.isanonymouscmt)
      this.presentToast('익명으로 댓글을 작성합니다.');
    else
      this.presentToast('익명 댓글 취소.');
  }

  trackByFnAdv(index, aditem) {
    return (aditem.img, aditem.event, aditem.adtext);
  }
  trackByFnCmt(index, cmt) {
    if(!cmt) return null;
    return (cmt.$key,index);
  }
  trackByFnImgs(index, imgs) {
    if(!imgs) return null;
    return (imgs.$key,index);
  }

}
