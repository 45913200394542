import { NgModule } from '@angular/core';
import { SearchResultCount } from './searchresultwithcount';

@NgModule({
  declarations: [
    SearchResultCount,
  ],
  imports: [
    
  ],
  exports: [
    SearchResultCount
  ]
})
export class SearchResultCountModule {}
