import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { IonicModule } from '@ionic/angular';

import { QnaPage } from './qna.page';

const routes: Routes = [
  {
    path: '',
    component: QnaPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,OrderByPipeModule,
    RouterModule.forChild(routes)
  ],
  declarations: [QnaPage]
})
export class QnaPageModule {}
