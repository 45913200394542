import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { FavchlistPage } from './favchlist.page';
//import { ChmainPageModule } from '../../../pages/general/chmain/chmain.module';
//import { ChviewPageModule } from '../../../pages/general/chview/chview.module';
const routes: Routes = [
  {
    path: '',
    component: FavchlistPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,OrderByPipeModule,TranslateModule,//ChmainPageModule,ChviewPageModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [FavchlistPage]
})
export class FavchlistPageModule {}
