import { Component,ViewChildren,QueryList } from '@angular/core';

import { Platform, AlertController, ModalController, PickerController, NavController,
  IonRouterOutlet,IonMenu,ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVars } from './providers/globalVars';
import { Badge } from '@ionic-native/badge/ngx';
import { Router,ActivatedRoute } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { map } from 'rxjs/operators';
import { AuthGuard } from './providers/auth-guard';

import { MessagePage } from './pages/menu/message/message.page';
import { AlarmPage } from './pages/menu/alarm/alarm.page';
import { NicknamePage } from './pages/menu/nickname/nickname.page';
import { BlockuserPage } from './pages/menu/blockuser/blockuser.page';
import { PwdchangePage } from './pages/menu/pwdchange/pwdchange.page';
import { NoticePage } from './pages/menu/notice/notice.page';
import { QnaPage } from './pages/menu/qna/qna.page';
import { VersionPage } from './pages/menu/version/version.page';
import { PointinfoPage } from './pages/menu/pointinfo/pointinfo.page';
import { MypointPage } from './pages/menu/mypoint/mypoint.page';
import { PitemdetailPage } from './pages/general/point/pitemdetail/pitemdetail.page';
import { ClosedPage } from './pages/auth/closed/closed.page';
import { ImgmodalPage } from './pages/support/imgmodal/imgmodal.page';
import { ChviewPage } from './pages/general/chview/chview.page';
import { MsgviewPage } from './pages/general/msgview/msgview.page';
//import { ActrecentPage } from './pages/menu/actrecent/actrecent.page';
import { SetnotiPage } from './pages/menu/setnoti/setnoti.page';
import { FavchlistPage } from './pages/menu/favchlist/favchlist.page';
import { ListmodalPage } from './pages/menu/listmodal/listmodal.page';
import { MybucketPage } from './pages/support/mybucket/mybucket.page';
import { AccountPage } from './pages/menu/account/account.page';
import { MylevelPage } from './pages/menu/mylevel/mylevel.page';

import { WorktoolPage } from './pages/custom/worktool/worktool/worktool.page';
import { InvitePage } from './pages/menu/invite/invite.page';
import { SetlocalPage } from './pages/admin/setlocal/setlocal.page';
import { SystemlogPage } from './pages/admin/systemlog/systemlog.page';
//import { LabsPage } from './pages/menu/labs/labs.page';
import * as firebase from 'firebase/app';
import { FCM } from '@ionic-native/fcm/ngx';
import { AuthData } from './providers/auth-data';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: 'app.component.html'
})
export class Channel {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2200;

  uid:any;
  usernickname:any;
  userlvl:any;
  userthumbimg:any;
  menutype:any = "activity";
  msgcnt:any = 0;
  msgreceived: any = 0;
  msgsent: any = 0;

  writecnt:any = 0;
  cmtcnt:any = 0;
//  likecnt:any = 0;
//  dislikecnt:any = 0;
  invitecnt:any = 0;
  thisver:any = '3.5.0';
  currentver:any;
  lockver:any;
  updateable:boolean = false;
  admin:boolean = false;
  isDeveloper:boolean = false;


  pltinfo:any;
  msglist:any[] = [];
//  searchUser:any = '';
  loaditemmsgcnt: any = 10;
  msgshowcount:number = 10;
  alarmshowcount:number = 10;
  blockuser: string = '';

  alram:any[] = [];
  alrammode:any = 'normal';
  alramloaded:boolean = false;
  datenow:any = new Date().getTime();

  alarmunread:any = 0;
  msgunread:any = 0;
  favcount:any=0;
  mybucketcount:any=0;
  postcount:any=0;
  replycount:any=0;
  likedcount:any=0;
  dislikedcount:any=0;

  lan:string;
  loc:string;

  loclan:string;
  initchk:boolean = true;

  showworktool:boolean = false;
  signined:boolean = false;

  notifi:any;
  mypoint:any;
  myacti:any;
  support:any;
  unreadcount:any;
  totalcount:any;

  notireject;

  notverified:boolean;
  
  constructor(
    private platform: Platform,public gv:GlobalVars,public router: Router,public alertCtrl: AlertController, 
    public translate: TranslateService,public iab: InAppBrowser,public badge: Badge,
    public afDB: AngularFireDatabase, public afAuth: AngularFireAuth, public modalCtrl:ModalController, public pickerCtrl:PickerController,
    private authguard:AuthGuard,private fcm: FCM,public toastCtrl:ToastController,
    private authdata:AuthData, private navCtrl:NavController, private activeroute:ActivatedRoute
  ) {
    SplashScreen.hide();
    this.initializeApp();
    this.backButtonEvent();
  }
  backButtonEvent() {
  this.platform.backButton.subscribeWithPriority(0, () => {
    //console.log('백버튼!')
    this.routerOutlets.forEach(async(outlet: IonRouterOutlet) => {

      /*
      console.log(window.location.pathname);
      console.log(outlet.activatedRoute.toString())
      console.log(outlet.activatedRoute.children.toString());
      console.log(outlet.activatedRoute.firstChild?outlet.activatedRoute.firstChild.toString():'no!');
      console.log(this.activeroute.children.toString());
      */      

      if (window.location.pathname == "/tabs/home" || window.location.pathname == "/signin") {
        if (new Date().getTime() - this.lastTimeBackPress >= this.timePeriodToExit) {
          this.lastTimeBackPress = new Date().getTime();
          this.presentToast('지금 뒤로가기를 누르시면 앱이 종료됩니다.');
        } else {
          navigator['app'].exitApp();
        }
      } 
      
      
      /*
      else if(window.location.pathname.indexOf("/chmain")>-1) {  
        this.navCtrl.pop();
      }*/
      
      else if(window.location.pathname.indexOf("/chmain")>-1) {
        if(window.location.pathname.indexOf("/tabs/pfmenu")>-1)
          this.navCtrl.navigateBack('/tabs/pfmenu');
        else if(window.location.pathname.indexOf("/tabs/search")>-1)
          this.navCtrl.navigateBack('/tabs/search');
        else if(window.location.pathname.indexOf("/tabs/sm")>-1)
          this.navCtrl.navigateBack('/tabs/sm');
        else
          this.navCtrl.navigateBack('/tabs/home');
      }

      else if(window.location.pathname == "/tabs/sm") {
        this.router.navigate(['/tabs/home']);
        //return false;
      }
      else if(window.location.pathname == "/tabs/pm") {
        this.router.navigate(['/tabs/home']);
        //return false;
        //this.router.navigate(['/tabs/home']);
      }
      else if(window.location.pathname == "/tabs/pfmenu") {
        this.router.navigate(['/tabs/home']);
        //return false;
      }
      else if(window.location.pathname == "/tabs/search") {
        this.router.navigate(['/tabs/home']);
      }
      else {
        if(window.location.pathname.indexOf("/tabs/") == 0)
          {
            if(outlet.activatedRoute.toString().indexOf("url:''") > -1)
            {
              if(window.location.pathname.indexOf("/tabs/pm") > -1){
                this.navCtrl.navigateBack(['/tabs/pm']);
              }
              else if(window.location.pathname.indexOf("/tabs/sm") > -1){
                this.navCtrl.navigateBack(['/tabs/sm']);
              }
              else if(window.location.pathname.indexOf("/tabs/search") > -1){
                this.navCtrl.navigateBack(['/tabs/search']);
              }
              else if(window.location.pathname.indexOf("/tabs/pfmenu") > -1){
                this.navCtrl.navigateBack(['/tabs/pfmenu']);
              }
              else
                {
                this.navCtrl.navigateBack(['/tabs/home']);
                }
            }
            else
              this.navCtrl.pop();
          }
        else
         {
           outlet.pop();
         } 
      }
    });
  });
}
/*
async presentToastConfirm() {
  const alert = await this.alertCtrl.create({
    // header: 'Confirm!',
    message: '앱을 종료하시겠습니까?',
    buttons: [{
      text: '취소',
      role: 'cancel',
      //cssClass: 'secondary',
      handler: (blah) => {}
    }, {
      text: '종료',
      handler: () => {
        navigator['app'].exitApp();
      }
    }]
  });

  await alert.present();
}*/

/*
  async alertnewversion(){
    var newveralarm, newvermsg, cancelbtn, updatebtn;
    this.translate.get('newversionalarm').subscribe(res=>{
    newveralarm = res;
    });
    this.translate.get('youneedupdateapp').subscribe(res=>{
    newvermsg = res;
    });
    this.translate.get('Cancel').subscribe(res=>{
    cancelbtn = res;
    });
    this.translate.get('updatenow').subscribe(res=>{
    updatebtn = res;
    });
    const alert = await this.alertCtrl.create({
    header: newveralarm,
    message: newvermsg,
    buttons: [
        {
        text: cancelbtn,
        handler: () => {
          this.afDB.database.ref('/userProfile/'+this.uid).update({skip:this.currentver});
          this.updateable = false;
        }
        },
        {
        text: updatebtn,
        handler: () => {
          this.updateapp();
        }
        }
    ]
    });
    return await alert.present();
  }*/

  
    updateapp()
    {
      let today = new Date();
      let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
      this.afDB.database.ref('/systemlog/' + (this.uid?this.uid:'unregister') + '/event/' + todaystr + '/appupdate').transaction(view=>{
        return (view || 0) + 1;
      })
      if(this.gv.pltinfo == 'ios')
        this.iab.create("https://itunes.apple.com/us/app/채널/id1343026996?l=ko&ls=1&mt=8", '_blank');
      else if(this.gv.pltinfo == 'android')
        this.iab.create("market://details?id=com.ionicframework.channel201800", "_system");
    }

    /*
  changenoti(){
  this.notireject = !this.notireject;
  this.afDB.database.ref('/userProfile/'+this.uid+'/alarm/reject').set(this.notireject);
  if(!this.notireject)
    this.presentToast('최신글과 활동알림, 메시지를 알람을 수신합니다.');
  else
    this.presentToast('모든 알람을 받지않습니다.');
  this.setnoti();
  }
  setnoti(target,val,fav?){
  if(this.gv.cordova)
  {
    //개인 알림 설정
    if(target == 'personal')
    {
    if(val)
      this.fcm.subscribeToTopic(this.uid);
    else
      this.fcm.unsubscribeFromTopic(this.uid);
    }
    //새글 알림 설정
    if(target == 'recent')
    {
      if(val)
      {
        this.fcm.subscribeToTopic('cairns_kr_recent');
        //최신글 서브스크라이브일시는 모든 즐겨찾기 서브스크라이브 해제
        this.afDB.database.ref('/userData/'+this.uid+'/favlist').once('value').then(rst=>{
          rst.forEach(item=>{
            this.fcm.unsubscribeFromTopic(item.val().chid);
          })
        })
        this.afDB.database.ref('/userData/'+this.uid+'/favlist').off();
      }
      else
      {
        this.fcm.unsubscribeFromTopic('cairns_kr_recent');   
      }
    }

    if(target == 'fav')
    {
      if(val)
        {
          this.afDB.database.ref('/userData/'+this.uid+'/favlist').on('child_added',rst=>{
            this.fcm.subscribeToTopic(rst.val().chid);
          })
          this.afDB.database.ref('/userData/'+this.uid+'/favlist').on('child_removed',rst=>{
            this.fcm.unsubscribeFromTopic(rst.val().chid);
          })
        }
        else
        {
          this.afDB.database.ref('/userData/'+this.uid+'/favlist').once('value').then(rst=>{
            rst.forEach(item=>{
              this.fcm.unsubscribeFromTopic(item.val().chid);
            })
          })
          this.afDB.database.ref('/userData/'+this.uid+'/favlist').off();
        } 
    }
    
  }
  }*/

  showNotidetail(type, id, msgid?, router?)
  {
    let pagename;
    let classname = 'modalfull';
    let prop; 
    
    if(type == 'view' || ((!type || type =='community') && id)){
      if(router)
        this.router.navigate(['/chview/'+id]);
      else{
        pagename = ChviewPage; 
        prop = {modal:true,id:id} 
        this.modalCtrl.create({
          component: pagename,
          componentProps:<null>prop,
          cssClass: classname
        }).then(_=>_.present())
      }
    }
    else if(type == 'msg'){
      pagename = MsgviewPage; 
      this.afDB.database.ref('/userProfile/'+ id +'/nickname').once('value').then(rst=>{
        prop = {id:msgid,chatwith:id,nickname:rst.val()}
        this.modalCtrl.create({
          component: pagename,
          componentProps:<null>prop,
          cssClass: classname
        }).then(_=>_.present())
      })
    }
    else if(type == 'custom'){
        pagename = ImgmodalPage; classname = 'imgmodal'; 
        let imglist = [];
        this.afDB.database.ref('/appmsg/' + id).once('value').then(rst=>{
          rst.forEach(img=>{
            if(img.val().url)
              imglist.push(img.val());
          })
          prop = {index: 0,img:imglist}  
          this.modalCtrl.create({
          component: pagename,
          componentProps:<null>prop,
          cssClass: classname
        }).then(_=>_.present())
        })
    }
    else if(type == 'pitem')
    {
      pagename = PitemdetailPage; 
      prop = {modal:true,id:id} 
      this.modalCtrl.create({
        component: pagename,
        componentProps:<null>prop,
        cssClass: classname
      }).then(_=>_.present())
    }
  }

  async showNotidetail1(type, id, msgid?)
  {
      /*
      if(type == 'view')
        this.modalCtrl.create('Chview',{itemId:id, showclose:true}, {cssClass:"modalfull"}).present();
      else if(type == 'work' || type == 'talent')
        this.modalCtrl.create('DetailNPage',{itemId:id, showclose:true}, {cssClass:"modalfull"}).present();
      else if(type == 'community')
        this.modalCtrl.create('DetailCPage',{itemId:id, showcat:true, showclose:true}, {cssClass:"modalfull"}).present();
      else if(type == 'service')
        this.modalCtrl.create('DetailSPage',{itemId:id, showcat:true, showclose:true}, {cssClass:"modalfull"}).present();
      else if(type == 'newmember')
        this.modalCtrl.create('MemberPage',{nickName:id}, {cssClass:"modalfull"}).present();
      else if(type == 'anytalk')
        this.modalCtrl.create('TalkDetailPage',{itemId:id}, {cssClass:"modalfull"}).present();
      else if(type == 'appmsg')
        this.modalCtrl.create('IntroPage',{msgid:id}, {cssClass:"modalfull"}).present();
      else if(type == 'msg' && msgid)
        this.modalCtrl.create('DetailMboxPage',{senderid:id, msgid:msgid, showclose:true}, {cssClass:"modalfull"}).present();
      else if(type == 'msg' && !msgid)  
        this.modalCtrl.create('DetailMsgPage',{senderid:id, showclose:true}, {cssClass:"modalfull"}).present();
      else if(type == 'receivedmsg') {}
      else{}
*/
    /*
        if(type == 'place' || type == 'share' || type == 'trade')
          this.modalCtrl.create('DetailPtPage',{itemId:id}, {cssClass:"modalfull"}).present();
          //this.navCtrl.push('DetailPtPage',{itemId:id});
        else if(type == 'work' || type == 'talent')
          this.navCtrl.push('DetailPtPage',{itemId:id});
        else if(type == 'work')
          this.navCtrl.push('DetailNPage',{itemId:id});
        else if(type == 'community')
          this.navCtrl.push('DetailCPage',{itemId:id, showcat:true});
        else if(type == 'trade')
          //this.navCtrl.setPages({page:'TradeDetailPage'},{itemId:key});
          this.navCtrl.push('DetailPtPage',{itemId:id});
        else if(type == 'talent')
          this.navCtrl.push('DetailNPage',{itemId:id});
        else if(type == 'newmember')
          this.navCtrl.push('MemberPage',{nickName:id});
        else if(type == 'anytalk')
          this.navCtrl.push('TalkDetailPage',{itemId:id});
        else if(type == 'appmsg')
          this.navCtrl.push('IntroPage',{msgid:id});
        else if(type == 'msg')
        {
          this.navCtrl.push('DetailMsgPage',{senderid:id});
        }
        else if(type == 'receivedmsg')
        {
          //this.navCtrl.parent.select(6);
        }
        else{}*/
  }


  async changelan()
  {
    var titlemsg;
    this.translate.get('Select Language').subscribe(res=>{
      titlemsg = res;
    });

    const optlan = [{text:'한국어', value:'kr'}, {text:'中文', value:'ch'}, {text:'English', value:'en'}];
    const optloc = [{text:'Cairns', value:'cairns'},{text:'Brisbane', value:'brisbane'},{text:'Test', value:'test'}];

    let columnset = [{name: 'lan',options: optlan},{name: 'loc',options: optloc}]
    
    const picker = await this.pickerCtrl.create({
      columns: columnset,
      buttons: [
      {
        text: '확인',
        handler: (data) => {
          let prelan = this.lan;
          let preloc = this.loc;
          if(data.lan)
          {
            this.gv.setLan(data.lan.value);
            this.lan = data.lan.text;
            //if(this.uid)
            //    this.afDB.database.ref('/userProfile/' + this.uid + '/info').update({lancode:data.lan.value});
          }
          if(data.loc)
          {
            this.gv.setLoc(data.loc.value);
            this.loc = data.loc.text;
            //if(this.uid)
            //    this.afDB.database.ref('/userProfile/' + this.uid + '/info').update({loccode:data.loc.value});
          }
          if(prelan != this.lan || preloc != this.loc)
          {
            if(!this.gv.cordova)
              setTimeout(_=>{window.location.reload()},100);
            else
              //this.initializeApp();
              this.presentToast('앱을 재실행해야 설정이 반영됩니디.');
          }
        }
      }
    ]});
    await picker.present();
  }

  /*
    getalram(){
    this.alram = [];
    this.afDB.list('/alram/' + this.uid).pipe(map( (Items) => {
    return Items; } )).pipe(map((Items) =>{
        Items.forEach(item => {
          if(!item.loclan)
            item.loclan = 'cairns_kr';
        });
      this.alramloaded = true;
      return Items;
    })).subscribe(rst=>{
      this.alramunread = 0;
      rst.forEach(element => {
        if(element.loclan == this.loclan && !element.read)
          this.alramunread++;
      });
      this.badge.set(this.unreadcount + this.alramunread);
      if(this.alramunread > 0)
        this.gv.setUnreadAlarm(true);
      else
        this.gv.setUnreadAlarm(false);
      this.alram = rst;
    });
    }*/

  async updatealert(){
    var newveralarm, newvermsg, cancelbtn, updatebtn;
    this.translate.get('newversionalarm').subscribe(res=>{
    newveralarm = res;
    });
    this.translate.get('youneedupdateapp').subscribe(res=>{
    newvermsg = res;
    });
    this.translate.get('Cancel').subscribe(res=>{
    cancelbtn = res;
    });
    this.translate.get('updatenow').subscribe(res=>{
    updatebtn = res;
    });
    let alert = await this.alertCtrl.create({
    header: newveralarm,
    message: newvermsg,
    buttons: [
        {
        text: cancelbtn,
        handler: () => {
          this.gv.setSkipver(this.currentver);
          this.updateable = false;
        }
        },
        {
        text: updatebtn,
        handler: () => {
          this.updateapp();
        }
        }
    ]
    });
    return await alert.present();
  }


  
  chkversion(){
        // 버전정보 비교해서 업데이트
        this.gv.thisver = this.thisver;
        this.afDB.database.ref('/app/ver').once('value').then(ver=>{
        if(this.gv.pltinfo == 'ios')
          {
          this.currentver = ver.val().ios;
          this.gv.currentver = ver.val().ios;
          this.lockver = ver.val().ioslock;
          }
        else if(this.gv.pltinfo == 'android')
          {
            this.currentver = ver.val().android;
            this.gv.currentver = ver.val().android;
            this.lockver = ver.val().androidlock;
          }
        else
          {
            this.currentver =  this.thisver;
            this.gv.currentver =  this.thisver;
          }
        }).then(_=>{
          //락은 해당버전 미만 락됨, 락버전과 같은버전은 엑세스 허용
          if( this.thisver.split('.')[0] < this.currentver.split('.')[0]
        || (this.thisver.split('.')[0] == this.currentver.split('.')[0])  && this.thisver.split('.')[1] < this.currentver.split('.')[1]  
            || (this.thisver.split('.')[1] == this.currentver.split('.')[1]) && this.thisver.split('.')[2] < this.currentver.split('.')[2] )
              { 
                this.updateable = true;
                if( this.thisver.split('.')[0] < this.lockver.split('.')[0]
              || (this.thisver.split('.')[0] == this.lockver.split('.')[0])  && this.thisver.split('.')[1] < this.lockver.split('.')[1]  
                  || (this.thisver.split('.')[1] == this.lockver.split('.')[1]) && this.thisver.split('.')[2] < this.lockver.split('.')[2] )
                {
                  this.closeapp();
                }  
              }
            else
              this.updateable = false;
        if((this.gv.pltinfo == 'ios' || this.gv.pltinfo == 'android') && this.updateable) //profile['appver'] != this.currentver && profile['skip'] != this.currentver)) //자동업데이트   
         {
           this.gv.getSkipver().then(skipver=>{
             if(skipver != this.currentver)
              this.updatealert();
           })
         } 
        })
  }
      
  initializeApp() {
      this.gv.cordova = this.platform.is('capacitor');
      if(this.gv.cordova)
        {
        
        //이하 fcm셋팅
        //this.fcm.getToken().then(rst=>{console.log('~~~~~~~~~~~~~~~~fcm token~~~~~~~~~~~~~~~~~~~~~');console.log(rst)})
        this.fcm.onNotification().subscribe(data => {
          if(data.badge)
              this.badge.set(data.badge);
            
          if(data.wasTapped){
            this.showNotidetail(data.type, data.id, data.msgid, true);
          }
            /*
          if(this.gv.unickname)
            this.showNotidetail(data.type, data.id, data.msgid);
          else
          setTimeout(() => {
            this.showNotidetail(data.type, data.id, data.msgid);
          }, 1200); 
          }*/ 
          else{this.armpresentToast(data.message,data.type, data.id);}

          // 로그에 노티받음 기록
          setTimeout(function() {
            let today = new Date();
            let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
            this.afDB.database.ref('/systemlog/' + (this.gv.uid?this.gv.uid:'unregister') + '/event/' + todaystr + '/notireceived').transaction(view=>{
            return (view || 0) + 1;
            })
          }, 5000);

        });
        this.platform.resume.subscribe(() => {
             //console.log('~~~~~~~~~~~~~~~~~~~~~~재시작~~~~~~~~~~~~~~~~~~~~~~')
            // 백그라운드에서 포그라운드 나올시 로그인 포인트 체크
            setTimeout(_=>{this.authdata.chkloginpoint(); this.authdata.setinitlog()},1000)
          })
        }
      //alert(this.platform.url());
      if (this.platform.is('ios'))
        {
        this.gv.pltinfo = 'ios' + (this.gv.cordova?'':'_web');
        this.gv.ios = true;
        }
      else if (this.platform.is('android'))
        {
        this.gv.pltinfo = 'android' + (this.gv.cordova?'':'_web');
        this.gv.android = true;
        if(this.gv.cordova)
        {
          // let status bar overlay webview
          //this.statusBar.overlaysWebView(true);
          // set status bar to white
          //>>>>>>>>>>>>>>this.statusBar.backgroundColorByHexString('#ffffff');
          //this.statusBar.styleDefault();//.backgroundColorByHexString('#ffffff');
          /*
          this.platform.backButton.subscribe(() => {
            this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
                if (window.location.pathname == "/tabs/home" || window.location.pathname == "/signin") {
                  //console.log(window.location.pathname);
                  //console.log(outlet);
                  //console.log(outlet.canGoBack())
                  navigator['app'].exitApp();
                }
                else if(window.location.pathname == "/tabs/sm") {
                  this.router.navigate(['']);
                }
                else if(window.location.pathname == "/tabs/pm") {
                  this.router.navigate(['']);
                }
                else if(window.location.pathname == "/tabs/pfmenu") {
                  this.router.navigate(['']);
                }
                else if(window.location.pathname == "/tabs/search") {
                  this.router.navigate(['']);
                }
                /*
                else if (outlet && !outlet.canGoBack()) {
                  this.router.navigate(['']);
                }*/
                /*
                else if(window.location.pathname.indexOf("/tabs/chmlist") == 0) {
                  this.router.navigate(['']);
                }*/
                /*
                else if (outlet && outlet.canGoBack()) {
                    outlet.pop();
                }*/
          //    })
          //});
        }
        }
      else
        {
        this.gv.pltinfo = 'others';
        }
      //현재버전//스킵버전
      this.authdata.init();
      this.chkversion();
     }

  
/*
if(this.gv.cordova)
this.platform.resume.subscribe(() => {
// 백그라운드에서 포그라운드 나올시 로그인 포인트 체크
  let today = new Date();
  let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
  this.afDB.database.ref('/systemlog/unregister').update({pltinfo:this.gv.pltinfo,lastlogin:firebase.database.ServerValue.TIMESTAMP});
  this.afDB.database.ref('/systemlog/unregister/event/' + todaystr + '/visit').transaction(visit=>{
    return (visit || 0) + 1;
  })
});
*/
  

  

/*
  async showmodal(page){
    let pagename;
    let type = null;
    let log = null;
    let prop; //{modal:true, type:null};log = 'actchk'; 
    prop = {modal:true, type:null};
    switch(page){
      case 'pointinfo':pagename = PointinfoPage; break;
      case 'mypoint':pagename = MypointPage; break;
      case 'msg':pagename = MessagePage; break;
      case 'alarm':pagename = AlarmPage; break;
      case 'nickname':pagename = NicknamePage; break;
      case 'blockuser':pagename = BlockuserPage; break;
      case 'pwdchange':pagename = PwdchangePage; break;
      case 'notice':pagename = NoticePage; log = 'noticechk'; break;
      case 'qna':pagename = QnaPage; log = 'qnachk'; break;
      case 'version':pagename = VersionPage; prop.currentver = this.currentver; prop.thisver = this.thisver; break;
      case 'favchlist':pagename = FavchlistPage; log = 'actchk'; break;
      case 'actrecent':pagename = ListmodalPage; prop.type='r'; log = 'actchk'; break;
      case 'actpost':pagename = ListmodalPage; prop.type="w"; log = 'actchk'; break;
      case 'actliked':pagename = ListmodalPage; prop.type="l"; log = 'actchk'; break;
      case 'actcomment':pagename = ListmodalPage; prop.type="c"; log = 'actchk'; break;
      case 'mybucket':pagename = MybucketPage; log = 'actchk'; break;
      case 'mylevel':pagename = MylevelPage;  log = 'levelchk'; break;
      case 'myaccount':pagename = AccountPage; break;
      case 'setnoti':pagename = SetnotiPage; log = 'alarmchk'; break;
      case 'setlocal':pagename = SetlocalPage; break;
      case 'systemlog':pagename = SystemlogPage; break;
      //case 'labs':pagename = LabsPage; break;
      }
    if(log)
    {
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
    this.afDB.database.ref('/systemlog/' + (this.uid?this.uid:'unregister') + '/event/' + todaystr + '/' + log).transaction(view=>{
      return (view || 0) + 1;
    })
    }

    const modal = await this.modalCtrl.create({
      component: pagename,
      componentProps:<null>prop,//{modal:true, type:type},
      cssClass: 'modalfull'
    });
    return await modal.present();
  }*/

  async closeapp(){
    let today = new Date();
    let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
    this.afDB.database.ref('/systemlog/' + (this.uid?this.uid:'unregister') + '/event/' + todaystr + '/appclosed').transaction(view=>{
    return (view || 0) + 1;
    })
    let prop = {pltinfo:this.gv.pltinfo}
    const modal = await this.modalCtrl.create({
      componentProps:<null>prop,
      component: ClosedPage,
      cssClass: 'modalfull'
    });
    return await modal.present();
  }

  adminfc(){
    //if(this.afAuth.auth.currentUser.email == 'hoxer80@gmail.com')
    {
      //this.showNotidetail('view', '-Lh2uajiqwCaQhZMsOwN');
      //this.showNotidetail('custom', 'ch2018event');
      //this.showNotidetail('msg', 'G00CkN8KxZQVNERsxFDrwwh73mB2','-LFbIathX9T9CUA89Pkr');
      
      //this.afDB.database.ref('/list').once('value').then(data=>{
      //  this.afDB.database.ref('/lists/cairns_kr').set(data.val());
      //})

      /*
      let count = 0;
      this.afDB.database.ref('userProfile').once('value').then(users=>{
        users.forEach(user => {
          //console.log(item.val().title + ' >> ' + item.key )
          if(user.val() && user.val().nickname)
          {
            count++;
            var nick = '',img = '',lvl = '';
            nick = user.val().nickname;
            if(user.val().profileThumbPicture)
              img = user.val().profileThumbPicture.url;
            if(user.val().level)
              lvl = user.val().level.currentlevel;
            //console.log(nick + ' (' + lvl + ') - ' + img)
            this.afDB.database.ref('userProfile/' + user.key + '/sum')
          .update({nick:nick, img:img, lvl:lvl});
          }
          });
        console.log(count);
      })*/
      /*
      let count = 0;
      this.afDB.database.ref('list').orderByChild('type').equalTo('trade').once('value').then(items=>{
        items.forEach(item => {
          //console.log(item.val().title + ' >> ' + item.key )
          count++;
          this.afDB.database.ref('list/' + item.key).update({categoryId:'-LZlRs1JmeYY6_M20Tns'});
        });
        console.log(count);
      })
      */
      //this.afDB.database.ref('/category/-KyU3z4upRvIv0oXJ1gM').update();
      /*
      this.afDB.database.ref('/category').push({
        chtype:'saleveiw',
        createdDate: firebase.database.ServerValue.TIMESTAMP,
        description: "케언즈의 중고거래 대표 채널입니다.",
        groupid:'cairns_kr',
        location:'Cairns',
        name:'매매',
        password:'',
        point:200,
        publiccommunity:true,
        userId:'NjQizqLkBINRT9LF9oHjmMing403'
      }).then(rst=>console.log(rst))
    */
    }
  }

  adminfunc(){
    
    
    /*/NjQizqLkBINRT9LF9oHjmMing403
    let allblockuser = [];
    let onuser = [];
    let oncount = 0;
    let offcount = 0;
    this.afDB.database.ref('/userProfile').once('value').then(rst=>{
      rst.forEach(r=>{
        let user = r.val();
        //console.log(user.nickname?user.nickname:'닉네임없음-'+r.key);
        let count = 0;
        
        if(user.alram){
          
          if(user.alram.community)
            count++;
          if(user.alram.msg)
            count++;
          if(user.alram.place)
            count++;
          if(user.alram.share)
            count++;
          if(user.alram.talent)
            count++;
          if(user.alram.trade)
            count++;
          if(user.alram.work)
            count++;
          //console.log(count>0?'이전버전알람'+count+'개':'이전버전 알람 모두 차단함-'+count+'개');
        }
        else
        {
          //console.log('이전버전에 알람기록 없음');
        }
        
        if(user.alarm && user.alarm.reject)
          {
            if(count == 0){
              this.afDB.database.ref('/userProfile/' + r.key + '/alarm').set({personal:false,recent:false,fav:false});
              offcount++
            }
            else
              {
              this.afDB.database.ref('/userProfile/' + r.key + '/alarm').set({personal:true,recent:true,fav:true});
              oncount++
            }
          }
          //allblockuser.push({nickname:user.nickname?user.nickname:'닉네임없음',prealarm:count,reject:user.alarm.reject,key:r.key})
        else
          {
            if(count == 0)
            {
              this.afDB.database.ref('/userProfile/' + r.key + '/alarm').set({personal:false,recent:false,fav:false});
              offcount++
              //allblockuser.push({nickname:user.nickname?user.nickname:'닉네임없음',prealarm:count,reject:(user.alarm && user.alarm.reject?user.alarm.reject:'no data'),key:r.key})
            }
            else
              {
              this.afDB.database.ref('/userProfile/' + r.key + '/alarm').set({personal:true,recent:true,fav:true});
              oncount++;
            }
              //onuser.push({nickname:user.nickname?user.nickname:'닉네임없음',prealarm:count,reject:(user.alarm && user.alarm.reject?user.alarm.reject:'no data'),key:r.key});
          }
          
        
        //this.afDB.database.ref('/userProfile/' + r.key + '/alarm').set('');
     

      })
      console.log('알람차단유저');
      console.log(offcount);
      console.log('알람온 유저!!')
      console.log(oncount);

    })
    /*
    this.afDB.database.ref('/category').once('value').then(rst=>{
      let itemcount = 0;
      
      rst.forEach(element => {
        if(element.val().category)
          {
          let folder;
          if(!element.val().groupid)
            console.log(element.key + ':' + element.val().name)
          itemcount++;
          //this.afDB.database.ref('/category/' + element.key).update({folder:folder});
          }
      });
      console.log(itemcount + '개 항목');
    })*/
  }

  async presentToast(message: string) {
    let toast = await this.toastCtrl.create({
    message: message,
    position: 'bottom',
    duration: 2200
    });
    toast.present();
    }

   async armpresentToast(message: string, type, id, msgid?) {
    if(message)
    {
    var show;
    this.translate.get('show').subscribe(res=>{
      show = res;
    });
    let toast = await this.toastCtrl.create({
      message: message,
      position: 'top',
      buttons:[{
        text:show,
        handler:()=>{
          this.showNotidetail(type, id, msgid);
        }
      }],
      duration: 3600
    });
    toast.present();
    }
    }


  
}
