import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ChcatPipe'
})
@Injectable()

export class ChcatPipe  implements PipeTransform {
 transform(items: any[], field: string, value: string): any[] {
     
   if (!items) return [];
   if (!value) return [];
   return items.filter(it => 
    {
      var chk = false;
      for(var i=0; i < value.split(',').length; i++)
        {
        if(it[field] == value.split(',')[i])
            chk = true;
        }
      return chk
    })
 }
}