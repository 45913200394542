import { Component, OnInit, Input } from '@angular/core';
import { PopoverController,NavParams } from '@ionic/angular';
import { GlobalVars } from '../../providers/globalVars';
@Component({
  selector: 'app-pagemenu',
  templateUrl: './pagemenu.component.html',
  styleUrls: ['./pagemenu.component.scss']
})
export class PagemenuComponent implements OnInit {
showmenu;
custom;
owner;
notice;
showshare;
id;
constructor(public popover:PopoverController, private navParams:NavParams, public gv:GlobalVars){
}
ngOnInit()
{
  /*
  this.showmenu = this.navParams.get('showmenu');
  this.showshare = this.navParams.get('showshare');
  this.owner = this.navParams.get('owner');
  this.custom = this.navParams.get('custom');*/
  
  // this.notice = this.navParams.get('notice');
  // console.log(this.notice);
}

submit(val){
  this.popover.dismiss({value:val});
}
async copyToClipboard(){
  let str = 'https://channel.cafe/chview/' + this.id;
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  }


}
