import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'BlockchkPipe',
})
@Injectable()
export class BlockchkPipe implements PipeTransform {
    transform(items: any[],  value: string, target?: string, reverse?: string): any[] {
   if (!items) return [];
   if (!value) return items;
  if(!target)
   return items.filter(it => 
       (!value.includes(it['userId'])
    ))
   else if(!reverse)
     return items.filter(it => 
       (!value.includes(it[target])))         
    else
        return items.filter(it => 
       (
           it[target]?!(it[target]+'').includes(value):true)
        ) 
    }
}