import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ObjPipe',
    pure: false
})
export class ObjPipe implements PipeTransform {
    transform(value): any {
        return Object.keys(value);
    }
}