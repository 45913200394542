import { NgModule, Injectable } from '@angular/core';
import { TimeAgoPipe } from './time-ago-pipe';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
@Injectable()
@NgModule({
  declarations: [
    TimeAgoPipe,
  ],
  imports: [
    TranslateModule
  ],
  providers:[TranslatePipe],
  exports: [
    TimeAgoPipe
  ]
})
export class TimeAgoPipeModule {}
