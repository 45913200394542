import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CatgoryPipe',
    pure:false
})
@Injectable()
export class CatgoryPipe  implements PipeTransform {
 transform(items: any[], field: string, value: any, reverse?:boolean): any[] {
   if (!items) return [];
   if (reverse)
     return items.filter(it => (it[field]) != value);
   else
     return items.filter(it => (it[field]) == value);
  
}
}