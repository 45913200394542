import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { GlobalVars } from '../../../providers/globalVars';
import { AngularFireDatabase} from '@angular/fire/database';
import {ChviewPage} from '../../../pages/general/chview/chview.page';
@Component({
  selector: 'app-discountdetail',
  templateUrl: './discountdetail.component.html',
  styleUrls: ['./discountdetail.component.scss']
})
export class DiscountdetailComponent implements OnInit {
  @ViewChild(IonSlides) slides:IonSlides;
  list;
  index;
  lan:any;
  constructor(private modalCtrl: ModalController,public gv:GlobalVars, private afDB:AngularFireDatabase) { }

  ngOnInit() {
    this.lan = this.gv.getLan();
  }
  async showpage(id, loclancode) {
    const modal = await this.modalCtrl.create({
      component: ChviewPage,
      componentProps: < null > {
        modal:true,
        id: id,
        loclancode: loclancode
      },
      cssClass: 'modalfull'
    });
    return await modal.present();
  }
  async closePopup() {
    await this.modalCtrl.dismiss();
  }
}
