import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './providers/auth-guard';
import { CanDeactivateGuard } from './providers/post-guard';
import { MetaModule, MetaConfig } from 'ng2-meta';

const metaConfig: MetaConfig = {
  //Append a title suffix such as a site name to all titles
  //Defaults to false
  warnMissingGuard:false,
  useTitleSuffix: true,
  defaults: {
    // title: '호주 우리동네 소식을 채널 앱에서 한번에!',
    titleSuffix: ' | Channel',
    // 'og:image': 'http://example.com/default-image.png',
    // 'any other': 'arbitrary tag can be used'
  }
};

const routes: Routes = [
  { path: '', canActivate: [AuthGuard],data: { 
            path: 'root'
         },  loadChildren:() => 
          import('./tabs/tabs.module').then(m => m.TabsPageModule)},// './pages/general/post/post.module#PostPageModule' },//./tabs/tabs.module#TabsPageModule
  //{ path: '', redirectTo:'signin', pathMatch:'full'},
  { path: 'home', data: {meta: {title: '채널 - 우리동네 정보를 한눈에!!',description: '채널은 호주 모든 정보를 한눈에 알기쉽게 볼수 있는 커뮤니티 앱입니다. 호주의 맛집, 장소, 관광, 쇼핑, 취미, 쉐어, 구인 등 다양한 주제로 여러분에게 맞춤형 정보를 제공해드립니다. 시드니, 멜버른, 브리즈번, 케언즈, 퍼스의 모든 정보를 확인하세요.'}}, loadChildren: () => import('./pages/general/home/home.module').then(m => m.HomePageModule) },
  { path: 'chmain/:ch', loadChildren: () => import('./pages/general/chmain/chmain.module').then(m => m.ChmainPageModule) },
  { path: 'chmain/:ch/:tab', loadChildren: () => import('./pages/general/chmain/chmain.module').then(m => m.ChmainPageModule) },
  { path: 'chview/:id', loadChildren: () => import('./pages/general/chview/chview.module').then(m => m.ChviewPageModule) },
  { path: 'chview/:id/:loclan', loadChildren: () => import('./pages/general/chview/chview.module').then(m => m.ChviewPageModule) },
  { path: 'menupan', loadChildren: () => import('./pages/general/chview/menupan/menupan.module').then(m => m.MenupanPageModule) }
  ,{ path: 'chadd',canActivate: [AuthGuard], loadChildren: () => import('./pages/general/chadd/chadd.module').then(m => m.ChaddPageModule) }
  ,{ path: 'chadd/:ch',canActivate: [AuthGuard], loadChildren: () => import('./pages/general/chadd/chadd.module').then(m => m.ChaddPageModule) },
          
 // { path: 'chview/:id/:ignore', loadChildren: './pages/general/chview/chview.module#ChviewPageModule' },
  { path: 'signin', loadChildren: () => import('./pages/auth/signin/signin.module').then(m => m.SigninPageModule) },
  { path: 'register', loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'forgot', loadChildren: () => import('./pages/auth/forgot/forgot.module').then(m => m.ForgotPageModule) },
  { path: 'chsignin', loadChildren: () => import('./pages/auth/chsignin/chsignin.module').then(m => m.ChsigninPageModule) },
  { path: 'mymain',canActivate: [AuthGuard], loadChildren: () => import('./pages/mypage/mymain/mymain.module').then(m => m.MymainPageModule) },
  { path: 'search', loadChildren: () => import('./pages/general/search/search.module').then(m => m.SearchPageModule) },
  { path: 'search/:id', loadChildren: () => import('./pages/general/search/search.module').then(m => m.SearchPageModule) },
  { path: 'recentlist', loadChildren: () => import('./pages/general/recentlist/recentlist.module').then(m => m.RecentlistPageModule) },
  { path: 'services', loadChildren: () => import('./pages/general/services/services.module').then(m => m.ServicesPageModule) },
  { path: 'addservice', loadChildren: () => import('./pages/general/services/addservice/addservice.module').then(m => m.AddservicePageModule) },
  { path: 'servicedetail/:id/:loclan', loadChildren: () => import('./pages/general/services/servicedetail/servicedetail.module').then(m => m.ServicedetailPageModule) },
  { path: 'servicepost', loadChildren: () => import('./pages/general/services/servicepost/servicepost.module').then(m => m.ServicepostPageModule) },
  { path: 'post/:ch',canActivate: [AuthGuard], loadChildren: () => import('./pages/general/post/post.module').then(m => m.PostPageModule)},
  { path: 'post/:ch/:id',canActivate: [AuthGuard], loadChildren: () => import('./pages/general/post/post.module').then(m => m.PostPageModule)},
  { path: 'message', loadChildren: () => import('./pages/menu/message/message.module').then(m => m.MessagePageModule) },
  { path: 'alarm', loadChildren: () => import('./pages/menu/alarm/alarm.module').then(m => m.AlarmPageModule) },
  { path: 'nickname', loadChildren: () => import('./pages/menu/nickname/nickname.module').then(m => m.NicknamePageModule) },
  { path: 'blockuser', loadChildren: () => import('./pages/menu/blockuser/blockuser.module').then(m => m.BlockuserPageModule) },
  { path: 'pwdchange', loadChildren: () => import('./pages/menu/pwdchange/pwdchange.module').then(m => m.PwdchangePageModule) },
  { path: 'signoff', loadChildren: () => import('./pages/menu/signoff/signoff.module').then(m => m.SignoffPageModule) },
  { path: 'notice', loadChildren: () => import('./pages/menu/notice/notice.module').then(m => m.NoticePageModule) },
  { path: 'noticedetail', loadChildren: () => import('./pages/menu/noticedetail/noticedetail.module').then(m => m.NoticedetailPageModule) },
  { path: 'version', loadChildren: () => import('./pages/menu/version/version.module').then(m => m.VersionPageModule) },
  { path: 'point', loadChildren: () => import('./pages/mypage/point/point.module').then(m => m.PointPageModule) },
  { path: 'act', loadChildren: () => import('./pages/mypage/act/act.module').then(m => m.ActPageModule) },
  { path: 'qna', loadChildren: () => import('./pages/menu/qna/qna.module').then(m => m.QnaPageModule) },
  { path: 'admin/ch/:id',canActivate: [AuthGuard], loadChildren: () => import('./pages/admin/ch/ch.module').then(m => m.ChPageModule) },
  //{ path: 'map', loadChildren: './pages/support/map/map.module#MapPageModule' },
  { path: 'topmenu/:loclan',canActivate: [AuthGuard], loadChildren: () => import('./pages/support/topmenu/topmenu.module').then(m => m.TopmenuPageModule) },
  { path: 'chlistmodal',canActivate: [AuthGuard], loadChildren: () => import('./pages/support/chlistmodal/chlistmodal.module').then(m => m.ChlistmodalPageModule) },
  { path: 'mybucket', canActivate: [AuthGuard], loadChildren: () => import('./pages/support/mybucket/mybucket.module').then(m => m.MybucketPageModule) },
  { path: 'msgview/:id/:chatwith/:nickname', loadChildren: () => import('./pages/general/msgview/msgview.module').then(m => m.MsgviewPageModule) },
  { path: 'mypoint', loadChildren: () => import('./pages/menu/mypoint/mypoint.module').then(m => m.MypointPageModule) },
  { path: 'pointinfo', loadChildren: () => import('./pages/menu/pointinfo/pointinfo.module').then(m => m.PointinfoPageModule) },
  //{ path: 'actrecent', loadChildren: './pages/menu/actrecent/actrecent.module#ActrecentPageModule' },
  //{ path: 'actpost', loadChildren: './pages/menu/actpost/actpost.module#ActpostPageModule' },
  //{ path: 'actliked', loadChildren: './pages/menu/actliked/actliked.module#ActlikedPageModule' },
  //{ path: 'actcomment', loadChildren: './pages/menu/actcomment/actcomment.module#ActcommentPageModule' },
  { path: 'invite', loadChildren: () => import('./pages/menu/invite/invite.module').then(m => m.InvitePageModule) },
  { path: 'account', loadChildren: () => import('./pages/menu/account/account.module').then(m => m.AccountPageModule) },
  { path: 'mylevel', loadChildren: () => import('./pages/menu/mylevel/mylevel.module').then(m => m.MylevelPageModule) },
  { path: 'levelinfo', loadChildren: () => import('./pages/menu/levelinfo/levelinfo.module').then(m => m.LevelinfoPageModule) },
  { path: 'discountlist', loadChildren: () => import('./pages/general/discountlist/discountlist.module').then(m => m.DiscountlistPageModule) },
  { path: 'adddiscount',canActivate: [AuthGuard], loadChildren: () => import('./pages/general/adddiscount/adddiscount.module').then(m => m.AdddiscountPageModule) },
  { path: 'imgmodal', loadChildren: () => import('./pages/support/imgmodal/imgmodal.module').then(m => m.ImgmodalPageModule) },
  { path: 'listmodal', loadChildren: () => import('./pages/menu/listmodal/listmodal.module').then(m => m.ListmodalPageModule) },
  { path: 'favchlist', loadChildren: () => import('./pages/menu/favchlist/favchlist.module').then(m => m.FavchlistPageModule) },
  { path: 'servicesetting', loadChildren: () => import('./pages/admin/servicesetting/servicesetting.module').then(m => m.ServicesettingPageModule) },
  { path: 'doublecatmodal', loadChildren: () => import('./pages/support/doublecatmodal/doublecatmodal.module').then(m => m.DoublecatmodalPageModule) },
  { path: 'setnoti',canActivate: [AuthGuard], loadChildren: () => import('./pages/menu/setnoti/setnoti.module').then(m => m.SetnotiPageModule) },
  { path: 'setaccount', loadChildren: () => import('./pages/menu/setaccount/setaccount.module').then(m => m.SetaccountPageModule) },
  { path: 'listmap', loadChildren: () => import('./pages/support/listmap/listmap.module').then(m => m.ListmapPageModule) },
  { path: 'selectchitem', loadChildren: () => import('./pages/support/selectchitem/selectchitem.module').then(m => m.SelectchitemPageModule) },
  { path: 'closed', loadChildren: () => import('./pages/auth/closed/closed.module').then(m => m.ClosedPageModule) },
  { path: 'addmap', loadChildren: () => import('./pages/general/post/addmap/addmap.module').then(m => m.AddmapPageModule) },
  { path: 'servicemain', loadChildren: () => import('./pages/general/services/servicemain/servicemain.module').then(m => m.ServicemainPageModule) },
  { path: 'pointmain', loadChildren: () => import('./pages/general/point/pointmain/pointmain.module').then(m => m.PointmainPageModule) },
  { path: 'menu', loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule) },
  { path: 'systemlog', loadChildren: () => import('./pages/admin/systemlog/systemlog.module').then(m => m.SystemlogPageModule) },
  { path: 'pfmenu', loadChildren: () => import('./pages/menu/pfmenu/pfmenu.module').then(m => m.PfmenuPageModule) },
  //{ path: 'pitemdetail', loadChildren: './pages/general/point/pitemdetail/pitemdetail.module#PitemdetailPageModule' },
  //{ path: 'pitemcheckout', loadChildren: './pages/general/point/pitemcheckout/pitemcheckout.module#PitemcheckoutPageModule' },
  { path: 'pitemdetail/:id', loadChildren: () => import('./pages/general/point/pitemdetail/pitemdetail.module').then(m => m.PitemdetailPageModule) }
  ,{path: 'pitemcheckout/:id',canActivate: [AuthGuard], loadChildren: () => import('./pages/general/point/pitemcheckout/pitemcheckout.module').then(m => m.PitemcheckoutPageModule)}
  //,{path: 'precharge/:id', loadChildren: () => import('./pages/general/point/precharge/precharge.module').then(m => m.PrechargePageModule)}
  ,{ path: 'chargeconfirm/:id', loadChildren: () => import('./pages/general/point/chargeconfirm/chargeconfirm.module').then(m => m.ChargeconfirmPageModule) }
  ,{ path: 'purchaseconfirm', loadChildren: () => import('./pages/general/point/purchaseconfirm/purchaseconfirm.module').then(m => m.PurchaseconfirmPageModule) }
  ,{ path: 'pitemreg', loadChildren: () => import('./pages/general/point/pitemreg/pitemreg.module').then(m => m.PitemregPageModule) },
  { path: 'mypitem', loadChildren: () => import('./pages/menu/mypitem/mypitem.module').then(m => m.MypitemPageModule) },
  { path: 'firstvisitmodal', loadChildren: () => import('./pages/support/firstvisitmodal/firstvisitmodal.module').then(m => m.FirstvisitmodalPageModule) },
  { path: 'dpmap', loadChildren: () => import('./pages/admin/dpmap/dpmap.module').then(m => m.DpmapPageModule) },

  { path: 'selectimg', loadChildren: () => import('./pages/support/selectimg/selectimg.module').then(m => m.SelectimgPageModule) },
  //{ path: 'stripe', loadChildren: () => import('./pages/support/stripe/stripe.module').then(m => m.StripePageModule) },
  { path: 'mailsender', loadChildren: () => import('./pages/admin/mailsender/mailsender.module').then(m => m.MailsenderPageModule) },
  {
    path: 'test',
    loadChildren: () => import('./pages/support/test/test.module').then( m => m.TestPageModule)
  },
 // { path: 'addworktool', loadChildren: './pages/custom/worktool/addworktool/addworktool.module#AddworktoolPageModule' },
  //{ path: 'worktool', loadChildren: './pages/custom/worktool/worktool/worktool.module#WorktoolPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    MetaModule.forRoot(metaConfig)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
