import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { BlockchkPipeModule } from '../../../pipe/blockchkpipe.module';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
//import { MsgviewPageModule } from '../../general/msgview/msgview.module';

import { MessagePage } from './message.page';

const routes: Routes = [
  {
    path: '',
    component: MessagePage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,//MsgviewPageModule,
    IonicModule,BlockchkPipeModule,OrderByPipeModule,TimeAgoPipeModule,TranslateModule,
    RouterModule.forChild(routes)
  ],
  providers:[TranslatePipe],
  declarations: [MessagePage]
})
export class MessagePageModule {}
