import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typeNamePipe'
})
@Injectable()
export class TypeNamePipe  implements PipeTransform {
 transform(item: string): any {
   if (!item) return [];
   if (item == 'trade')
    return 'assets/icon/mypage_written.png';
   if (item == 'place')
    return 'assets/icon/mypage_written.png';
   if (item == 'house')
    return 'assets/icon/mypage_written.png';
   if (item == 'work')
    return 'assets/icon/mypage_written.png';
   if (item == 'community')
    return 'assets/icon/mypage_written.png';
   if (item == 'msgbox')
    return 'assets/icon/mypage_msgsent.png';
   if (item == 'msgboxread')
    return 'assets/icon/mypage_msgsentr.png';
   if (item == 'receivedmsgread')
    return 'assets/icon/mypage_msgreceiver.png';
   if (item == 'sentmsgread')
    return 'assets/icon/mypage_msgsentr.png';
   if (item == 'receivedmsg')
    return 'assets/icon/mypage_msgreceive.png';
   if (item == 'sentmsg')
    return 'assets/icon/mypage_msgsent.png';
   if (item == 'talent')
    return 'assets/icon/mypage_written.png';
   if (item == 'like')
    return 'assets/icon/mypage_like.png';
   if (item == 'comment')
    return 'assets/icon/mypage_cmt.png';
   if (item == 'Like')
    return 'assets/icon/like_icon.png';
   if (item == 'Dislike')
    return 'assets/icon/dislike_icon.png';
   if (item == 'Comment')
    return 'assets/icon/cmt_icon.png';
   if (item == 'Posting')
    return 'assets/icon/post_icon.png';
   if (item == 'Inviting')
    return 'assets/icon/invite_icon.png';
   else
    return '';
   //return item.replace(/\n/g, "<br>");
}
}