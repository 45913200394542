import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'LinkAttachPipe'
})
@Injectable()
export class LinkAttachPipe  implements PipeTransform {
 transform(item: string): any {
   if (!item || item.length == 0) return [];
   var indexes = [], i = -1;
   while ((i = item.indexOf('class=\"maploc*#*', i+1)) != -1){
        let mapstr = 
        item.substring(i,item.indexOf('\"', i+14))
        item = item.replace(mapstr, "tappable onclick=\"document.getElementById('\showmap'\).innerText = \'" 
        + mapstr + "\'; document.getElementById('\showmap'\).click()\"").replace('class=\"maploc*#*','')
    } 

    // while ((i = item.indexOf('class=\"link*#*', i+1)) != -1){
    //     let chlinkstr = item.substring(i,item.indexOf('\"', i+14))
    //     item = item.replace(chlinkstr, "tappable onclick=\"document.getElementById('\linkpage'\).innerText = \'" 
    //     + chlinkstr + "\'; document.getElementById('\linkpage'\).click();\"")
    //     .replace('class=\"link*#*','')
    // }
    while ((i = item.indexOf('class=\"link*#*', i+1)) != -1){
        let chlinkstr = item.substring(i,item.indexOf('\"', i+14))
        item = item.replace(chlinkstr, "tappable onclick=\"document.getElementById('\linkpage'\).innerText = \'"
        + chlinkstr + "\'; document.getElementById('\linkpage'\).click();\"")
        .replace('class=\"link*#*','')
    }
   return item;
}
}