import { Component, OnInit } from '@angular/core';
import { ModalController,Platform } from '@ionic/angular';

import { RuleservicePage } from '../ruleservice/ruleservice.page';
import { RuleprivacyPage } from '../ruleprivacy/ruleprivacy.page';
import { GlobalVars } from '../../../providers/globalVars';
@Component({
  selector: 'app-version',
  templateUrl: './version.page.html',
  styleUrls: ['./version.page.scss'],
})
export class VersionPage implements OnInit {
  cordova:boolean = false;
  thisver;
  currentver;
  updateable:any;
  constructor(public modalCtrl: ModalController,private platform:Platform,public gv:GlobalVars) { }

  ngOnInit() {
    this.thisver = this.gv.thisver;
    this.currentver = this.gv.currentver;
    this.chkver();
  }

  async showmodal(page){
    let pagename;
    let type = null;
    switch(page){
      case 'service':pagename = RuleservicePage; break;
      case 'privacy':pagename = RuleprivacyPage; break;
    }
    const modal = await this.modalCtrl.create({
      component: pagename,
      componentProps:<null>{modal:true, type:type},
      cssClass: 'modalfull'
    });
    return await modal.present();
  }

  chkver(){
    if( this.thisver.split('.')[0] < this.currentver.split('.')[0]
    || (this.thisver.split('.')[0] == this.currentver.split('.')[0])  && this.thisver.split('.')[1] < this.currentver.split('.')[1]  
    || (this.thisver.split('.')[1] == this.currentver.split('.')[1]) && this.thisver.split('.')[2] < this.currentver.split('.')[2] )
    { 
      this.updateable = true;
    }
    else
      this.updateable = false;
  }

    updateapp()
    {
      if(this.platform.is('ios'))
        window.open('https://itunes.apple.com/us/app/채널/id1343026996?l=ko&ls=1&mt=8', '_blank');
        //this.iab.create("https://itunes.apple.com/us/app/채널/id1343026996?l=ko&ls=1&mt=8", '_blank');
      else if(this.platform.is('android'))
        window.open('market://details?id=com.ionicframework.channel201800', '_system');
        //this.iab.create("market://details?id=com.ionicframework.channel201800", "_system");
    }

}
