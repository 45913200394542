import {Injectable} from '@angular/core';
import {  Http,Headers } from '@angular/http';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase/app';

@Injectable()
export class msgfunc {
  constructor(public http:Http,public translate: TranslateService) {
  }

  strip(html)
  {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
  }

  sendmsg(senderid, receiverid, message, sendername){
    var curmsgid, curchatboxid, oppsitemsgid, oppsitechatboxid;
    firebase.database().ref('/msgbox/' + senderid).orderByChild('chatwith').equalTo(receiverid)
    .once('value').then(msgbox=>
    {
        if(msgbox.val()) 
        {
        msgbox.forEach(box => {
            curmsgid = box.val().msgid;
            curchatboxid = box.key;
        });
        }
    })
    .then(_=>{
    firebase.database().ref('/msgbox/' + receiverid).orderByChild('chatwith').equalTo(senderid)
    .once('value').then(msgboxoppsite=>
    {
        if(msgboxoppsite.val()) 
        {
        msgboxoppsite.forEach(box=>{
            oppsitemsgid = box.val().msgid;
            oppsitechatboxid = box.key;
        })
        }
        // 두 멤버의 기존 메시지 박스 확인 완료
        if(curmsgid && (curmsgid == oppsitemsgid)) //두회원이 같은대화방을 쓴다면 메시지 해당방에 추가, 상대 unread카운트 1올림
        {
        firebase.database().ref('/msg/' + curmsgid + '/message').push({
            createdDate:firebase.database.ServerValue.TIMESTAMP,
            content: message,
            sendfrom: senderid
        }).then(_=>{
        firebase.database().ref('/msgbox/' + senderid + '/' + curchatboxid)
        .update({lastupdate:firebase.database.ServerValue.TIMESTAMP});

        firebase.database().ref('/msgbox/' + receiverid + '/' + oppsitechatboxid)
        .update({unread:msgboxoppsite.val()[oppsitechatboxid].unread+1, lastupdate:firebase.database.ServerValue.TIMESTAMP});
        })
        }
        else if(!curmsgid && !oppsitemsgid) // 두회원이 대화한적이 없다면 새방 각각 추가
        {
        firebase.database().ref('/msg').push({message:[{
            createdDate:firebase.database.ServerValue.TIMESTAMP,
            content: message,
            sendfrom: senderid
        }]}).then(msgid=>
        {
            curmsgid = msgid.key;
            firebase.database().ref('/msgbox/' + senderid)
            .push({msgid:msgid.key, chatwith:receiverid, lastupdate:firebase.database.ServerValue.TIMESTAMP,
            unread:0, lastchecked:firebase.database.ServerValue.TIMESTAMP});

            firebase.database().ref('/msgbox/' + receiverid)
            .push({msgid:msgid.key, chatwith:senderid, lastupdate:firebase.database.ServerValue.TIMESTAMP,
            unread:1, lastchecked:firebase.database.ServerValue.TIMESTAMP});
        })
        }
        else if(curmsgid && !oppsitemsgid) // 보내는 사람은 방이 있고 상대는 방이없는경우 상대방 추가 
        {
        firebase.database().ref('/msg/' + curmsgid + '/message').push({
            createdDate:firebase.database.ServerValue.TIMESTAMP,
            content: message,
            sendfrom: senderid
        }).then(_=>
        {
            firebase.database().ref('/msgbox/' + senderid + '/' + curchatboxid)
            .update({lastupdate:firebase.database.ServerValue.TIMESTAMP});

            firebase.database().ref('/msgbox/' + receiverid)
            .push({msgid:curmsgid, chatwith:senderid, lastupdate:firebase.database.ServerValue.TIMESTAMP,
            unread:1, lastchecked:firebase.database.ServerValue.TIMESTAMP});
        })
        }
        else if(!curmsgid && oppsitemsgid) // 보내는 사람은 방이 없고 상대는 기존방 있는경우 상대방으로 귀속
        {
        firebase.database().ref('/msg/' + oppsitemsgid + '/message').push({
            createdDate:firebase.database.ServerValue.TIMESTAMP,
            content: message,
            sendfrom: senderid
        }).then(_=>
        {
            curmsgid = oppsitemsgid;
            firebase.database().ref('/msgbox/' + senderid)
            .push({msgid:oppsitemsgid, chatwith:receiverid, lastupdate:firebase.database.ServerValue.TIMESTAMP,
            unread:0, lastchecked:firebase.database.ServerValue.TIMESTAMP});

            firebase.database().ref('/msgbox/' + receiverid + '/' + oppsitechatboxid)
            .update({unread:msgboxoppsite.val()[oppsitechatboxid].unread+1,lastupdate:firebase.database.ServerValue.TIMESTAMP});
        })
        }
    })
    }).then(_=>{
        var badgecount = 1;
        firebase.database().ref('/msgbox/' + receiverid).once('value').then(msgbox=>{
        msgbox.forEach(element => {
            if(element.val().unread)
            badgecount = badgecount + element.val().unread;
        })
    }).then(_=>{
        firebase.database().ref('/alram/' + receiverid).once('value').then(alram=>{
        alram.forEach(element => {
            if(!element.val().read)
            badgecount++;
        })
       
    }).then(_=>{
        var msgfrom, receivedmsg;
        this.translate.get('s message').subscribe(res=>{
            msgfrom = res;
        });
        this.translate.get('New message arrived').subscribe(res=>{
            receivedmsg = res;
        });
        var contentshort = '';
        if(message.length > 51)
            contentshort = this.strip(message).replace(/\n/g, ' ').substr(0,50) + '...';
        else
            contentshort = this.strip(message).replace(/\n/g, ' ')


        this.sendnotiwithbadge(sendername + msgfrom + '] ' + contentshort, badgecount, receivedmsg, sendername + ': ' + contentshort,
         "msg", senderid, receiverid, curmsgid);
    })
    })
    })
  }

  commentnoti(itemId, loclan, commentstr, writerid, recmt){
    var contentshort = '';
    if(commentstr.length > 51)
      contentshort = this.strip(commentstr).replace(/\n/g, ' ').substr(0,50) + '...';
    else
      contentshort = this.strip(commentstr).replace(/\n/g, ' ')

    var cmtofcmt, receivedcmt, newcmt, newcmttypemsg;
    
    if(!recmt)
    {
        this.translate.get('Comment of my posting').subscribe(res=>{
        cmtofcmt = res;
        });
        this.translate.get('New comment for my posting').subscribe(res=>{
        receivedcmt = res;
        });
        this.translate.get('New comment').subscribe(res=>{
        newcmt = res;
        });
        this.translate.get('New comment on your writing').subscribe(res=>{
        newcmttypemsg = res;
        });
    }
    else
    {
        this.translate.get('Comment of my comment').subscribe(res=>{
        cmtofcmt = res;
        });
        this.translate.get('New comment for my comment').subscribe(res=>{
        receivedcmt = res;
        });
        this.translate.get('New comment').subscribe(res=>{
        newcmt = res;
        });
        this.translate.get('New comment on your writing').subscribe(res=>{
        newcmttypemsg = res;
        });
    }
    
    firebase.database().ref('/alram/' + writerid).push
    ({type:'view', loclan:loclan, id:itemId, message:contentshort, msgtype:newcmttypemsg,
      read:false, createdDate:firebase.database.ServerValue.TIMESTAMP})
    .then(_=>{
        var badgecount = 0;
        firebase.database().ref('/msgbox/' + writerid).once('value').then(msgbox=>{
        msgbox.forEach(element => {
            if(element.val().unread)
            badgecount = badgecount + element.val().unread;
        })
    }).then(_=>{
        firebase.database().ref('/alram/' + writerid).once('value').then(alram=>{
                alram.forEach(element => {
                    if(!element.val().read)
                    badgecount++;
                })
       this.sendnotiwithbadge(cmtofcmt + '] ' + contentshort, badgecount, receivedcmt, 
            newcmt + ' - '+ contentshort, 'view',
            itemId, writerid); 
            })
        })
    })
  }

  servicenoti(itemId, loclan, type, commentstr, writerid, state){
    var contentshort = '';
    if(commentstr.length > 51)
      contentshort = this.strip(commentstr).replace(/\n/g, ' ').substr(0,50) + '...';
    else
      contentshort = this.strip(commentstr).replace(/\n/g, ' ')
    //서비스 상태변경알림 메시지
    /*
    var cmtofcmt, receivedcmt, newcmt, newcmttypemsg;
    this.translate.get('Comment of my comment').subscribe(res=>{
    cmtofcmt = res;
    });
    this.translate.get('New comment for my comment').subscribe(res=>{
    receivedcmt = res;
    });
    this.translate.get('New comment').subscribe(res=>{
    newcmt = res;
    });
    this.translate.get('New comment on your writing').subscribe(res=>{
    newcmttypemsg = res;
    });*/

    var msgtypestr, notititle;//, notimsg;
    if(state == 'new')
    {
        msgtypestr = "새로운 요청이 왔습니다.";
        notititle = "서비스 새요청 알림";
        //notimsg = "새요청 - ";
    }
    else if(state == 'accepted')
    {
        msgtypestr = "요청이 수락되었습니다.";  
        notititle = "서비스 요청 수락알림";
        //notimsg = "수락알림 - ";  
    }
    else if(state == 'changeaccepted')
    {
        msgtypestr = "변경요청이 수락되었습니다.";  
        notititle = "서비스 변경요청 수락알림";
        //notimsg = "수락알림 - ";  
    }
    else if(state == 'changed')
    {
        msgtypestr = "변경요청이 왔습니다.";  
        notititle = "서비스 요청 변경알림";
        //notimsg = "변경알림 - ";  
    }
    else if(state == 'writercancelled')
    {
        msgtypestr = "요청이 취소되었습니다.";  
        notititle = "서비스 취소 알림";
        //notimsg = "취소알림 - ";  
    }
    else if(state == 'providercancelled')
    {
        msgtypestr = "요청이 취소되었습니다.";  
        notititle = "서비스 취소 알림";
        //notimsg = "취소알림 - ";  
    }
    
    firebase.database().ref('/alram/' + writerid).push
    ({type:type, loclan:loclan, id:itemId, message:contentshort, msgtype:msgtypestr,
      read:false, createdDate:firebase.database.ServerValue.TIMESTAMP})
    .then(_=>{
        var badgecount = 0;
        firebase.database().ref('/msgbox/' + writerid).once('value').then(msgbox=>{
        msgbox.forEach(element => {
            if(element.val().unread)
            badgecount = badgecount + element.val().unread;
        })
    }).then(_=>{
        firebase.database().ref('/alram/' + writerid).once('value').then(alram=>{
                alram.forEach(element => {
                    if(!element.val().read)
                    badgecount++;
                })
       this.sendnotiwithbadgealarm('서비스' + '] ' + contentshort, badgecount, notititle, contentshort, type,
            itemId, writerid); 
            })
        })
    })
  }

  servicecmtnoti(itemId, loclan, type, commentstr, writerid, recmt){
    var contentshort = '';
    if(commentstr.length > 51)
      contentshort = this.strip(commentstr).replace(/\n/g, ' ').substr(0,50) + '...';
    else
      contentshort = this.strip(commentstr).replace(/\n/g, ' ')

    var cmtofcmt, receivedcmt, newcmt, newcmttypemsg;
    
    if(!recmt)
    {
        this.translate.get('Comment of my posting').subscribe(res=>{
        cmtofcmt = res;
        });
        this.translate.get('New comment for my posting').subscribe(res=>{
        receivedcmt = res;
        });
        this.translate.get('New comment').subscribe(res=>{
        newcmt = res;
        });
        this.translate.get('New comment on your writing').subscribe(res=>{
        newcmttypemsg = res;
        });
    }
    else
    {
        this.translate.get('Comment of my comment').subscribe(res=>{
        cmtofcmt = res;
        });
        this.translate.get('New comment for my comment').subscribe(res=>{
        receivedcmt = res;
        });
        this.translate.get('New comment').subscribe(res=>{
        newcmt = res;
        });
        this.translate.get('New comment on your writing').subscribe(res=>{
        newcmttypemsg = res;
        });
    }
    
    firebase.database().ref('/alram/' + writerid).push
    ({type:type, loclan:loclan, id:itemId, message:contentshort, msgtype:newcmttypemsg,
      read:false, createdDate:firebase.database.ServerValue.TIMESTAMP})
    .then(_=>{
        var badgecount = 0;
        firebase.database().ref('/msgbox/' + writerid).once('value').then(msgbox=>{
        msgbox.forEach(element => {
            if(element.val().unread)
            badgecount = badgecount + element.val().unread;
        })
    }).then(_=>{
        firebase.database().ref('/alram/' + writerid).once('value').then(alram=>{
                alram.forEach(element => {
                    if(!element.val().read)
                    badgecount++;
                })
       this.sendnotiwithbadgealarm(cmtofcmt + '] ' + contentshort, badgecount, receivedcmt, newcmt + ' - '+ contentshort, type,
            itemId, writerid); 
            })
        })
    })
  }


  pitemsoldnoti(pitemId, type, datatitle, msgbody, writerid){
    var contentshort = '';
    if(msgbody.length > 51)
      contentshort = this.strip(msgbody).replace(/\n/g, ' ').substr(0,50) + '...';
    else
      contentshort = this.strip(msgbody).replace(/\n/g, ' ')
    /*
    var cmtofcmt, receivedcmt, newcmt, newcmttypemsg;
    this.translate.get('Comment of my comment').subscribe(res=>{
    cmtofcmt = res;
    });
    this.translate.get('New comment for my comment').subscribe(res=>{
    receivedcmt = res;
    });
    this.translate.get('New comment').subscribe(res=>{
    newcmt = res;
    });
    this.translate.get('New comment on your writing').subscribe(res=>{
    newcmttypemsg = res;
    });*/
    var badgecount = 0;
    firebase.database().ref('/msgbox/' + writerid).once('value').then(msgbox=>{
    msgbox.forEach(element => {
        if(element.val().unread)
        badgecount = badgecount + element.val().unread;
    })
    }).then(_=>{
        firebase.database().ref('/alram/' + writerid).once('value').then(alram=>{
                alram.forEach(element => {
                    if(!element.val().read)
                    badgecount++;
                })
        this.sendnotiwithbadgealarm(contentshort, badgecount, 
                datatitle,contentshort, type,
                pitemId, writerid); 
        })
    })
  }


  likednoti(itemId, loclan, type, usernickname, writerid)
  {
    var liked;
    this.translate.get('Liked').subscribe(res=>{
        liked = res;
    });
    var yourpostingliked, newcmttypemsg;
    this.translate.get('liked your posting').subscribe(res=>{
        yourpostingliked = res;
    });
    this.translate.get('New liked on your writing').subscribe(res=>{
        newcmttypemsg = res;
    });
    var msgfrom, receivedmsg;
    this.translate.get('s message').subscribe(res=>{
        msgfrom = res;
    });
    this.translate.get('New message arrived').subscribe(res=>{
        receivedmsg = res;
    });

    firebase.database().ref('/alram/' + writerid).push
    ({type:type, loclan:loclan, id:itemId, message:usernickname + yourpostingliked, msgtype:newcmttypemsg,
        read:false, createdDate:firebase.database.ServerValue.TIMESTAMP})
        .then(_=>{
        var badgecount = 0;
        firebase.database().ref('/msgbox/' + writerid).once('value').then(msgbox=>{
        msgbox.forEach(element => {
            if(element.val().unread)
            badgecount = badgecount + element.val().unread;
        })
    }).then(_=>{
        firebase.database().ref('/alram/' + writerid).once('value').then(alram=>{
                alram.forEach(element => {
                    if(!element.val().read)
                    badgecount++;
                })
       this.sendnotiwithbadge(usernickname + yourpostingliked, badgecount, liked, usernickname + yourpostingliked, 'view',
            itemId, writerid); 
            })
        })
    })
  }

  sendnotiwithbadge(body, badge, datatitle, datamsg, datatype, dataid, towho, msgid?)
  {
    if(!msgid)
        msgid = '';
    var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://fcm.googleapis.com/fcm/send';
    var data =
    {
        "notification":{
        "title": datatitle,
        "body": body,  
        "click_action":"FCM_PLUGIN_ACTIVITY",  
        "badge":badge
        },
        "data":{
            "title":datatitle,   
            "message":datamsg,  
            "type":datatype,  
            "id":dataid,
            "badge":badge,
            "msgid":msgid
        },
        "to":"/topics/" + towho,
        "priority":"high" 
    }
    new Promise((resolve, reject)=>{
    let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');
        // console.log('gogo')
        this.http.post(apiUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
            console.log(res);
            resolve(res);
            }, (err) => {
            console.log(err);
            reject(err);
            });
        })
        .then(_=>
        {
            var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://fcm.googleapis.com/fcm/send';
            var data =
            {
                "data":{
                    "badge":badge
                },
                "to":"/topics/" + towho,
                "priority":"high", 
            }
            new Promise((resolve, reject)=>{
            let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');

                this.http.post(apiUrl, JSON.stringify(data), {headers: headers})
                .subscribe(res => {
                    console.log(res)
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
                })
        })
  }


  sendnotiwithbadgealarm(body, badge, datatitle, datamsg, datatype, dataid, towho, msgid?)
  {
    if(!msgid)
        msgid = '';
    var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://fcm.googleapis.com/fcm/send';
    var data =
    {
        "notification":{
        "title": datatitle,
        "body": body,  
        "click_action":"FCM_PLUGIN_ACTIVITY",  
        "badge":badge,
        "sound":"default"
        },
        "data":{
            "title":datatitle,   
            "message":datamsg,  
            "type":datatype,  
            "id":dataid,
            "badge":badge,
            "msgid":msgid?msgid:null
        },
        "to":"/topics/" + towho,
        "priority":"high" 
    }
    new Promise((resolve, reject)=>{
    let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');

        this.http.post(apiUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
            console.log(res);
            resolve(res);
            }, (err) => {
            console.log(err);
            reject(err);
            });
        })
        .then(_=>
        {
            var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://fcm.googleapis.com/fcm/send';
            var data =
            {
                "data":{
                    "badge":badge
                },
                "to":"/topics/" + towho,
                "priority":"high", 
            }
            new Promise((resolve, reject)=>{
            let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');

                this.http.post(apiUrl, JSON.stringify(data), {headers: headers})
                .subscribe(res => {
                    console.log(res)
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
                })
        })
  }

  sendnotionly(body, datatitle, datamsg, datatype, dataid, towho)
  {
    var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://fcm.googleapis.com/fcm/send';
    var data =
    {
        "notification":{
        "title": datatitle,
        "body": body,  
            "click_action":"FCM_PLUGIN_ACTIVITY",  
        },
        "data":{
            "title":datatitle,   
            "message":datamsg,  
            "type":datatype,  
            "id":dataid,
        },
        "to":"/topics/" + towho,
        "priority":"high" 
    }
    new Promise((resolve, reject)=>{
    let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');

        this.http.post(apiUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
            console.log(res);
            resolve(res);
            }, (err) => {
            console.log(err);
            reject(err);
            });
        })
  }

  sendnotionlywithcondition(title, body, datatitle, datamsg, datatype, dataid, condition)
  {
    var apiUrl = 'https://stark-sands-51177.herokuapp.com/https://fcm.googleapis.com/fcm/send';
    var data =
    {
        "notification":{
        "title": title,
        "body": body,  
            "click_action":"FCM_PLUGIN_ACTIVITY",  
        },
        "data":{
            "title":datatitle,   
            "message":datamsg,  
            "type":datatype,  
            "id":dataid,
        },
        "condition": condition,
        "priority":"high" 
    }
    new Promise((resolve, reject)=>{
    let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'key=AAAAZv3z3gE:APA91bHg8qjJ-eilTN-kTY7CliN2UlzOAJ4QpbwKoAlh3w0p6tnb0nODSC8Rs45fKNuM5bh1PjctPSVua_mhFT67NY1h_wEKXExyPCk8lgZQolj_A8cDe3WjAKgdxRRlFQsOZ080BD3N');

        this.http.post(apiUrl, JSON.stringify(data), {headers: headers})
            .subscribe(res => {
            console.log(res);
            resolve(res);
            }, (err) => {
            console.log(err);
            reject(err);
            });
        })
  }

}