import { Component, OnInit, Optional } from '@angular/core';
import {NavController, AlertController,IonRouterOutlet,ModalController,PickerController} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { GlobalVars } from '../../../../providers/globalVars';
import { msgfunc } from '../../../../providers/msgfunc';
import { TranslateService } from '@ngx-translate/core';
import { SelectchannelComponent } from '../../../../component/selectchannel/selectchannel.component';
@Component({
  selector: 'app-pitemdetail',
  templateUrl: './pitemdetail.page.html',
  styleUrls: ['./pitemdetail.page.scss'],
})
export class PitemdetailPage implements OnInit {
  pitemid:any;
  tagVal:any;
  groupVal:any;
  titVal:any;
  images:any;
  imagethumbs:any;
  desc:any;
  initpoint:any;
  limit:any;
  eventend:any;
  editable:boolean = false;
  useritemid:any;
  usedload:boolean = false;
  salelist:any = [];
  itemtype:any;
  monthlist:any = [];

  adv:boolean = false;
  sold:any;
  once:any;
  exist:any = 'notset';
  posterId:any;
  modal;
  id;
  activetab:any;
  selectedchann:any;
  selectedchdesc:any;
  seleltedchimg:any;
  selchannOpt:any;
  selectedchcategory:any;
  selectedchkey:any;
  selectedcat:any;
  newboard:any;
  linkurl:any;
  selectedregion:any;

  adbanner:any = false;
  appitem:any;
  bannermonths:any=[];
  
  advtotalpoint:any=0;
  thisyear:any = new Date().getFullYear();

  salelistcount:any = 0;

  pointratio:any = 1;
  selectedname:any;
  selectedkey:any;
  selectionlist:any=[];
  alarmlist:any=[];
  constructor(private route: ActivatedRoute, private router:Router, @Optional() public outlet: IonRouterOutlet,
    private msgfunc:msgfunc,private translate: TranslateService, private modalCtrl:ModalController,private navCtrl:NavController,
    public gv: GlobalVars, public alertController: AlertController,private pickerController:PickerController) {}

  usedprd = false;
  ngOnInit() {
    if(`${this.id}` != 'undefined')
      this.pitemid = `${this.id}`;
    if(`${this.modal}` != 'undefined')
      this.modal = `${this.modal}`;
    if(this.route.snapshot.paramMap.get('id'))
    this.pitemid = this.route.snapshot.paramMap.get('id');
    if(this.route.snapshot.paramMap.get('useritemid'))
    this.useritemid = this.route.snapshot.paramMap.get('useritemid');

    //willenter에 있던것 oninit로 이동
    
  }
  ionViewDidEnter() {this.getdata();}

  gochkout(){
    let parent = this.router.url.substring(0, this.router.url.indexOf('/pitemdetail'));
    this.navCtrl.navigateForward(parent+'/pitemcheckout/' + this.pitemid)
  }
  close(){
    if(`${this.modal}` != 'undefined') 
    {
    this.modalCtrl.dismiss();
    }
    else
    if(this.outlet && this.outlet.canGoBack())
      { this.outlet.pop()}
    else
      {
      this.navCtrl.back();
      //this.router.navigate(['/tabs/pm']);
      }
  }

  ionViewWillEnter(){
    /*this.getdata();
    //console.log(this.gv.aditem);
    if(this.gv.aditem)
    {
      this.activetab = 'banner_channel';
      this.selchannOpt = this.gv.aditem.options;
      this.selectedchann = this.gv.aditem.name;
      this.selectedchdesc = this.gv.aditem.desc;
      this.selectedchcategory = this.gv.aditem.category;
      this.seleltedchimg = this.gv.aditem.img;
      this.gv.aditem = null;
    }*/
  }
  // ionViewWillLeave(){
  //   this.gv.appitem = '';
  // }

  doInfinite(infiniteScroll) {
    this.salelistcount = this.salelistcount+10;
    infiniteScroll.target.complete()
  }

  checktotal(){
    this.advtotalpoint = 0;
    if(this.activetab == 'banner_region' && this.selectedregion == 'all-aus')
      this.pointratio = 2;
    else if((this.activetab == 'banner_region' && !this.selectedregion) || (this.activetab == 'banner_channel' && !this.selectedchann))
      this.pointratio = 0;
    else this.pointratio = 1;
    this.monthlist.forEach(month=>{
      if(month.selected)
        this.advtotalpoint+=month.point*this.pointratio;
    })
  }
  setchkout(){
    if(this.advtotalpoint)
      this.gv.advtotalpoint = this.advtotalpoint;
    if(this.selectedchkey)
      this.gv.pm_selectedcatkey = this.selectedchkey;
    if(this.selectedcat || this.selectedcat > -1 )
      this.gv.pm_selectedcatname = this.selectedchcategory[this.selectedcat].name;
    if(this.selectedchann)
      this.gv.pm_selectedchname = this.selectedchann;
    if(this.linkurl)
      this.gv.pm_linkurl = this.linkurl;
    if(this.adbanner){
      let pagetarget;
      let loctarget;
      if(this.activetab == 'banner_region')
      {
        pagetarget = 'home';
        loctarget = this.selectedregion;
      }
      else{
        pagetarget = 'post';
        loctarget = this.selectedchkey;
      }
      this.bannermonths = [];
      this.monthlist.forEach(month=>{
        if(month.selected)
          this.bannermonths.push(pagetarget+'@'+month.datestr+'@'+loctarget);
      })
      this.gv.bannertarget = this.bannermonths;
    }
  }
  getdata(){
    if(this.pitemid)
    {
      firebase.database().ref('/pmall/' + this.pitemid).once('value').then(rst=>{
        let data = rst.val();
        if(data)
        {
          this.tagVal = data.tag;
          this.groupVal = data.shortinfo;
          this.titVal = data.title;
          this.images = data.images;
          this.imagethumbs = data.imagethumb;
          this.desc = data.desc,
          this.initpoint = data.point;
          this.limit = data.limit;
          this.sold = data.sold;
          this.once = data.once;
          this.itemtype = data.type;
          this.adv = data.adv?data.adv:false;
          this.adbanner = data.adbanner?data.adbanner:false;
          this.appitem = data.appitem?data.appitem:null;
          this.eventend = data.eventend?new Date(data.eventend):null;
          this.posterId = data.userId;
          this.alarmlist = data.alarmuser?data.alarmuser:[];
          this.selectionlist = data.selection?data.selection:[]
          //console.log(data.selection);
          if(data.userId == this.gv.uid || this.gv.admin)
            this.editable = true;
          
          if(this.adbanner)
          {
            if(this.gv.aditem)
            {
              if(this.gv.aditem.name)
              {
              this.activetab = 'banner_channel';
              this.selchannOpt = this.gv.aditem.options;
              this.selectedchann = this.gv.aditem.name;
              this.selectedchdesc = this.gv.aditem.desc;
              this.selectedchcategory = this.gv.aditem.category;
              this.seleltedchimg = this.gv.aditem.img;
              this.selectedchkey = this.gv.aditem.key;
              this.gv.aditem = null;
              }
              else
              {
              this.activetab = 'banner_region';
              if(this.gv.loc == 'cairns')
                this.selectedregion = 'cairns'
              else if(this.gv.loc == 'brisbane')
                this.selectedregion = 'brisbane'
              else
                this.selectedregion = 'all-aus'
              this.gv.aditem = null;
              }
            }

            this.monthlist = [];
            let now = new Date();
            for(let i = 0; i < 6; i++)
            {
            if(i == 0)
            {
              let firstmonthpoint = 1000;
              let daysinthismonth;
              switch(now.getMonth()){
                case 1: now.getFullYear()%4 == 0? daysinthismonth = 29:daysinthismonth = 28; break;
                case 3: daysinthismonth = 30; break;
                case 5: daysinthismonth = 30; break;
                case 8: daysinthismonth = 30; break;
                case 10: daysinthismonth = 30; break;
                default: daysinthismonth = 31; break;
              }
              let dayremain = daysinthismonth-now.getDate()-2;
              if(dayremain <= 0)
              {
              firstmonthpoint = 0;
              }
              else
                firstmonthpoint = Math.ceil(1000*dayremain/daysinthismonth);
              this.monthlist.push(
              {datestr:now.getFullYear() +
              (((now.getMonth()+1)<10)?'0'+(now.getMonth()+1):''+now.getMonth()), selected:false, point:firstmonthpoint})
            }
            else
            {
              now.setDate(1);
              now.setMonth(now.getMonth()+1);
              let data = {datestr:now.getFullYear() +
              (((now.getMonth()+1)<10)?'0'+(now.getMonth()+1):''+now.getMonth()), selected:false, point:1000};
              this.monthlist.push(data
              )
            }
            }

          }
          if(this.gv.uid && this.once)
          {
            firebase.database().ref('/userData/' + this.gv.uid + '/pointitem').orderByChild('pitemid').
            equalTo(this.pitemid).once('value').then(item=>{
              if(item.val())
                this.exist = item.val().createdDate;
              else
                this.exist = 'ok';
            })
          }
          if(!this.gv.uid && this.once)
            this.exist = 'nouid';
        }
        if(this.editable)
        {
          firebase.database().ref('/pmalldata/' + this.pitemid).orderByChild('createdDate').once('value').then(list=>{
            this.salelist = [];
            list.forEach(l=>{
              let data = l.val();
              this.salelist.unshift({createdDate:data.createdDate,title:data.title,used:data.used,selectedname:(data.selectedname?data.selectedname:null),
                nickname:firebase.database().ref('/userProfile/' + data.uid + '/nickname').once('value').then(rst=>{return rst.val()})})
            })
//            this.salelist = list.val()
          })
        }
      })

      if(this.useritemid)
      firebase.database().ref('/userData/' + this.gv.uid + '/pointitem/' + this.useritemid)
      .once('value').then(rst=>{
        if(rst.val())
          {
          this.usedprd = rst.val().used;
          if(rst.val().selectedname)
            this.selectedname = rst.val().selectedname;
          }
        this.usedload = true;
      })
    }
  }

  async usemyprd(appitem?) {
    let cancelstr,confirmstr;
    this.translate.get('Cancel').subscribe(r=>cancelstr=r);
    this.translate.get('Confirm').subscribe(r=>confirmstr=r);
    let buttondata = [{
        text: cancelstr,
        role: 'cancel',
        cssClass: 'cancel',
        handler: (blah) => {}
        },
        {
          text: confirmstr,
          role: 'confirm',
          cssClass: 'confirm',
          handler: (blah) => {
            firebase.database().ref('/userData/' + this.gv.uid + '/pointitem/' + this.useritemid).update({used:true, selectedname:this.selectedname?this.selectedname:null}).then(_=>{
              firebase.database().ref('/pmalldata/' + this.pitemid + '/' + this.useritemid).update({used:true,selectedname:this.selectedname?this.selectedname:null,createdDate:firebase.database.ServerValue.TIMESTAMP});
              this.usedprd = true;
              firebase.database().ref('/point/' + this.posterId).push(
                {pcode:'itemsale', point:parseInt(this.initpoint?this.initpoint:0), itemid:this.pitemid, uid:this.gv.uid, createdDate:firebase.database.ServerValue.TIMESTAMP}).then(_=>{
                  var lvltxt;
                  this.translate.get('Level'+this.gv.ulvl).subscribe(res=>{
                    lvltxt = res;
                  })
                  this.msgfunc.pitemsoldnoti(this.pitemid, 'pitem', '사용함]' + this.titVal, lvltxt + '님이 ' + this.titVal + ' 상품을 사용하셨습니다.', this.posterId);
                  
                  //이하 추가 알람유저한테 알림
                  if(this.selectionlist && this.selectedkey)
                  {
                    if(this.selectionlist[this.selectedkey].alarmuser)
                    {
                      Object.keys(this.selectionlist[this.selectedkey].alarmuser).forEach(u=>{
                        this.alarmlist.push({uid:this.selectionlist[this.selectedkey].alarmuser[u].uid});
                      })
                    }
                  }
                  if(this.alarmlist)
                  {
                    this.alarmlist.forEach(arm=>{
                      this.msgfunc.pitemsoldnoti(this.pitemid, 'pitem', '사용함]' + this.titVal, lvltxt + '님이 ' + this.titVal + ' 상품을 사용하셨습니다.', arm.uid);
                    })
                  }
                  // 추가 알람유저 여기까지.
                });
            })
          }
        }];
    if(this.selectedname)
      buttondata.unshift({
        text: '다시선택',
        role: 'cancel',
        cssClass: 'cancel',
        handler: (blah) => {this.selectedname = null; this.selectedkey = null; this.usemyprd(appitem?appitem:null);}
      })
    if(!this.selectionlist.length || this.selectedname)
      {
      const alert = await this.alertController.create({
        message: '이 화면을 <br/><span class=\'warning\'>' + (this.selectedname?this.selectedname:this.tagVal) + '</span><br/>담당자에게 보여주세요<i>!</i><br/><b>\'확인\'</b>버튼을 누르시면 <br/><span class=\'warning\'>즉시 사용됩니다</span> ^^',
        cssClass: 'usemyprd_alert',
        buttons: buttondata
      });
      await alert.present();
      }
      else
      {
        let sltoptions = [];
        Object.keys(this.selectionlist).forEach(s=>{
          sltoptions.push({text:this.selectionlist[s].name, value:s});
        })
        const picker = await this.pickerController.create({
        columns: [{name:'name',options:sltoptions}],
        buttons: [
          {
            text: '취소',
            role: 'cancel'
          },
          {
            text: '선택',
            handler: (value) => {
              this.selectedname = value.name.text;
              this.selectedkey = value.name.value;
              this.usemyprd(appitem?appitem:null);
            }
          }
        ]
      });

      await picker.present();
      }
  }
    async chlistmodal() {
    const chselectmodal = await this.modalCtrl.create({
      component: SelectchannelComponent,
      cssClass: 'ph-custom-modal',
      componentProps: <null>{
        // name: name,
        // desc: desc,
        // imgval: imgval
        adbanner: this.adbanner?this.adbanner:null,
        appitem: this.appitem?this.appitem:null
      }
    });
    // .then(modal => {
    //   modal.present();
    // })
    chselectmodal.onDidDismiss().then(rst=>{
      // console.log(rst);
      // console.log(rst.data);
      if(rst.data) {
        this.selectedchkey = rst.data.key;
        this.selchannOpt = rst.data.options;
        this.selectedchann = rst.data.name;
        this.selectedchdesc = rst.data.desc;
        this.selectedchcategory = rst.data.category;
        this.seleltedchimg = rst.data.img;
        //console.log(this.selchannOpt);
      }
      this.checktotal();
    })
    return await chselectmodal.present();
  }
}
