import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { GlobalVars } from '../../../providers/globalVars';
import { AngularFireDatabase,AngularFireList } from '@angular/fire/database';
import { ChviewPage } from '../../../pages/general/chview/chview.page';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.page.html',
  styleUrls: ['./alarm.page.scss'],
})
export class AlarmPage implements OnInit {
  uid:any;
  msgbox:any[] = [];//<any[]>;
  templist:any[]=[];
  msgshowcount:any = 10;
  editlist:any;
  lastdate:any=null;
  loadingdone:boolean = false;
  constructor(public navCtrl: NavController,private modalCtrl: ModalController, private gv: GlobalVars,public afDB:AngularFireDatabase) { }

  ngOnInit() {
    this.uid = this.gv.uid;
    this.getdata();
    this.readall();
  }

  getdata(infi?){
    this.afDB.database.ref('/alram/' + this.uid)
    .orderByChild('createdDate').endAt(this.lastdate?this.lastdate-1:9999999999999)
    .limitToLast(this.msgshowcount).once('value').then(msgs=>{      
      if(msgs.val() && msgs.val()[Object.keys(msgs.val())[0]].createdDate)
        this.lastdate = msgs.val()[Object.keys(msgs.val())[0]].createdDate;
         
        this.templist=[];
         msgs.forEach(msg => {
          var data = msg.val();
          data.key = msg.key;
          data.searchuser = this.afDB.database.ref('/userProfile/'+data.chatwith+'/nickname').once('value')
          this.templist.unshift(data); 
        });
        }).then(_=>{
          this.templist.map(data=>{this.msgbox.push(data)});
          if(infi)
            infi.target.complete();
          this.loadingdone = true;
        });
  }

  readall() {
    this.afDB.database.ref('/alram/' + this.uid).orderByChild('read').equalTo(false).once('value').then(msgs=>{
      if( msgs.val() ) {
        Object.keys(msgs.val()).forEach(key=>{
          this.afDB.database.ref('/alram/' + this.uid + '/' + key).update({read : true});
        });
      }
    });
  }

    showpage(id){
    for(let i = 0; i < this.msgbox.length; i++)
    {
      if(id == this.msgbox[i].id)
        this.msgbox[i].read = true;
    }
    /*
    const modal = await this.modalCtrl.create({
      component: ChviewPage,
      componentProps:<null>{id:id,loclancode:loclan},
      cssClass: 'modalfull'
    });
    /*modal.onDidDismiss().then(_=>{
      this.getdata();
    });*/
    //return await modal.present();
    }
    doRefreshMsg(infiniteScroll){
    //this.msgshowcount = this.msgshowcount+10;
    this.getdata(infiniteScroll);
    //infiniteScroll.target.complete();
    }

    removealarm(key,i){
    this.afDB.database.ref('/alram/' + this.uid + '/' + key).remove();
    this.msgbox.splice(i,1);
    }


}
