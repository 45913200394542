import { Component, OnInit,ViewChildren,QueryList, ViewChild, ElementRef} from '@angular/core';
import { trigger,state,style,animate,transition} from '@angular/animations';
import { ModalController, NavController, PopoverController,ToastController,AlertController, AnimationController } from '@ionic/angular';
import { MorebtnComponent } from '../morebtn/morebtn.component';
import { AngularFireDatabase, AngularFireList , AngularFireObject} from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { ChviewPage } from '../../../pages/general/chview/chview.page';
import { GlobalVars } from '../../../providers/globalVars';
import { AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-mybucket',
  templateUrl: './mybucket.page.html',
  styleUrls: ['./mybucket.page.scss'],
  /*
  animations: [
    trigger('elementState', [
      state('open', style({
        // opacity: 1,
        'max-height': '335px',
        // transform: 'scaleY(1)',
        // 'visibility': 'visible'
      })),
      state('closed', style({
        // opacity: 0,
        'max-height': 0,
        // transform: 'scaleY(0)',
        // 'visibility': 'hidden'
      })),
      transition('open => closed', animate('150ms ease-in')),
      transition('closed => open', animate('150ms ease-in'))
      // transition('* => *', animate('500ms'))
      // transition(':enter', animate('400ms')),
      // transition('* => *', animate('400ms')),
    ])
  ]
  */
})

export class MybucketPage implements OnInit {
  @ViewChildren('ip') folderInput: QueryList<any>;
  // @ViewChildren('folders') qlfolders: QueryList<any>;
  // @ViewChildren('fd') fd: ElementRef;
  @ViewChildren('fd') fd: QueryList<any>;
  folderlist:any[] = [];

  folders: any[] = [];
  default: any[] = [];
  selectedFolder: any = '';
  folderlimit = 5;
  uid:any;
  removebin:any;
  openindex:any = null;
  loadingdone:boolean = false;
  modal;
  showrighticons:boolean = false;
  datenow:any = new Date().getTime();
  animation?: Animation;
  constructor(
    public router: Router,
    public popoverController: PopoverController,
    public toastController: ToastController,
    public alertController: AlertController,
    public afDB: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public modalCtrl: ModalController,public gv: GlobalVars,
    public navCtrl: NavController,private fs:AngularFirestore,
    private animationCtrl: AnimationController
  ) {
    
  }

  ngOnInit() {
    if(`${this.modal}` != 'undefined')
      this.modal = `${this.modal}`;
    if(this.gv.ios)
      {
        setTimeout(_=>{this.showrighticons = true},400)
      }
    else
      this.showrighticons = true;
    this.getdata();
  }




  async morebtnPopover(folder, ev: any, ip) {
    const popover = await this.popoverController.create({
      cssClass: 'popover_morebtn',
      component: MorebtnComponent,
      componentProps: <null>{
        "targetfolder": folder,
        "targetinput": ip
      },
      showBackdrop: false,
      translucent: false,
      event: ev
    });
    popover.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        console.log(dataReturned);
      }
    });
    
    return await popover.present();
  }

  getdata() {
          this.uid = this.gv.uid;
          this.afDB.list('/userData/' + this.uid + '/mybucket/items').snapshotChanges().subscribe(items=>{
            this.default = items.map(item=>{
              this.fs.collection('post').doc(item.payload.val()['itemid']).get().subscribe(rst=>{
                let returndata;
                if(rst.data())
                {
                let data = rst.data();
                returndata = data;
                returndata['title'] = data.title;
                returndata['communityname'] = data.communityname;
                returndata['type'] = data.type;
                returndata['createdDate'] = data.createdDate;
                returndata['replycount'] = data.replycount;
                if(data.imagethumb)
                  returndata['thumburl'] = data.imagethumb[0].url;
                }
              return returndata;
              })
              
            })
            })

            this.afDB.list('/userData/' + this.uid + '/mybucket/folders').snapshotChanges().subscribe(items=>{
              this.folderlist = [];
              for(let i = 0; i < items.length; i++){
                this.folderlist.unshift(items[i]);
              }
            });
            this.afDB.database.ref('/userData/' + this.uid + '/mybucket').update({uncheck:0});
            this.loadingdone = true;
        
        let today = new Date();
        let todaystr = today.getFullYear() + '-' + (today.getMonth()+1>9?today.getMonth()+1:'0' + (today.getMonth()+1)) + '-' + (today.getDate()>9?today.getDate():'0'+today.getDate())
        this.afDB.database.ref('/systemlog/' + (this.uid?this.uid:'unregister') + '/event/' + todaystr + '/mybucket').transaction(view=>{
          return (view || 0) + 1;
        })    
  }

  
  foldernameupdate(folderid,newname){
    this.afDB.database.ref('/userData/' + this.uid + '/mybucket/folders/' + folderid)
    .update({title:newname});
  }

  // foldertoggle(i)
  // {
  //   if(this.openindex == i)
  //     this.openindex = null;
  //   else this.openindex = i;
  // }

  folderToggle(i, toAdding?){
    let _folders = this.fd.toArray();
    // 닫힌 folder offsetHeight = 83
    if( toAdding && _folders[i].nativeElement.offsetHeight > 100  ) {

    } else if( !toAdding && _folders[i].nativeElement.offsetHeight > 100 ) {
      const animation_thisclose = this.animationCtrl.create()
        .addElement(_folders[i].nativeElement)
        .duration(150)
        .iterations(1)
        .fromTo('max-height', '335px', '0');
      animation_thisclose.play();
    } else {
      for(let idx=0; idx < _folders.length; idx++) {
        if( i != idx ) {
          // console.log("_folders[" + idx + "] offsetHeight = " + _folders[idx].nativeElement.offsetHeight);
          if( _folders[idx].nativeElement.offsetHeight > 100 ) {
            const animation_Allclose = this.animationCtrl.create()
              .addElement(_folders[idx].nativeElement)
              .duration(150)
              .iterations(1)
              .fromTo('max-height', '335px', '0');
            animation_Allclose.play();
          }
        }
      }
      setTimeout(_=>{
        const animation_Allopen = this.animationCtrl.create()
        .addElement(_folders[i].nativeElement)
        .duration(150)
        .iterations(1)
        .fromTo('max-height', '0', '335px');
  
        animation_Allopen.play();
      },50);
    }
    
  }

  addItem(folder, idx) {
    if (this.selectedFolder == folder) {
      this.selectedFolder = '';
    } else {
      this.selectedFolder = folder;
      // this.openindex = idx;
      //this.foldertoggle(folder, idx);
      this.folderToggle(idx, true);
    }
  }


  addtofolder(key){
    this.afDB.database.ref('/userData/' + this.uid + '/mybucket/items/' + key).update({fid:this.selectedFolder.$key, updatedDate:firebase.database.ServerValue.TIMESTAMP});
  }
  removeAddedItems(item){
    this.afDB.database.ref('/userData/' + this.uid + '/mybucket/items/' + item.$key).set({itemid:item.itemid, updatedDate:firebase.database.ServerValue.TIMESTAMP});
  }

  addfolder() {
    if (this.folderlist.length < this.folderlimit) {
      let i = 0;
      while (i < 20) {
        let hassamename = false;
        for (let folder of this.folderlist) 
        {
          if (folder.title == '새 폴더 ' + (i==0?'':i))
            hassamename = true;
        }
        if(hassamename)
          i++;
        else
          break;
      }

      this.afDB.database.ref('/userData/' + this.uid + '/mybucket/folders').push({title:'새 폴더 ' + (i==0?'':i)})
      .then(_=>this.editfoldername());

    } else {
      this.folderlimitalert();
    }
  }

  async folderlimitalert() {
    const toast = await this.toastController.create({
      message: '폴더 최대 갯수는 ' + this.folderlimit + '개 입니다.',
      duration: 2000,
      color: "warning"
    });
    toast.present();
  }
  removefolder(fkey, count)
  {
    if(count)
      this.removefolderConfirm(fkey);
    else{
      this.default.forEach(item=>{
        if(item.fid == fkey)
          this.afDB.database.ref('/userData/' + this.uid + '/mybucket/items/' + item.$key).remove();
      })
      this.afDB.database.ref('/userData/' + this.uid + '/mybucket/folders/' + fkey).remove();
    }
  }
  removeItems(itemkey) {
    this.afDB.database.ref('/userData/' + this.uid + '/mybucket/items/' + itemkey).remove();
  }
  async removefolderConfirm(fkey) {
    const alert = await this.alertController.create({
      header: '폴더에 글이 담겨 있어요!',
      message: '삭제 해도 될까요?',
      buttons: [{
        text: '취소',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          // console.log('Confirm Cancel: blah');
        }
      }, {
        text: '삭제',
        handler: () => {
          this.default.forEach(item=>{
            {
            if(item.fid == fkey)
              this.afDB.database.ref('/userData/' + this.uid + '/mybucket/items/' + item.$key).remove();
            }
          })
          this.afDB.database.ref('/userData/' + this.uid + '/mybucket/folders/' + fkey).remove();
        }
      }]
    });

    await alert.present();
  }

  editfoldername(folder?, ip?) {
    if(folder)
    {folder.editmode = !folder.editmode}
    else
    {this.folderlist[this.folderlist.length-1].editmode = true;}
    if( ip ) {
      setTimeout(_=>{
        ip.setFocus();
      },50);
    } else {
      setTimeout(_=>{
        this.folderInput.toArray()[0].setFocus();
      },150);
    }
    
  }
  
  confirmName(folderid, newname): void {
      this.foldernameupdate(folderid, newname);
  }

  async openDetailonModal(itemkey) {
    this.modalCtrl.create({
     component: ChviewPage,
     cssClass: 'modalfull',
     componentProps: <null>{id:itemkey}
   }).then(modal => {
     modal.present();
   });
  }

  openDetail(itemkey)
  {
    if(!this.modal)
      this.navCtrl.navigateForward(['/chview/' + itemkey]);
    else
      this.openDetailonModal(itemkey);
  }

}