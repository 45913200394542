import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ChmainPage } from './chmain.page';
import { CategoryPipeModule } from '../../../pipe/categorypipe.module';
import { MatchPipeModule } from '../../../pipe/matchpipe.module';
import { BlockchkPipeModule } from '../../../pipe/blockchkpipe.module';
import { OrderByPipeModule } from '../../../pipe/orderbypipe.module';
import { TimeAgoPipeModule } from '../../../pipe/time-ago-pipe.module';
import { SearchPipeModule } from '../../../pipe/searchpipe.module';
import { ObjPipeModule } from '../../../pipe/objpipe.module';
import { RemovehtmltagPipeModule } from '../../../pipe/removehtmltag.module';
import { TypeKrNamePipeModule } from '../../../pipe/tkrnamepipe.module';
import { SafeHtmlPipeModule } from '../../../pipe/safehtmlpipe.module';
import { InfomodalComponent } from './infomodal/infomodal.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';

const routes: Routes = [
  {
    path: '',
    component: ChmainPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,OrderByPipeModule,BlockchkPipeModule,TimeAgoPipeModule,ObjPipeModule,RemovehtmltagPipeModule,
    IonicModule,TranslateModule,CategoryPipeModule,MatchPipeModule,SearchPipeModule,TypeKrNamePipeModule,SafeHtmlPipeModule,
    RouterModule.forChild(routes)
  ],
  declarations: [ChmainPage, InfomodalComponent],
  entryComponents: [InfomodalComponent],
  providers:[TranslatePipe,Geolocation] 
})
export class ChmainPageModule {}
